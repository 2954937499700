import React, { useEffect, useState } from 'react'
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { useParams } from 'react-router-dom';
import axios from "../../../common/axios"
import dayjs from 'dayjs';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import 'dayjs/locale/ko';
import _ from 'lodash';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';


const AccidentTypeStackChart = ({ height }) => {
    const [tlist, settlist] = useState(["S-CAR + 안전개선지시서", "S-CAR", "안전개선지시서"]);
    const [tstate, settState] = useState(tlist[0]);
    const [SCARData, setSCARData] = useState([]);
    const [SMTData, setSMTData] = useState([]);
    const [scarType, setScarType] = React.useState("S-CAR + 안전개선지시서");
    const [value, setValue] = useState([dayjs().subtract(5, "year"), dayjs()]);
    const [project, setProject] = React.useState("전체");
    const [projectList, setProjectList] = useState([]);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    const [chartData, setChartData] = useState([]);


    const handletChange = (event) => {
        settState(event.target.value);
    };

    useEffect(() => {

        const start = value.map(v => new Date(dayjs(v).format("MM-DD-YYYY")))[0];
        const end = value.map(v => new Date(dayjs(v).format("MM-DD-YYYY")))[1];

        const fetchData = async () => {
            const res = await axios.get(`/scardata?start=${start}&end=${end}`)
            const res2 = await axios.get(`/smtscardata?start=${start}&end=${end}`)
            if (res.data.success && res2.data.success) {
                const data = res.data.data.filter(c => c.SCARIssueDO && c.SCARRiskDPK && c.status !== "Terminated")
                    .map((v) => ({ ...v, type: v.SCARRiskDPK }))
                const data2 = res2.data.data.filter(c => c.SMTSCARIssueDO && c.SMTRiskDPK && c.status !== "Terminated")
                    .map((v) => ({ ...v, type: v.SMTRiskDPK }))

                setProjectList(["전체", ...new Set([...res.data.data, ...res2.data.data].map(v => v.project_projectname))]);

                if (tstate === "S-CAR") {
                    setChartData(data)
                } else if (tstate === "안전개선지시서") {
                    setChartData(data2)
                } else {
                    setChartData([...data, ...data2])
                }
            }
        }
        fetchData()
    }, [value, tstate])


    useEffect(() => {

        const chartFilteredData = _(project === "전체" ? chartData : chartData.filter(v => v.project_projectname === project))
            .groupBy("type")
            .map((objs, key) => ({
                key: key,
                counts: objs.length
            }))
            .value();


        var root = am5.Root.new("chartdiv999");
        root.setThemes([
            am5themes_Animated.new(root)
        ]);



        // Create chart
        // https://www.amcharts.com/docs/v5/charts/xy-chart/
        var chart = root.container.children.push(am5xy.XYChart.new(root, {
            panX: false,
            panY: false,
            wheelX: "panX",
            wheelY: "zoomX",
            paddingLeft: 0,
            layout: root.verticalLayout
        }));

        var cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));



        // Create axes
        // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
        var xRenderer = am5xy.AxisRendererX.new(root, {
            // cellStartLocation: 0.1,
            // cellEndLocation: 0.9,
            minorGridEnabled: true,
            minGridDistance: 20
        });

        var xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
            categoryField: "key",
            renderer: xRenderer,
            tooltip: am5.Tooltip.new(root, {})
        }));

        xRenderer.grid.template.setAll({
            location: 1
        })

        xAxis.data.setAll(chartFilteredData);

        var yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
            min: 0,
            renderer: am5xy.AxisRendererY.new(root, {
                strokeOpacity: 0
            })
        }));

        yAxis.get("renderer").labels.template.setAll({
            strokeOpacity: 1,
            visible: false
        });

        xAxis.get("renderer").labels.template.setAll({
            oversizedBehavior: "wrap",
            visible: true,
            maxWidth: 150,
            strokeOpacity: 1,
            textAlign: "center",
            fontSize: "0.8rem",

        });


        yAxis.get("renderer").grid.template.set("forceHidden", true);
        xAxis.get("renderer").grid.template.set("forceHidden", true);
        // Add legend
        // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
        var legend = chart.children.push(am5.Legend.new(root, {
            centerX: am5.p50,
            x: am5.p50,
            // height: "50px",
            // width: am5.percent(100),
            layout: am5.GridLayout.new(root, {
                maxColumns: 4,
                fixedWidthGrid: false,
            }),
            height: am5.percent(15),
            verticalScrollbar: am5.Scrollbar.new(root, {
                orientation: "vertical",
                visible: "false",
                width: "2px",
                opacity: 0
            })
        }));


        // Add series
        // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
        var series = chart.series.push(am5xy.ColumnSeries.new(root, {
            name: "유형별",
            // stacked: true,
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: "counts",
            categoryXField: "key"
        }));

        series.columns.template.setAll({
            tooltipText: "{categoryX}: {valueY}",
            tooltipY: am5.percent(10),
        });
        series.data.setAll(chartFilteredData);

        // Make stuff animate on load
        // https://www.amcharts.com/docs/v5/concepts/animations/
        series.appear();

        series.bullets.push(function () {
            return am5.Bullet.new(root, {
                sprite: am5.Label.new(root, {
                    text: "{valueY}",
                    fill: "black",
                    fontSize: "10px",
                    centerY: am5.p100,
                    centerX: am5.p50,
                    populateText: true
                })
            });
        });

        // legend.data.push(series);
        // legend.markers.template.setAll({
        //     width: 10,
        //     height: 10
        // });
        // legend.labels.template.setAll({ fontSize: 10 });
        // legend.valueLabels.template.set("forceHidden", true);


        chart.appear(1000, 100);

        return () => {
            root && root.dispose();
        }
    }, [chartData, project])

    const handleProjectChange = (event) => {
        setProject(event.target.value)
    }


    return (
        <>



            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', padding: '6px', marginTop: -56 }}>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ko">
                    <DemoContainer components={['SingleInputDateRangeField']}>
                        <DateRangePicker
                            slots={{ field: SingleInputDateRangeField }}
                            name="allowedRange"
                            label=""
                            value={value}
                            disableFuture
                            onChange={(newValue) => setValue(newValue)}
                            className='daterange2'
                            style={{ marginRight: '40px', fontSize: '0.8rem', height: '30px', fontWeight: 600 }}
                        />
                    </DemoContainer>
                </LocalizationProvider>
                <DropDownList
                    style={{
                        width: windowWidth <= 1900 ? '120px' : "150px",
                        fontSize: '0.7rem',
                        height: 30,
                        fontWeight: 600,
                        marginRight: '10px'  // Add margin between the dropdowns
                    }}
                    data={projectList}
                    value={project}
                    onChange={handleProjectChange}
                />
                <DropDownList
                    style={{
                        width: windowWidth <= 1900 ? '120px' : "170px",
                        fontSize: '0.7rem',
                        height: 30,
                        fontWeight: 600,
                    }}
                    data={tlist}
                    value={tstate}
                    onChange={handletChange}
                />
            </div>

            <div id="chartdiv999" style={{ height: height, marginTop: 0 }}></div>
        </>
    )
}

export default AccidentTypeStackChart
