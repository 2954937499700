import React, { useMemo } from "react";
import { useRecoilValue } from "recoil";
import { QualityPQIData } from "../../../../atom/qualityState";
import QualityGaugeChart from "../../qualityRate1/qualityGaugeChart";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";

const SelfAssessment = ({ height, targetProject, targetVendor }) => {
  const data = useRecoilValue(QualityPQIData);

  const filterPqiData = useMemo(() => {
    const result = {
      ncr: [],
      inspection_log: [],
      factory_inspection: [],
      material_inspection: [],
      inspection_request: [],
      completion_inspection: [],
    };

    if (targetProject.value !== "all" && targetVendor?.label) {
      const find_target_data = data.find(
        (c) => c.projectnumber === targetProject.value
      );

      if (!find_target_data) {
        return {
          ...result,
        };
      }

      const { data: target_data } = find_target_data;

      return {
        ncr: (target_data?.ncr ?? []).filter(
          (c) => c.uveVendorNameTB50 === targetVendor.label
        ),
        inspection_log: (target_data?.inspection_log ?? []).filter(
          (c) => c.uveVendorNameTB50 === targetVendor.label
        ),
        factory_inspection: (target_data?.factory_inspection ?? []).filter(
          (c) => c.uveVendorNameTB50 === targetVendor.label
        ),
        material_inspection: (target_data?.material_inspection ?? []).filter(
          (c) => c.uveVendorNameTB50 === targetVendor.label
        ),
        inspection_request: (target_data?.inspection_request ?? []).filter(
          (c) => c.uveVendorNameTB50 === targetVendor.label
        ),
        completion_inspection: (
          target_data?.completion_inspection ?? []
        ).filter((c) => c.uveVendorNameTB50 === targetVendor.label),
      };
    }

    return result;
  }, [targetProject, targetVendor, data]);

  const pqi_value = useMemo(() => {
    const {
      ncr,
      inspection_log,
      factory_inspection,
      material_inspection,
      inspection_request,
      completion_inspection,
    } = filterPqiData;

    const inspection_actual = inspection_log.filter(
      (c) => c.PreviousAction === "시공사 검사결과 입력 요청"
    ).length;

    const inspection_approval = inspection_log.filter(
      (c) => c.PreviousAction === "시공사 합격"
    ).length;

    const inspection_point2 = returnZeroIfInvalid(
      (inspection_approval / inspection_actual) * 100
    );

    const inspection_point = adjust_inspection_approval_point(
      returnZeroIfInvalid(inspection_point2)
    );

    const ncr_accrual_average_actual = [
      ...factory_inspection,
      ...material_inspection,
      ...inspection_request,
      ...completion_inspection,
    ].length;

    const ncr_accrual_average_value = returnZeroIfInvalid(
      ncr.length / ncr_accrual_average_actual
    );

    const ncr_accrual_average_point = pointRange(
      "ncr_accrual_average",
      25 - 25 * ncr_accrual_average_value
    );

    const action_on_time_count = ncr.filter(
      (c) => c.NCRActionDuration <= 5
    ).length;

    const action_on_time_acutal = ncr.length;

    const action_on_time_point = adjust_action_on_time_point(
      returnZeroIfInvalid((action_on_time_count / action_on_time_acutal) * 100)
    );

    const ncr_count_point = adjust_ncr_count_point(
      ncr.filter((c) => c.NCRCategoryTxt === "검사 외").length
    );

    return (
      inspection_point +
      ncr_accrual_average_point +
      action_on_time_point +
      ncr_count_point
    );
  }, [filterPqiData]);

  return (
    <>
      <GridLayout
        rows={[
          {
            height: "100%",
          },
        ]}
        cols={[
          {
            width: "100%",
          },
        ]}
        gap={{
          rows: 10,
          cols: 0,
        }}
        style={{ position: "relative" }}
      >
        <GridLayoutItem row={1} col={1} style={{ position: "relative" }}>
          {targetVendor ? (
            <QualityGaugeChart
              id={"id_detail"}
              height={height}
              pqi_value={pqi_value}
            />
          ) : (
            <div>협력업체를 선택하여 주시기 바랍니다.</div>
          )}
        </GridLayoutItem>
      </GridLayout>
    </>
  );
};

export default SelfAssessment;

const pointRange = (name, value) => {
  if (value < 0) {
    return 0;
  }

  if (name === "inspection_approval") {
    if (value >= 40) {
      return 40;
    }
  }

  if (name === "ncr_accrual_average") {
    if (value >= 25) {
      return 25;
    }
  }

  if (name === "action_on_time") {
    if (value >= 30) {
      return 30;
    }
  }

  if (name === "ncr_count") {
    if (value >= 5) {
      return 5;
    }
  }

  return value;
};

const returnZeroIfInvalid = (value) => {
  if (value === Infinity || isNaN(value) || value === false) {
    return 0;
  }
  return value;
};

const adjust_inspection_approval_point = (value) => {
  if (value >= 99) {
    return 40;
  }

  if (value < 70) {
    return 10;
  }

  return value - 60;
};

const adjust_action_on_time_point = (value) => {
  if (value < 90) {
    return 0;
  }

  return 30 - (100 - value);
};

const adjust_ncr_count_point = (value) => {
  const value2 = 5 - value;

  if (value2 <= -5) {
    return -5;
  }

  return value2;
};
