import React, { useState, useEffect, useMemo } from "react";
import axios from "../../../common/axios";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import MuiToggleButton from "@mui/material/ToggleButton";
import MuiToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import dayjs from "dayjs";
import _ from "lodash";
import "./targetStatus.css";

const TargetStatus = ({ height }) => {
  const BoxHeight = 2 * (height / 6.5) - 70;

  const [projects, setProjects] = useState(["전체"]);
  const [selectedValue, setSelectedValue] = useState("전체");

  const [costData, setCostData] = useState({ upper_data: [], line_data: [] });

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res2 = await axios.get(`/getprojectlist`);

        if (res2.data.success) {
          setProjects(["전체", ...res2.data.data.map((v) => v.projectname)]);
        }
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res2 = await axios
          .get(`/getOverallTargetStatus?year=${Number(dayjs().format("YYYY"))}`)
          .then((data) => data.data.data)
          .catch({ upper_data: [], line_data: [] });

        setCostData(res2);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, []);

  const table_data = useMemo(() => {
    const { upper_data, line_data } = costData;

    let copy_upper_data = [...upper_data];
    let copy_line_data = [...line_data];

    if (selectedValue !== "전체") {
      copy_upper_data = copy_upper_data.filter(
        (c) => c.ProjectNM === selectedValue
      );

      copy_line_data = copy_line_data.filter(
        (c) => c.ProjectNM === selectedValue
      );
    }
    const total_plan_outlook = _.sumBy(copy_upper_data, "BudgetETC");
    const total_increment_variance_outlook = _.sumBy(
      copy_upper_data,
      "EstimatedProfit"
    );
    const total_actual_outlook =
      total_plan_outlook - total_increment_variance_outlook;

    const total_plan_cost = _.sumBy(copy_line_data, "PlanCostIncrement");
    const total_actual_cost = _.sumBy(copy_line_data, "ActualCostIncrement");
    const total_increment_variance_cost = _.sumBy(
      copy_line_data,
      "IncrementVariance"
    );

    const this_month_data = copy_line_data.filter(
      (c) => Number(c.CostMonthIPD) <= Number(dayjs().format("M"))
    );

    const this_month_plan_cost_plan = _.sumBy(
      this_month_data,
      "PlanFCostIncrement"
    );
    const this_month_actual_cost_plan = _.sumBy(
      this_month_data,
      "ActualFCostIncrement"
    );
    const this_month_increment_variance_cost_plan =
      this_month_plan_cost_plan - this_month_actual_cost_plan;

    const this_month_plan_cost_act = _.sumBy(
      this_month_data,
      "PlanCostIncrement"
    );
    const this_month_actual_cost_act = _.sumBy(
      this_month_data,
      "ActualCostIncrement"
    );
    const this_month_increment_variance_cost_act = _.sumBy(
      this_month_data,
      "IncrementVariance"
    );

    const this_year = dayjs().format("YY");
    const this_month = dayjs().format("M");
    return [
      {
        title: "매출",
        detail: [
          {
            title: `${this_year}년 계획`,
            value: total_plan_cost,
            unit: "억원",
          },
          { title: "現 전망", value: total_plan_outlook, unit: "억원" },
          {
            title: `~${this_month}월 계획`,
            value: this_month_plan_cost_plan,
            unit: "억원",
          },
          {
            title: `~${this_month}월 실적`,
            value: this_month_plan_cost_act,
            unit: "억원",
          },
        ],
      },
      {
        title: "원가",
        detail: [
          {
            title: `${this_year}년 계획`,
            value: total_actual_cost,
            unit: "억원",
          },
          { title: "現 전망", value: total_actual_outlook, unit: "억원" },
          {
            title: `~${this_month}월 계획`,
            value: this_month_actual_cost_plan,
            unit: "억원",
          },
          {
            title: `~${this_month}월 실적`,
            value: this_month_actual_cost_act,
            unit: "억원",
          },
        ],
      },
      {
        title: "손익",
        detail: [
          {
            title: `${this_year}년 계획`,
            value: total_increment_variance_cost,
            unit: "억원",
          },
          {
            title: "現 전망",
            value: total_increment_variance_outlook,
            unit: "억원",
          },
          {
            title: `~${this_month}월 계획`,
            value: this_month_increment_variance_cost_plan,
            unit: "억원",
          },
          {
            title: `~${this_month}월 실적`,
            value: this_month_increment_variance_cost_act,
            unit: "억원",
          },
        ],
      },
    ];
  }, [costData, selectedValue]);

  return (
    <GridLayout
      style={{ height: BoxHeight }}
      rows={[
        {
          height: 30,
        },
        {
          height: (BoxHeight - 30) / 4,
        },
        {
          height: (BoxHeight - 30) / 4,
        },
        {
          height: (BoxHeight - 30) / 4,
        },
        {
          height: (BoxHeight - 30) / 4,
        },
      ]}
      cols={[
        {
          width: "calc((100% - 20px) / 3)",
        },
        {
          width: "calc((100% - 20px) / 3)",
        },
        {
          width: "calc((100% - 20px) / 3)",
        },
      ]}
      gap={{
        rows: 0,
        cols: 10,
      }}
    >
      <FormControl
        size="small"
        sx={{
          m: 1,
          minWidth: 120,
          position: "absolute", // Use relative positioning
          top: 6,
          right: 10,
          float: "right", // Align to the right
        }}
      >
        <InputLabel id="demo-select-small-label">프로젝트</InputLabel>
        <Select
          labelId="demo-select-small-label"
          id="demo-select-small"
          value={selectedValue}
          label="프로젝트"
          onChange={handleChange}sx={{ fontSize: "0.8rem" }}
        >
          {projects &&
            projects.map((v, index) => (
              <MenuItem key={index} value={v}sx={{ fontSize: "0.8rem" }}>
                {v}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <GridLayoutItem
        row={1}
        rowSpan={5}
        col={1}
        className="target-status-border-box"
      />
      <GridLayoutItem
        row={1}
        rowSpan={5}
        col={2}
        className="target-status-border-box"
      />
      <GridLayoutItem
        row={1}
        rowSpan={5}
        col={3}
        className="target-status-border-box"
      />
      {table_data.map((c, i) => {
        const { title, detail } = c;

        return (
          <React.Fragment key={i}>
            <GridLayoutItem row={1} col={i + 1}>
              <div className="target-status-contents-title">{title}</div>
            </GridLayoutItem>
            {detail.map((c2, i2) => {
              const { title: detail_title, value, unit } = c2;

              return (
                <GridLayoutItem row={i2 + 2} col={i + 1} className="target-status-contents-detail-body">
                  <ContentsDetail
                    title={detail_title}
                    value={value}
                    unit={unit}
                  />
                </GridLayoutItem>
              );
            })}
          </React.Fragment>
        );
      })}
    </GridLayout>
  );
};

export default TargetStatus;

const ContentsDetail = ({ title, value, unit }) => {
  return (
    <div className="target-status-contents-detail-box">
      <div className="target-status-contents-detail-title">{title}</div>
      <div className="target-status-contents-detail-value">
        <div>
          {Number(returnZeroIfInvalid(value).toFixed(0)).toLocaleString()}
        </div>
        <div>{unit}</div>
      </div>
    </div>
  );
};

const returnZeroIfInvalid = (value) => {
  if (value === Infinity || isNaN(value) || value === false) {
    return 0;
  }
  return value;
};

const ToggleButton2 = styled(MuiToggleButton)(() => ({
  "&.Mui-selected, &.Mui-selected:hover": {
    color: "white",
    backgroundColor: "#FF6F61",
  },
  "&.MuiToggleButton-root": {
    fontSize: "0.6rem",
    fontFamily: "Pretendard-R",
    padding: 0,
    width: 50,
    borderRadius: "5px 5px 5px 5px!important",
    border: "1px solid rgba(0, 0, 0, 0.12)!important",
  },
}));

const ToggleButtonGroup2 = styled(MuiToggleButtonGroup)(() => ({
  "&.MuiToggleButtonGroup-root": {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
  },
}));

const theme = createTheme({
  palette: {
    text: {
      primary: "#00ff00",
    },
  },
});
