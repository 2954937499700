import React, { useState, useEffect, useMemo } from 'react';
import { TileLayout } from "@progress/kendo-react-layout";
import { DashboardLayout } from '../../../layout/Layout';
import useBreakpoint from "use-breakpoint";
import useWindowDimensions from '../../common/useWindowDimensions';
import axios from "../../../common/axios";
import { useParams } from 'react-router-dom';
import ManPower from '../manpower/manPeriod';
import Equip from '../manpower/equipPeriod';

const BREAKPOINTS = { mobile: 0, tablet: 768, desktop: 1280, };

const CostPage = () => {
    const [fixblock, setfixblock] = useState(false);
    const { height, width } = useWindowDimensions();
    const [grid, setgrid] = useState({
        mobile:
          [
    
            {
              col: 1,
              colSpan: 16,
              rowSpan: 6,
              status: true,
            },
            {
              col: 1,
              colSpan: 16,
              status: true,
              rowSpan: 2,
            },
            {
              col: 1,
              colSpan: 16,
              status: true,
              rowSpan: 2,
            },
            {
              col: 1,
              colSpan: 16,
              status: true,
              rowSpan: 2,
            },
    
            {
              col: 1,
              colSpan: 16,
              rowSpan: 2,
              status: true,
            },
    
    
            {
              col: 1,
              colSpan: 16,
              rowSpan: 2,
              status: true,
            },
    
    
            {
              col: 1,
              colSpan: 16,
              rowSpan: 2,
              status: true,
            },
            {
              col: 1,
              colSpan: 16,
              rowSpan: 2,
              status: true,
            }
    
    
    
          ]
        ,
        tablet:
          [
    
            {
              col: 1,
              colSpan: 4,
              rowSpan: 6,
              status: true,
            },
            {
              col: 5,
              colSpan: 12,
              status: true,
              rowSpan: 2,
            },
    
    
    
            {
              col: 5,
              colSpan: 4,
              rowSpan: 2,
              status: true,
            },
            {
              col: 9,
              colSpan: 4,
              status: true,
              rowSpan: 2,
            },
            {
              col: 13,
              colSpan: 4,
              status: true,
              rowSpan: 2,
            },
            {
              col: 5,
              colSpan: 3,
              status: true,
              rowSpan: 2,
            },
            {
              col: 8,
              colSpan: 3,
              status: true,
              rowSpan: 2,
            },
    
            {
              col: 11,
              colSpan: 6,
              rowSpan: 2,
              status: true,
            }
    
          ]
        ,
        desktop:
          [
    
            {
              col: 1,
              colSpan: 4,
              rowSpan: 6,
              status: true,
            },
            {
              col: 5,
              colSpan: 12,
              status: true,
              rowSpan: 2,
            },
    
    
    
            {
              col: 5,
              colSpan: 4,
              rowSpan: 2,
              status: true,
            },
            {
              col: 9,
              colSpan: 4,
              status: true,
              rowSpan: 2,
            },
            {
              col: 13,
              colSpan: 4,
              status: true,
              rowSpan: 2,
            },
            {
              col: 5,
              colSpan: 3,
              status: true,
              rowSpan: 2,
            },
            {
              col: 8,
              colSpan: 3,
              status: true,
              rowSpan: 2,
            },
    
            {
              col: 11,
              colSpan: 6,
              rowSpan: 2,
              status: true,
            }
    
    
          ]
      });
    const { breakpoint } = useBreakpoint(BREAKPOINTS, 'desktop')

    const togglefix = () => {
      setfixblock(!fixblock);
    };
    const defaultvalue = new Date()
    const [positiondata, setPositionData] = useState(grid['desktop']);


    const [datevalue, setValue] = useState(defaultvalue);


    useEffect(() => {
      setPositionData(grid[breakpoint])
    }, [breakpoint]);

    const tiles = [{
      defaultPosition: {
        col: 1,
        colSpan: 4,
        rowSpan: 6,
      },
        header: "Project 개요",
        body: "",
        resizable: fixblock,
        reorderable: fixblock,
    
      }, {
        defaultPosition: {
          col: 5,
          colSpan: 12,
          rowSpan: 2,
        },
        header: "Project Milestone",
        body: "",
        resizable: fixblock,
        reorderable: fixblock,
        className: 'milestone'
      },
    
      {
        defaultPosition: {
          col: 5,
          colSpan: 4,
          rowSpan: 2,
        },
        header: "공정현황",
        body: "",
        resizable: fixblock,
        reorderable: fixblock,
      },
      {
        defaultPosition: {
          col: 9,
          colSpan: 4,
          rowSpan: 2
        },
        header:"원가현황",
        body: "",
        resizable: fixblock,
        reorderable: fixblock,
    
      },
      {
        defaultPosition: {
          colSpan: 4,
          rowSpan: 2,
        },
        header: "안전활동",
        body: "",
        resizable: fixblock,
        reorderable: fixblock,
    
      },
    
      {
        defaultPosition: {
          col: 5,
          colSpan: 3,
          rowSpan: 2,
        },
        header: "인원",
        body: "",
        resizable: fixblock,
        reorderable: fixblock,
      },
      {
        defaultPosition: {
          col: 8,
          colSpan: 3,
          rowSpan: 2,
        },
        header: "장비",
        body: "",
        resizable: fixblock,
        reorderable: fixblock,
      },
    
      {
        defaultPosition: {
          col: 11,
          colSpan: 6,
          rowSpan: 2,
        },
        header: "품질활동",
        body: "",
        resizable: fixblock,
        reorderable: fixblock,
      }
      ];
      const handleReposition = (e) => {
        setPositionData(e.value);
      };

    return(
        <DashboardLayout>
           
            <TileLayout
            columns={16}
            rowHeight={height/6.5}
            positions={positiondata}
            gap={{
                rows: 10,
                columns: 10
            }}
            items={tiles}
            autoFlow="row dense" 
            onReposition={handleReposition}
            // style={{marginTop:20}}
            /> 
        </DashboardLayout>
    );

};

export default CostPage
