import React, { useEffect, useState } from 'react'
import _ from 'lodash';
import {
    GridLayout,
    GridLayoutItem,
} from "@progress/kendo-react-layout";
import AccidentReportTable from './wpTable';
import AccidentReportLineChart from './wpLineChart';
import AccidentReportPieChart from './wpPieChart';
import axios from "../../../common/axios"
import dayjs from "dayjs"
import AccidentReportTable2 from './wpTable2';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import 'dayjs/locale/ko';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Circles } from 'react-loader-spinner';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';


const AccidentReport = ({ height }) => {
    const [DRIdata, setDRIdata] = useState([]);
    const [tableData, setTableData] = useState([{
        grade: 'A등급',
        dangerCount: 0,
        fireCount: 0
    },
    {
        grade: 'B등급',
        dangerCount: 0,
        fireCount: 0
    },
    {
        grade: 'C, D등급',
        dangerCount: 0,
        fireCount: 0
    }]);
    const [lineData, setLineData] = useState([]);
    const [projectList, setProjectList] = useState([]);
    const [pieData, setPieData] = useState([
        {
            id: 'A등급',
            value: 0,
        },
        {
            id: 'B등급',
            value: 0,
        },
        {
            id: 'C, D등급',
            value: 0,
        },
    ]);
    const [value, setValue] = useState([dayjs().subtract(3, "month"), dayjs()]);
    const [project, setProject] = React.useState("전체");
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const start = value.map(v => new Date(dayjs(v).format("MM-DD-YYYY")))[0];
                const end = value.map(v => new Date(dayjs(v).format("MM-DD-YYYY")))[1];
                const res = await axios.get(`/dridata?start=${start}&end=${end}`);
                if (res.data.success) {
                    setDRIdata(project === "전체" ? res.data.data : res.data.data.filter(v => v.project_projectname === project));
                    setProjectList(["전체", ...new Set(res.data.data.map(v => v.project_projectname))]);
                } else {
                    console.log("에러");
                }
            } catch (error) {
                console.error('데이터 가져오기 중 오류 발생:', error);
            }
            setLoading(false);
        };
        fetchData();
    }, [value, project]);


    useEffect(() => {

        const lineData = DRIdata.filter(c => c.DRIWorkDate && c._bp_lineitems)
            .map((v) => [...v._bp_lineitems.filter(o => o.uuu_tab_id === "작업등급")]).flat()
            .filter(v => !v.DRIWorkRB.includes("일반"))

        var result1 =
            _(lineData.map((v) => ({ ...v, HZGradePD: v.HZGradePD === "C" || v.HZGradePD === "D" ? "C, D" : v.HZGradePD })))
                .groupBy('HZGradePD')
                .map((objs, key) => ({
                    grade: `${key}등급`,
                    dangerCount: objs.filter(v=>v.DRIWorkRB ==="위험작업").length ,
                    fireCount:objs.filter(v=>v.DRIWorkRB ==="화기작업").length ,

                }))
                .value();

        // 결과 출력
        setTableData(result1)

        // 라인 차트 데이터
        let result = {};

        DRIdata.filter(c => c.DRIWorkDate).forEach(item => {
            let formattedDate = item.DRIWorkDate ? dayjs(item.DRIWorkDate).format('YYYY-MM-DD') : 'N/A';

            if (!result[formattedDate]) {
                result[formattedDate] = {
                    date: formattedDate,
                    "A등급": 0,
                    "B등급": 0,
                    "C, D등급": 0
                };
            }

            if (item._bp_lineitems) {
                item._bp_lineitems.forEach(lineItem => {
                    if (lineItem.HZGradePD) {
                        const grades = lineItem.HZGradePD.split(' ');

                        grades.forEach(grade => {
                            const gradeKey = grade === 'A' ? 'A등급' : (grade === 'B' ? 'B등급' : 'C, D등급');
                            result[formattedDate][gradeKey] += 1;
                        });
                    }
                });
            }
        });

        let data = _.sortBy(Object.values(result), "date");

        setLineData(data)


        var result2 =
            _(lineData.map((v) => ({ ...v, HZGradePD: v.HZGradePD === "C" || v.HZGradePD === "D" ? "C, D" : v.HZGradePD })))
                .groupBy('HZGradePD')
                .map((objs, key) => ({
                    'id': `${key}등급`,
                    'value': objs.length,

                }))
                .value();


        // 결과 출력
        // console.log(result2);

        console.log(result2)

        setPieData(result2);

    }, [DRIdata]);



    const handleProjectChange = (event) => {
        setProject(event.target.value)
    }

    if (loading) {
        return <div className="loadingContainer">
            <Circles ariaLabel="loading" color="#00BFFF" height={50} width={50} />
        </div>;
    }

    return (
        <>

            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', padding: '6px', marginTop: -56 }}>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ko">
                    <DemoContainer components={['SingleInputDateRangeField']}>
                        <DateRangePicker
                            slots={{ field: SingleInputDateRangeField }}
                            name="allowedRange"
                            label=""
                            value={value}
                            disableFuture
                            onChange={(newValue) => setValue(newValue)}
                            className='daterange2'
                            style={{ marginRight: '40px', fontSize: '0.8rem', height: '30px', fontWeight: 600 }}
                        />
                    </DemoContainer>
                </LocalizationProvider>

                <DropDownList
                    style={{
                        width: "180px",
                        fontSize: '0.7rem',
                        height: '30px',
                        fontWeight: 600
                    }}
                    data={projectList}
                    value={project}
                    onChange={handleProjectChange}
                />
            </div>


            <GridLayout style={{ height: height }}
                rows={[
                    {
                        height: '100%',
                    },
                ]}
                cols={[
                    {
                        width: '25%',
                    },
                    {
                        width: '50%',
                    },
                    {
                        width: '25%',
                    },

                ]}
                gap={{
                    rows: 10,
                    cols: 0,
                }}>

                <GridLayoutItem row={1} col={1} style={{ position: 'relative' }}>
                    {/* <AccidentReportTable tableData={tableData} /> */}
                    <b style={{ fontSize: "12px" }}>유형별 작업수</b>
                    <AccidentReportTable2 tableData={tableData} height={height - 15} />
                </GridLayoutItem>
                <GridLayoutItem row={1} col={2} style={{ position: 'relative' }}>
                    <b style={{ fontSize: "12px" }}>작업 현황 그래프</b>
                    <AccidentReportLineChart height={height - 15} lineData={lineData} id="accidentReport" />
                </GridLayoutItem>
                <GridLayoutItem row={1} col={3} style={{ position: 'relative' }}>
                    <b style={{ fontSize: "12px" }}>등급별 작업수</b>
                    <AccidentReportPieChart pieData={pieData} />
                </GridLayoutItem>
            </GridLayout>


        </>
    )
}

export default AccidentReport
