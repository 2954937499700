import React from "react";
import { useRecoilState } from "recoil";
import { QualityChartStatus } from "../../../atom/qualityState";

const PageChangeBtn = () => {
  const [qualityChartStatus, setQualityChartStatus] =
    useRecoilState(QualityChartStatus);

  const handle_page_value = (value) => {
    setQualityChartStatus(value);
  };

  return (
    <div className="quality-page-change-btn-box">
      <div
        className={`quality-page-change-btn ${
          qualityChartStatus === "main" ? "active" : ""
        }`}
        onClick={() => handle_page_value("main")}
      >
        메인
      </div>
      <div
        className={`quality-page-change-btn ${
          qualityChartStatus === "detail" ? "active" : ""
        }`}
        onClick={() => handle_page_value("detail")}
      >
        상세
      </div>
    </div>
  );
};

export default PageChangeBtn;
