import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';
import {
  GridLayout,
  GridLayoutItem,
} from "@progress/kendo-react-layout";
import MuiToggleButton from '@mui/material/ToggleButton';
import MuiToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import dayjs from 'dayjs';
import PieChart from '../manpower/manPowerPieChart';


const ToggleButton2 = styled(MuiToggleButton)(() => ({
  '&.Mui-selected, &.Mui-selected:hover': {
    color: 'white',
    backgroundColor: '#FF6F61',
  },
  '&.MuiToggleButton-root': {
    fontSize: '0.6rem',
    fontFamily: 'Pretendard-R',
    padding: 0,
    width: 60,
    borderRadius: '5px 5px 5px 5px!important',
    border: '1px solid rgba(0, 0, 0, 0.12)!important'

  }
}));

const ToggleButtonGroup2 = styled(MuiToggleButtonGroup)(() => ({
  '&.MuiToggleButtonGroup-root': {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1)
    }
  }
}));


const theme = createTheme({
  palette: {
    text: {
      primary: '#00ff00',
    },
  },
});

const Equip = ({ drdata, height }) => {

  const location = useLocation();
  const pathname = location.pathname.split('/').pop();


  const [equipdata, setEquipData] = useState([])

  const [dateValue2, setDateValue2] = useState(new Date(dayjs().add(-1, 'days')));


  const [selectedValue2, setSelectedValue2] = React.useState("종류");

  const handleChange2 = (event, selectedValue2) => {
    if (selectedValue2 !== null) {
      setSelectedValue2(selectedValue2);
    }
  };

  useEffect(() => {
    if (drdata.length > 0) {
      const equipline = drdata
        .filter(v => v._bp_lineitems !== undefined)
        .reduce((c, v) => c.concat(v._bp_lineitems.map(o => ({ ...o, ReportingDO: v.ReportingDO, PALContractorBPK: v.PALContractorBPK }))), [])
        .filter(v => v.uuu_tab_id === '투입장비');

      const latestDate2 = _.sortBy(equipline, dr => new Date(dr.ReportingDO))[equipline.length - 1].ReportingDO;
      setDateValue2(new Date(latestDate2));
    }
  }, [drdata]);

  const compareDate2 = new Date(dateValue2);
  compareDate2.setHours(0, 0, 0, 0);

  const filterdData2 = _.filter(equipdata, (data) => {
    const dataDate = new Date(data.date);
    dataDate.setHours(0, 0, 0, 0);
    return dataDate.getTime() === compareDate2.getTime();
  });

  const equipPieData = _.sortBy(filterdData2.map((c) => ({
    date: c.date.toLocaleDateString('ko-KR', { year: '2-digit', month: '2-digit', day: '2-digit' }),
    id: c.xtype,
    value: c.count
  })).filter(item => item.id !== "_date_date" && item.id !== "count"), "date").flat();

  const totalSum2 = _.sumBy(equipPieData, "value");


  useEffect(() => {

    const linedata = drdata.filter(v=>v._bp_lineitems !== undefined).reduce((c, v) => c.concat(v._bp_lineitems.map(o => Object.assign(o, { ReportingDO: v.ReportingDO,PALContractorBPK:v.PALContractorBPK }))), [])
    .filter(v=>v.uuu_tab_id ==='투입장비').map((v)=>({...v, uniq1:v.DREquipBPK+"//"+v.ReportingDO, uniq2:v.PALContractorBPK+"//"+v.ReportingDO}))

    const chartdata = 
    selectedValue2 ==='종류'?
    _(linedata)
      .groupBy("uniq1")
      .map((objs, key) => ({
        date: new Date(key.split('//')[1].slice(0, 10)),
        xtype: key.split("//")[0].split(' | ')[1],
        count: _.sumBy(objs, "DRResAssignDayDA"),
      }))
      .value() :

      _(linedata)
      .groupBy("uniq2")
      .map((objs, key) => ({
        date: new Date(key.split('//')[1].slice(0, 10)),
        xtype: key.split("//")[0],
        count: _.sumBy(objs, "DRResAssignDayDA"),
      }))
      .value()

    const man_result = _.sortBy(
      chartdata.map(function (obj) {
        var index = obj.xtype;
        obj[index] = obj.count;
        return obj;
      }),
      "date"
    );
    

   
    setEquipData(man_result)

  }, [drdata, selectedValue2]);

  return (
    <>
      <GridLayout style={{ height: height }}
        rows={[
          {
            height: '100%',
          },
        ]}
        cols={[
          {
            width: '100%',
          }
        ]}
        gap={{
          rows: 10,
          cols: 0,
        }}>

        <GridLayoutItem row={1} col={1} style={{ position: 'relative' }}>
          {pathname === "overview" ? (
            <>
              <div style={{ position: 'absolute', transform: 'translateY(-50%)', zIndex: 100 }} className='manRB'>
                <ThemeProvider theme={theme}>
                  <ToggleButtonGroup2 size="small" onChange={handleChange2} value={selectedValue2} exclusive aria-label="baseunit">
                    <ToggleButton2 value="종류" aria-label='종류' >
                      종류
                    </ToggleButton2>
                    <ToggleButton2 value="업체별" aria-label="업체별">
                      업체별
                    </ToggleButton2>
                  </ToggleButtonGroup2>
                </ThemeProvider>
              </div>
            </>) : (
            <>
              <div style={{ position: 'absolute', transform: 'translateY(-50%)', zIndex: 100 }} className='manRB'>
                <ThemeProvider theme={theme}>
                  <ToggleButtonGroup2 size="small" onChange={handleChange2} value={selectedValue2} exclusive aria-label="baseunit">
                    <ToggleButton2 value="종류" aria-label='종류' >
                      종류
                    </ToggleButton2>
                    <ToggleButton2 value="업체별" aria-label="업체별">
                      업체별
                    </ToggleButton2>
                  </ToggleButtonGroup2>
                </ThemeProvider>
              </div>
            </>
          )}
          <PieChart data={equipPieData} text="대" totalSum={totalSum2} dateValue={dateValue2} setDateValue={setDateValue2} margin={0} fontSize={14}/>
        </GridLayoutItem>

      </GridLayout>

    </>
  );

}

export default Equip
