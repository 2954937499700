import React, { useEffect } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import styled from "styled-components";
import dayjs from "dayjs";

const SCurveChart = ({
  height,
  data,
  chart_id,
  columnItems,
  lineItems,
  lineLabel,
  category,
  actual_mark_use,
}) => {
  useEffect(() => {
    let root = am5.Root.new(chart_id);
    root.setThemes([am5themes_Animated.new(root)]);

    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelX: "panX",
        wheelY: "zoomX",
        layout: root.verticalLayout,
        maxTooltipDistance: -1,
      })
    );

    chart.zoomOutButton.set("forceHidden", true);

    let tooltip = am5.Tooltip.new(root, {});

    chart.plotContainer.set("tooltipPosition", "pointer");
    chart.plotContainer.set("tooltipText", "a");
    chart.plotContainer.set("tooltip", tooltip);

    tooltip.label.adapters.add("text", function (text, target) {
      text = "";
      let i = 0;
      chart.series.each(function (series) {
        let tooltipDataItem = series.get("tooltipDataItem");

        if (tooltipDataItem) {
          if (i === 0) {
            text += "[bold]" + tooltipDataItem.get("categoryX") + "\n";
          }

          if (i !== 0) {
            text += "\n";
          }

          text +=
            "[" +
            series.get("stroke") +
            "]●[/] [width:100px]" +
            series.get("name") +
            ":[/] " +
            Number(
              (tooltipDataItem.get("valueY") ?? 0).toFixed(2)
            ).toLocaleString() +
            " " ;
            // (unit ? unit : series.get("name").includes("시간") ? "h" : "%");
        }
        i++;
      });
      return text;
    });

    tooltip.get("background").setAll({
      stroke: am5.color(0x000000),
      strokeOpacity: 0.8,
      fill: am5.color(0xffffff),
      fillOpacity: 0.8,
    });
    // Add cursor
    // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
    let cursor = chart.set(
      "cursor",
      am5xy.XYCursor.new(root, {
        behavior: "zoomX",
      })
    );

    cursor.lineX.setAll({
      stroke: "#525252",
      strokeWidth: 1,
      strokeDasharray: [5, 5],
    });

    cursor.lineY.setAll({
      stroke: "#525252",
      strokeWidth: 1,
      strokeDasharray: [5, 5],
    });

    // cursor.lineY.set("visible", false);

    // let data = [...data];

    let legend = chart.children.push(
      am5.Legend.new(root, {
        centerX: am5.p50,
        x: am5.p50,
      })
    );

    legend.markers.template.setAll({
      width: 10,
      height: 10,
    });

    legend.labels.template.setAll({
      fontSize: "0.7rem",
      fontWeight: "400",
      width: 90,
    //   fill: "#fff",
    });

    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    let xRenderer = am5xy.AxisRendererX.new(root, {
      minGridDistance: 30,
      cellStartLocation: 0.1,
      cellEndLocation: 0.9,
      strokeOpacity: 0,
      // strokeWidth: 1,
      // stroke: "rgba(224, 224, 224, 0.5)",
    });

    let xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: category,
        renderer: xRenderer,
      })
    );

    xRenderer.grid.template.setAll({
      location: 1,
      strokeOpacity: 0,
    });

    // xRenderer.labels.template.setAll({
    //   paddingTop: 10,
    //   scale: 0.7,
    //   fill: "#fff",
    // });

    xAxis.data.setAll(data);

    xAxis.get("renderer").labels.template.setAll({
      // oversizedBehavior: "fit",
      oversizedBehavior: "wrap",
      textAlign: "center",
      fill: "#525252",
      maxWidth: 50,
      fontSize: "0.8rem",
      minScale:0.6
    });


    let yRenderer = am5xy.AxisRendererY.new(root, {
      strokeOpacity: 1,
      strokeWidth: 1,
      stroke: "rgba(224, 224, 224, 0.5)",
      grid: {
        disabled: true,
      },
    });

    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        strictMinMaxSelection: true,
        // extraMin: 0.03,
        extraMax: 0.06,
        renderer: yRenderer,
      })
    );

    // if (unit !== "") {
    //   let yAxislabel = am5.Label.new(root, {
    //     rotation: 0,
    //     text: unit,
    //     y: am5.p50,
    //     centerX: am5.p50,
    //     fill: "#FFF",
    //     //x: am5.p0,
    //     //centerY: am5.p0
    //   });

    //   yAxis.children.unshift(yAxislabel);
    // }

    yRenderer.grid.template.setAll({
      strokeOpacity: 0,
    });

    yRenderer.labels.template.setAll({
      paddingRight: 10,
      scale: 0.7,
      fill: "#fff",
    });

    yAxis.set("numberFormat", "#,###");

    let paretoAxisRenderer = am5xy.AxisRendererY.new(root, {
      opposite: true,
      strokeOpacity: 1,
      strokeWidth: 1,
      stroke: "rgba(224, 224, 224, 0.5)",
    });

    let paretoAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        strictMinMaxSelection: true,
        extraMax: 0.06,

        renderer: paretoAxisRenderer,
        visible: lineLabel,
      })
    );

    paretoAxisRenderer.grid.template.setAll({
      strokeOpacity: 0,
    });

    paretoAxisRenderer.labels.template.setAll({
      paddingLeft: 10,
      scale: 0.7,
      fill: "#fff",
      text: "asd",
    });

    chart.yAxes.autoDispose = false;
    // paretoAxisRenderer.grid.template.set("forceHidden", true);
    // paretoAxis.set("numberFormat", "#");

    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/

    const makeSeries = (name, fieldName, color) => {
      let series = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: name,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: fieldName,
          categoryXField: category,
          fill: color,
          stroke: color,
          legendLabelText: name + " ({valueY.formatNumber('#,###.##')})",
          legendRangeLabelText: name,
        })
      );

      series.bullets.push(function () {
        return am5.Bullet.new(root, {
          locationY: 0.5,
          sprite: am5.Label.new(root, {
            text: "{valueY}",
            fill: root.interfaceColors.get("alternativeText"),
            centerY: am5.p50,
            centerX: am5.p50,
            populateText: true
          })
        });
      });
      
      series.data.setAll(data);

      // Make stuff animate on load
      // https://www.amcharts.com/docs/v5/concepts/animations/
      series.appear();

      series.bullets.push(function () {
        return am5.Bullet.new(root, {
          locationY: 0,
          sprite: am5.Label.new(root, {
            // text: "{valueY}",
            fill: root.interfaceColors.get("alternativeText"),
            centerY: 0,
            centerX: am5.p50,
            populateText: true,
          }),
        });
      });

      legend.data.push(series);
    };

    const makeParetoSeries = (name, fieldName, color) => {
      let paretoSeries = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: name,
          xAxis: xAxis,
          yAxis: paretoAxis,
          valueYField: fieldName,
          categoryXField: category,
          stroke: color,
          fill: color,
          // maskBullets: false,
          // tooltip: am5.Tooltip.new(root, {
          //   labelText: "[bold]{name}[/]\n{valueX.formatDate()}: {valueY}",
          // }),
          legendLabelText: name + " ({valueY.formatNumber('#,###.##')})",
          legendRangeLabelText: name,
        })
      );

      paretoSeries.strokes.template.setAll({
        strokeWidth: 2,
      });

      paretoSeries.bullets.push(function () {
        return am5.Bullet.new(root, {
          locationY: 1,
          sprite: am5.Circle.new(root, {
            radius: 3,
            stroke: paretoSeries.get("stroke"),
            strokeWidth: 2,
            fill: am5.color(0xffffff),
          }),
        });
      });

      paretoSeries.data.setAll(data);

      legend.data.push(paretoSeries);
    };

    columnItems.forEach((com) => {
      const { name, field_name, color } = com;

      makeSeries(name, field_name, color);
    });

    lineItems.forEach((com) => {
      const { name, field_name, color } = com;

      makeParetoSeries(name, field_name, color);
    });


    chart.appear(1000, 100);

    return () => {
      root && root.dispose();
    };
  }, [
    chart_id,
    data,
    columnItems,
    lineItems,
    lineLabel,
    category,
    actual_mark_use,
  ]);

  return (
    <SCurveChartDiv>
      <div id={chart_id} chartHeight={height - 10} style={{height:height}}/>
    </SCurveChartDiv>
  );
};

export default SCurveChart;

const SCurveChartDiv = styled.div``;

const AmChartDiv = styled.div`
  width: 100%;
  height: ${(props) => {
    return props.chartHeight + "px";
  }};
  z-index: 999;
  margin: auto;
`;

// {
//   months: dayjs().add(3, "months").format("YY년 MM월"),
//   plan: 0,
//   act: 0,
//   cum_plan: 0,
//   cum_act: 0,
// },
