import React from "react";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";

const InspectionApprovalCard3 = ({ height, type, data }) => {
  const { title, result, result_unit } = data;

  return (
    <GridLayout
      rows={[
        {
          height: height * 0.2,
        },
        {
          height: height * 0.2,
        },
        {
          height: height * 0.2,
        },
        {
          height: height * 0.2,
        },
        {
          height: height * 0.2,
        },
      ]}
      cols={[
        {
          width: "100%",
        },
      ]}
      gap={{
        rows: 0,
        cols: 0,
      }}
      style={{ position: "relative" }}
      className="inspection-approval-card-body"
    >
      <GridLayoutItem
        row={1}
        col={1}
        className="inspection-approval-card card-top bold-text"
      >
        {title}
      </GridLayoutItem>
      <GridLayoutItem
        row={2}
        col={1}
        rowSpan={4}
        className="inspection-approval-card card-mid bold-text"
        style={{ borderBottom: "none" }}
      >
        <div style={{ display: "flex", flexDirection: "row", gap: "5px" }}>
          <div style={{ fontSize: "32px" }}>{result}</div>
          <div>{result_unit}</div>
        </div>
      </GridLayoutItem>
    </GridLayout>
  );
};

export default InspectionApprovalCard3;
