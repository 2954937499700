import { useNavigate } from 'react-router-dom';
import axios from './axios';

export const useLogout = () => {
  const navigate = useNavigate();

  const logout = async () => {
    try {
      await axios.get(`/dashlogout`);
      window.location.replace('/dashlogin')

    } catch (error) {
      console.error("Error during logout:", error);
    }
  };

  return logout;
};
