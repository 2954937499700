import React, { useEffect, useState, useLayoutEffect } from 'react';
import _ from 'lodash';
import {
  GridLayout, GridLayoutItem
} from "@progress/kendo-react-layout";
import axios from "../../../common/axios";
import * as am5 from "@amcharts/amcharts5";
import * as am5radar from "@amcharts/amcharts5/radar";
import * as am5xy from "@amcharts/amcharts5/xy";
import { useParams } from 'react-router-dom';

import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

const SafetyTile = ({ height }) => {
  const [ltif, setData] = useState([]);
  const { project_code } = useParams();


  const CHART_ID = "gauge"

  useEffect(() => {
    const fetchData = async () => {
      const res = await axios.get(`/getltirData`)
      const res2 = await axios.get(`/getprojectlist`)
      if (res.data.success) {
        setData(res.data.data.find(v => v.project === res2.data.data.find(vf=>vf.projectnumber === project_code).projectname))
      }
    };
    fetchData();

  }, [project_code]);




  useLayoutEffect(() => {

    if (!ltif || Object.keys(ltif).length === 0) return; // Ensure ltif is not empty

    let root = am5.Root.new(CHART_ID);
    root.setThemes([
      am5themes_Animated.new(root)
    ]);


    var chart = root.container.children.push(
      am5radar.RadarChart.new(root, {
        panX: false,
        panY: false,
        startAngle: 180,
        endAngle: 360
      })
    );

    var axisRenderer = am5radar.AxisRendererCircular.new(root, {
      innerRadius: -10,
      strokeOpacity: 1,
      strokeWidth: 15,
      strokeGradient: am5.LinearGradient.new(root, {
        rotation: 0,
        stops: [
          { color: am5.color(0x19d228) },
          { color: am5.color(0xf4fb16) },
          { color: am5.color(0xf6d32b) },
          { color: am5.color(0xfb7116) }
        ]
      })
    });

    var xAxis = chart.xAxes.push(
      am5xy.ValueAxis.new(root, {
        maxDeviation: 0,
        min: 0,
        max: 3,
        strictMinMax: true,
        renderer: axisRenderer
      })
    );

    var axisDataItem1 = xAxis.makeDataItem({});
    axisDataItem1.set("value", 0);

    var clockHand1 = am5radar.ClockHand.new(root, {
      pinRadius: 0,
      radius: am5.percent(98),
      bottomWidth: 10
    });

    clockHand1.pin.setAll({
      fill: "#F8443E"
    });

    clockHand1.hand.setAll({
      fill: "#F8443E"
    });



    var bullet = axisDataItem1.set("bullet", am5xy.AxisBullet.new(root, {
      sprite: clockHand1
    }));



    // Add clock hand
    var axisDataItem2 = xAxis.makeDataItem({});

    var clockHand2 = am5radar.ClockHand.new(root, {
      pinRadius: 0,
      radius: am5.percent(80),
      bottomWidth: 10
    });

    clockHand2.pin.setAll({
      fill: "#FFB83F"
    });

    clockHand2.hand.setAll({
      fill: "#FFB83F"
    });

    var bullet2 = axisDataItem2.set("bullet", am5xy.AxisBullet.new(root, {
      sprite: clockHand2
    }));

    xAxis.createAxisRange(axisDataItem1);
    xAxis.createAxisRange(axisDataItem2);

    axisDataItem1.get("grid").set("visible", false);

    axisDataItem1.animate({
      key: "value",
    //   to: 1,
    to: ltif?.LTIF_actual ?? 0, 
    duration: 1000,
      duration: 1000,
      easing: am5.ease.out(am5.ease.cubic)
    });

    axisDataItem2.animate({
      key: "value",
    //   to: 2,
    to: ltif?.LTIF_aTRCF_actualtual ?? 0, 
      duration: 1000,
      easing: am5.ease.out(am5.ease.cubic)
    });

    chart.appear(1000, 100);

    return () => {
      root && root.dispose();
    };

  }, [ltif]);

  console.log(ltif)


  const renderMetrics = () => {
    if (!ltif || Object.keys(ltif).length === 0) {
      return       <GridLayoutItem row={1} col={1} style={{ height: 50 }}>
      <div className="metric">No data available</div></GridLayoutItem>;
    }

    return (
      <GridLayoutItem row={1} col={1} style={{ height: 50 }}>
        <div className="metric">
          <span style={{ color: "#F8443E" }}>LTIF</span>
          <span className="value">
            {ltif.ltir_plan || 0} / 
            <span className={`indicator ${(ltif.trcf_plan - ltif.TRCF_actual || 0) < 0 ? 'positive' : 'negative'}`}>
              {ltif.LTIF_actual || 0}
            </span>
          </span>
          <span style={{ fontSize: 'smaller' }} className={`indicator ${(ltif.ltir_plan - ltif.LTIF_actual || 0) < 0 ? 'positive' : 'negative'}`}>
            ({Math.abs(Number((ltif.ltir_plan - ltif.LTIF_actual).toFixed(2))  || 0)} {(Number((ltif.ltir_plan - ltif.LTIF_actual).toFixed(2))  || 0) < 0 ? '↓' : '↑'})
          </span>
        </div>
        <div className="metric">
          <span style={{ color: "#FFB83F" }}>TRCF</span>
          <span className="value">
            {ltif.trcf_plan || 0} / 
            <span className={`indicator ${(ltif.trcf_plan - ltif.TRCF_actual || 0) < 0 ? 'positive' : 'negative'}`}>
              {ltif.TRCF_actual || 0}
            </span>
          </span>
          <span style={{ fontSize: 'smaller' }} className={`indicator ${(ltif.trcf_plan - ltif.TRCF_actual || 0) < 0 ? 'positive' : 'negative'}`}>
            ({Math.abs(Number((ltif.trcf_plan - ltif.TRCF_actual).toFixed(2)) || 0)} {(Number((ltif.trcf_plan - ltif.TRCF_actual).toFixed(2)) || 0) < 0 ? '↓' : '↑'})
          </span>
        </div>
      </GridLayoutItem>
    );
  }

  return (
    <GridLayout style={{ height: 2 * (height / 6.5) - 70 }}
      rows={[
        { height: "10%" },
        { height: "90%" },
      ]}
      cols={[
        { width: '100%' },
      ]}
      gap={{ rows: 10, cols: 0 }}>
      {renderMetrics()}
      <div id="gauge" style={{ height: 0.9 * (2 * (height / 6.5) - 70), }}></div>
    </GridLayout>
  );
}

export default SafetyTile