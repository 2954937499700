import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ResponsivePie } from "@nivo/pie";
import DayPicker from "./DayPicker";
import dayjs from "dayjs";

const PieChart = ({ data, totalSum, text, dateValue, setDateValue, color ,margin, fontSize}) => {
  const filteredData = data.filter((item) => item.value !== 0);
  const [toggleValue, setToggleValue] = useState(true);
  const date_picker = useRef(null);

  useEffect(() => {
    const select_body = window.document.querySelector("#root");

    const handleExternalClick = (e) => {
      if (
        date_picker?.current?.contains(e.target) ||
        !select_body.contains(e.target)
      ) {
        return;
      }

      return setToggleValue(true);
    };

    document.addEventListener("click", handleExternalClick);

    return () => {
      document.removeEventListener("click", handleExternalClick);
    };
  }, [date_picker]);
  return (
    <>
      <ResponsivePie
        data={filteredData.map((com) => ({
            ...com,
            label: "Label with\nline breaks",
        }))}
        margin={{ top: 50, right: margin, bottom: 50, left: margin }}
        innerRadius={0.75}
        padAngle={2}
        activeOuterRadiusOffset={8}
        // colors={["#5CAAAC", "#1F3864", "#A0A0A0", "#EB7A25"]}
        colors={color}
        // colors={{ scheme: 'category10' }}
        borderWidth={1}
        borderColor={{
            from: "color",
            modifiers: [["darker", "0.1"]],
        }}
        arcLinkLabel={(e) => {
            if (e.value !== 0) {
            return (
                <React.Fragment>
                <tspan x="0" dy="-0.6em">
                    {e.id}
                </tspan>
                <tspan x="0" dy="1.2em">
                    {e.value}
                    {text}
                </tspan>
                </React.Fragment>
            );
            }

            return "";
        }}
        arcLinkLabelsTextOffset={10}
        //   arcLinkLabelsTextColor="#fff"
        // arcLinkLabelsDiagonalLength={20}
        arcLinkLabelsThickness={1}
        //   arcLinkLabelsColor="#ffffff"
        arcLabelsSkipAngle={50}
        // arcLinkLabelsColor={{ from: "color" }}
        enableArcLabels={false}
        arcLabelsRadiusOffset={0}
        arcLinkLabelsDiagonalLength={5}
        // arcLabelsSkipAngle={10}
        arcLabelsTextColor={{
            from: "color",
            modifiers: [["darker", "2.3"]],
        }}
        layers={[
            // 가운데 텍스트 레이어 추가
            "arcs",
            "arcLabels",
            "arcLinkLabels",
            (props) => (
            <CustomCenterLabelLayer {...props} totalSum={totalSum} text={text} fontSize={fontSize}/>
            ),
            "legends",
        ]}
      />
      <StatusTitle ref={date_picker}>
        {toggleValue ? (
            <CardMonthsDiv
            onClick={() => setToggleValue((prev) => !prev)}
            style={{ cursor: "pointer" }}
            >
            {dayjs(dateValue).format("YYYY년 MM월 DD일 기준")}
            </CardMonthsDiv>
        ) : (
            <DayPicker
            value={dateValue}
            setValue={setDateValue}
            data={data}
            />
        )}
        </StatusTitle>
    </>
   
  );
};

export default PieChart;

// 커스텀 가운데 텍스트 레이어 컴포넌트
const CustomCenterLabelLayer = ({ centerX, centerY, totalSum, text, fontSize }) => {
  return (
    <text
      x={centerX}
      y={centerY}
      textAnchor="middle"
      dominantBaseline="central"
      style={{
        fontSize: fontSize,
        fontWeight: "bold",
        // fill: "#fff",
      }}
    >
      {totalSum}
      {text}
    </text>
  );
};

const StatusTitle = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: relative;
  top: -30px;
`;


const CardMonthsDiv = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  font-size: 0.8rem;
  font-weight: bold;
  /* color: rgb(92, 92, 92); */
`;
