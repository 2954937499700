import React, { useState, useEffect, useMemo } from 'react';
import { TileLayout } from "@progress/kendo-react-layout";
import { DashboardLayout } from '../../../../layout/Layout';
import useBreakpoint from "use-breakpoint";
import useWindowDimensions from '../../../common/useWindowDimensions';
import VProgressChart from './vPgressTileSCurve';
import VBulletPage from './vProgressBullet';
import axios from "../../../../common/axios";
import { useParams } from 'react-router-dom';
import VProgressChartNew from './vPgressTileSCurveNew';

const BREAKPOINTS = { mobile: 0, tablet: 768, desktop: 1280, };


const VProgressPage = () => {
    const [fixblock, setfixblock] = useState(false);
    const { height, width } = useWindowDimensions();
    const [newWBS, setNewWBS] = useState(false);
    const { project_code } = useParams();

    useEffect(() => {
      const fetchData = async () => {
        try {
            const res = await axios.get(`/newwbs?project=${project_code}`);
            setNewWBS(res.data.newWBS)

        } catch (error) {
            console.error(`데이터 가져오기 중 오류 발생:`, error);
            return []; // 오류 발생 시 빈 배열 반환
        }
    };
    fetchData();
      }, [ project_code]);

    const [grid, setgrid] = useState({
      mobile: [
        {
          col: 1,
          colSpan: 16,
          rowSpan: 6,
          status: true,
        },
        {
          col: 1,
          colSpan: 16,
          rowSpan: 6,
          status: true,
        },     
      ]
      ,
      tablet: [
        {
          col: 1,
          colSpan: 16,
          rowSpan: 5,
          status: true,
        },
        {
          col: 1,
          colSpan: 16,
          rowSpan: 7,
          status: true,}
      ],
      desktop: [
        {
          col: 1,
          colSpan: 16,
          rowSpan: 2,
          status: true,
        },
        {
          col: 1,
          colSpan: 16,
          rowSpan: 4,
          status: true,
        },
      ]
    });
    const { breakpoint } = useBreakpoint(BREAKPOINTS, 'desktop')

    const [positiondata, setPositionData] = useState(grid['desktop']);

    useEffect(() => {
      setPositionData(grid[breakpoint])
    }, [breakpoint]);

    const tiles = [{
      header: (<div style={{ position: 'relative'}} >업체별 공정률</div>),
      body:<VBulletPage  height={height} width={width}/>,
      status: true,
      resizable: false,
      reorderable: false,
    },
    {
      header: (<div style={{ position: 'relative'}} >{newWBS?"업체 공정 상세 현황":"업체별 공정률 S-Curve"}</div>),
      body: newWBS?<VProgressChartNew height={height} width={width}/> :<VProgressChart height={height} width={width}/>,
      status: true,
      resizable: false,
      reorderable: false,
    },
  
    ];
      const handleReposition = (e) => {
        setPositionData(e.value);
      };


    return(
        <DashboardLayout>
            <TileLayout
            className='projectpage'

            columns={16}
            rowHeight={height/6.5}
            positions={positiondata}
            gap={{
                rows: 10,
                columns: 10
            }}
            items={tiles}
            autoFlow="row dense" 
            onReposition={handleReposition}
            // style={{marginTop:20}}
            /> 
        </DashboardLayout>
    );

};

export default VProgressPage;

