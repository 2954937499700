import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import 'hammerjs';
import axios from "../../../../common/axios";
import SCurveChart from '../../../common/SCurveChart';
import { GridLayout, GridLayoutItem } from '@progress/kendo-react-layout';
import moment from 'moment';
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import {
    IntlProvider,
    LocalizationProvider,
    loadMessages,
} from "@progress/kendo-react-intl";
import loadCldrData from "../../../common/loadCldrData";
import krMessages from "../../../common/kr.json";
import { Circles } from 'react-loader-spinner';
import { ToggleButton, ToggleButtonGroup } from '../../../common/CustomToggleButton';
import useWindowDimensions from '../../../common/useWindowDimensions';
import dayjs from 'dayjs';
import { Select, MenuItem ,FormControl} from '@mui/material';

const tooltiptitle = `${dayjs().add(-1, "days").format('YYYY년 MM월 DD일')}까지 일할 계산된 계획값입니다.`
const tooltiptitle2 = `실적은 최신 실적(주로 전일 작업일보)을 한시간마다 집계합니다.`

loadCldrData();
loadMessages(krMessages, "ko-KR");

const VProgressChartO = () => {
    const { height, width } = useWindowDimensions();
    const [selectedProject, setSelectedProject] = useState('');
    const [progressData, setProgressData] = useState([]);
    const [projectList, setProjectList] = useState([]);
    const [companyName, setCompanyName] = useState(null);
    const [actualpercent, setActualpercent] = useState(0);
    const [planpercent, setPlanpercent] = useState(0);
    const [checked, setChecked] = React.useState("월간");
    const [loading, setLoading] = useState(true);

    const handleChangeProject = (event) => {
        setSelectedProject(event.target.value);
    };

    const handleChangeX = (event, selectedValue) => {
        if (selectedValue !== null) {
            setChecked(selectedValue);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await axios.get(`/getvendorprojectlist`);
                const projectList = res.data.projects.map((project) => ({
                    project_code: project.project_projectnumber,
                    project_name: project.project_projectname,
                }));

                setProjectList(projectList);

                if (projectList.length > 0) {
                    setSelectedProject(projectList[0].project_code);
                }
            } catch (error) {
                console.error(`프로젝트 리스트 가져오기 중 오류 발생:`, error);
            }
        };

        fetchData();
    }, []);
    useEffect(() => {
        setLoading(true);

        const fetchData = async (reportName) => {
            try {
                const res = await axios.get(`/getprogressv2?project=${selectedProject}`);
                setProgressData(checked === "월간" ? res.data.month : res.data.week);
                setPlanpercent(res.data.planpercent);
                setActualpercent(res.data.actualpercent);
                setCompanyName(res.data.companyName);
                setLoading(false);
            } catch (error) {
                console.error(`${reportName} 데이터 가져오기 중 오류 발생:`, error);
                return []; // 오류 발생 시 빈 배열 반환
            }
        };
        fetchData();
    }, [selectedProject, checked]);

    const columnItems = [
        {
            name: checked === "월간" ? "월간 계획" : "주간 계획",
            field_name: "PV2",
            color: "#EB7A25",
        },
        {
            name: checked === "월간" ? "월간 실적" : "주간 실적",
            field_name: "EV2",
            color: "rgb(103, 148, 220)",
        },
    ];

    const lineItems = [
        {
            name: "누적 계획",
            field_name: "CC_PV",
            color: "#EB7A25",
        },
        {
            name: "누적 실적",
            field_name: "CC_EV",
            color: "rgb(103, 148, 220)",
        },
    ];

    if (loading) {
        return (
            <div className="loadingContainer">
                <Circles ariaLabel="loading" color="#00BFFF" height={100} width={100} />
            </div>
        );
    }

    if (projectList.length === 0) {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                }}
            >
                <div
                    style={{
                        backgroundColor: '#f0f0f0',
                        padding: '20px',
                        borderRadius: '8px',
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                        textAlign: 'center',
                    }}
                >
                    <h2 style={{ color: '#333', fontSize: '24px' }}>
                        현재 진행 중인 프로젝트는 없습니다.
                    </h2>
                </div>
            </div>
        );
    }


    const maxWidth = progressData && width * 0.8 / progressData.length;

    return (
        <>
        <div  style={{ padding: '16px' }}>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
                <div style={{ marginRight: '10px', fontSize: '1.2rem', fontWeight: 'bold' }}>{companyName}</div>
                {/* <InputLabel id="demo-simple-select-label">프로젝트</InputLabel> */}
                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">

                <Select
                    value={selectedProject}
                    onChange={handleChangeProject}
                    displayEmpty
                    inputProps={{ 'aria-label': 'select project' }}
                    style={{ minWidth: '250px' }}
                >
                    {projectList.map((project) => (
                        <MenuItem key={project.project_code} value={project.project_code}>
                            {project.project_name}
                        </MenuItem>
                    ))}
                </Select>
                </FormControl>
                <div style={{ marginLeft:8}}>
                                {moment().add(-1, 'days').format('YYYY년 MM월 DD일')} 기준
                            </div>
            </div>
            <GridLayout
                style={{ height: (height / 6.5) * 5 -50 }}
                rows={[
                    {
                        height: '20%',
                    },
                    {
                        height: '80%',
                    },
                ]}
                cols={[
                    {
                        width: '20%',
                    },
                    {
                        width: '80%',
                    },
                ]}
                gap={{
                    rows: 0,
                    cols: 0,
                }}
            >
                <div
                    style={{
                        position: 'absolute',
                        top: '24px',
                        right: '12px',
                        transform: 'translateY(-50%)',
                        zIndex: '99999999',
                    }}
                >
                    <ToggleButtonGroup
                        size="small"
                        onChange={handleChangeX}
                        value={checked}
                        exclusive
                        aria-label="baseunit"
                    >
                        <ToggleButton value="월간" aria-label="weekly">
                            월간
                        </ToggleButton>
                        <ToggleButton value="주간" aria-label="monthly">
                            주간
                        </ToggleButton>
                    </ToggleButtonGroup>
                </div>
                <GridLayoutItem col={1} row={1}>
                </GridLayoutItem>
                <GridLayoutItem col={1} row={2}>
                    <div style={{ width: '100%', height: ((height / 6.5) * 4 - 50) * 0.8 }}>
                        <div style={{ position: 'relative' }}>
                            <div
                                style={{
                                    position: 'absolute',
                                    top: '50px',
                                    left: '50%',
                                    transform: 'translateX(-50%)',
                                }}
                            >
                                <div style={{ textAlign: 'center', color: '#363945' }}>Dev.</div>

                                <div style={{ textAlign: 'center', fontSize: '2rem', fontWeight: 'bold' }}>
                                    <span
                                        style={{
                                            color: actualpercent - planpercent > 0 ? '#00539C' : '#9E1030',
                                        }}
                                    >
                                        {(actualpercent - planpercent).toFixed(2)}
                                    </span>
                                    %
                                </div>
                            </div>
                            <div
                                style={{
                                    position: 'absolute',
                                    top: ((height / 6.5) * 4 - 50) * 0.8 - 200,
                                    left: '8px',
                                }}
                            >
                                <div
                                    style={{ color: '#939597', fontSize: '1rem', float: 'left' }}
                                    title={tooltiptitle}
                                >
                                    Plan
                                </div>
                                <div style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
                                    {planpercent && planpercent.toFixed(2)}%
                                </div>
                            </div>
                            <div
                                style={{
                                    position: 'absolute',
                                    top: ((height / 6.5) * 4 - 50) * 0.8 - 200,
                                    right: '8px',
                                }}
                            >
                                <div
                                    style={{
                                        textAlign: 'center',
                                        color: '#939597',
                                        fontSize: '1rem',
                                        float: 'right',
                                    }}
                                    title={tooltiptitle2}
                                >
                                    Actual
                                </div>
                                <div
                                    style={{
                                        textAlign: 'center',
                                        fontSize: '1.5rem',
                                        fontWeight: 'bold',
                                        color: '#525252',
                                    }}
                                >
                                    {actualpercent && actualpercent.toFixed(2)}%
                                </div>
                            </div>

                        </div>
                    </div>
                </GridLayoutItem>
                <GridLayoutItem col={2} row={1} rowSpan={2} >
                    {progressData && (
                        <SCurveChart
                            data={progressData}
                            maxWidth={maxWidth}
                            columnItems={columnItems}
                            lineItems={lineItems}
                            chart_id={"scurve"}
                            height={"65vh"}
                            category={"date"}
                            lineLabel={true}
                        />
                    )}
                </GridLayoutItem>
            </GridLayout>
            </div>
        </>
    );
};

export default VProgressChartO;