import React from "react";

import BodyWrapper from "./BodyWrapper";
import NavSidebarT from "./NavSidebarTrue";

export const DashboardLayoutTotalT = ({ children }) => {
  return (
    <BodyWrapper>
      <div className="flex h-screen bg-gray-200">
        <NavSidebarT />

        <div className="flex flex-col flex-1 overflow-hidden" style={{backgroundColor:'#fafafa'}}>
          <main className="content" style={{overflowY:'auto'}}>
            <section className="sm:flex-row flex flex-col flex-1">
              <div
                // className="content-box"
                style={{ flexGrow: 2, flexBasis: "0%", backgroundColor:'#fafafa' }}
              >
                {children}
              </div>
            </section>
          </main>
        </div>
      </div>
    </BodyWrapper>
  );
};
