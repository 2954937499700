import React, { useEffect, useState, useLayoutEffect } from 'react';
import _ from 'lodash';
import {
  Card,
  CardTitle,
  CardBody,
  GridLayout, GridLayoutItem
} from "@progress/kendo-react-layout";
import moment from 'moment';
import { Sparkline, ChartValueAxis, ChartValueAxisItem, ChartTooltip } from '@progress/kendo-react-charts';
import { Tooltip } from "@progress/kendo-react-tooltip";
import axios from "../../common/axios";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import * as am5 from "@amcharts/amcharts5";
import * as am5radar from "@amcharts/amcharts5/radar";
import * as am5xy from "@amcharts/amcharts5/xy";

import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

const SafetyTotal = ({ height }) => {
  const [ltif, setData] = useState([]);
  const [selectedValue, setSelectedValue] = React.useState("전체");
  const [projects, setProjects] = React.useState([]);

  const handleChange = (event) => {
    console.log(event)
    setSelectedValue(event.target.value);
  };




  useEffect(() => {
    const fetchData = async () => {
      const res = await axios.get(`/getltirData`)
      const res2 = await axios.get(`/getprojectlist`)
      if (res.data.success && res2.data.success) {
        setProjects(["전체", ...res2.data.data.map(v => v.projectname)])
        setData(res.data.data.find(v => v.project === selectedValue))
      }
    };
    fetchData();

  }, [selectedValue]);



  const CHART_ID = "gauge"

  useLayoutEffect(() => {
    let root = am5.Root.new(CHART_ID);
    root.setThemes([
      am5themes_Animated.new(root)
    ]);


    var chart = root.container.children.push(
      am5radar.RadarChart.new(root, {
        panX: false,
        panY: false,
        startAngle: 180,
        endAngle: 360
      })
    );

    var axisRenderer = am5radar.AxisRendererCircular.new(root, {
      innerRadius: -10,
      strokeOpacity: 1,
      strokeWidth: 15,
      strokeGradient: am5.LinearGradient.new(root, {
        rotation: 0,
        stops: [
          { color: am5.color(0x19d228) },
          { color: am5.color(0xf4fb16) },
          { color: am5.color(0xf6d32b) },
          { color: am5.color(0xfb7116) }
        ]
      })
    });

    var xAxis = chart.xAxes.push(
      am5xy.ValueAxis.new(root, {
        maxDeviation: 0,
        min: 0,
        max: 3,
        strictMinMax: true,
        renderer: axisRenderer
      })
    );

    var axisDataItem1 = xAxis.makeDataItem({});
    axisDataItem1.set("value", 0);

    var clockHand1 = am5radar.ClockHand.new(root, {
      pinRadius: 0,
      radius: am5.percent(98),
      bottomWidth: 10
    });

    clockHand1.pin.setAll({
      fill: "#F8443E"
    });

    clockHand1.hand.setAll({
      fill: "#F8443E"
    });



    var bullet = axisDataItem1.set("bullet", am5xy.AxisBullet.new(root, {
      sprite: clockHand1
    }));



    // Add clock hand
    var axisDataItem2 = xAxis.makeDataItem({});

    var clockHand2 = am5radar.ClockHand.new(root, {
      pinRadius: 0,
      radius: am5.percent(80),
      bottomWidth: 10
    });

    clockHand2.pin.setAll({
      fill: "#FFB83F"
    });

    clockHand2.hand.setAll({
      fill: "#FFB83F"
    });

    var bullet2 = axisDataItem2.set("bullet", am5xy.AxisBullet.new(root, {
      sprite: clockHand2
    }));

    xAxis.createAxisRange(axisDataItem1);
    xAxis.createAxisRange(axisDataItem2);

    axisDataItem1.get("grid").set("visible", false);

    axisDataItem1.animate({
      key: "value",
      to: 1,
      // to: ltif.LTIF_actual,
      duration: 1000,
      easing: am5.ease.out(am5.ease.cubic)
    });

    axisDataItem2.animate({
      key: "value",
      to: 2,
      // to: ltif.TRCF_actual,
      duration: 1000,
      easing: am5.ease.out(am5.ease.cubic)
    });

    chart.appear(1000, 100);

    return () => {
      root && root.dispose();
    };

  }, [ltif]);




  return (
    <>
      <GridLayout style={{ height: 2 * (height / 6.5) - 70 }}
        rows={[
          {
            height: "10%",
            height: "90%",
          },
        ]}
        cols={[
          {
            width: '100%',
          },
          ,
        ]}
        gap={{
          rows: 10,
          cols: 0,
        }}>
        <FormControl sx={{
          m: 1,
          minWidth: 120,
          position: 'absolute', // Use relative positioning
          top: 6,
          right: 10,
          float: 'right', // Align to the right
        }} size="small">
          <InputLabel id="demo-select-small-label">프로젝트</InputLabel>
          <Select
            sx={{ fontSize: "0.8rem" }}
            labelId="demo-select-small-label"
            id="demo-select-small"
            value={selectedValue}
            label="프로젝트"
            onChange={handleChange}
          >{projects && projects.map((v, index) => (
            <MenuItem key={index} value={v} sx={{ fontSize: "0.8rem" }}>{v}</MenuItem>
          ))}
          </Select>
        </FormControl>
        <GridLayoutItem row={1} col={1} style={{ height: 50 }}>
          <div className="metric">
            <span style={{ color: "#F8443E" }}>LTIF</span>
            <span className="value">
              {ltif?.ltir_plan ?? 0} /
              <span className={`indicator ${(ltif?.trcf_plan - ltif?.TRCF_actual) < 0 ? 'positive' : 'negative'}`}>
                {ltif?.LTIF_actual ?? 0}
              </span>
            </span>
            <span style={{ fontSize: 'smaller' }} className={`indicator ${(ltif?.ltir_plan - ltif?.LTIF_actual) < 0 ? 'positive' : 'negative'}`}>
              ({Math.abs(ltif?.ltir_plan - ltif?.LTIF_actual ?? 0)} {(ltif?.ltir_plan - ltif?.LTIF_actual) < 0 ? '↓' : '↑'})
            </span>
          </div>
          <div className="metric">
            <span style={{ color: "#FFB83F" }}>TRCF</span>
            <span className="value">
              {ltif?.trcf_plan ?? 0} /
              <span className={`indicator ${(ltif?.trcf_plan - ltif?.TRCF_actual) < 0 ? 'positive' : 'negative'}`}>
                {ltif?.TRCF_actual ?? 0}
              </span>
            </span>
            <span style={{ fontSize: 'smaller' }} className={`indicator ${(ltif?.trcf_plan - ltif?.TRCF_actual) < 0 ? 'positive' : 'negative'}`}>
              ({Math.abs(ltif?.trcf_plan - ltif?.TRCF_actual ?? 0)} {(ltif?.trcf_plan - ltif?.TRCF_actual) < 0 ? '↓' : '↑'})
            </span>
          </div>
        </GridLayoutItem>


        <div id="gauge" style={{ height: 0.9 * (2 * (height / 6.5) - 70), marginTop: -0.8 * (2 * (height / 6.5) - 70) }}></div>


      </GridLayout>


    </>);
}

export default SafetyTotal
