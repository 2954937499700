import { ResponsivePie } from '@nivo/pie'
import _ from 'lodash';
import React, { useEffect, useState } from 'react'

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.


const SFPieChart = ({pieData}) => {
   return (
        <ResponsivePie
            data={pieData}
            margin={{ top: 60, right: 60, bottom: 60, left: 60 }}
            padAngle={0.7}
            cornerRadius={3}
            activeOuterRadiusOffset={8}
            colors={{ scheme: 'reds' }}
            borderWidth={1}
            borderColor={{
                from: 'color',
                modifiers: [
                    [
                        'darker',
                        0.2
                    ]
                ]
            }}
            arcLinkLabel={d => (
                <React.Fragment>
                    <tspan style={{ fontSize: 10 }} x="0" dy="-0.6em">{d.id}</tspan>
                    <tspan style={{ fontSize: 10 }} x="0" dy="1.2em">{"(" + ((d.value / _.sumBy(pieData, 'value')) * 100).toFixed(0) + "%)"}</tspan>
                </React.Fragment>
            )}
            arcLinkLabelsSkipAngle={10}
            arcLinkLabelsTextColor="#333333"
            arcLinkLabelsThickness={2}
            arcLinkLabelsDiagonalLength={6}
            arcLinkLabelsStraightLength={8}
            arcLinkLabelsColor={{ from: 'color' }}
            arcLabelsSkipAngle={10}
            arcLabelsTextColor={{
                from: 'color',
                modifiers: [
                    [
                        'darker',
                        2
                    ]
                ]
            }}
        />
    )
}

export default SFPieChart
const data = [
    {
      "id": "php",
      "label": "php",
      "value": 457,
      "color": "hsl(30, 70%, 50%)"
    },
    {
      "id": "go",
      "label": "go",
      "value": 179,
      "color": "hsl(180, 70%, 50%)"
    },
    {
      "id": "lisp",
      "label": "lisp",
      "value": 67,
      "color": "hsl(40, 70%, 50%)"
    },
    {
      "id": "python",
      "label": "python",
      "value": 457,
      "color": "hsl(321, 70%, 50%)"
    },
    {
      "id": "elixir",
      "label": "elixir",
      "value": 583,
      "color": "hsl(250, 70%, 50%)"
    }
  ]