import React, { useState, useMemo } from "react";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { useRecoilValue } from "recoil";
import {
  QualityInspectionData,
  QualityNCRData,
  QualityNCRRateData,
} from "../../../../atom/qualityState";
import _ from "lodash";
import dayjs from "dayjs";
import DataRangeFilter from "../../public/DataRangeFilter";
import ChangeButton from "../../public/ChangeButton";
import InspectionApprovalCard from "./InspectionApprovalCard";
import InspectionApprovalCard2 from "./InspectionApprovalCard2";
import InspectionApprovalCard3 from "./InspectionApprovalCard3";

import "./inspectionApproval.css";

const customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);

const InspectionApproval = ({ height, targetPhase, targetProject }) => {
  const inspection_data = useRecoilValue(QualityInspectionData);
  const ncr_data = useRecoilValue(QualityNCRData);
  const { quality_dashboard_rate: ncrRateData } =
    useRecoilValue(QualityNCRRateData);

  const [dateValue, setDateValue] = useState([
    dayjs().subtract(1, "year"),
    dayjs(),
  ]);

  const [changeButtonValue, setChangeButtonValue] = useState(true);

  const filter_inspection_data = useMemo(() => {
    let copy_inspection_data = [
      ...inspection_data.map((c) => {
        return { ...c, phase: "" };
      }),
    ];
    let copy_ncr_data = [...ncr_data];

    if (targetPhase?.value && targetPhase?.value !== "all") {
      copy_inspection_data = copy_inspection_data.filter(
        (c) => c.project_projectname_slice === targetPhase.label
      );

      copy_ncr_data = copy_ncr_data.filter(
        (c) => c.project_projectname_slice === targetPhase.label
      );
    }

    if (targetProject !== null) {
      copy_inspection_data = copy_inspection_data.filter(
        (c) => c.project_projectnumber === targetProject.value
      );

      copy_ncr_data = copy_ncr_data.filter(
        (c) => c.project_projectnumber === targetProject.value
      );
    }

    const [startDate, endDate] = dateValue;

    if (startDate && endDate) {
      copy_inspection_data = [...copy_inspection_data].filter((c) => {
        const { QIDateTime } = c;

        const QIDateTime_dayjs = dayjs(QIDateTime, "MM-DD-YYYY");

        return startDate <= QIDateTime_dayjs && QIDateTime_dayjs <= endDate;
      });

      copy_ncr_data = [...copy_ncr_data].filter((c) => {
        const { NCRIssueDate } = c;

        const NCRIssueDate_dayjs = dayjs(NCRIssueDate, "MM-DD-YYYY");

        return startDate <= NCRIssueDate_dayjs && NCRIssueDate_dayjs <= endDate;
      });
    }

    return {
      inspection_approval_arr: copy_inspection_data,
      ncr_data_arr: copy_ncr_data,
    };
  }, [inspection_data, ncr_data, dateValue, targetPhase, targetProject]);

  const table_data = useMemo(() => {
    const { inspection_approval_arr, ncr_data_arr } = filter_inspection_data;

    const result = {
      inspection_approval: { ...initial_inspection_approval },
      ncr: { ...initial_ncr },
      disc_arr: [...initial_disc_data],
    };

    result.inspection_approval = {
      ...result.inspection_approval,
      ...create_inspection_approval_data(inspection_approval_arr),
    };

    result.ncr = {
      ...result.ncr,
      ...create_ncr_data(ncr_data_arr),
    };

    result.disc_arr = create_disc_data(inspection_approval_arr, ncr_data_arr);

    return result;
  }, [filter_inspection_data]);

  const ncr_rate_data = useMemo(() => {
    const result = {
      education_data: { ...initial_education_data },
      ncr_percent: { ...initial_ncr_percent },
    };

    if (targetPhase?.value && targetPhase?.value === "all") {
      const find_ncr_rate_data = ncrRateData.find((c) => {
        const { QDashFabSPD } = c;

        return QDashFabSPD === "전체";
      });

      if (find_ncr_rate_data) {
        result.education_data.result = find_ncr_rate_data.QTrainingDoneRate;
        result.ncr_percent.result = find_ncr_rate_data.QNCRRate;
      }
    }

    if (
      targetPhase?.value &&
      targetPhase?.value !== "all" &&
      targetProject === null
    ) {
      const find_ncr_rate_data = ncrRateData.find((c) => {
        const { QDashFabSPD } = c;

        return QDashFabSPD === targetPhase.label;
      });

      if (find_ncr_rate_data) {
        result.education_data.result = find_ncr_rate_data.QTrainingDoneRate;
        result.ncr_percent.result = find_ncr_rate_data.QNCRRate;
      }
    }

    if (
      targetPhase?.value &&
      targetPhase?.value !== "all" &&
      targetProject !== null
    ) {
      const find_ncr_rate_data = ncrRateData.find((c) => {
        const { QDashFabSPD, QDashProjectSPD } = c;

        return (
          QDashFabSPD === targetPhase.label &&
          QDashProjectSPD === targetProject.label
        );
      });

      if (find_ncr_rate_data) {
        result.education_data.result = find_ncr_rate_data.QTrainingDoneRate;
        result.ncr_percent.result = find_ncr_rate_data.QNCRRate;
      }
    }

    return result;
  }, [ncrRateData, targetPhase, targetProject]);

  return (
    <GridLayout
      rows={[
        {
          height: height,
        },
      ]}
      cols={[
        {
          width: "calc((100% - 30px) * 0.25)",
        },
        {
          width: "calc((100% - 30px) * 0.25)",
        },
        {
          width: "calc((100% - 30px) * 0.25)",
        },
        {
          width: "calc((100% - 30px) * 0.25)",
        },
      ]}
      gap={{
        rows: 0,
        cols: 10,
      }}
      style={{ position: "relative" }}
    >
      <div className="quality-filter-btn-box">
        <DataRangeFilter dateValue={dateValue} setDateValue={setDateValue} />

        <ChangeButton
          click_event_fn={() => setChangeButtonValue((prev) => !prev)}
        />
      </div>
      {changeButtonValue ? (
        <>
          <GridLayoutItem row={1} col={1}>
            <InspectionApprovalCard
              height={height}
              type="합격률"
              data={table_data.inspection_approval}
            />
          </GridLayoutItem>
          <GridLayoutItem row={1} col={2}>
            <InspectionApprovalCard
              height={height}
              type="부적합"
              data={table_data.ncr}
            />
          </GridLayoutItem>
        </>
      ) : (
        <GridLayoutItem row={1} col={1} colSpan={2}>
          <InspectionApprovalCard2 height={height} data={table_data.disc_arr} />
        </GridLayoutItem>
      )}
      <GridLayoutItem row={1} col={3}>
        <InspectionApprovalCard3
          height={height}
          data={ncr_rate_data.education_data}
        />
      </GridLayoutItem>
      <GridLayoutItem row={1} col={4}>
        <InspectionApprovalCard3
          height={height}
          data={ncr_rate_data.ncr_percent}
        />
      </GridLayoutItem>
    </GridLayout>
  );
};

export default InspectionApproval;

const initial_inspection_approval = {
  title: "검사합격률",
  result_1: "감리:",
  result_1_result: 0,
  result_1_unit: "%",
  result_1_info: "(감리 합격 0건 / 수행 0건)",
  result_2: "중공업:",
  result_2_result: 0,
  result_2_unit: "%",
  result_2_info: "(중공업 합격 0건 / 수행 0건)",
};

const initial_ncr = {
  title: "부적합 평균 조치일",
  result_1: "감리:",
  result_1_result: 0,
  result_1_unit: "일",
  result_1_info: "(감리 0건 발행)",
  result_2: "중공업:",
  result_2_result: 0,
  result_2_unit: "일",
  result_2_info: "(중공업 0건 발행)",
};

const initial_disc_data = [
  {
    disc: "설비",
    supervision_approval: 0,
    contruction_approval: 0,
    supervision_duration: 0,
    contruction_duration: 0,
  },
  {
    disc: "건축",
    supervision_approval: 0,
    contruction_approval: 0,
    supervision_duration: 0,
    contruction_duration: 0,
  },
  {
    disc: "전기",
    supervision_approval: 0,
    contruction_approval: 0,
    supervision_duration: 0,
    contruction_duration: 0,
  },
  {
    disc: "제어",
    supervision_approval: 0,
    contruction_approval: 0,
    supervision_duration: 0,
    contruction_duration: 0,
  },
];

const initial_education_data = {
  title: "품질 교육 이수율",
  result: 0,
  result_unit: "%",
};

const initial_ncr_percent = {
  title: "품질 부적합률",
  result: 0,
  result_unit: "ppm",
};

const create_inspection_approval_data = (inspection_approval_arr) => {
  // const approvedSHICount = inspection_approval_arr.filter(
  //   (c) => c.PreviousAction === "감리단 결과 입력 요청"
  // ).length;

  // const constructionNotFixedCount = inspection_approval_arr.filter(
  //   (c) =>
  //     // c.QIKeyParty === "시공사" &&
  //     c.PreviousAction === "감리 검사취소" ||
  //     c.PreviousAction === "시공사 지적 미조치 (검사취소)"
  // ).length;

  // const exceptionCount = inspection_approval_arr.filter(
  //   (c) =>
  //     // c.QIKeyParty === "시공사" &&
  //     c.PreviousAction === "예외 (검사취소)"
  // ).length;

  // const inspection_approval_supervision_actual =
  //   approvedSHICount - constructionNotFixedCount - exceptionCount > 0
  //     ? approvedSHICount - constructionNotFixedCount - exceptionCount
  //     : 0;

  const inspection_approval_supervision_approval =
    inspection_approval_arr.filter((c) => c.PreviousAction === "합격").length;

  const inspection_approval_supervision_not_actual =
    inspection_approval_arr.filter(
      (c) => c.PreviousAction === "감리단 불합격 통지"
    ).length;

  const inspection_approval_construction_actual =
    inspection_approval_arr.filter(
      (c) => c.PreviousAction === "시공사 검사결과 입력 요청"
    ).length;

  const inspection_approval_construction_approval =
    inspection_approval_arr.filter(
      (c) => c.PreviousAction === "시공사 합격"
    ).length;

  return {
    result_1_result:
      returnZeroIfInvalid(
        inspection_approval_supervision_approval /
          (inspection_approval_supervision_approval +
            inspection_approval_supervision_not_actual)
      ) * 100,
    result_1_info: `(감리 합격 ${inspection_approval_supervision_approval}건 / 수행 ${
      inspection_approval_supervision_approval +
      inspection_approval_supervision_not_actual
    }건)`,
    result_2_result:
      returnZeroIfInvalid(
        inspection_approval_construction_approval /
          inspection_approval_construction_actual
      ) * 100,
    result_2_info: `(중공업 합격 ${inspection_approval_construction_approval}건 / 수행 ${inspection_approval_construction_actual}건)`,
  };
};

const create_ncr_data = (ncr_data_arr) => {
  const ncr_supervision_data = ncr_data_arr.filter(
    (c) => c.NCRIssuerPD === "감리"
  );

  const ncr_construction_data = ncr_data_arr.filter(
    (c) => c.NCRIssuerPD === "삼성중공업"
  );

  const ncr_supervision_count = ncr_supervision_data.length;

  const ncr_supervision_duration_sum = _.sumBy(
    ncr_supervision_data,
    "NCRActionDuration"
  );

  const ncr_construction_count = ncr_construction_data.length;

  const ncr_supervision_sum = _.sumBy(
    ncr_construction_data,
    "NCRActionDuration"
  );

  return {
    result_1_result: Number(
      returnZeroIfInvalid(ncr_supervision_duration_sum / ncr_supervision_count)
    ),
    result_1_info: `(감리 ${ncr_supervision_count}건 발행)`,
    result_2_result: Number(
      returnZeroIfInvalid(ncr_supervision_sum / ncr_construction_count)
    ),
    result_2_info: `(중공업 ${ncr_construction_count}건 발행)`,
  };
};

const create_disc_data = (inspection_data, ncr_data) => {
  const disc_base = ["설비", "건축", "전기", "제어"].map((c) => {
    const disc_inspection_approval_arr = inspection_data.filter(
      (c2) => c2.ITPDiscipline === c
    );

    const disc_ncr_data_arr = ncr_data.filter((c2) => c2.NCRDiscipline === c);

    const discApprovedSHICount = disc_inspection_approval_arr.filter(
      (c) => c.PreviousAction === "시공사 합격"
    ).length;

    const discConstructionNotFixedCount = disc_inspection_approval_arr.filter(
      (c) =>
        c.QIKeyParty === "시공사" &&
        c.PreviousAction === "시공사 지적 미조치 (검사취소)"
    ).length;

    const discExceptionCount = disc_inspection_approval_arr.filter(
      (c) => c.QIKeyParty === "시공사" && c.PreviousAction === "예외 (검사취소)"
    ).length;

    const disc_inspection_approval_supervision_actual =
      discApprovedSHICount -
        discConstructionNotFixedCount -
        discExceptionCount >
      0
        ? discApprovedSHICount -
          discConstructionNotFixedCount -
          discExceptionCount
        : 0;

    const disc_inspection_approval_supervision_approval =
      disc_inspection_approval_arr.filter(
        (c) => c.CMInspResult === "합격"
      ).length;

    const disc_inspection_approval_construction_actual =
      disc_inspection_approval_arr.filter(
        (c) => c.PreviousAction === "시공사 검사결과 입력 요청"
      ).length;

    const disc_inspection_approval_construction_approval =
      disc_inspection_approval_arr.filter(
        (c) =>
          c.PreviousAction === "합격" && c.PreviousAction === "Approved_SHI"
      ).length;

    const disc_ncr_supervision_data = disc_ncr_data_arr.filter(
      (c) => c.NCRIssuerPD === "감리"
    );

    const disc_ncr_construction_data = disc_ncr_data_arr.filter(
      (c) => c.NCRIssuerPD === "삼성중공업"
    );

    const disc_ncr_supervision_count = disc_ncr_supervision_data.length;

    const disc_ncr_supervision_duration_sum = _.sumBy(
      disc_ncr_supervision_data,
      "NCRActionDuration"
    );

    const disc_ncr_construction_count = disc_ncr_construction_data.length;

    const disc_ncr_supervision_sum = _.sumBy(
      disc_ncr_construction_data,
      "NCRActionDuration"
    );

    return {
      disc: c,
      supervision_approval:
        returnZeroIfInvalid(
          disc_inspection_approval_supervision_approval /
            disc_inspection_approval_supervision_actual
        ) * 100,
      contruction_approval:
        returnZeroIfInvalid(
          disc_inspection_approval_construction_approval /
            disc_inspection_approval_construction_actual
        ) * 100,

      supervision_duration: Number(
        returnZeroIfInvalid(
          disc_ncr_supervision_duration_sum / disc_ncr_supervision_count
        )
      ),
      contruction_duration: Number(
        returnZeroIfInvalid(
          disc_ncr_supervision_sum / disc_ncr_construction_count
        )
      ),
    };
  });

  return disc_base;
};

const returnZeroIfInvalid = (value) => {
  if (value === Infinity || isNaN(value) || value === false) {
    return 0;
  }
  return value;
};
