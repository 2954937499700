import { atom } from "recoil";

export const QualityChartStatus = atom({
  key: "QualityChartStatus",
  default: "main",
});

export const QualityProjectList = atom({
  key: "QualityProjectList",
  default: [],
});

export const QualityInspectionData = atom({
  key: "QualityInspectionData",
  default: [],
});

export const QualityNCRData = atom({
  key: "QualityNCRData",
  default: [],
});

export const QualityNCRRateData = atom({
  key: "QualityNCRRateData",
  default: {
    ncr_rate: [],
    ncr_quarterly_rate: [],
    quality_dashboard_rate: [],
  },
});

export const QualityPQIData = atom({
  key: "QualityPQIData",
  default: [],
});

export const QualityInspectionStatusData = atom({
  key: "QualityInspectionStatusData",
  default: [],
});

export const QualityInspCadsData = atom({
  key: "QualityInspCadsData",
  default: [],
});

export const QualityInspectionProgressData = atom({
  key: "QualityInspectionProgressData",
  default: {
    quantity: [],
    inprogress: [],
    complete: [],
  },
});
