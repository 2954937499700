import React, { useEffect, useState, useMemo } from "react";
import { useRecoilValue } from "recoil";
import { QualityNCRRateData } from "../../../../atom/qualityState";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import dayjs from "dayjs";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import _ from "lodash";
import NCRRateMonthlyChart from "./NCRRateMonthlyChart";
import NCRRateQuarterChart from "./NCRRateQuarterChart";

import NCRRateQuarterTable from "./NCRRateQuarterTable";

import "./ppmStatus.css";

const customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);

dayjs.extend(quarterOfYear);

const NCRRateMonthlyChartMemo = React.memo(NCRRateMonthlyChart);
const NCRRateQuarterChartMemo = React.memo(NCRRateQuarterChart);

const PPMStatus = ({ height, targetPhase }) => {
  const { ncr_rate: ncrRateData, ncr_quarterly_rate: ncrQuarterlyRateData } =
    useRecoilValue(QualityNCRRateData);

  const [monthlyData, setMonthlyData] = useState(new Map());

  useEffect(() => {
    if (ncrRateData.length > 0) {
      const uniq_phase = _.sortBy(
        _.uniqBy(ncrRateData, "SHIPNSPD"),
        "SHIPNSPD"
      ).reverse();

      // setProjectList(uniq_phase.map((c) => c.SHIPNSPD));
      // setTargetProject(uniq_phase?.[0]?.SHIPNSPD ?? null);

      const phase_monthly_data = new Map();

      uniq_phase.forEach((c) => {
        const { _bp_lineitems, SHIPNSPD } = c;

        const lineitem_data = (_bp_lineitems ?? []).map((c2) => {
          const { vpeYearSPD, monthspd } = c2;

          const year = vpeYearSPD.replaceAll("년", "");
          const month = monthspd.replaceAll("월", "");

          const newDateFormat = new Date(`${year}-${month}-01`);

          return {
            ...c2,
            newDateFormat,
            dayjsMonth: dayjs(newDateFormat).format("YYYY-MM-01"),
          };
        });

        if (lineitem_data.length > 0) {
          const max_date = _.maxBy(lineitem_data, "newDateFormat");
          const min_date = _.minBy(lineitem_data, "newDateFormat");

          const date_range = create_months_range(
            min_date.newDateFormat,
            max_date.newDateFormat
          );

          const monthly_data = date_range.map((c2) => {
            const { dayjs_date, date } = c2;

            const find_target_data = lineitem_data.find(
              (c3) => c3.dayjsMonth === dayjs_date
            );

            return {
              date,
              cate: dayjs(date).format("YY년 MM월"),
              shi_ncr_value: find_target_data?.NCRRate ?? 0,
              shi_ncr_value_cum: find_target_data?.CumulNCRRate ?? 0,
              cnt_ncr_value: find_target_data?.SCTNCRRate ?? 0,
              cnt_ncr_value_cum: find_target_data?.SCTCumulNCRRate ?? 0,
              ena_ncr_value: find_target_data?.SECLNCRRate ?? 0,
              ena_ncr_value_cum: find_target_data?.SECLCumulNCRRate ?? 0,
            };
          });

          phase_monthly_data.set(SHIPNSPD, monthly_data);
        }
      }, []);

      setMonthlyData(phase_monthly_data);
    }
  }, [ncrRateData]);

  const quarterData = useMemo(() => {
    const result = [];

    const target_phase_data = monthlyData.get(targetPhase.label);

    const target_project_quarter_data = ncrQuarterlyRateData.find(
      (c) => c.SHIPNSPD === targetPhase.label
    );

    if (target_phase_data) {
      const { _bp_lineitems } = target_project_quarter_data;

      const max_date_data = _.maxBy(target_phase_data, "date");

      const { date } = max_date_data;

      const quarter_date = dayjs(date).quarter();

      const target_quarter_data = (_bp_lineitems ?? []).find((c) => {
        const { vpeYearSPD, NCRRateQuarter } = c;

        return (
          vpeYearSPD === dayjs(date).format("YYYY") &&
          NCRRateQuarter === `${quarter_date}분기`
        );
      });

      const quarter_data = {
        cate: `${dayjs(date).format("YY")}년 ${quarter_date}분기`,
        shi: target_quarter_data?.NCRRate ?? 0,
        cnt: target_quarter_data?.SCTNCRRate ?? 0,
        ena: target_quarter_data?.SECLNCRRate ?? 0,
      };

      const date_range = create_months_range_2(date);

      const month_data = date_range.map((c) => {
        const { date } = c;

        const cate = dayjs(date).format("YY년 MM월");

        const find_target_data = target_phase_data.find(
          (c2) => c2.cate === cate
        );

        return {
          cate,
          shi: find_target_data?.shi_ncr_value ?? 0,
          cnt: find_target_data?.cnt_ncr_value ?? 0,
          ena: find_target_data?.ena_ncr_value ?? 0,
        };
      });

      return [quarter_data, ...month_data];
    }

    return result;
  }, [monthlyData, ncrQuarterlyRateData, targetPhase]);

  if (ncrRateData.length === 0) {
    return null;
  }

  return (
    <GridLayout
      rows={[
        {
          height: (height - 20 - 120) * 0.5,
        },
        {
          height: (height - 20 - 120) * 0.5,
        },
        {
          height: 120,
        },
      ]}
      cols={[
        {
          width: 120,
        },
        {
          width: "calc((100% - 120px) * 0.25)",
        },
        {
          width: "calc((100% - 120px) * 0.75)",
        },
      ]}
      gap={{
        rows: 10,
        cols: 0,
      }}
      style={{ position: "relative" }}
    >
      {(monthlyData.get(targetPhase.label) ?? []).length > 0 ? (
        <>
          <GridLayoutItem row={1} col={1} colSpan={3}>
            <NCRRateMonthlyChartMemo
              height={(height - 20 - 120) * 0.5}
              data={monthlyData.get(targetPhase.label) ?? []}
              category={"cate"}
              columnItems={columnItems}
              lineItems={lineItems}
            />
          </GridLayoutItem>
          <GridLayoutItem row={2} col={1} colSpan={3}>
            <NCRRateQuarterChartMemo
              height={(height - 20 - 120) * 0.5}
              data={quarterData}
            />
          </GridLayoutItem>
          <GridLayoutItem row={3} col={1} colSpan={3}>
            <NCRRateQuarterTable height={120} data={quarterData} />
          </GridLayoutItem>
          <GridLayoutItem
            row={2}
            rowSpan={2}
            col={2}
            style={{ borderRight: "2px solid #d1d1d1" }}
          />
        </>
      ) : targetPhase.value === "all" ? (
        <GridLayoutItem row={1} col={1} colSpan={3}>
          <div>대상 건물을 선택하여 주시기 바랍니다.</div>
        </GridLayoutItem>
      ) : (
        <GridLayoutItem row={1} col={1} colSpan={3}>
          <div>유니파이어에 입력된 데이터가 없습니다.</div>
        </GridLayoutItem>
      )}
    </GridLayout>
  );
};

export default PPMStatus;

const columnItems = [
  {
    name: "부적합률",
    field_name: "shi_ncr_value",
    color: "#00B4D8",
  },
];

const lineItems = [
  {
    name: "누적 부적합률",
    field_name: "shi_ncr_value_cum",
    color: "#03045E",
  },
];

const create_months_range = (start, end) => {
  const result = [];

  const diff_day = dayjs(end).diff(start, "months");

  for (let i = 0; i <= diff_day; i++) {
    const year_date = dayjs(start).add(i, "months").format("YY년");
    const quarter_date = dayjs(start).add(i, "months").quarter();

    result.push({
      dayjs_date: dayjs(start).add(i, "months").format("YYYY-MM-01"),
      date: new Date(dayjs(start).add(i, "months").format("YYYY-MM-DD")),
      quarterDate: `${year_date} ${quarter_date}분기`,
    });
  }
  return result;
};

const create_months_range_2 = (start) => {
  const result = [];

  for (let i = -2; i <= 0; i++) {
    const year_date = dayjs(start).add(i, "months").format("YY년");
    const quarter_date = dayjs(start).add(i, "months").quarter();

    result.push({
      dayjs_date: dayjs(start).add(i, "months").format("YYYY-MM-01"),
      date: new Date(dayjs(start).add(i, "months").format("YYYY-MM-DD")),
      quarterDate: `${year_date} ${quarter_date}분기`,
    });
  }
  return result;
};
