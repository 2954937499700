import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";
import axios from "../../../../common/axios";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import {
  Chart,
  ChartSeries,
  ChartSeriesItem,
  ChartValueAxis,
  ChartValueAxisItem,
  ChartTooltip,
  ChartTitle,
} from "@progress/kendo-react-charts";
import "hammerjs";
import { useRecoilValue } from "recoil";
import { value3State,value1State } from '../../../../atom/progressState';


const BulletPage = ({ height, width }) => {
  const { project_code } = useParams();
  const [iconstate, seticonstate] = useState([]);
  const [data2, setdata2] = useState([]);
  const value3 = useRecoilValue(value3State);
  const value1= useRecoilValue(value1State);

  console.log(value1)
  console.log(value3)


  useEffect(() => {
    const fetchData = async () => {
      const res = await axios.get(`/getdiscprogress?project=${project_code}&value1=${value1}&value3=${value3}`);
      setdata2(res.data.data)
    };

    fetchData();

  }, [project_code, value3, value1]);

  useEffect(() => {
    seticonstate(data2.map((_, index) => ({ id: index, state: true })))
  }, [data2])

  const handle2 = (index) => {
    seticonstate(prevState => 
      prevState.map(item => 
        item.id === index ? { ...item, state: !item.state } : item
      )
    );
  };


  const colmunNo = Math.round(data2.length / 2);

  const chartwidth =
    (width / colmunNo / 2) * 0.95 > 200 ? 200 : (width / colmunNo / 2) * 0.95;

  const columnList = [];

  for (var i = 1; i <= colmunNo * 2; i++) {
    columnList.push({ width: `${100 / colmunNo / 2}%` });
  }

  const hidden = {
    visible: false,
  };

  const SharedTooltip = (props) => {
    const { points } = props;
    return (
      <div style={{ fontSize: "0.6rem" }}>
        <div>계획: {points[0].value.target.toFixed(2)}%</div>
        <div>실적: {points[0].value.current.toFixed(2)}%</div>
        <div>
          차이: {(points[0].value.current - points[0].value.target).toFixed(2)}%
        </div>
      </div>
    );
  };

  const sharedTooltipRender = (context) => <SharedTooltip {...context} />;



  return (
    <>
      <GridLayout
        style={{
          height: 2 * (height / 6.5), width: (width - 48 - 14 * 16),

        }}
        rows={[
          {
            height: "0%",
          },
          {
            height: "0%",
          },
          {
            height: "15%",
          },
          {
            height: "35%",
          },
          {
            height: "15%",
          },
          {
            height: "35%",
          },
        ]}
        cols={columnList}
        gap={{
          rows: 0,
          cols: 0,
        }}
      >

        {data2.map((v, index) => (
          <GridLayoutItem
            key={index}
            col={
              index + 1 > columnList.length * 0.5
                ? index * 2 + 1 - columnList.length
                : index * 2 + 1
            }
            row={index + 1 > columnList.length * 0.5 ? 5 : 3}
            colSpan={2}
            rowSpan={2}
            style={{
              position: 'relative',  // Add this to make it a positioning context
              height: '100%',        // Ensure it takes full height
              textAlign:'center'
            }}
          >
            {/* 
            <button style={{
              border: 'none', position: 'relative', zIndex: 999, top: '20%', right: '-35%', cursor: 'pointer', background: 'none',
              color:
                iconstate && iconstate[index] && iconstate[index]['state'] ?
                  '#34568B'
                  : '#FA7A35'
            }}
              onClick={handle2}
              value={index}
            > <MdOpenInNew /></button> */}
            <div
              onClick={() => handle2(index)}
              style={{
                position: 'absolute',
                top: '0',
                left: '0',
                right: '0',
                fontSize: '12px',
                fontWeight: 'bold',
                color: '#000',
                padding: '20px',
                cursor: 'pointer',
               
              }}
            >
              {/* <span style={{ zIndex: 1000,}}> */}
              {v.companyName}
              {/* </span> */}
            </div>

            <div style={{
              position: 'absolute',
              top: '40px',  // Adjust based on the height of the company name div
              left: '0',
              right: '0',
              bottom: '0',
              overflowY: 'auto',  // In case content overflows
              zIndex:999999
            }}>
              {iconstate[index] && iconstate[index]['state'] ?
                <Chart
                  style={{
                    height: (height - 410) * 5 / 12 / 2, marginTop: -20, 
                  }}
                >
                  <ChartTooltip shared={true} render={sharedTooltipRender} />

                  <ChartTitle
                    // onClick={handle2}
                
                    text={`&nbsp;\n${(v.Actual - v.Plan).toFixed(2)}% \n (계획:${v.Plan.toFixed(2)}% 실적:${v.Actual.toFixed(
                      2
                    )}%)\n\n`}
                    font="0.7rem pretendard-B"
                  />
                  <ChartSeries>
                    <ChartSeriesItem
                      type="bullet"
                      color={v.Actual - v.Plan < 0 ? "#CD212A" : "#00A170"}
                      data={[v.Actual, v.Plan]}
                    />
                  </ChartSeries>
                  <ChartValueAxis>
                    <ChartValueAxisItem
                      majorGridLines={hidden}
                      minorTicks={hidden}
                      min={0}
                      max={100}
                      labels={{ font: "0.7rem Arial, KoPub" }}
                    />
                  </ChartValueAxis>
                </Chart>

                :

                <>
                  <br />

                  <div style={{ font: '0.7rem pretendard-B' }}>현황별 액티비티 개수</div>
                  <div style={{ font: '0.7rem pretendard-B' }}>
                    <span>전체: {v.total}</span>&nbsp;&nbsp;
                    <span>선행: {v.ahead}</span>&nbsp;&nbsp;
                    <span>지연: {v.behind}</span>
                  </div>
                </>

              }
            </div>
          </GridLayoutItem>
        ))}


      </GridLayout>
    </>
  );
};

export default BulletPage;
