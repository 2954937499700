import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import axios from '../common/axios';
import { Circles } from 'react-loader-spinner';

const checkSession = async () => {
  try {
    const response = await axios.post(
      `/dashis_logged_in`,
      {},
      {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    return { isValidSession: response.data.isLoggedIn };
  } catch (error) {
    console.error("Error verifying session:", error);
    return { isValidSession: false };
  }
};

const ProtectedRoute = ({ children }) => {
  const [isValidSession, setIsValidSession] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      const validity = await checkSession();
      setIsValidSession(validity.isValidSession);
      setIsLoading(false);
    };
    fetchData();
  }, []);

  if (isLoading) {
    return (
      <div className="loadingContainer">
        <Circles ariaLabel="loading" color="#00BFFF" height={100} width={100} />
      </div>
    );
  }

  if (!isValidSession) {
    return <Navigate to={`/dashlogin?redirect=${encodeURIComponent(location.pathname)}`} replace />;
  }

  return children;
};

export default ProtectedRoute;