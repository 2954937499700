import React from "react";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";

const ConsTable = ({ height, data }) => {
  const {
    request,
    canceled,
    exception,
    actual,
    request_actaul_percent,
    first_pass,
    retry_first_pass,
    retry_second_pass,
    retry_third_pass,
    failed,
    first_pass_percent,
    retry_first_pass_percent,
    retry_second_pass_percent,
    retry_third_pass_percent,
    pass_percent,
    untaken_action,
  } = data;

  return (
    <GridLayout
      style={{ height: height }}
      className="quality-detail-inspection-cons-table"
      rows={Array.from({ length: 10 }).map(() => {
        return {
          height: height / 10,
        };
      })}
      cols={Array.from({ length: 6 }).map(() => {
        return {
          width: "calc(100% / 6)",
        };
      })}
      gap={{
        rows: 0,
        cols: 0,
      }}
    >
      <GridLayoutItem
        className="quality-detail-inspection-cons-table-item double-line"
        row={1}
        rowSpan={4}
        col={1}
        colSpan={6}
      />
      <GridLayoutItem
        className="quality-detail-inspection-cons-table-item double-line"
        row={5}
        rowSpan={5}
        col={1}
        colSpan={6}
      />
      <GridLayoutItem
        className="quality-detail-inspection-cons-table-item bottom-bold"
        row={8}
        col={2}
        colSpan={5}
      />
      <GridLayoutItem
        className="quality-detail-inspection-cons-table-item right-bold bg-color-light-blue"
        row={1}
        rowSpan={4}
        col={1}
      >
        <div className="quality-detail-inspection-table-text bold">
          계획
          <br />
          &
          <br />
          관리
        </div>
      </GridLayoutItem>
      <GridLayoutItem
        className="quality-detail-inspection-cons-table-item bg-color-light-blue"
        row={1}
        col={2}
        colSpan={2}
      >
        <div className="quality-detail-inspection-table-text bold">신청</div>
      </GridLayoutItem>
      <GridLayoutItem
        className="quality-detail-inspection-cons-table-item right-bold"
        row={1}
        col={4}
      >
        <div className="quality-detail-inspection-table-text">{request}</div>
      </GridLayoutItem>
      <GridLayoutItem
        className="quality-detail-inspection-cons-table-item bg-color-light-gray"
        row={2}
        col={2}
        colSpan={2}
      >
        <div className="quality-detail-inspection-table-text bold">취소</div>
      </GridLayoutItem>
      <GridLayoutItem
        className="quality-detail-inspection-cons-table-item right-bold bg-color-light-gray"
        row={2}
        col={4}
      >
        <div className="quality-detail-inspection-table-text">{canceled}</div>
      </GridLayoutItem>
      <GridLayoutItem
        className="quality-detail-inspection-cons-table-item bg-color-light-gray"
        row={3}
        col={2}
        colSpan={2}
      >
        <div className="quality-detail-inspection-table-text bold">예외</div>
      </GridLayoutItem>
      <GridLayoutItem
        className="quality-detail-inspection-cons-table-item right-bold bg-color-light-gray"
        row={3}
        col={4}
      >
        <div className="quality-detail-inspection-table-text">{exception}</div>
      </GridLayoutItem>
      <GridLayoutItem
        className="quality-detail-inspection-cons-table-item bg-color-light-blue"
        row={4}
        col={2}
        colSpan={2}
      >
        <div className="quality-detail-inspection-table-text bold">수행</div>
      </GridLayoutItem>
      <GridLayoutItem
        className="quality-detail-inspection-cons-table-item right-bold"
        row={4}
        col={4}
      >
        <div className="quality-detail-inspection-table-text">{actual}</div>
      </GridLayoutItem>
      <GridLayoutItem
        className="quality-detail-inspection-cons-table-item right-bold bg-color-light-blue"
        row={1}
        rowSpan={4}
        col={5}
      >
        <div className="quality-detail-inspection-table-text bold">
          <div className="quality-detail-inspection-table-text bold">
            계획
            <br />
            대비
            <br />
            수행률
          </div>
        </div>
      </GridLayoutItem>
      <GridLayoutItem
        className="quality-detail-inspection-cons-table-item right-bold"
        row={1}
        rowSpan={4}
        col={6}
      >
        <div className="quality-detail-inspection-table-text bold">
          {request_actaul_percent.toFixed(2)}%
        </div>
      </GridLayoutItem>

      {/* 중단 */}
      <GridLayoutItem
        className="quality-detail-inspection-cons-table-item right-bold"
        row={5}
        rowSpan={5}
        col={1}
      >
        <div className="quality-detail-inspection-table-text bold">
          수행
          <br />
          검사
          <br />
          실적
        </div>
      </GridLayoutItem>
      <GridLayoutItem
        className="quality-detail-inspection-cons-table-item bg-color-light-gray"
        row={5}
        col={2}
        colSpan={2}
      >
        <div className="quality-detail-inspection-table-text bold">
          One-Pass(1차 합격)
        </div>
      </GridLayoutItem>
      <GridLayoutItem
        className="quality-detail-inspection-cons-table-item right-bold"
        row={5}
        col={4}
      >
        <div className="quality-detail-inspection-table-text">{first_pass}</div>
      </GridLayoutItem>
      <GridLayoutItem
        className="quality-detail-inspection-cons-table-item bg-color-light-yellow"
        row={6}
        rowSpan={3}
        col={2}
      >
        <div className="quality-detail-inspection-table-text bold">재검사</div>
      </GridLayoutItem>
      <GridLayoutItem
        className="quality-detail-inspection-cons-table-item bg-color-light-yellow"
        row={6}
        col={3}
      >
        <div className="quality-detail-inspection-table-text bold">1차</div>
      </GridLayoutItem>
      <GridLayoutItem
        className="quality-detail-inspection-cons-table-item right-bold"
        row={6}
        col={4}
      >
        <div className="quality-detail-inspection-table-text">
          {retry_first_pass}
        </div>
      </GridLayoutItem>

      <GridLayoutItem
        className="quality-detail-inspection-cons-table-item bg-color-light-yellow"
        row={7}
        col={3}
      >
        <div className="quality-detail-inspection-table-text bold">2차</div>
      </GridLayoutItem>
      <GridLayoutItem
        className="quality-detail-inspection-cons-table-item right-bold"
        row={7}
        col={4}
      >
        <div className="quality-detail-inspection-table-text">
          {retry_second_pass}
        </div>
      </GridLayoutItem>

      <GridLayoutItem
        className="quality-detail-inspection-cons-table-item bg-color-light-yellow"
        row={8}
        col={3}
      >
        <div className="quality-detail-inspection-table-text bold">
          3차 이상
        </div>
      </GridLayoutItem>
      <GridLayoutItem
        className="quality-detail-inspection-cons-table-item right-bold"
        row={8}
        col={4}
      >
        <div className="quality-detail-inspection-table-text">
          {retry_third_pass}
        </div>
      </GridLayoutItem>
      <GridLayoutItem
        className="quality-detail-inspection-cons-table-item"
        row={9}
        col={2}
        colSpan={2}
      >
        <div className="quality-detail-inspection-table-text bold">불합격</div>
      </GridLayoutItem>
      <GridLayoutItem
        className="quality-detail-inspection-cons-table-item right-bold"
        row={9}
        col={4}
      >
        <div className="quality-detail-inspection-table-text">{failed}</div>
      </GridLayoutItem>
      <GridLayoutItem
        className="quality-detail-inspection-cons-table-item right-bold bg-color-light-gray"
        row={5}
        rowSpan={4}
        col={5}
      >
        <div className="quality-detail-inspection-table-text bold">
          검사
          <br />
          차수별
          <br />
          비율
        </div>
      </GridLayoutItem>
      <GridLayoutItem
        className="quality-detail-inspection-cons-table-item right-bold bg-color-light-gray"
        row={5}
        col={6}
      >
        <div className="quality-detail-inspection-table-text bold">
          {first_pass_percent.toFixed(2)}%
        </div>
      </GridLayoutItem>
      <GridLayoutItem
        className="quality-detail-inspection-cons-table-item right-bold bg-color-light-gray"
        row={6}
        col={6}
      >
        <div className="quality-detail-inspection-table-text bold">
          {retry_first_pass_percent.toFixed(2)}%
        </div>
      </GridLayoutItem>
      <GridLayoutItem
        className="quality-detail-inspection-cons-table-item right-bold bg-color-light-gray"
        row={7}
        col={6}
      >
        <div className="quality-detail-inspection-table-text bold">
          {retry_second_pass_percent.toFixed(2)}%
        </div>
      </GridLayoutItem>
      <GridLayoutItem
        className="quality-detail-inspection-cons-table-item right-bold bg-color-light-gray"
        row={8}
        col={6}
      >
        <div className="quality-detail-inspection-table-text bold">
          {retry_third_pass_percent.toFixed(2)}%
        </div>
      </GridLayoutItem>
      <GridLayoutItem
        className="quality-detail-inspection-cons-table-item right-bold"
        row={9}
        col={5}
      >
        <div className="quality-detail-inspection-table-text bold">합격률</div>
      </GridLayoutItem>
      <GridLayoutItem
        className="quality-detail-inspection-cons-table-item right-bold"
        row={9}
        col={6}
      >
        <div className="quality-detail-inspection-table-text bold">
          {pass_percent.toFixed(2)}%
        </div>
      </GridLayoutItem>

      <GridLayoutItem
        className="quality-detail-inspection-cons-table-item right-bold bottom-bold"
        row={10}
        col={1}
        colSpan={4}
      >
        <div className="quality-detail-inspection-table-text bold">
          시공사 지적 미조치 건
        </div>
      </GridLayoutItem>
      <GridLayoutItem
        className="quality-detail-inspection-cons-table-item right-bold bottom-bold"
        row={10}
        col={5}
        colSpan={2}
      >
        <div className="quality-detail-inspection-table-text bold">
          {untaken_action}
        </div>
      </GridLayoutItem>
    </GridLayout>
  );
};

export default ConsTable;
