import React, { useState, useEffect } from "react";
import { useSetRecoilState, useRecoilValue } from "recoil";
import {
  QualityChartStatus,
  QualityProjectList,
  QualityInspectionData,
  QualityNCRData,
  QualityNCRRateData,
  QualityPQIData,
  QualityInspectionStatusData,
  QualityInspCadsData,
  QualityInspectionProgressData,
} from "../../atom/qualityState";
import axios from "../../common/axios";
import QualityMain from "./QualityMain";
import QualityDetail from "./QualityDetail";
import "./quality.css";

const QualityPage = () => {
  const qualityChartStatus = useRecoilValue(QualityChartStatus);
  const setProjectList = useSetRecoilState(QualityProjectList);
  const setNCRData = useSetRecoilState(QualityNCRData);
  const setInspectionData = useSetRecoilState(QualityInspectionData);
  const setPqiData = useSetRecoilState(QualityPQIData);
  const setNCRRateData = useSetRecoilState(QualityNCRRateData);
  const setInspectionStatusData = useSetRecoilState(
    QualityInspectionStatusData
  );
  const setInspCadsData = useSetRecoilState(QualityInspCadsData);
  const setInpectionProgressData = useSetRecoilState(
    QualityInspectionProgressData
  );

  useEffect(() => {
    const fetchData = async () => {
      const request_project_list = await axios
        .get("/getQualityPjtList")
        .then((data) =>
          data.data.data.map((c) => {
            const { projectnumber, projectname, contract_data } = c;

            return { label: projectname, value: projectnumber, contract_data };
          })
        )
        .catch([]);

      setProjectList(request_project_list);

      const request_inspection_data = await axios
        .get(`/getInspectionData`)
        .then((data) => data.data.data)
        .catch([]);

      setInspectionData(request_inspection_data);

      const request_ncr_data = await axios
        .get(`/getNcrData`)
        .then((data) => data.data.data)
        .catch([]);

      setNCRData(request_ncr_data);

      const request_ncr_rate_data = await axios
        .get(`/getNCRRate`)
        .then((data) => data.data.data)
        .catch({
          ncr_rate: [],
          ncr_quarterly_rate: [],
          quality_dashboard_rate: [],
        });

      setNCRRateData(request_ncr_rate_data);

      const request_insp_cads_data = await axios
        .get(`/getInspCadsData`)
        .then((data) => data.data.data)
        .catch([]);

      setInspCadsData(request_insp_cads_data);

      const request_inspection_status_data = await axios
        .get(`/getInspectionStatus`)
        .then((data) => data.data.data)
        .catch([]);

      setInspectionStatusData(request_inspection_status_data);

      const request_inspection_progress_data = await axios
        .get(`/getInpectionProgressData`)
        .then((data) => data.data.data)
        .catch({
          quantity: [],
          inprogress: [],
          complete: [],
        });

      setInpectionProgressData(request_inspection_progress_data);

      const request_pqi_data = await axios
        .get(`/getQualityPQIData`)
        .then((data) => data.data.data)
        .catch([]);

      setPqiData(request_pqi_data);
    };
    fetchData();
    //eslint-disable-next-line
  }, []);

  const switch_page = () => {
    switch (qualityChartStatus) {
      case "main":
        return <QualityMain />;

      case "detail":
        return <QualityDetail />;

      default:
        return <QualityMain />;
    }
  };

  return <div className="quality-page-main">{switch_page()}</div>;
};

export default QualityPage;
