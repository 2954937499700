import React from 'react';
import { DataGridPremium, GridColumnGroupingModel } from '@mui/x-data-grid-premium';
import { FaCircle } from "react-icons/fa";
import moment from 'moment';
import StarIcon from '@mui/icons-material/Star';

const ProgressSchedule = ({ height, tableData }) => {
    // 날짜 차이 계산 함수
    const calculateDateDifference = (date1, date2) => {
        if (!date1 || !date2) return null;
        const d1 = moment(date1);
        const d2 = moment(date2);
        return d1.diff(d2, 'days');
    };

    const columnGroupingModel = [
        {
            groupId: 'plan',
            headerName: '계획',
            children: [
                { field: 'planStart' },
                { field: 'planFinish' },
            ],
        },
        {
            groupId: 'forecast',
            headerName: '예상',
            children: [
                { field: 'forecastStart' },
                { field: 'forecastFinish' },
            ],
        },
        {
            groupId: 'actual',
            headerName: '실적',
            children: [
                { field: 'actualStart' },
                { field: 'actualFinish' },
            ],
        },
        {
            groupId: 'dev',
            headerName: 'Dev',
            children: [
                { field: 'dev1' },
                { field: 'dev2' },
            ],
        },
    ];
    
    

    const columns = [
        {
            field: 'activityName',
            headerName: "Activity Name",
            flex: 3.2,
            headerClassName: 'bold-header',
        },
        {
            field: 'status',
            headerName: "상태",
            flex: 0.5,
            headerClassName: 'bold-header',
            cellClassName: 'cell-center',

        },
        {
            field: 'status2', 
            headerName: "신호등",
            flex: 0.2,
            cellClassName: 'cell-center',
            headerClassName: 'bold-header',
            renderCell: (params) => {
                const color = params.row.status1 === 'ahead' ? 'green' : params.row.status1 === 'delayed' ? 'red' : 'yellow';
                return <FaCircle color={color} />;
            },
        },
        {
            field: 'cp',
            headerName: "CP",
            flex: 0.2,
            cellClassName: 'cell-center',
            headerClassName: 'bold-header',
            renderCell: (params) => {
                return params.row.tf === 0 ? <StarIcon fontSize="small" color="secondary" /> : null;
            },
        },
        {
            field: 'tf',
            headerName: "TF",
            flex: 0.2,
            cellClassName: 'cell-center',
            headerClassName: 'bold-header',
        },
        {
            field: 'planStart',
            headerName: "착수(A)",
            type:'date',
            flex: 1.5,
            headerClassName: 'bold-header',
            valueFormatter: (value) => value && moment(value).format('YYYY-MM-DD'),

        },
        {
            field: 'planFinish',
            headerName: "완료(B)",
            type:'date',
            flex: 1.5,
            headerClassName: 'bold-header',
            valueFormatter: (value) => moment(value).format('YYYY-MM-DD'),

        },
        {
            field: 'forecastStart',
            headerName: "착수(C)",
            type:'date',
            flex: 1.5,
            headerClassName: 'bold-header',
            valueFormatter: (value) => value && moment(value).format('YYYY-MM-DD'),

        },
        {
            field: 'forecastFinish',
            headerName: "완료(D)",
            type:'date',
            flex: 1.5,
            headerClassName: 'bold-header',
            valueFormatter: (value) => value && moment(value).format('YYYY-MM-DD'),
        },
        {
            field: 'actualStart',
            headerName: "착수(E)",
            type:'date',
            flex: 1.5,
            headerClassName: 'bold-header',
            valueFormatter: (value) => value && moment(value).format('YYYY-MM-DD'),

        },
        {
            field: 'actualFinish',
            headerName: "완료(F)",
            type:'date',
            flex: 1.5,
            headerClassName: 'bold-header', 
            valueFormatter: (value) => value && moment(value).format('YYYY-MM-DD'),

        },
        {
            field: 'dev1',
            headerName: "(D-B)",
            flex: 0.2,
            cellClassName: 'cell-center',
            headerClassName: 'bold-header',
            renderCell: (params) => {
                const diff = calculateDateDifference(params.row.forecastFinish, params.row.planFinish);
                return diff !== null ? `${diff}일` : '';
            },
        }, 
        {
            field: 'dev2',
            cellClassName: 'cell-center',
            headerName: "(F-B)",
            flex: 0.2,
            headerClassName: 'bold-header',
            renderCell: (params) => {
                const diff = calculateDateDifference(params.row.actualFinish, params.row.planFinish);
                return diff !== null ? `${diff}일` : '';
            },
        },
    ];
    const commonGridProps = {
        rows: tableData,
        columns: columns,
        density: "compact",
        // rowHeight: 'auto',  
        hideFooter: true,
        columnGroupingModel: columnGroupingModel,
        sx: {
            '& .MuiDataGrid-columnHeaderTitle': {
                fontWeight: 'bold',
                fontSize: 12,
                
            },
            '& .MuiDataGrid-cell': {
                fontSize: 12,
                whiteSpace: 'normal',
                lineHeight: 'normal',
                display: 'flex',
                alignItems: 'center',
                paddingTop: '4px',
                paddingBottom: '4px',
            },
            '& .cell-center': {
                justifyContent: 'center',
                textAlign: 'center',
            },
        },
    };
    return (
        <div style={{ height: height - 100, width: '100%' }}>
            <DataGridPremium {...commonGridProps} />
        </div>
    );
}

export default ProgressSchedule;