import React, { useState, useEffect, useMemo } from 'react';
import { TileLayout } from "@progress/kendo-react-layout";
import { DashboardLayout } from '../../../layout/Layout';
import useBreakpoint from "use-breakpoint";
import useWindowDimensions from '../../common/useWindowDimensions';
import axios from "../../../common/axios";
import { useParams } from 'react-router-dom';
import ManPower from '../manpower/manPeriod';
import Equip from '../manpower/equipPeriod';
import WeekltyMaterial from './weekltyMaterial/WeekltyMaterial';
import MonthlyMaterial from './monthlyMaterial/MonthlyMaterial';
import InStockStatus from './inStockStatus/InStockStatus';
import InventoryStatus from './inventoryStatus/InventoryStatus';

const BREAKPOINTS = { mobile: 0, tablet: 768, desktop: 1280, };

const MaterialPage = () => {
    const { project_code } = useParams();
    const [fixblock, setfixblock] = useState(false);
    const { height, width } = useWindowDimensions();
    const [grid, setgrid] = useState({
        mobile:
          [
    
            {
              col: 1,
              colSpan: 16,
              rowSpan: 6,
              status: true,
            },
            {
              col: 1,
              colSpan: 16,
              status: true,
              rowSpan: 2,
            },
            {
              col: 1,
              colSpan: 16,
              status: true,
              rowSpan: 2,
            },
            {
              col: 1,
              colSpan: 16,
              status: true,
              rowSpan: 2,
            },
    
            {
              col: 1,
              colSpan: 16,
              rowSpan: 2,
              status: true,
            },
    
    
            {
              col: 1,
              colSpan: 16,
              rowSpan: 2,
              status: true,
            },
    
    
            {
              col: 1,
              colSpan: 16,
              rowSpan: 2,
              status: true,
            },
            {
              col: 1,
              colSpan: 16,
              rowSpan: 2,
              status: true,
            }
    
    
    
          ]
        ,
        tablet:
          [
    
            {
              col: 1,
              colSpan: 4,
              rowSpan: 6,
              status: true,
            },
            {
              col: 5,
              colSpan: 12,
              status: true,
              rowSpan: 2,
            },
    
    
    
            {
              col: 5,
              colSpan: 4,
              rowSpan: 2,
              status: true,
            },
            {
              col: 9,
              colSpan: 4,
              status: true,
              rowSpan: 2,
            },
            {
              col: 13,
              colSpan: 4,
              status: true,
              rowSpan: 2,
            },
            {
              col: 5,
              colSpan: 3,
              status: true,
              rowSpan: 2,
            },
            {
              col: 8,
              colSpan: 3,
              status: true,
              rowSpan: 2,
            },
    
            {
              col: 11,
              colSpan: 6,
              rowSpan: 2,
              status: true,
            }
    
          ]
        ,
        desktop:
          [
    
            {
              col: 1,
              colSpan: 8,
              rowSpan: 3,
              status: true,
            },
            {
              col: 9,
              colSpan: 8,
              status: true,
              rowSpan: 3,
            },
    
    
            {
              col: 1,
              colSpan: 8,
              rowSpan: 3,
              status: true,
            },
            {
              col: 9,
              colSpan: 8,
              status: true,
              rowSpan: 3,
            },
    
    
          ]
      });
    const { breakpoint } = useBreakpoint(BREAKPOINTS, 'desktop')

    const togglefix = () => {
      setfixblock(!fixblock);
    };
    const defaultvalue = new Date()
    const [positiondata, setPositionData] = useState(grid['desktop']);


    const [datevalue, setValue] = useState(defaultvalue);

    const [mData1, setmData1] = useState([]);
    const [mData2, setmData2] = useState([]);
    const [mData3, setmData3] = useState([]);



    useEffect(() => {
      setPositionData(grid[breakpoint])
    }, [breakpoint]);


    useEffect(() => {
      const fetchData = async () => {
        let body1 = {
          "bpname": "Materials List",
          "record_fields": "SHImatAccumOutDA;MatinDateDP;record_no;SHIMatOrderDate;SHIMatplanQtyDA;SHIskuQuantityDA;SHIMatinqnty;SHIskuQuantityDA;umatSHICategoryPD;SHITransferrNot;uuu_creation_date;SHImatVendorNameTX50;DestinationName;MatunitpriceCA;SHIMatDeliveryDateExp",
          "lineitem": "no",
          // "filter_criteria": {
          //   "join": "AND",
          //   "filter": [
          //     {
          //       "field": "status",
          //       "value": 'Active',
          //       "condition_type": "eq"
          //     },
  
          //   ]
          // }
        }

        let body2 = {
          "bpname": "Materials List (Junja)",
          "record_fields": "SHIskuOrderedDA;SHImatAccumOutDA;MatinDateDP;record_no;SHIMatOrderDate;SHIskuQuantityDA;SHIMatplanQtyDA;SHIMatinqnty;SHIskuQuantityDA;umatSHICategoryPD;SHITransferrNot;uuu_creation_date;SHImatVendorNameTX50;DestinationName;MatunitpriceCA;SHIMatDeliveryDateExp",
          "lineitem": "no",
          // "filter_criteria": {
          //   "join": "AND",
          //   "filter": [
          //     {
          //       "field": "status",
          //       "value": 'Active',
          //       "condition_type": "eq"
          //     },
  
          //   ]
          // }
        }

        
        let body3 = {
          "bpname": "Release Materials",
          "record_fields": "uuu_creation_date;record_no;SHIMatoutDate2;SHIMatoutType;",
          "lineitem": "yes",
          "lineitem_fields": "SHIMatoutQnty;umatSHICategoryPD;"
          // "filter_criteria": {
          //   "join": "AND",
          //   "filter": [
          //     {
          //       "field": "status",
          //       "value": 'Active',
          //       "condition_type": "eq"
          //     },
  
          //   ]
          // }
        }
  
  
        const res1 = await axios.post(`/getbprecordsdash?path=${project_code}`, body1);
        const res2 = await axios.post(`/getbprecordsdash?path=${project_code}`, body2);
        const res3 = await axios.post(`/getbprecordsdash?path=${project_code}`, body3);
  

        setmData1(res1.data.data.data)
        setmData2(res2.data.data.data)
        setmData3(res3.data.data.data)
      }
      
      fetchData();
    }, [project_code])

    const tiles = [
      {
      defaultPosition: {
        col: 1,
        colSpan: 8,
        rowSpan: 3,
      },
        header: "월별 자재 발주 및 입출고 추이",
        body: <MonthlyMaterial height={(3 * height / 6.5) - 50} mData1={mData1} mData2={mData2} mData3={mData3} />,
        resizable: fixblock,
        reorderable: fixblock,
    
      }, {
        defaultPosition: {
          col: 9,
          colSpan: 8,
          rowSpan: 3,
        },
        header: "구매자재 입고 현황",
        body: <InStockStatus height={(3 * height / 6.5) - 50}  mData1={mData1} mData2={mData2} />,
        resizable: fixblock,
        reorderable: fixblock,
        className: 'milestone'
      },
      {
      defaultPosition: {
        col: 1,
        colSpan: 8,
        rowSpan: 3,
      },
        header: "주간 자재이관 현황",
        body: <WeekltyMaterial height={(3 * height / 6.5) - 50} mData1={mData1} mData2={mData2} mData3={mData3} />,
        resizable: fixblock,
        reorderable: fixblock,
    
      }, {
        defaultPosition: {
          col: 9,
          colSpan: 8,
          rowSpan: 3,
        },
        header: "재고 현황",
        body: <InventoryStatus height={(3 * height / 6.5) - 50} mData1={mData1}/>,
        resizable: fixblock,
        reorderable: fixblock,
        className: 'milestone'
      },
    
      // {
      //   defaultPosition: {
      //     col: 5,
      //     colSpan: 4,
      //     rowSpan: 2,
      //   },
      //   header: "공정현황",
      //   body: "",
      //   resizable: fixblock,
      //   reorderable: fixblock,
      // },
      // {
      //   defaultPosition: {
      //     col: 9,
      //     colSpan: 4,
      //     rowSpan: 2
      //   },
      //   header:"원가현황",
      //   body: "",
      //   resizable: fixblock,
      //   reorderable: fixblock,
    
      // },
      // {
      //   defaultPosition: {
      //     colSpan: 4,
      //     rowSpan: 2,
      //   },
      //   header: "안전활동",
      //   body: "",
      //   resizable: fixblock,
      //   reorderable: fixblock,
    
      // },
    
      // {
      //   defaultPosition: {
      //     col: 5,
      //     colSpan: 3,
      //     rowSpan: 2,
      //   },
      //   header: "인원",
      //   body: "",
      //   resizable: fixblock,
      //   reorderable: fixblock,
      // },
      // {
      //   defaultPosition: {
      //     col: 8,
      //     colSpan: 3,
      //     rowSpan: 2,
      //   },
      //   header: "장비",
      //   body: "",
      //   resizable: fixblock,
      //   reorderable: fixblock,
      // },
    
      // {
      //   defaultPosition: {
      //     col: 11,
      //     colSpan: 6,
      //     rowSpan: 2,
      //   },
      //   header: "품질활동",
      //   body: "",
      //   resizable: fixblock,
      //   reorderable: fixblock,
      // }
      ];
      const handleReposition = (e) => {
        setPositionData(e.value);
      };

    return(
        <DashboardLayout>
           
            <TileLayout
            className='projectpage'

            columns={16}
            rowHeight={height/6.5}
            positions={positiondata}
            gap={{
                rows: 10,
                columns: 10
            }}
            items={tiles}
            autoFlow="row dense" 
            onReposition={handleReposition}
            // style={{marginTop:20}}
            /> 
        </DashboardLayout>
    );

};

export default MaterialPage
