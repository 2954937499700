// CustomToggleButton.js
import React from 'react';
import MuiToggleButton from '@mui/material/ToggleButton';
import MuiToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { styled } from '@mui/material/styles';

export const ToggleButton = styled(MuiToggleButton)(() => ({
    '&.Mui-selected, &.Mui-selected:hover': {
        color: '#ecf1f8',
        backgroundColor: '#4B5335 !important',
    },
    '&.MuiToggleButtonGroup-grouped': {
        color: 'black',
        backgroundColor: '#transparent',
        border: '1px solid snow!important',
        
    },

    '&.Mui-selected, ': {
        color: '#ecf1f8',
        backgroundColor: 'transparent',
    },
    '&.MuiToggleButton-root': {
        fontSize: '0.7rem',
        fontFamily: 'Pretendard-R',
        padding: 0,
        width: 75,
        // backgroundColor: 'white',
        borderRadius: '5px 5px 5px 5px!important',
        border: '1px solid black!important',
        // color: 'white',
        // backgroundColor: '#4B5335',


    }
}));

export const ToggleButtonGroup = styled(MuiToggleButtonGroup)(({theme }) => ({
    '&.MuiToggleButtonGroup-root': {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        "& > *:not(:last-child)": {
            marginRight: theme.spacing(1)
        }
    }}));