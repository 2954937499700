import React, { useEffect, useLayoutEffect } from 'react'
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

const LtiLineChart = ({ chartID, height, chartData, seriesValue }) => {

  console.log(chartID, seriesValue, chartData)


  useLayoutEffect(() => {

    var root = am5.Root.new(chartID);

    root.setThemes([
      am5themes_Animated.new(root)
    ]);


    var chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelX: "none",
        wheelY: "none"
      })
    );

    let tooltip = am5.Tooltip.new(root, {});

    // chart.plotContainer.set("tooltipPosition", "pointer");
    // chart.plotContainer.set("tooltipText", "a");
    // chart.plotContainer.set("tooltip", tooltip);

    // tooltip.label.adapters.add("text", function (text, target) {
    //   text = "";
    //   let i = 0;
    //   chart.series.each(function (series) {
    //     let tooltipDataItem = series.get("tooltipDataItem");
    //     if (tooltipDataItem) {
    //       console.log(tooltipDataItem)
    //       if (i === 0) {
    //         text += "[bold]" + tooltipDataItem.get("valueX") + "월" + "\n";
    //       }

    //       if (i !== 0) {
    //         text += "\n";
    //       }

    //       if (tooltipDataItem.get("valueY")) {
    //         text +=

    //           "[" +
    //           series.get("stroke") +
    //           "]●[/] [width:100px]" +
    //           series.get("name") +
    //           ": " +
    //           Number(
    //             (tooltipDataItem.get("valueY") ?? 0).toFixed(2)
    //           ).toLocaleString()

    //       }
    //     }


    //     i++;
    //   });
    //   return text;
    // });

    // tooltip.get("background").setAll({
    //   stroke: am5.color(0x000000),
    //   strokeOpacity: 0.8,
    //   fill: am5.color(0xffffff),
    //   fillOpacity: 0.8,
    // });

    var xAxis = chart.xAxes.push(
      am5xy.ValueAxis.new(root,

        {
          min: 1,
          max: 12, strictMinMax: true, numberFormat: "#'월",
          renderer: am5xy.AxisRendererX.new(root, {
            minGridDistance: 50
          }),
          tooltip: am5.Tooltip.new(root, {})
        })
    );

    xAxis.get("renderer").labels.template.setAll({
      fontSize: 12,
      textAlign: "center",

    });

    var yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        min: 0,
        strictMinMax: true,
        renderer: am5xy.AxisRendererY.new(root, {})
      })
    );

    var legend = chart.children.push(am5.Legend.new(root, {
      // centerY: am5.percent(50),
      y: am5.percent(90),
      // x:am5.percent(90),
      // layout: root.verticalLayout,
      height: am5.percent(40),
      width:am5.percent(100),

      layout: am5.GridLayout.new(root, {
        maxColumns: 4,
        fixedWidthGrid: true
      }),
      verticalScrollbar: am5.Scrollbar.new(root, {
        orientation: "vertical"
      })
    }));


    function makeSeries(name, fieldName) {
      var series = chart.series.push(am5xy.LineSeries.new(root, {
        name: name,
        minBulletDistance: 10,
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: fieldName,
        valueXField: "month",
        // tooltip: am5.Tooltip.new(root, {}),
        // legendLabelText: "[bold {stroke}]{name}:[/]",
        // legendValueText: "[bold {stroke}]{valueY}[/]",

      }));




      series.bullets.push(function () {
        return am5.Bullet.new(root, {
          sprite: am5.Circle.new(root, {
            radius: 5,
            fill: series.get("fill"),
            stroke: root.interfaceColors.get("background"),
            strokeWidth: 2,
            tooltipText: "{name}:{valueY}",
            // showTooltipOn: "always",
            tooltip: am5.Tooltip.new(root, {})
          })
        });
      });

      series.strokes.template.setAll({ strokeWidth: 1 });

      series.data.setAll(chartData);
      // series.get("tooltip").label.set("text", "[bold]{name}[/]: {valueY}")

      // Make stuff animate on load
      // https://www.amcharts.com/docs/v5/concepts/animations/
      series.appear();

      series.bullets.push(function () {
        return am5.Bullet.new(root, {
          sprite: am5.Label.new(root, {
            text: "{valueY}",
            fill: root.interfaceColors.get("alternativeText"),
            centerY: am5.p50,
            centerX: am5.p50,
            populateText: true
          })
        });
      });
      series.bullets.push(function () {
        return am5.Bullet.new(root, {
          sprite: am5.Circle.new(root, {
            radius: 3,
            fill: series.get("fill"),
            stroke: root.interfaceColors.get("background"),
            strokeWidth: 2
          })
        });
      });
      legend.data.push(series);

      legend.labels.template.setAll({ fontSize: 10 });
    }




    if (chartData.length > 0) {
      seriesValue && seriesValue.forEach(element => {
        makeSeries(element.name, element.filed);

        console.log(element.name, element.filed)
      });

    }

    // Add cursor
    // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
    var cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
      xAxis: xAxis
    }));
    // Add cursor


    chart.appear(1000, 100);

    // Create axis ranges
    function createRange(value, endValue, color, text) {
      var rangeDataItem = yAxis.makeDataItem({
        value: value,
        endValue: endValue
      });

      var range = yAxis.createAxisRange(rangeDataItem);

      if (endValue) {
        range.get("axisFill").setAll({
          fill: color,
          fillOpacity: 0.2,
          visible: true
        });

        range.get("label").setAll({
          fill: am5.color(0xffffff),
          text: text,
          location: 1,
          background: am5.RoundedRectangle.new(root, {
            fill: color
          })
        });
      }
      else {
        range.get("label").setAll({
          fill: am5.color(0xffffff),
          text: text,
          fontSize: 11,
          background: am5.RoundedRectangle.new(root, {
            fill: color
          })
        });
      }

      range.get("grid").setAll({
        stroke: color,
        strokeOpacity: 1,
        location: 1
      });

    }
    if (chartData.length > 0) {
      createRange(chartData[0]?.ltir_plan, undefined, am5.color(0xff621f), "목표 LTIF");
      // createRange(1, undefined, am5.color(0xff621f), "목표 LTIF");
      createRange(chartData[0]?.trcf_plan, undefined, am5.color(0x297373), "목표 TRCF");
    }

    return () => {
      root && root.dispose();
    }
  }, [chartData, seriesValue])

  return (
    <>
      <div id={chartID} style={{ height: height - 18, }}></div>
    </>
  )
}

export default LtiLineChart
