import React, { useEffect, useState } from 'react';
import { GridLayout, GridLayoutItem } from '@progress/kendo-react-layout';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';
import { ComboBox } from '@progress/kendo-react-dropdowns';
import SCurveChart from './MonthlyChart';
import _ from 'lodash';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'; // isSameOrAfter 플러그인을 가져옵니다.
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'; // isSameOrBefore 플러그인을 가져옵니다.
import { DropDownList } from "@progress/kendo-react-dropdowns"

// dayjs에 플러그인을 확장합니다.
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

const MonthlyMaterial = ({ height, mData1, mData2, }) => {
    const defaultvalue = new Date();
    const [datevalue, setDateValue] = React.useState([
        dayjs(dayjs(defaultvalue).subtract(1, 'months').format("YYYY-MM-DD")),
        dayjs(dayjs(defaultvalue).format("YYYY-MM-DD")),
    ]);


    const tlist = ["전체", "PIPE", "FLANGE", "FITTING", "VALVE", "OTHERS"];
    const [tstate, settState] = useState(tlist[0]);
    const [chartdata, setChartdata] = useState([]);

    

    const handletChange = (event) => {
        settState(event.target.value);
    };


    useEffect(() => {

        const totalData = tstate !== "" && tstate === "전체" ? [...mData1, ...mData2] : [...mData1, ...mData2].filter(v => v.umatSHICategoryPD === tstate)

        console.log(mData2)

        const plan = totalData.map((v) => ({ category: dayjs(v.SHIMatOrderDate).format("YYYY-MM"), 
        계획: ( v.SHIMatplanQtyDA === null || v.SHIMatplanQtyDA === undefined ?0:v.SHIMatplanQtyDA  )+(v.SHIskuOrderedDA ===null|| v.SHIskuOrderedDA === undefined?0 :v.SHIskuOrderedDA)}))

        const indata = totalData.filter(v => v.MatinDateDP !== null)
            .map((v) => ({ category: dayjs(v.MatinDateDP).format("YYYY-MM"), "입고": v.SHIMatinqnty }))

        const outdata = totalData.filter(v => v.MatinDateDP !== null)
            .map((v) => ({ category: dayjs(v.MatinDateDP).format("YYYY-MM"), "출고": v.SHImatAccumOutDA }))

        const stockdata = totalData.filter(v => v.MatinDateDP !== null)
            .map((v) => ({ category: dayjs(v.MatinDateDP).format("YYYY-MM"), "재고": v.SHIskuQuantityDA }))



        let chartData =
            _([...plan, ...indata, ...outdata, ...stockdata])
                .groupBy("category")
                .map((objs, key) => ({
                    "category": key,
                    "계획": _.sumBy(objs, "계획"),
                    "입고": _.sumBy(objs, "입고"),
                    "출고": _.sumBy(objs, "출고"),
                    "재고": _.sumBy(objs, "재고"),
                }))
                .value();

        // datevalue 배열에서 날짜 범위를 구함
        const startDate = dayjs(datevalue[0]).format("YYYY-MM");
        const endDate = dayjs(datevalue[1]).format("YYYY-MM");


        // chartData를 날짜 범위로 필터링
        chartData = chartData.filter(item => {
            const itemDate = dayjs(item.category, "YYYY-MM");
            return itemDate.isSameOrAfter(startDate) && itemDate.isSameOrBefore(endDate);
        });


        setChartdata(_.sortBy(chartData, "category").map((v)=>({...v, category:v.category.slice(-5)})));
    }, [mData1, mData2, , tstate, datevalue]);

    const columnItems = [
        { name: "전자자재 발주량+ \n중공업자재 계획수량 ", field_name: "계획", color: "#68AAAC" },
        { name: "입고", field_name: "입고", color: "#1F3864" },
        { name: "출고", field_name: "출고", color: "#A0A0A0" },
    ];

    const lineItems = [
        { name: "재고", field_name: "재고", color: "#E37912" },
    ];

    // const unit = "h"; 
    const lineLabel = true;
    const category = "category";
    const actual_mark_use = true;

    return (
        <>
            <GridLayout style={{ height: height }}
                rows={[
                    {
                        height: '10%',
                    },
                    {
                        height: '90%',
                    },
                ]}
                cols={[
                    {
                        width: '100%',
                    },
                ]}
                gap={{
                    rows: 10,
                    cols: 0,
                }}>
                <GridLayoutItem row={1} col={1} style={{ position: 'relative' }}>
                    <div style={{ display: "flex", fontSize: '0.8rem', top: 0, zIndex: 9999, justifyContent: "flex-end" }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['SingleInputDateRangeField']}>
                                <div style={{ minWidth: 200 }}>
                                    <DateRangePicker
                                        slots={{ field: SingleInputDateRangeField }}
                                        name="allowedRange"
                                        format='YYYY-MM-DD'
                                        value={datevalue}
                                        onChange={(newValue) => setDateValue(newValue)}
                                        className='daterange daterangecolor'
                                    />
                                </div>
                            </DemoContainer>
                        </LocalizationProvider>
                        <DropDownList
                            style={{
                                width: "150px", fontSize: '0.7rem', height: 30, fontWeight: 600, marginLeft: "10px"
                            }}
                            data={tlist}
                            value={tstate}
                            onChange={handletChange}
                        />
                    </div>
                </GridLayoutItem>
                <GridLayoutItem row={2} col={1} style={{ position: 'relative' }}>
                    <SCurveChart
                        height={height * 0.9}
                        data={chartdata}
                        chart_id="exampleChart1"
                        columnItems={columnItems}
                        lineItems={lineItems}
                        lineLabel={lineLabel}
                        category={category}
                        actual_mark_use={actual_mark_use}
                    />
                </GridLayoutItem>
            </GridLayout>
        </>
    );
}

export default MonthlyMaterial;
