import React, { useEffect, useState, useLayoutEffect } from 'react';
import _ from 'lodash';
import {
  Card,
  CardTitle,
  CardBody,
  GridLayout, GridLayoutItem
} from "@progress/kendo-react-layout";
import moment from 'moment';
import { Sparkline, ChartValueAxis, ChartValueAxisItem, ChartTooltip } from '@progress/kendo-react-charts';
import { Tooltip } from "@progress/kendo-react-tooltip";
import axios from "../../common/axios";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import * as am5 from "@amcharts/amcharts5";
import * as am5radar from "@amcharts/amcharts5/radar";
import * as am5xy from "@amcharts/amcharts5/xy";
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'; // isSameOrAfter 플러그인을 가져옵니다.
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'; //
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';
import * as am5percent from "@amcharts/amcharts5/percent";
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import MuiToggleButton from '@mui/material/ToggleButton';
import MuiToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import PieChart from '../common/nivopie';

const ToggleButton2 = styled(MuiToggleButton)(() => ({
  '&.Mui-selected, &.Mui-selected:hover': {
    color: 'white',
    backgroundColor: '#FF6F61',
  },
  '&.MuiToggleButton-root': {
    fontSize: '0.6rem',
    fontFamily: 'Pretendard-R',
    padding: 0,
    width: 50,
    borderRadius: '5px 5px 5px 5px!important',
    border: '1px solid rgba(0, 0, 0, 0.12)!important'

  }
}));

const ToggleButtonGroup2 = styled(MuiToggleButtonGroup)(() => ({
  '&.MuiToggleButtonGroup-root': {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1)
    }
  }
}));


const theme = createTheme({
  palette: {
    text: {
      primary: '#00ff00',
    },
  },
});

const QualityTotal = ({ height }) => {
  const [chartData, setData] = useState([]);
  const [selectedValue, setSelectedValue] = React.useState("전체");
  const [projects, setProjects] = React.useState([]);
  const [type, setType] = useState("감리");


  const handleTypeChange = (event, type) => {
    if (type !== null) {
      setType(type);
    }
  };

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const defaultvalue = new Date();
  const [datevalue, setDateValue] = React.useState([
    dayjs(dayjs(defaultvalue).subtract(1, 'years').format("YYYY-MM-DD")),
    dayjs(dayjs(defaultvalue).format("YYYY-MM-DD")),
  ]);

  const startDate = dayjs(datevalue[0]).format("MM-DD-YYYY");
  const endDate = dayjs(datevalue[1]).format("MM-DD-YYYY");

  useEffect(() => {
    const fetchData = async () => {
      const res2 = await axios.get(`/getprojectlist`)
      if (res2.data.success) {
      setProjects(["전체", ...res2.data.data.map(v => v.projectname)])
      }
    };
    fetchData();
  }, []);



  useEffect(() => {
    const fetchData = async () => {
      const res = await axios.get(`/getqData?start=${startDate}&finish=${endDate}&type=${type}&project=${selectedValue}`)
      if (res.data.success) {
        setData(res.data.data.map((v)=>({...v, color:v.id ==="합격건수"?"#1FD12F":"#E0E0E0"})))
      } else {
        setData([])
      }
    };
    fetchData();

  }, [selectedValue, startDate, endDate, type]);




  return (
    <>
<GridLayout style={{ height: 2 * (height / 6.5) - 70 }}
  rows={[
    {
      height: "10%",
    },
    {
      height: "90%",
    },
  ]}
  cols={[
    {
      width: '50%',
    },
    {
      width: '50%',
    },
  ]}
  gap={{
    rows: 10,
    cols: 0,
  }}>


    {/* 프로젝트 선택기 */}
    <FormControl size="small" sx={{
          m: 1,
          minWidth: 120,
          position: 'absolute', // Use relative positioning
          top: 6,
          right: 10,
          float: 'right', // Align to the right
        }}>
      <InputLabel id="demo-select-small-label">프로젝트</InputLabel>
      <Select
        labelId="demo-select-small-label"
        sx={{fontSize:"0.8rem"}}
        id="demo-select-small"
        value={selectedValue}
        label="프로젝트"
        onChange={handleChange}
      >
        {projects && projects.map((v, index) => (
          <MenuItem key={index} value={v} sx={{fontSize:"0.8rem"}} >{v} </MenuItem>
        ))}
      </Select>
    </FormControl>

    {/* 날짜 선택기 */}
    <div style={{ position: 'absolute', // Use relative positioning
          top: 40
          ,
          left: 16,
          float: 'right', }}>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['SingleInputDateRangeField']}>
        <div style={{ width: "auto" }}>
          <DateRangePicker
            slots={{ field: SingleInputDateRangeField }}
            name="allowedRange"
            format='YYYY-MM-DD'
            value={datevalue}
            disableFuture
            onChange={(newValue) => setDateValue(newValue)}
            sx={{color:"#004098", minWidth:200}}
            className='daterange2'
            
          />
        </div>
      </DemoContainer>
    </LocalizationProvider>
      {/* 프로젝트 선택과 날짜 선택기를 상단 왼쪽에 배치 */}

    </div>

    <div style={{position: 'absolute', // Use relative positioning
          top: 70
          ,
          right: 18,
          float: 'right',}}>
  <ThemeProvider theme={theme}>
            <ToggleButtonGroup2 size="small" onChange={handleTypeChange} value={type} exclusive aria-label="baseunit">
              <ToggleButton2 value="감리" aria-label='감리' >
                감리
              </ToggleButton2>
              <ToggleButton2 value="시공" aria-label="시공">
                시공
              </ToggleButton2>
            </ToggleButtonGroup2>
          </ThemeProvider>
          </div>
  {/* 차트를 중앙에 배치 */}
  <GridLayoutItem row={2} col={1} colSpan={2}>
  <div style={{ position: 'absolute', top: '65%', left: '50%', transform: 'translate(-65%, -50%)', zIndex: '999' }}><span style={{fontSize:14}}>{_.sumBy(chartData, 'value')}</span></div>
        {chartData && 
    <PieChart chartdata={chartData}/>
    }
    {/* <div id="qchart" style={{ width: '100%', height: '100%' }}></div> */}
  </GridLayoutItem>

</GridLayout>



    </>);
}

export default QualityTotal
