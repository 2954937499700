import { Milestones } from 'react-milestones-vis';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';
import axios from "../../../common/axios";
import dayjs from 'dayjs';
import { Circles } from 'react-loader-spinner';

const Milestone = ({ height }) => {
  const { project_code } = useParams(null);
  const [milestonData, setMilestonData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      let body = {
        "bpname": "Project Summary",
        "lineitem": "yes",
        "filter_criteria": {
          "join": "AND",
          "filter": [
            {
              "field": "status",
              "value": 'Active',
              "condition_type": "eq"
            },
          ]
        }
      }
      const res = await axios.post(`/getbprecordsdash?path=${project_code}`, body);
      if (res.data.success && _.sortBy(res.data.data.data, "record_no").reverse()[0] && _.sortBy(res.data.data.data, "record_no").reverse()[0]['_bp_lineitems']) {
        const data = _.sortBy(res.data.data.data, "record_no").reverse()[0]['_bp_lineitems'].filter(v => v.MilestoneCategorySRB === 'Key Milestone');
        const sourcedata = data.filter(v => v.MPlanDateDO !== null)
          .map((v) => ({
            date: v.MActualDateDO !== null ? dayjs(v.MActualDateDO).format('YYYY-MM-DD') : dayjs(v.MPlanDateDO).format('YYYY-MM-DD'),
            title: v.MActualDateDO !== null ? v.MilestoneName + '(Plan: ' + moment(v.MPlanDateDO).format('YYYY-MM-DD') + ')' : v.MilestoneName,
            titleStyle: {
              color: v.MActualDateDO !== null && parseFloat(moment(v.MActualDateDO).diff(moment(v.MPlanDateDO), 'days')) > 0 ? '#A2242F' :
                v.MActualDateDO !== null && parseFloat(moment(v.MActualDateDO).diff(moment(v.MPlanDateDO), 'days')) <= 0 ? '#00A170' : '#525252'
            },
          }))

        const filteredData = sourcedata.filter(data => data.date && data.title);
        const uniqueData = _.uniqBy(filteredData, (data) => data.title + data.date);
        setMilestonData(uniqueData);
      }
    };
    fetchData();
  }, [project_code]);

  return (
    <>
      <div style={{ height: height }}>
        <div style={{
          marginTop: '100px',
          height: height,
        }}>
          {milestonData.length > 0 && (
            <Milestones
              aggregateBy="day"
              data={milestonData}
              mapping={{
                text: 'title',
                timestamp: 'date'
              }}
              optimize={true}
              parseTime="%Y-%m-%d"
              distribution='top-bottom'
              autoResize={true}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Milestone;