import React, { useState, useEffect } from 'react'
import { GridLayout, GridLayoutItem } from '@progress/kendo-react-layout'
import { Box, Stack } from '@mui/material'
import { DropDownList } from "@progress/kendo-react-dropdowns"
import _ from 'lodash';
import { ResponsivePie } from '@nivo/pie'


const InventoryStatus = ({ height, mData1 }) => {
    const tlist = ["물량", "금액"]
    const [tstate, settState] = useState(tlist[0]);
    const [selectedVendor, setSelectedVendor] = useState("전체");
    const [selectedDestination, setSelectedDestination] = useState("전체");

    const [cost, setCost] = useState(0);
    const [remainCost, setRemainCost] = useState(0);
    const [remainQty, setRemainQty] = useState(0);

    const [chartData, setChartData] = useState([]);


    useEffect(() => {
        const cost = _.sumBy(
            mData1.filter(v =>
                (selectedVendor === "전체" || v.SHImatVendorNameTX50 === selectedVendor) &&
                (selectedDestination === "전체" || v.DestinationName === selectedDestination))
                .map((v) => ({ cost: v.MatunitpriceCA * v.SHIMatplanQtyDA })), "cost");

        const remainCost = _.sumBy(
            mData1.filter(v =>
                (selectedVendor === "전체" || v.SHImatVendorNameTX50 === selectedVendor) &&
                (selectedDestination === "전체" || v.DestinationName === selectedDestination))
                .map((v) => ({ cost: v.MatunitpriceCA * v.SHIskuQuantityDA })), "cost");

        const remainQty = _.sumBy(mData1.filter(v =>
            (selectedVendor === "전체" || v.SHImatVendorNameTX50 === selectedVendor) &&
            (selectedDestination === "전체" || v.DestinationName === selectedDestination)), "SHIskuQuantityDA");

        setCost(cost);
        setRemainCost(remainCost);
        setRemainQty(remainQty);

    }, [mData1, selectedVendor, selectedDestination]);


    useEffect(() => {

        let chartDataInter =
            _(mData1)
                .groupBy("umatSHICategoryPD")
                .map((objs, key) => ({
                    "id": key,
                    "label": key,
                    "value": tstate === "물량" ? _.sumBy(objs, "SHIskuQuantityDA") : _.sumBy(objs.map((vf) => ({ cost: vf.SHIskuQuantityDA * vf.MatunitpriceCA }), "cost")),
                }))
                .value();

        setChartData(chartDataInter)

    }, [mData1, tstate]);

    const vendorList = ["전체", ..._.uniq(mData1.map(v => v.SHImatVendorNameTX50).filter(v => v !== null))]
    const destinationList = ["전체", ..._.uniq(mData1.map(v => v.DestinationName).filter(v => v !== null))]

    console.log(vendorList)

    const handleVendorChange = (event) => {
        setSelectedVendor(event.target.value);
    };

    const handleDestinationChange = (event) => {
        setSelectedDestination(event.target.value);
    };

    const handletChange = (event) => {
        settState(event.target.value);
    };
    return (
        <>
            <GridLayout style={{ height: height }}
                rows={[
                    {
                        height: '8%',
                    },
                    {
                        height: '92%',
                    },

                ]}
                cols={[
                    {
                        width: '50%',
                    },
                    {
                        width: '50%',
                    },


                ]}
                gap={{
                    rows: 10,
                    cols: 0,
                }}>


                <GridLayoutItem row={1} col={2} colSpan={2} style={{ position: 'relative' }}>

                    <div style={{ display: "flex", fontSize: '0.8rem', top: 0, zIndex: 9999, justifyContent: "flex-end" }}>
                        <DropDownList
                            style={{
                                width: "100px", fontSize: '0.7rem', height: 30, fontWeight: 600, marginLeft: "10px"
                            }}
                            data={tlist}
                            value={tstate}
                            onChange={handletChange}
                        />
                    </div>
                </GridLayoutItem>
                <GridLayoutItem row={1} col={1} rowSpan={2} style={{ position: 'relative' }}>
                    <div style={{ display: "flex", marginBottom: "20px", justifyContent: "center" }}>
                        <div style={{ display: "flex", textAlign: "center", marginRight: "10px", fontSize: "12px", alignItems: "center" }}><b>협력사 선택</b></div>
                        <DropDownList
                            style={{
                                width: "70%", marginRight: '5px'
                            }}
                            data={vendorList}
                            placeholder="협력사를 선택하세요"
                            value={selectedVendor}
                            onChange={handleVendorChange}
                            className='disc'
                        />
                    </div>
                    <div style={{ display: "flex", marginBottom: "30px", justifyContent: "center" }}>
                        <div style={{ display: "flex", textAlign: "center", marginRight: "10px", fontSize: "12px", alignItems: "center" }}><b>도착지 선택</b></div>
                        <DropDownList
                            style={{
                                width: "70%", marginRight: '5px'
                            }}
                            data={destinationList}
                            placeholder="도착지를 선택하세요"
                            value={selectedDestination}
                            onChange={handleDestinationChange}
                            className='disc'
                        />
                    </div>

                    <Box sx={{ width: "90%", margin: "auto" }}>
                        <Stack spacing={{ xs: 1, sm: 2 }} direction="column" useFlexGap flexWrap="wrap">
                            <div style={{ height: height * 0.5 * 0.4 - 8, border: "1px solid #E7E6E6", borderRadius: "4px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <div > {/* 이 부분을 추가하여 내부 div를 수평으로 배치 */}
                                    <div style={{ textAlign: 'center', color: '#939597', fontSize: '1rem', marginRight: "8px" }}>발주금액</div>
                                    <div style={{ textAlign: 'center', fontSize: '1.5rem', fontWeight: 'bold', color: "#424242" }}>
                                        {(cost / 100000000).toFixed(2)}억
                                    </div>
                                </div>
                            </div>
                            <div style={{ height: height * 0.5 * 0.4 - 8, border: "1px solid #E7E6E6", borderRadius: "4px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <div > {/* 이 부분을 추가하여 내부 div를 수평으로 배치 */}
                                    <div style={{ textAlign: 'center', color: '#939597', fontSize: '1rem', marginRight: "8px" }}>총 재고자산</div>
                                    <div style={{ textAlign: 'center', fontSize: '1.5rem', fontWeight: 'bold', color: "#424242" }}>
                                        {(remainCost / 100000000).toFixed(2)}억
                                    </div>
                                </div>
                            </div>
                            <div style={{ height: height * 0.5 * 0.4 - 8, border: "1px solid #E7E6E6", borderRadius: "4px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <div > {/* 이 부분을 추가하여 내부 div를 수평으로 배치 */}
                                    <div style={{ textAlign: 'center', color: '#939597', fontSize: '1rem', marginRight: "8px" }}>총 재고 물량</div>
                                    <div style={{ textAlign: 'center', fontSize: '1.5rem', fontWeight: 'bold', color: "#424242" }}>
                                        {remainQty.toFixed(0)}
                                    </div>
                                </div>
                            </div>

                        </Stack>
                    </Box>
                </GridLayoutItem>
                <GridLayoutItem row={1} rowSpan={2} col={2} style={{ position: 'relative' }}>
                <div style={{ position: 'absolute', top: '45%', left: '50%', transform: 'translate(-45%, -50%)', zIndex: '999' }}><span style={{ fontSize: '0.9rem'  }} >대분류별 재고현황</span></div>

                    <ResponsivePie
                        data={chartData}
                        margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                        innerRadius={0.7}
                        cornerRadius={3}
                        activeOuterRadiusOffset={8}
                        borderWidth={1}
                        borderColor={{
                            from: 'color',
                            modifiers: [
                                [
                                    'darker',
                                    0.2
                                ]
                            ]
                        }}
                        arcLinkLabelsSkipAngle={10}
                        arcLinkLabelsTextColor="#333333"
                        arcLinkLabelsThickness={2}
                        arcLinkLabelsColor={{ from: 'color' }}
                        arcLabelsSkipAngle={10}
                        arcLabelsTextColor={{
                            from: 'color',
                            modifiers: [
                                [
                                    'darker',
                                    2
                                ]
                            ]
                        }}
                        legends={[
                            {
                                anchor: 'bottom',
                                direction: 'row',
                                justify: false,
                                translateX: 0,
                                translateY: 44,
                                itemsSpacing: 0,
                                itemWidth: 78,
                                itemHeight: 10,
                                itemTextColor: '#999',
                                itemDirection: 'left-to-right',
                                itemOpacity: 1,
                                symbolSize: 11,
                                symbolShape: 'circle',
                                effects: [
                                    {
                                        on: 'hover',
                                        style: {
                                            itemTextColor: '#000'
                                        }
                                    }
                                ]
                            }
                        ]}
                    />
                </GridLayoutItem>
            </GridLayout>

        </>
    )
}

export default InventoryStatus
