import React from "react";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";

const SupervisionTable = ({ height, data }) => {
  const {
    request,
    canceled,
    exception,
    actual,
    request_actaul_percent,
    failed,
    first_pass_percent,
  } = data;

  return (
    <GridLayout
      style={{ height: height }}
      className="quality-detail-inspection-supervision-table"
      rows={Array.from({ length: 5 }).map(() => {
        return {
          height: height / 5,
        };
      })}
      cols={Array.from({ length: 6 }).map(() => {
        return {
          width: "calc(100% / 6)",
        };
      })}
      gap={{
        rows: 0,
        cols: 0,
      }}
    >
      <GridLayoutItem
        className="quality-detail-inspection-cons-table-item double-line"
        row={1}
        rowSpan={4}
        col={2}
        colSpan={5}
      />
      <GridLayoutItem
        className="quality-detail-inspection-cons-table-item double-line"
        row={1}
        rowSpan={5}
        col={1}
        colSpan={6}
      />
      <GridLayoutItem
        className="quality-detail-inspection-cons-table-item right-bold bg-color-light-blue"
        row={1}
        rowSpan={5}
        col={1}
      >
        <div className="quality-detail-inspection-table-text bold">
          계획
          <br />
          &
          <br />
          관리
        </div>
      </GridLayoutItem>
      <GridLayoutItem
        className="quality-detail-inspection-cons-table-item bg-color-light-blue"
        row={1}
        col={2}
        colSpan={2}
      >
        <div className="quality-detail-inspection-table-text bold">신청</div>
      </GridLayoutItem>
      <GridLayoutItem
        className="quality-detail-inspection-cons-table-item right-bold"
        row={1}
        col={4}
      >
        <div className="quality-detail-inspection-table-text">{request}</div>
      </GridLayoutItem>
      <GridLayoutItem
        className="quality-detail-inspection-cons-table-item bg-color-light-gray"
        row={2}
        col={2}
        colSpan={2}
      >
        <div className="quality-detail-inspection-table-text bold">취소</div>
      </GridLayoutItem>
      <GridLayoutItem
        className="quality-detail-inspection-cons-table-item right-bold bg-color-light-gray"
        row={2}
        col={4}
      >
        <div className="quality-detail-inspection-table-text">{canceled}</div>
      </GridLayoutItem>
      <GridLayoutItem
        className="quality-detail-inspection-cons-table-item bg-color-light-gray"
        row={3}
        col={2}
        colSpan={2}
      >
        <div className="quality-detail-inspection-table-text bold">예외</div>
      </GridLayoutItem>
      <GridLayoutItem
        className="quality-detail-inspection-cons-table-item right-bold bg-color-light-gray"
        row={3}
        col={4}
      >
        <div className="quality-detail-inspection-table-text">{exception}</div>
      </GridLayoutItem>
      <GridLayoutItem
        className="quality-detail-inspection-cons-table-item bg-color-light-blue"
        row={4}
        col={2}
        colSpan={2}
      >
        <div className="quality-detail-inspection-table-text bold">수행</div>
      </GridLayoutItem>
      <GridLayoutItem
        className="quality-detail-inspection-cons-table-item right-bold"
        row={4}
        col={4}
      >
        <div className="quality-detail-inspection-table-text">{actual}</div>
      </GridLayoutItem>
      <GridLayoutItem
        className="quality-detail-inspection-cons-table-item right-bold bg-color-light-blue"
        row={1}
        rowSpan={4}
        col={5}
      >
        <div className="quality-detail-inspection-table-text bold">
          <div className="quality-detail-inspection-table-text bold">
            계획
            <br />
            대비
            <br />
            수행률
          </div>
        </div>
      </GridLayoutItem>
      <GridLayoutItem
        className="quality-detail-inspection-cons-table-item right-bold"
        row={1}
        rowSpan={4}
        col={6}
      >
        <div className="quality-detail-inspection-table-text bold">
          {request_actaul_percent.toFixed(2)}%
        </div>
      </GridLayoutItem>
      <GridLayoutItem
        className="quality-detail-inspection-cons-table-item"
        row={5}
        col={2}
        colSpan={2}
      >
        <div className="quality-detail-inspection-table-text bold">불합격</div>
      </GridLayoutItem>
      <GridLayoutItem
        className="quality-detail-inspection-cons-table-item right-bold"
        row={5}
        col={4}
      >
        <div className="quality-detail-inspection-table-text">{failed}</div>
      </GridLayoutItem>
      <GridLayoutItem
        className="quality-detail-inspection-cons-table-item right-bold"
        row={5}
        col={5}
      >
        <div className="quality-detail-inspection-table-text bold">합격률</div>
      </GridLayoutItem>
      <GridLayoutItem
        className="quality-detail-inspection-cons-table-item right-bold"
        row={5}
        col={6}
      >
        <div className="quality-detail-inspection-table-text bold">
          {first_pass_percent.toFixed(2)}%
        </div>
      </GridLayoutItem>
    </GridLayout>
  );
};

export default SupervisionTable;
