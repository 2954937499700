import React from "react";
import { DataGrid } from '@mui/x-data-grid';

const IndicatorKTable = ({ rows = [], height }) => {
  return (
    <div style={{ height }}>
      <DataGrid
        rows={rows}
        columns={columns}
        hideFooter={true}
        sx={{
          "& .MuiDataGrid-cell": {
            fontFamily: "Inter",
            fontSize: "14px",
            color: "black",
          },
          "& .MuiDataGrid-columnHeaders": {
            fontFamily: "Inter",
            fontWeight: 700,
            backgroundColor: "#F9FAFB",
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            fontFamily: "Inter",
            fontWeight: 700,
          },
        }}
        columnHeaderHeight={40}
        rowHeight={40}
      />
    </div>
  );
};

export default IndicatorKTable;

const columns = [
  { field: "ranking", headerName: "순위", width: 80 },
  { field: "vendor_name", headerName: "협력업체명", flex: 1 },
  { field: "vendor_point", headerName: "점수", width: 120 },
];
