import React, { useCallback, useEffect, useState } from 'react'
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import { Badge, BadgeContainer } from "@progress/kendo-react-indicators";

const AccidentReportTable2 = ({ tableData, height }) => {

    const [visibleDialog, setVisibleDialog] = useState(true);
    const [visibleDialog2, setVisibleDialog2] = useState(true);
    const [visibleDialog3, setVisibleDialog3] = useState(true);

    const toggleDialog1 = useCallback(() => {
        setVisibleDialog(!visibleDialog);
    }, [visibleDialog]);
    const toggleDialog2 = useCallback(() => {
        setVisibleDialog2(!visibleDialog2);
    }, [visibleDialog2]);

    const toggleDialog3 = useCallback(() => {
        setVisibleDialog3(!visibleDialog3);
    }, [visibleDialog3]);
    return (
        <table style={{ fontSize: '0.8rem', width: '90%', fontWeight: '500', borderCollapse: 'collapse', marginTop: "10px" }}>
            <thead>
                <tr>
                    <th></th>
                    <th style={{ backgroundColor: '#f8f8f8', padding: '3px', textAlign: 'center' }}>
                        <Button style={{ fontSize: '0.8rem', fontWeight: '600', backgroundColor: '#939597', color: 'white', width: '90%' }}>
                            위험
                        </Button>
                    </th>
                    <th style={{ backgroundColor: '#ffe8e6', textAlign: 'center' }}>
                        <Button style={{ fontSize: '0.8rem', fontWeight: '600', backgroundColor: '#FF6F61', color: 'white', width: '90%' }}>
                            화기
                        </Button>
                    </th>
                    {/* <th style={{ backgroundColor: '#ffe8e6', textAlign: 'center' }}>
      <Button style={{ fontSize: '0.8rem', fontWeight: '600', backgroundColor: '#FF6F61', color: 'white', width: '90%' }}>
        작업중지 &gt;
      </Button>
    </th> */}
                </tr>
            </thead>
            <tbody style={{ borderBottom: '1px solid #EDF1FF' }}>
                <tr style={{ height: height * 0.22, textAlign: 'center', borderTop: '1px solid #EDF1FF', padding: '3px' }}>
                    <td style={{ paddingLeft: '3px', paddingRight: '3px', fontSize: '0.8rem', cursor: 'pointer' }} onClick={toggleDialog1}>A등급</td>
                    <td style={{ backgroundColor: '#f8f8f8' }}>{tableData.filter(c => c.grade === "A등급")[0] && tableData.filter(c => c.grade === "A등급")[0].dangerCount}</td>
                    {/* <td style={{ backgroundColor: '#eff5f4' }}><Badge shape="dot" style={{ fontSize: '0.8rem', fontWeight: '700', width: '1.8rem', height: '1.8rem', backgroundColor: 'white', color: '#006b54', borderColor: '#006b54' }}></td> */}
                    <td style={{ backgroundColor: '#ffe8e6' }}>{tableData.filter(c => c.grade === "A등급")[0] && tableData.filter(c => c.grade === "A등급")[0].fireCount}</td>
                </tr>
                <tr style={{ height: height * 0.22, textAlign: 'center', borderTop: '1px solid #EDF1FF', padding: '3px' }}>
                    <td style={{ paddingLeft: '3px', paddingRight: '3px', fontSize: '0.8rem', cursor: 'pointer' }} onClick={toggleDialog2}>B등급 </td>
                    <td style={{ backgroundColor: '#f8f8f8' }}>{tableData.filter(c => c.grade === "B등급")[0] && tableData.filter(c => c.grade === "B등급")[0].dangerCount}</td>
                    {/* <td style={{ backgroundColor: '#eff5f4' }}><Badge shape="dot" style={{ fontSize: '0.8rem', fontWeight: '700', width: '1.8rem', height: '1.8rem', backgroundColor: 'white', color: '#006b54', borderColor: '#006b54' }}></td> */}
                    <td style={{ backgroundColor: '#ffe8e6' }}>{tableData.filter(c => c.grade === "B등급")[0] && tableData.filter(c => c.grade === "B등급")[0].fireCount}</td>
                </tr>
                <tr style={{ height: height * 0.22, textAlign: 'center', borderTop: '1px solid #EDF1FF', padding: '3px' }}>
                    <td style={{ paddingLeft: '3px', paddingRight: '3px', fontSize: '0.8rem', cursor: 'pointer' }} onClick={toggleDialog2}>C, D등급 </td>
                    <td style={{ backgroundColor: '#f8f8f8' }}>{tableData.filter(c => c.grade === "C, D등급")[0] && tableData.filter(c => c.grade === "C, D등급")[0].dangerCount}</td>
                    {/* <td style={{ backgroundColor: '#eff5f4' }}><Badge shape="dot" style={{ fontSize: '0.8rem', fontWeight: '700', width: '1.8rem', height: '1.8rem', backgroundColor: 'white', color: '#006b54', borderColor: '#006b54' }}></td> */}
                    <td style={{ backgroundColor: '#ffe8e6' }}>{tableData.filter(c => c.grade === "C, D등급")[0] && tableData.filter(c => c.grade === "C, D등급")[0].fireCount}</td>
                </tr>
            </tbody>
        </table>
    )
}


export default AccidentReportTable2