import React from 'react';
import { DataGridPremium } from '@mui/x-data-grid-premium';

const ProgressDetail1 = ({ height, tableData }) => {
  const columnGroupingModel = [
    {
      groupId: 'accumulated',
      headerName: '누적',
      children: [
        { field: 'spi_a' },
        { field: 'planProgress' },
        { field: 'actualProgress' },
        { field: 'dev1' },
      ],
    },
    {
      groupId: 'weekly',
      headerName: '주간',
      children: [
        { field: 'spi_w' },
        { field: 'weekPlanProgress' },
        { field: 'weekActualProgress' },
        { field: 'dev2' },
      ],
    },
  ];

  const columns = [
    {
      field: 'wbs1',
      headerName: "시공사",
      flex: 0.3,
      headerClassName: 'bold-header',
    },
    {
      field: 'wbs2',
      headerName: "상품",
      flex: 0.3,
      headerClassName: 'bold-header',
    },
    {
      field: 'wbs3',
      headerName: "대공종",
      flex: 0.3,
      headerClassName: 'bold-header',
    },
    {
      field: 'spi_a',
      headerName: "SPI",
      flex: 0.25,
            cellClassName: 'cell-center',
            headerClassName: 'bold-header',
      renderCell: (params) => (
        <div style={{ color: params.value >= 1 ? 'green' : 'red' }}>
          {params.value.toFixed(2)}
        </div>
      ),
    },
    {
      field: 'planProgress',
      headerName: "계획",
      flex: 0.25,
            cellClassName: 'cell-center',
            headerClassName: 'bold-header',
      valueFormatter: (value) => `${(value * 100).toFixed(1)}%`,
    },
    {
      field: 'actualProgress',
      headerName: "실적",
      flex: 0.25,
            cellClassName: 'cell-center',
            headerClassName: 'bold-header',
      valueFormatter: (value) => `${(value * 100).toFixed(1)}%`,
    },
    {
      field: 'dev1',
      headerName: "dev",
      flex: 0.25,
            cellClassName: 'cell-center',
            headerClassName: 'bold-header',
      valueFormatter: (value) => `${(value * 100).toFixed(1)}%`,
    },
    {
      field: 'spi_w',
      headerName: "SPI",
      flex: 0.25,
            cellClassName: 'cell-center',
            headerClassName: 'bold-header',
      renderCell: (params) => (
        <div style={{ color: params.value >= 1 ? 'green' : 'red' }}>
          {params.value.toFixed(2)}
        </div>
      ),
    },
    {
      field: 'weekPlanProgress',
      headerName: "계획",
      flex: 0.25,
            cellClassName: 'cell-center',
            headerClassName: 'bold-header',
      valueFormatter: (value) => `${(value * 100).toFixed(1)}%`,
    },
    {
      field: 'weekActualProgress',
      headerName: "실적",
      flex: 0.25,
            cellClassName: 'cell-center',
            headerClassName: 'bold-header',
      valueFormatter: (value) => `${(value * 100).toFixed(1)}%`,
    },
    {
      field: 'dev2',
      headerName: "dev",
      flex: 0.25,
            cellClassName: 'cell-center',
            headerClassName: 'bold-header',
      valueFormatter: (value) => `${(value * 100).toFixed(1)}%`,
    },
  ];

  const processedData = tableData.map((row) => ({
    ...row,
    spi_a: row.actualProgress / row.planProgress,
    spi_w: row.weekActualProgress / row.weekPlanProgress,
    dev1: row.actualProgress - row.planProgress,
    dev2: row.weekActualProgress - row.weekPlanProgress,
  }));

  console.log(processedData)

  const commonGridProps = {
    rows: processedData,
    columns: columns,
    density: "compact",
    // rowHeight: 38,
    hideFooter: true,
    columnGroupingModel: columnGroupingModel,
    sx: {
      '& .MuiDataGrid-columnHeaderTitle': {
        fontWeight: 'bold',
        fontSize: 12,
      },
      '& .MuiDataGrid-cell': {
        fontSize: 12,
        whiteSpace: 'normal',
        lineHeight: 'normal',
        display: 'flex',
        alignItems: 'center',
        paddingTop: '4px',
        paddingBottom: '4px',

      },
      '& .cell-center': {
        justifyContent: 'center',
        textAlign: 'center',
    },
    },
  };

  return (
    <div style={{ height: height/3 - 100, width: '100%' }}>
      <DataGridPremium {...commonGridProps} />
    </div>
  );
};

export default ProgressDetail1;