import React, { useMemo, useState } from "react";
import { useRecoilValue } from "recoil";
import {
  QualityProjectList,
  QualityPQIData,
} from "../../../../atom/qualityState";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import _ from "lodash";
import IndicatorKTable from "./IndicatorKTable";

import "./indicatorK.css";

const IndicatorK = ({ height, targetPhase, targetProject }) => {
  const projectList = useRecoilValue(QualityProjectList);
  const data = useRecoilValue(QualityPQIData);

  // const [targetProject, setTargetProject] = useState({
  //   label: "전체",
  //   value: "all",
  // });

  const filter_data = useMemo(() => {
    const result = {
      ncr: [],
      inspection_log: [],
      factory_inspection: [],
      material_inspection: [],
      inspection_request: [],
      completion_inspection: [],
      vendor_contact_cost: [],
    };

    let copy_data = [...data];

    if (
      targetPhase?.value &&
      targetPhase?.value !== "all" &&
      targetProject === null
    ) {
      copy_data = copy_data.filter((c) => {
        const { projectname, phase, projectnumber } = c;
        return targetPhase.label === phase;
      });
    }

    if (
      targetPhase?.value &&
      targetPhase?.value !== "all" &&
      targetProject !== null
    ) {
      copy_data = copy_data.filter((c) => {
        const { projectname, phase, projectnumber } = c;
        return targetProject.value === projectnumber;
      });
    }

    // if (targetProject?.value !== "all") {
    // const find_target_data = data.find(
    //   (c) => c.projectnumber === targetProject?.value
    // );
    // if (!find_target_data) {
    //   return {
    //     ...result,
    //   };
    // }
    // const find_target_project_vendor_cost =
    //   projectList.find((c2) => {
    //     const { value } = c2;
    //     return value === targetProject?.value;
    //   }) ?? [];
    // return {
    //   ...find_target_data.data,
    //   vendor_contact_cost: (
    //     find_target_project_vendor_cost?.contract_data ?? []
    //   ).map((c2) => {
    //     return { ...c2, data_type: "contact_cost" };
    //   }),
    // };
    // }

    copy_data.forEach((c) => {
      const {
        projectnumber,
        data: {
          ncr,
          inspection_log,
          factory_inspection,
          material_inspection,
          inspection_request,
          completion_inspection,
        },
      } = c;

      const find_target_project_vendor_cost =
        projectList.find((c2) => {
          const { value } = c2;

          return value === projectnumber;
        }) ?? [];

      const vendor_cost = (
        find_target_project_vendor_cost?.contract_data ?? []
      ).map((c2) => {
        const { project_projectname, uveVendorNameTB50 } = c2;

        const uveVendorNameTB502 =
          targetPhase?.value !== "all"
            ? uveVendorNameTB50
            : `${project_projectname.substring(0, 2)} / ${uveVendorNameTB50}`;

        return {
          ...c2,
          data_type: "contact_cost",
          uveVendorNameTB50: uveVendorNameTB502,
        };
      });

      result.vendor_contact_cost.push(...vendor_cost);

      result.ncr.push(
        ...ncr.map((c) => {
          const { uveVendorNameTB50, project_projectname } = c;

          const uveVendorNameTB502 =
            targetPhase?.value !== "all"
              ? uveVendorNameTB50
              : `${project_projectname.substring(0, 2)} / ${uveVendorNameTB50}`;

          return {
            ...c,
            data_type: "ncr",
            uveVendorNameTB50: uveVendorNameTB502,
          };
        })
      );

      result.inspection_log.push(
        ...inspection_log.map((c) => {
          const { uveVendorNameTB50, project_projectname } = c;

          const uveVendorNameTB502 =
            targetPhase?.value !== "all"
              ? uveVendorNameTB50
              : `${project_projectname.substring(0, 2)} / ${uveVendorNameTB50}`;
          return {
            ...c,
            data_type: "inspection_log",
            uveVendorNameTB50: uveVendorNameTB502,
          };
        })
      );

      result.factory_inspection.push(
        ...factory_inspection.map((c) => {
          const { uveVendorNameTB50, project_projectname } = c;

          const uveVendorNameTB502 =
            targetPhase?.value !== "all"
              ? uveVendorNameTB50
              : `${project_projectname.substring(0, 2)} / ${uveVendorNameTB50}`;
          return {
            ...c,
            data_type: "factory_inspection",
            uveVendorNameTB50: uveVendorNameTB502,
          };
        })
      );

      result.material_inspection.push(
        ...material_inspection.map((c) => {
          const { uveVendorNameTB50, project_projectname } = c;

          const uveVendorNameTB502 =
            targetPhase?.value !== "all"
              ? uveVendorNameTB50
              : `${project_projectname.substring(0, 2)} / ${uveVendorNameTB50}`;
          return {
            ...c,
            data_type: "material_inspection",
            uveVendorNameTB50: uveVendorNameTB502,
          };
        })
      );

      result.inspection_request.push(
        ...inspection_request.map((c) => {
          const { uveVendorNameTB50, project_projectname } = c;

          const uveVendorNameTB502 =
            targetProject?.value !== "all"
              ? uveVendorNameTB50
              : `${project_projectname.substring(0, 2)} / ${uveVendorNameTB50}`;
          return {
            ...c,
            data_type: "inspection_request",
            uveVendorNameTB50: uveVendorNameTB502,
          };
        })
      );

      result.completion_inspection.push(
        ...completion_inspection.map((c) => {
          const { uveVendorNameTB50, project_projectname } = c;

          const uveVendorNameTB502 =
            targetProject?.value !== "all"
              ? uveVendorNameTB50
              : `${project_projectname.substring(0, 2)} / ${uveVendorNameTB50}`;
          return {
            ...c,
            data_type: "completion_inspection",
            uveVendorNameTB50: uveVendorNameTB502,
          };
        })
      );
    });

    return result;
  }, [data, targetProject, targetPhase]);

  const table_data = useMemo(() => {
    const {
      ncr,
      inspection_log,
      factory_inspection,
      material_inspection,
      inspection_request,
      completion_inspection,
      vendor_contact_cost,
    } = filter_data;

    const vendor_group = _([
      ...ncr,
      ...inspection_log,
      ...factory_inspection,
      ...material_inspection,
      ...inspection_request,
      ...completion_inspection,
      ...vendor_contact_cost,
    ])
      .groupBy("uveVendorNameTB50")
      .map((objs, key) => {
        const vendor_ncr_data = [];
        const vendor_inspection_log_data = [];
        const vendor_factory_inspection_data = [];
        const vendor_material_inspection_data = [];
        const vendor_inspection_request_data = [];
        const vendor_completion_inspection_data = [];
        const vendor_contact_cost = [];

        objs.forEach((c) => {
          const { data_type } = c;

          if (data_type === "contact_cost") {
            vendor_contact_cost.push(c);
          }

          if (data_type === "ncr") {
            vendor_ncr_data.push(c);
          }

          if (data_type === "inspection_log") {
            vendor_inspection_log_data.push(c);
          }

          if (data_type === "factory_inspection") {
            vendor_factory_inspection_data.push(c);
          }

          if (data_type === "material_inspection") {
            vendor_material_inspection_data.push(c);
          }

          if (data_type === "inspection_request") {
            vendor_inspection_request_data.push(c);
          }

          if (data_type === "completion_inspection") {
            vendor_completion_inspection_data.push(c);
          }
        });

        const inspection_actual = vendor_inspection_log_data.filter(
          (c) => c.PreviousAction === "시공사 검사결과 입력 요청"
        ).length;

        const inspection_approval = vendor_inspection_log_data.filter(
          (c) => c.PreviousAction === "시공사 합격"
        ).length;

        const inspection_point2 = returnZeroIfInvalid(
          (inspection_approval / inspection_actual) * 100
        );

        const inspection_point = adjust_inspection_approval_point(
          returnZeroIfInvalid(inspection_point2)
        );

        const ncr_accrual_average_ncr = vendor_ncr_data.filter(
          (c) => c.NCRCategoryTxt === "검사 중"
        ).length;

        const ncr_accrual_average_actual = [
          ...vendor_factory_inspection_data,
          ...vendor_material_inspection_data,
          ...vendor_inspection_request_data,
          ...vendor_completion_inspection_data,
        ].length;

        const ncr_accrual_average_value = returnZeroIfInvalid(
          vendor_ncr_data.length / ncr_accrual_average_actual
        );

        const ncr_accrual_average_point = pointRange(
          "ncr_accrual_average",
          25 - 25 * ncr_accrual_average_value
        );

        const action_on_time_count = vendor_ncr_data.filter(
          (c) => c.NCRActionDuration <= 5
        ).length;

        const action_on_time_acutal = vendor_ncr_data.length;

        const action_on_time_point = adjust_action_on_time_point(
          returnZeroIfInvalid(
            (action_on_time_count / action_on_time_acutal) * 100
          )
        );

        const ncr_count_point = adjust_ncr_count_point(
          vendor_ncr_data.filter((c) => c.NCRCategoryTxt === "검사 외").length
        );

        const use = [
          vendor_ncr_data,
          vendor_inspection_log_data,
          vendor_factory_inspection_data,
          vendor_material_inspection_data,
          vendor_inspection_request_data,
          vendor_completion_inspection_data,
          vendor_contact_cost,
        ].every((c) => c.length === 0);

        return {
          vendor_name: key,
          contact_cost: _.sumBy(vendor_contact_cost, "LatestContractAmount"),
          inspection_point,
          ncr_accrual_average_point,
          action_on_time_point,
          ncr_count_point,
          vendor_point: use
            ? 0
            : Number(
                inspection_point +
                  ncr_accrual_average_point +
                  action_on_time_point +
                  ncr_count_point
              ).toFixed(1),
        };
      })
      .value();

    const major_vendor_group = vendor_group
      .filter((c) => c.contact_cost >= 1500000000)
      .filter((c) => c.vendor_point !== 0);

    const best_vendor_ranking = _.sortBy(major_vendor_group, "vendor_point")
      .reverse()
      .slice(0, 10)
      .map((c, i) => {
        return { ...c, id: i + 1, ranking: i + 1 };
      });

    return { best_vendor_ranking };
  }, [filter_data]);

  return (
    <GridLayout
      rows={[
        {
          height: height,
        },
      ]}
      cols={[
        {
          width: "100%",
        },
      ]}
      gap={{
        rows: 10,
        cols: 0,
      }}
      style={{ position: "relative" }}
    >
      {/* <div className="quality-filter-btn-box">
        <DropDownList
          className="quality-detail-filter-drop-down-list"
          data={[
            {
              label: "전체",
              value: "all",
            },
            ...projectList,
          ]}
          value={targetProject}
          textField="label"
          dataItemKey="value"
          onChange={(e) => setTargetProject(e.value)}
        />
      </div> */}
      <GridLayoutItem row={1} col={1}>
        <div className="indicator-k-table-body">
          {/* <div className="indicator-k-table-title">Best 5 협력업체</div> */}
          <IndicatorKTable
            rows={table_data.best_vendor_ranking}
            columns={columns}
            height={height}
          />
        </div>
      </GridLayoutItem>
    </GridLayout>
  );
};

export default IndicatorK;

const columns = [
  { field: "ranking", headerName: "순위", width: 120 },
  { field: "vendor_name", headerName: "협력업체명", flex: 1 },
  { field: "vendor_point", headerName: "점수", width: 120 },
];

const pointRange = (name, value) => {
  if (value < 0) {
    return 0;
  }

  if (name === "inspection_approval") {
    if (value >= 40) {
      return 40;
    }
  }

  if (name === "ncr_accrual_average") {
    if (value >= 25) {
      return 25;
    }
  }

  if (name === "action_on_time") {
    if (value >= 30) {
      return 30;
    }
  }

  if (name === "ncr_count") {
    if (value >= 5) {
      return 5;
    }
  }

  return value;
};

const returnZeroIfInvalid = (value) => {
  if (value === Infinity || isNaN(value) || value === false) {
    return 0;
  }
  return value;
};

const adjust_inspection_approval_point = (value) => {
  if (value >= 99) {
    return 40;
  }

  if (value < 70) {
    return 10;
  }

  return value - 60;
};

const adjust_action_on_time_point = (value) => {
  if (value < 90) {
    return 0;
  }

  return 30 - (100 - value);
};

const adjust_ncr_count_point = (value) => {
  const value2 = 5 - value;

  if (value2 <= -5) {
    return -5;
  }

  return value2;
};
