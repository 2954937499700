import React, { useState, useEffect } from 'react';
import { GridLayout, GridLayoutItem } from '@progress/kendo-react-layout';
import { ComboBox } from '@progress/kendo-react-dropdowns';
import _ from 'lodash';
import dayjs from 'dayjs';
import {    DataGrid} from '@mui/x-data-grid';
import { randomId } from '@mui/x-data-grid-generator';
import { DropDownList } from "@progress/kendo-react-dropdowns";
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';

const InStockStatus = ({ height, mData1, mData2 }) => {
    const tlist = ["도착지별", "대분류별", "납품회사별"]
    const [dlist, setdlist] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [tstate, settState] = useState(tlist[0]);
    const [dstate, setdState] = useState(dlist[0]);
    const [includeElectronicPayment, setIncludeElectronicPayment] = useState(false);

    const handletChange = (event) => {
        settState(event.target.value);
    };

    const handledChange = (event) => {
        setdState(event.target.value);
    };

    useEffect(() => {
        const data = includeElectronicPayment ? [...mData1, ...mData2] : [...mData1];
        
        const groupType =
            tstate === "도착지별" ? "DestinationName" : tstate === "납품회사별" ? "SHImatVendorNameTX50" :
                tstate === "대분류별" && dstate ? "typeSpecial" : "umatSHICategoryPD"

        var tabledata =
            _(data)
                .groupBy(groupType)
                .map((objs, key) => ({
                    "id": randomId(),
                    "key": key,
                    'plan': _.sumBy(objs.filter(v => v[groupType] === key), "SHIMatplanQtyDA"),
                    'thisMonthPlan': _.sumBy(objs.filter(v => v[groupType] === key && dayjs(v.SHIMatDeliveryDateExp).format("YYYY-MM") === dayjs().format("YYYY-MM")), "SHIMatplanQtyDA"),
                    'nextMonthPlan': _.sumBy(objs.filter(v => v[groupType] === key && dayjs(v.SHIMatDeliveryDateExp).format("YYYY-MM") === dayjs().add(1, 'month').format("YYYY-MM")), "SHIMatplanQtyDA"),
                    'nextnextMonthPlan': _.sumBy(objs.filter(v => v[groupType] === key && dayjs(v.SHIMatDeliveryDateExp).format("YYYY-MM") === dayjs().add(2, 'month').format("YYYY-MM")), "SHIMatplanQtyDA"),
                    'in': _.sumBy(objs.filter(v => v[groupType] === key), "SHIMatinqnty"),
                    'notIn': _.sumBy(objs.filter(v => v[groupType] === key), "SHIMatplanQtyDA") - _.sumBy(objs.filter(v => v[groupType] === key), "SHIMatinqnty"),
                    'inRate': (_.sumBy(objs.filter(v => v[groupType] === key), "SHIMatinqnty") / _.sumBy(objs.filter(v => v[groupType] === key), "SHIMatplanQtyDA")) * 100,
                }))
                .value()
                .filter(v=>v.key !== 'null')
                ;

        var tabledata2 =
            _(data.filter(v => v.DestinationName === dstate))
                .groupBy("umatSHICategoryPD")
                .map((objs, key) => ({
                    "id": randomId(),
                    "key": key,
                    'plan': _.sumBy(objs.filter(v => v[groupType] === key), "SHIMatplanQtyDA"),
                    'thisMonthPlan': _.sumBy(objs.filter(v => v[groupType] === key && dayjs(v.SHIMatDeliveryDateExp).format("YYYY-MM") === dayjs().format("YYYY-MM")), "SHIMatplanQtyDA"),
                    'nextMonthPlan': _.sumBy(objs.filter(v => v[groupType] === key && dayjs(v.SHIMatDeliveryDateExp).format("YYYY-MM") === dayjs().add(1, 'month').format("YYYY-MM")), "SHIMatplanQtyDA"),
                    'nextnextMonthPlan': _.sumBy(objs.filter(v => v[groupType] === key && dayjs(v.SHIMatDeliveryDateExp).format("YYYY-MM") === dayjs().add(2, 'month').format("YYYY-MM")), "SHIMatplanQtyDA"),
                    'in': _.sumBy(objs.filter(v => v[groupType] === key), "SHIMatinqnty"),
                    'notIn': _.sumBy(objs.filter(v => v[groupType] === key), "SHIMatplanQtyDA") - _.sumBy(objs.filter(v => v[groupType] === key), "SHIMatinqnty"),
                    'inRate': (_.sumBy(objs.filter(v => v[groupType] === key), "SHIMatinqnty") / _.sumBy(objs.filter(v => v[groupType] === key), "SHIMatplanQtyDA")) * 100,
                }))
                .value().filter(v=>v.key !== 'null');

        setTableData(groupType !== "typeSpecial" ? tabledata : tabledata2);

        const typeList = _.uniq(data.map(v => v.DestinationName)).filter(v => v != null)
        setdlist(typeList)
    }, [mData1, mData2, tstate, dstate, includeElectronicPayment]);

    const columns = [
        { field: 'key', headerName: '', width: 185 },
        { field: 'plan', headerName: '계획', width: 86 },
        { field: 'in', headerName: '입고', width: 86 },
        { 
          field: 'inRate', 
          headerName: '입고율', 
          width: 86,
          valueFormatter: (params) => {
              if (isNaN(params.value)) {
                  return '-';
              }
              const valueFormatted = Number(params.value).toFixed(1);
              return `${valueFormatted}%`;
          }
        },
        { field: 'notIn', headerName: '미입고', width: 86 },
        { field: 'thisMonthPlan', headerName: dayjs().format("YY.MM"), width: 86 },
        { field: 'nextMonthPlan', headerName: dayjs().add(1, "month").format("YY.MM"), width: 86 },
        { field: 'nextnextMonthPlan', headerName: dayjs().add(2, "month").format("YY.MM"), width: 86 },
    ]

    const columnGroupingModel = [
        {
            groupId: '입고 계획',
            children: [{ field: 'thisMonthPlan' }, { field: 'nextMonthPlan' }, { field: 'nextnextMonthPlan' }],
        },
    ];

    return (
        <>
            <GridLayout style={{ height: height }}
                rows={[
                    {
                        height: '10%',
                    },
                    {
                        height: '90%',
                    },
                ]}
                cols={[
                    {
                        width: '100%',
                    },
                ]}
                gap={{
                    rows: 10,
                    cols: 0,
                }}>

                <GridLayoutItem row={1} col={1} style={{ position: 'relative' }}>
                    <div style={{ display: "flex", alignItems: "center", fontSize: '0.8rem', top: 0, zIndex: 9999 }}>
                        <DropDownList
                            style={{
                                width: "150px", float: 'right', fontSize: '0.7rem', height: 30, fontWeight: 600, position: 'absolute', right: 216
                            }}
                            data={tlist}
                            value={tstate}
                            onChange={handletChange}
                        />
                        {tstate === "대분류별" &&
                            <ComboBox
                                style={{
                                    width: "200px", float: 'right', fontSize: '0.7rem', height: 30, fontWeight: 600, position: 'absolute', right: 8
                                }}
                                data={dlist}
                                value={dstate}
                                onChange={handledChange}
                            />
                        }
                        <div style={{ marginLeft: "16px" }}>
                            <FormControlLabel
                                control={
                                    <Switch 
                                        checked={includeElectronicPayment}
                                        onChange={() => setIncludeElectronicPayment(!includeElectronicPayment)}
                                    />
                                }
                                label="전자지급 자재 포함"
                            />
                        </div>
                    </div>
                </GridLayoutItem>
                <GridLayoutItem row={2} col={1} style={{ position: 'relative', paddingBottom: 16 }}>
                    <DataGrid
                        experimentalFeatures={{ columnGrouping: true }}
                        density="compact"
                        rows={tableData}
                        columns={columns}
                        hideFooter
                        disableRowSelectionOnClick
                        columnGroupingModel={columnGroupingModel}
                        sx={{
                            '& .MuiDataGrid-cell': {
                                color: '#525252',
                            },
                            '& .MuiDataGrid-columnHeaders': {
                                color: '#525252',
                            },
                            '& .MuiDataGrid-footerContainer': {
                                color: '#525252',
                            },
                            '& .MuiTablePagination-root': {
                                color: '#525252',
                            },
                        }}
                    />
                </GridLayoutItem>
            </GridLayout>
        </>
    )
}

export default InStockStatus