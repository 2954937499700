import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import axios from "../../../../common/axios";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { orderBy } from "@progress/kendo-data-query";
import { Dialog } from '@progress/kendo-react-dialogs';
import dayjs from 'dayjs';
import { MdOpenInNew } from "react-icons/md"
import { DatePicker } from "@progress/kendo-react-dateinputs";
import {
  IntlProvider,
  LocalizationProvider,
  loadMessages,
} from "@progress/kendo-react-intl";
import loadCldrData from "../../../common/loadCldrData";
import krMessages from "../../../common/kr.json";

loadCldrData();
loadMessages(krMessages, "ko-KR");


const ActStatus = ({height}) => {
    const defaultvalue = new Date()
    const { project_code } = useParams();
    const [tabledata, settabledata] = useState([]);
    const [datevalue, setValueDate] = useState(defaultvalue);

    const today = Number(dayjs(datevalue).format('YYYYMMDD'));


    useEffect(() => {
        const fetchData = async () => {
          const res2 = await axios.get(`/getactivitystatus?project=${project_code}&value=${today}`)
          settabledata(res2.data)
        };
        fetchData();
    
      }, [project_code, today]);


      const [sort, setSort] = useState([{
        "field": "notstarted",
        "dir": "desc"
    }]);
      const [visibleDialog, setVisibleDialog] = useState(false);
      const [visibleDialog2, setVisibleDialog2] = useState(false);
      const [discipline, setDiscipline] = useState("");

    
      const tabledata2 = tabledata.filter(v => v['공종'] === discipline)[0] && tabledata.filter(v => v['공종'] === discipline)[0]['delayed_name']
      const tabledata3 = tabledata.filter(v => v['공종'] === discipline)[0] && tabledata.filter(v => v['공종'] === discipline)[0]['notstarted_name']
    
    
      const CountSumCell = (props) => {
        const field = props.field || "";
        const total = tabledata.reduce((acc, current) => acc + current[field], 0);
        return (
          <td colSpan={props.colSpan} style={props.style}>
            {total}
          </td>
        );
      };
    
      const handleClick = e => {
        setVisibleDialog(!visibleDialog)
        setDiscipline(e) 
      }
    
      const handleClick2 = e => {
        setVisibleDialog2(!visibleDialog2)
        setDiscipline(e)
      }
    
    
      const NormalCell = (props) => {
        const cellvalue = props.dataItem.delayed;
        const cellvalue2 = props.dataItem['공종'];
        return (
            <td colSpan={props.colSpan} style={props.style}>
              <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                <span>{cellvalue}</span> 
                <button 
                  style={{ 
                    fontSize: 12, 
                    border: 'none', 
                    background: 'none', 
                    cursor: 'pointer',
                    marginLeft: '5px', // 텍스트와 버튼 사이의 간격
                    display: 'flex', // Flexbox 레이아웃 적용
                    alignItems: 'center', // 버튼 내부 요소들을 세로로 중앙 정렬
                  }} 
                  onClick={() => handleClick(cellvalue2)}
                >
                  <MdOpenInNew />
                </button>
              </div>
            </td>
          );
        };
        

        const NormalCell2 = (props) => {
            const field = props.field || "";
            const cellvalue = props.dataItem.notstarted;
            const cellvalue2 = props.dataItem['공종'];
          
            return (
              <td colSpan={props.colSpan} style={props.style}>
                <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                  <span>{cellvalue}</span> 
                  <button 
                    style={{ 
                      fontSize: 12, 
                      border: 'none', 
                      background: 'none', 
                      cursor: 'pointer',
                      marginLeft: '5px', // 텍스트와 버튼 사이의 간격
                      display: 'flex', // Flexbox 레이아웃 적용
                      alignItems: 'center', // 버튼 내부 요소들을 세로로 중앙 정렬
                    }} 
                    onClick={() => handleClick2(cellvalue2)}
                  >
                    <MdOpenInNew />
                  </button>
                </div>
              </td>
            );
          };
          
    
      const toggleDialog = () => {
        setVisibleDialog(!visibleDialog);
      };
    
      const toggleDialog2 = () => {
        setVisibleDialog2(!visibleDialog2);
      };
      const handleDateChange = (event) => {
        setValueDate(event.target.value);
    };
    
    
      return (
        <>
            <div style={{ position: 'absolute', right: 16, fontSize: '0.8rem', top:8 }}>
            <LocalizationProvider language="ko-KR">
                <IntlProvider locale="ko-KR">
                <DatePicker format={"yyyy년 MM월 dd일 EEEE"} onChange={handleDateChange} value={datevalue} />
                </IntlProvider>
            </LocalizationProvider>
            </div>
          {visibleDialog && (
            <Dialog title={`${discipline} 3% 이상 차질 액티비티`} onClose={toggleDialog} width={1200} height={500}>
              <Grid
                data={orderBy(tabledata2, sort)}
                sortable={true}
                sort={sort}
                onSortChange={(e) => {
                  setSort(e.sort);
                }}
                style={{ width: 1150, height: 400 }}
                className='atable2'
              >
                <GridColumn
                  field="name"
                  title="작업 이름"
    
                />
    
                <GridColumn
                  field="subcon"
                  title="담당 업체명"
    
                />
                <GridColumn
                  field="plan"
                  title="계획 공정률"
    
    
                />
                <GridColumn
                  field="actual"
                  title="실적 공정률"
                />
    
              </Grid>
    
            </Dialog>
          )}
    
          {visibleDialog2 && (
            <Dialog title={`${discipline} 미착수 액티비티`} onClose={toggleDialog2} width={1200} height={500}>
              <Grid
                data={orderBy(tabledata3, sort)}
                sortable={true}
                sort={sort}
                onSortChange={(e) => {
                  setSort(e.sort);
                }}
                style={{ width: 1150, height: 400 }}
                className='atable3'
              >
                <GridColumn
                  field="name"
                  title="작업 이름"
                />
    
                <GridColumn
                  field="subcon"
                  title="담당 업체명"
                />
                <GridColumn
                  field="plan"
                  title="계획공정률"
                />
                {/* <GridColumn
                  field="actual"
                  title="실적공정률"
                /> */}
                <GridColumn
                  field="planstart"
                  title="계획 시작일"
                />
                <GridColumn
                  field="planfinish"
                  title="계획 종료일"
                />
    
              </Grid>
    
            </Dialog>
          )}
          <Grid
            data={orderBy(tabledata, sort)}
            sortable={true}
            sort={sort}
            onSortChange={(e) => {
              setSort(e.sort);
            }}
            style={{ width: '100%', height: 3*height/7-80 }}
            className='atable'
            size={'small'} 
    
          >
            <GridColumn
              field="공종"
              title="공종"
            // width={370/4}
    
            />
    
            <GridColumn
              field="total"
              title="전체"
              footerCell={CountSumCell}
            // width={370/4}
    
            />
    
    
    
            <GridColumn
              field="ahead"
              title="계획"
              footerCell={CountSumCell}
            // width={370/4}
            />
    
            <GridColumn title="차질">
    
              <GridColumn
                field="notstarted"
                title="미착수"
                footerCell={CountSumCell}
                cell={NormalCell2}
              // width={370/4}
    
              />
              <GridColumn
                field="delayed"
                title="3% 이상"
                footerCell={CountSumCell}
                cell={NormalCell}
              // width={370/4}
              />
            </GridColumn>
          </Grid>
            <div style={{marginTop:8, marginLeft:8}}>
              <span>*미착수: 계획일 도래 중 미착수한 액티비티</span><br/>
              <span>*3% 이상 차이: 착수한 액티비티 중, 계획공정률-실적공정률 &gt;= 3%</span>
            </div>
        </>
      );
    };

export default ActStatus;
