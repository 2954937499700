import React, { useEffect } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

const InspectionNestedPieChart = ({
  height,
  id = "InspectionNestedPieChart",
  data = [],
  detail_data = [],
}) => {
  useEffect(() => {
    var root = am5.Root.new("InspectionNestedPieChart");

    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    root.setThemes([am5themes_Animated.new(root)]);

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/
    // start and end angle must be set both for chart and series

    var container = root.container.children.push(
      am5.Container.new(root, {
        width: am5.p100,
        height: am5.p100,
        layout: root.verticalLayout,
      })
    );

    var chartContainer = container.children.push(
      am5.Container.new(root, {
        width: am5.p100,
        height: height - 60,
        layout: root.verticalLayout,
      })
    );

    var chart = chartContainer.children.push(
      am5percent.PieChart.new(root, {
        layout: root.verticalLayout,
        innerRadius: am5.percent(40),
      })
    );

    var legendContainer = container.children.push(
      am5.Container.new(root, {
        width: am5.p100,
        height: 60,
        layout: root.verticalLayout,
        verticalScrollbar: am5.Scrollbar.new(root, {}),
      })
    );

    let legend = legendContainer.children.push(
      am5.Legend.new(root, {
        centerX: am5.percent(50),
        x: am5.percent(50),
      })
    );

    // Create series
    // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Series
    // start and end angle must be set both for chart and series
    var series0 = chart.series.push(
      am5percent.PieSeries.new(root, {
        valueField: "value",
        categoryField: "category",
        alignLabels: false,
      })
    );

    var bgColor = root.interfaceColors.get("background");

    series0.ticks.template.setAll({ forceHidden: true });
    series0.labels.template.setAll({ forceHidden: true });

    series0.slices.template.setAll({
      stroke: bgColor,
      strokeWidth: 2,
      toggleKey: "none",
      tooltipHTML:
        "<div class=quality-detail-inspection-status-pie-chart-tooltip-text>{category}: {valuePercentTotal.formatNumber('0.00')}% ({value} 건)</div>",
    });

    series0.slices.template.states.create("hover", { scale: 0.95 });

    series0
      .get("colors")
      .set("colors", ["#00AF50", "#91CF50", "#FFE699", "#FEC000", "#D1D1D1"]);

    series0.data.setAll(detail_data);

    series0.appear(1000, 100);

    var series1 = chart.series.push(
      am5percent.PieSeries.new(root, {
        valueField: "value",
        categoryField: "category",
        alignLabels: false,
      })
    );

    series1.labels.template.setAll({
      textType: "circular",
      radius: 4,
    });

    series1.slices.template.setAll({
      stroke: bgColor,
      strokeWidth: 2,
      toggleKey: "none",
      tooltipHTML:
        "<div class=quality-detail-inspection-status-pie-chart-tooltip-text>{category}: {valuePercentTotal.formatNumber('0.00')}% ({value} 건)</div>",
    });

    series1.ticks.template.set("visible", false);

    series1.get("colors").set("colors", ["#48CAE4", "#D1D1D1"]);

    series1.data.setAll(data);

    series1.appear(1000, 100);

    legend.data.setAll([...series0.dataItems]);

    return () => {
      root && root.dispose();
    };
  }, [id, height, data, detail_data]);

  return <div id="InspectionNestedPieChart" style={{ height }} />;
};

export default InspectionNestedPieChart;

// const sample_data = [
//   {
//     category: "수행",
//     title: "수행",
//     value: 6,
//   },
//   {
//     category: "취소",
//     title: "취소",
//     value: 15,
//   },
// ];

// const sample_data_2 = [
//   {
//     category: "One-Pass(1차 합격)",
//     value: 6,
//   },
//   {
//     category: "1차 재검사 후 합격",
//     value: 4,
//   },
//   {
//     category: "2차 재검사 후 합격",
//     value: 2,
//   },
//   {
//     category: "3차 이상 재검사 후 합격",
//     value: 3,
//   },
//   {
//     category: "불합격",
//     value: 11,
//   },
// ];
