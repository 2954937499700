// loadCldrData.js
import { load } from '@progress/kendo-react-intl';

import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
import currencyData from "cldr-core/supplemental/currencyData.json";
import weekData from "cldr-core/supplemental/weekData.json";
import numbers from "cldr-numbers-full/main/ko/numbers.json";
import currencies from "cldr-numbers-full/main/ko/currencies.json";
import caGregorian from "cldr-dates-full/main/ko/ca-gregorian.json";
import dateFields from "cldr-dates-full/main/ko/dateFields.json";
import timeZoneNames from "cldr-dates-full/main/ko/timeZoneNames.json";

const loadCldrData = () => {
    load(
        likelySubtags,
        currencyData,
        weekData,
        numbers,
        currencies,
        caGregorian,
        dateFields,
        timeZoneNames
    );
};

export default loadCldrData;
