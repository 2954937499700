import React, { useState, useMemo } from "react";
import { useRecoilValue } from "recoil";
import { QualityInspectionProgressData } from "../../../../atom/qualityState";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import { DataGrid } from "@mui/x-data-grid";
import MuiToggleButton from "@mui/material/ToggleButton";
import MuiToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import _ from "lodash";
import dayjs from "dayjs";
import "./inspectionProgressStatus.css";

const customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);

const InspectionProgressStatus = ({
  height,
  targetProject,
  targetDisc,
  targetVendor,
}) => {
  const data = useRecoilValue(QualityInspectionProgressData);

  const [buttonValue, setButtonValue] = useState("시공검사");

  const target_data = useMemo(() => {
    const { inprogress, complete } = data;

    if (buttonValue === "시공검사") {
      return inprogress;
    }

    if (buttonValue === "완공검사") {
      return complete;
    }

    return [];
  }, [data, buttonValue]);

  const filter_data = useMemo(() => {
    const { quantity } = data;

    let quantity_copy = [...quantity];
    let target_data_copy = [...target_data];

    if (targetProject.value !== "all") {
      quantity_copy = [...quantity_copy].filter(
        (c) => c.project_projectnumber === targetProject.value
      );

      target_data_copy = [...target_data_copy].filter(
        (c) => c.project_projectnumber === targetProject.value
      );
    }

    if (targetDisc !== "전체") {
      quantity_copy = [...quantity_copy].filter(
        (c) => c.ITPDiscipline === targetDisc
      );

      target_data_copy = [...target_data_copy].filter(
        (c) => c.ITPDiscipline === targetDisc
      );
    }

    if (targetVendor !== null && targetVendor?.label) {
      quantity_copy = [...quantity_copy].filter(
        (c) => c.uveVendorNameTB50 === targetVendor.label
      );
      target_data_copy = [...target_data_copy].filter(
        (c) => c.uveVendorNameTB50 === targetVendor.label
      );
    }

    return {
      quantity: quantity_copy,
      target_data: target_data_copy,
    };
  }, [targetProject, targetDisc, targetVendor, data, target_data]);

  const table_data = useMemo(() => {
    const { quantity, target_data } = filter_data;

    const table_data_group = _(quantity)
      .groupBy((item) => {
        const { ITPDiscipline, MDiscipline, SDiscipline } = item;

        return `${ITPDiscipline}^${MDiscipline}^${SDiscipline}`;
      })
      .map((objs, key) => {
        const [ITPDiscipline, MDiscipline, SDiscipline] = key.split("^");

        console.log({ ITPDiscipline, MDiscipline, SDiscipline, target_data });

        const group_data = objs.map((c) => {
          const { QInspQtyDA } = c;

          const actual_data = target_data.filter((c2) => {
            return (
              c2.ITPDiscipline === ITPDiscipline &&
              c2.QIMDisc === MDiscipline &&
              c2.SDiscipline === SDiscipline
            );
          });

          const actual_qty = _.sumBy(actual_data, "QIItemQty");

          const percent = returnZeroIfInvalid((actual_qty / QInspQtyDA) * 100);

          return { ITPDiscipline, MDiscipline, SDiscipline, percent };
        });

        return group_data;
      })
      .value();

    return table_data_group.flat(1).map((c, i) => ({ ...c, id: i }));
  }, [filter_data]);

  const handleChange = (event) => {
    setButtonValue(event.target.value);
  };

  return (
    <GridLayout
      style={{ height: height }}
      rows={[
        {
          height: height,
        },
      ]}
      cols={[
        {
          width: "100%",
        },
      ]}
      gap={{
        rows: 0,
        cols: 0,
      }}
      className="quality-inspection-progress-status-box"
    >
      <div className="quality-inspection-progress-status-filter-box">
        <ThemeProvider theme={theme}>
          <ToggleButtonGroup2
            size="small"
            onChange={handleChange}
            value={buttonValue}
            exclusive
            aria-label="baseunit"
          >
            <ToggleButton2 value="시공검사" aria-label="시공검사">
              시공검사
            </ToggleButton2>
            <ToggleButton2 value="완공검사" aria-label="완공검사">
              완공검사
            </ToggleButton2>
          </ToggleButtonGroup2>
        </ThemeProvider>
      </div>
      <GridLayoutItem row={1} col={1}>
        <DataGrid
          rows={table_data}
          columns={column}
          hideFooter={true}
          style={{ maxHeight: height }}
          columnHeaderHeight={50}
          sx={{
            "& .MuiDataGrid-cell": {
              fontFamily: "Inter",
              fontSize: "14px",
              color: "black",
            },
            "& .MuiDataGrid-columnHeaders": {
              fontFamily: "Inter",
              fontWeight: 700,
              backgroundColor: "#F9FAFB",
            },
            "& .MuiDataGrid-columnHeaderTitle": {
              fontFamily: "Inter",
              fontWeight: 700,
            },
          }}
        />
      </GridLayoutItem>
    </GridLayout>
  );
};

export default InspectionProgressStatus;

const ToggleButton2 = styled(MuiToggleButton)(() => ({
  "&.MuiToggleButton-root": {
    fontSize: "12px",
    fontFamily: "Inter",
    fontWeight: 500,
    padding: "2px",
    width: 80,
    color: "#3d3d3d",
    borderRadius: "none",
    border: "1px solid #d1d1d1 !important",
    backgroundColor: "#fff",
    transition: "all 0.3s ease-out",
  },
  "&.Mui-selected, &.MuiToggleButton-root:hover": {
    color: "white",
    backgroundColor: "#004098",
  },
}));

const ToggleButtonGroup2 = styled(MuiToggleButtonGroup)(() => ({
  "&.MuiToggleButtonGroup-root": {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
}));

const theme = createTheme({
  palette: {
    text: {
      primary: "#00ff00",
    },
  },
});

const column = [
  { field: "ITPDiscipline", headerName: "대공종", width: 150 },
  { field: "MDiscipline", headerName: "중공종", flex: 1 },
  { field: "SDiscipline", headerName: "세부공종", flex: 1 },
  {
    field: "percent",
    headerName: "완료율(%)",
    width: 150,
    renderCell: (params) => {
      return Number(params.value).toFixed(2) + " %";
    },
  },
];

const returnZeroIfInvalid = (value) => {
  if (value === Infinity || isNaN(value) || value === false) {
    return 0;
  }
  return value;
};
