import React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { SingleInputDateRangeField } from "@mui/x-date-pickers-pro/SingleInputDateRangeField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";

const DataRangeFilter = ({ dateValue, setDateValue }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ko">
      <DemoContainer
        components={["SingleInputDateRangeField"]}
        sx={{ paddingTop: 0 }}
      >
        <div style={{ minWidth: 200 }}>
          <DateRangePicker
            slots={{ field: SingleInputDateRangeField }}
            name="allowedRange"
            format="YYYY-MM-DD"
            value={dateValue}
            onChange={(newValue) => setDateValue(newValue)}
            className="daterange2"
            sx={{
              width: 200,
              color: "black",
              "& .MuiInputBase-root": {
                padding: 0,
              },
              "& .MuiOutlinedInput-input": {
                fontFamily: "Inter",
                fontSize: "14px",
                fontWeight: 400,
                background: "var(--Gray-50, #F9FAFB)",
              },
              input: {
                padding: "7px !important",
                height: "15px !important",
                // color: "black !important",
                fontSize: "14px !important",
                marginTop: 0,
                width: "100%",
              },
            }}
          />
        </div>
      </DemoContainer>

      {/* <SingleInputDateRangeField
        label=""
        value={dateValue}
        onChange={(newValue) => setDateValue(newValue)}
        sx={{
          "& .MuiInputBase-root": {
            padding: 0,
          },
          "& .MuiOutlinedInput-input": {
            fontFamily: "Inter",
            fontSize: "14px",
            fontWeight: 400,
            background: "var(--Gray-50, #F9FAFB)",
          },
          input: {
            padding: "7px !important",
            height: "15px !important",
            color: "black !important",
            fontSize: "14px !important",
            marginTop: 0,
          },
        }}
      /> */}
    </LocalizationProvider>
  );
};

export default DataRangeFilter;
