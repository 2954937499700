import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import {
    GridLayout,
    GridLayoutItem,
} from "@progress/kendo-react-layout";
import axios from "../../../common/axios";
import dayjs from "dayjs"
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { DropDownList } from '@progress/kendo-react-dropdowns';

const PyramidChart = ({ height }) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const [chartData1, setChartData1] = useState([]);
    const [chartData, setChartData] = useState([]);
    const [yearList, setYearList] = useState([]);
    const [selectedYear, setSelectedYear] = useState("전체");

    const [monthList, setMonthList] = useState([]);
    const [monthList2, setMonthList2] = useState([]);
    const [chartData2, setChartData2] = useState([]);
    const [projectList, setProjectList] = useState([]);
    const [type, setType] = useState('scar');
    const [type2, setType2] = useState('scar');
    const [selectedProject, setSelectedProject] = useState(null);
    const [selectedMonth, setSelectedMonth] = useState(dayjs().add(-1, "month").format("YYYY-MM"));
    const [selectedMonth2, setSelectedMonth2] = useState(dayjs().add(-1, "month").format("YYYY-MM"));
    const [isEditing, setIsEditing] = useState(false);
    const [isEditing2, setIsEditing2] = useState(false);
    const [isEditingProject, setIsEditingProejct] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await axios.get(`/kpidata`);
                if (res.data.success) {
                    const rawData = res.data.data.filter(v=>v.month !== "Invalid date");

                    setChartData(rawData);

                    const totalData =_.sortBy( _(rawData)
                        .groupBy("month")
                        .map((objs, key) => ({
                            project: objs[0].project,
                            month: key, // "YYYY-MM" 형식 유지
                            year: key.split("-")[0],
                            LTI: _.sumBy(objs, "LTI"),
                            MTC: _.sumBy(objs, "MTC"),
                            FAC: _.sumBy(objs, "FAC"),
                            ADI: _.sumBy(objs, "ADI"),
                            LTI_hipo: _.sumBy(objs, "LTI_hipo"),
                            MTC_hipo: _.sumBy(objs, "MTC_hipo"),
                            FAC_hipo: _.sumBy(objs, "FAC_hipo"),
                            ADI_hipo: _.sumBy(objs, "ADI_hipo"),
                            Fatality_hipo:_.sumBy(objs, "Fatality_hipo"),
                            count: type === "scar" ? _.sumBy(objs, "scarcount") : type === "smt" ? _.sumBy(objs, "smtcount") : _.sumBy(objs, "spcount"),
                        }))
                        .value(), "month");

                    

                    const cumulativeData = totalData.reduce((acc, item) => {
                        const last = acc.length > 0 ? acc[acc.length - 1] : {
                            LTI_accum: 0,
                            MTC_accum: 0,
                            FAC_accum: 0,
                            ADI_accum: 0,
                            count_accum: 0, // 초기값 설정
                        };

                        // 유효하지 않은 값을 처리하기 위해 기본값을 0으로 설정
                        const currentLTI = item.LTI || 0;
                        const currentMTC = item.MTC || 0;
                        const currentFAC = item.FAC || 0;
                        const currentADI = item.ADI || 0;
                        const currentCount = item.count || 0; // item.count가 undefined일 경우를 대비

                        acc.push({
                            ...item,
                            LTI_accum: currentLTI + last.LTI_accum,
                            MTC_accum: currentMTC + last.MTC_accum,
                            FAC_accum: currentFAC + last.FAC_accum,
                            ADI_accum: currentADI + last.ADI_accum,
                            count_accum: currentCount + last.count_accum, // 누적 값 계산
                        });

                        return acc;
                    }, []);

                    // cumulativeData에서 selectedMonth에 해당하는 데이터를 필터링
                    let filteredData = cumulativeData.filter(v => v.month === selectedMonth);

                       // 필터링된 데이터가 빈 배열이면 month가 가장 큰 값을 선택
                    if (filteredData.length === 0) {
                        const maxMonth = _.sortBy(cumulativeData,"month").reverse()[0].month
                        setSelectedMonth(maxMonth)

                        filteredData = cumulativeData.filter(v => v.month === maxMonth);
                    }

                    setChartData1(filteredData);
                    setProjectList([...new Set(totalData.map(v => v.project))]);
                    setSelectedProject(totalData[0].project)
                    setMonthList([...new Set(totalData.map(v => v.month))]);
                    setYearList(["전체", ...new Set(totalData.map(v => v.year))]);
                } else {
                    console.log("에러");
                }
            } catch (error) {
                console.error('데이터 가져오기 중 오류 발생:', error);
            }
        };
        fetchData();
    }, [type, selectedMonth]);

    const setProjectChartData = () => {
        if (selectedProject) {
            const filteredData = chartData.filter(item => item.project === selectedProject);
            const totalData = _(filteredData)
                .groupBy("month")
                .map((objs, key) => ({
                    project: objs[0].project,
                    month: key,
                    LTI: _.sumBy(objs, "LTI"),
                    MTC: _.sumBy(objs, "MTC"),
                    FAC: _.sumBy(objs, "FAC"),
                    ADI: _.sumBy(objs, "ADI"),
                    LTI_hipo: _.sumBy(objs, "LTI_hipo"),
                    MTC_hipo: _.sumBy(objs, "MTC_hipo"),
                    FAC_hipo: _.sumBy(objs, "FAC_hipo"),
                    ADI_hipo: _.sumBy(objs, "ADI_hipo"),
                    Fatality_hipo: _.sumBy(objs, "Fatality_hipo"),
                    count: type2 === "scar" ? _.sumBy(objs, "scarcount") : type2 === "smt" ? _.sumBy(objs, "smtcount") : _.sumBy(objs, "spcount"),
                }))
                .value();

            const cumulativeData = totalData.reduce((acc, item) => {
                const last = acc.length > 0 ? acc[acc.length - 1] : {
                    LTI_accum: 0,
                    MTC_accum: 0,
                    FAC_accum: 0,
                    ADI_accum: 0,
                    count_accum: 0, // 초기값 설정
                };

                // 유효하지 않은 값을 처리하기 위해 기본값을 0으로 설정
                const currentLTI = item.LTI || 0;
                const currentMTC = item.MTC || 0;
                const currentFAC = item.FAC || 0;
                const currentADI = item.ADI || 0;
                const currentCount = item.count || 0; // item.count가 undefined일 경우를 대비

                acc.push({
                    ...item,
                    LTI_accum: currentLTI + last.LTI_accum,
                    MTC_accum: currentMTC + last.MTC_accum,
                    FAC_accum: currentFAC + last.FAC_accum,
                    ADI_accum: currentADI + last.ADI_accum,
                    count_accum: currentCount + last.count_accum, // 누적 값 계산
                });

                return acc;
            }, []);

            console.log(cumulativeData)

            setMonthList2([...new Set(cumulativeData.map(v => v.month))]);

            let filteredData2 = cumulativeData.filter(v => v.month === selectedMonth2);

            // 필터링된 데이터가 빈 배열이면 month가 가장 큰 값을 선택
            if (filteredData2.length === 0) {
                const maxMonth = _.sortBy(cumulativeData,"month").reverse()[0].month

                console.log(cumulativeData)

                setSelectedMonth2(maxMonth)

                filteredData2 = cumulativeData.filter(v => v.month === maxMonth);
            }

            
            setChartData2(filteredData2);
        }
    };

    useEffect(() => {
        setProjectChartData();
    }, [selectedMonth2, type2]);

    const handleProjectChange = (project) => {
        setSelectedProject(project.target.value);
        setProjectChartData();
    };

    const handleClick = () => {
        setIsEditing(true);
    };

    const handleClick2 = () => {
        setIsEditing2(true);
    };

    const handleSelectChange = (event) => {
        handleChangeMonth(event);
        setIsEditing(false);
    };

    const handleSelectChange2 = (event) => {
        handleChangeMonth2(event);
        setIsEditingProejct(false);
    };

    const handleClickProject = () => {
        setIsEditingProejct(true);
    };

    const handleDropDownChange = (event) => {
        handleProjectChange(event);
        setIsEditingProejct(false);
    };

    const handleChange = (event) => {
        setType(event.target.value);
    };

    const handleChangeYear = (event) => {
        setSelectedYear(event.target.value);
    };

    const handleChangeMonth = (event) => {
        setSelectedMonth(event.target.value);
    };

    const handleChangeMonth2 = (event) => {
        setSelectedMonth2(event.target.value);
    };

    const handleChange2 = (event) => {
        setType2(event.target.value);
    };

    return (
        <>
            <GridLayout style={{ height: height }}
                rows={[
                    {
                        height: '100%',
                    },
                ]}
                cols={[
                    {
                        width: '50%',
                    },
                    {
                        width: '50%',
                    },
                ]}
                gap={{ rows: 10, cols: 0, }}>
                <div style={{ position: "absolute", right: 8, top: 0 }}>
                    <FormControl size='small' sx={{ m: 1, minWidth: 120, fontSize: 11, }}>
                        <InputLabel id="demo-simple-select-standard-label"></InputLabel>
                        <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            value={selectedYear}
                            onChange={handleChangeYear}
                            label=""
                            sx={{ fontSize: "0.8rem" }}
                        >
                            {yearList.map((v, index) => (
                                <MenuItem key={index} value={v}>{v}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
                <GridLayoutItem row={1} col={1} style={{ position: 'relative' }}>
                    <div style={{ marginTop: -12, marginBottom: 4, display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <div>전사 안전 KPI 종합</div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                    <table className='pyramid'>
                        <thead>
                            <tr>
                                <th></th>
                                <th style={{ minWidth: 80 , padding:4}}>
                                    {isEditing ? (
                                        <FormControl
                                            variant="standard"
                                            size="small"
                                            sx={{ m: 1, minWidth: 60, fontSize: 11, marginBottom: 0 }}
                                        >
                                            <Select
                                                id="demo-simple-select-standard"
                                                value={selectedMonth}
                                                onChange={handleSelectChange}
                                                onBlur={() => setIsEditing(false)}
                                                autoFocus
                                            >
                                                {(selectedYear === "전체"
                                                    ? monthList
                                                    : monthList.filter((v) =>
                                                        v.includes(selectedYear)
                                                    )
                                                ).map((v, index) => (
                                                    <MenuItem key={index} value={v}>
                                                        {v}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    ) : (
                                        <div style={{ textAlign:"center"}}>
                                            <span
                                                style={{ cursor: "pointer" }}
                                                onClick={handleClick}
                                            >
                                                월간 {selectedMonth && selectedMonth.slice(2,7)}월 <br/>실적
                                            </span>
                                        </div>
                                    )}
                                </th>
                                <th style={{ minWidth: 80,textAlign:"center"}}>HIPO 등급<br/> 발생(월간)</th>
                                <th style={{ minWidth: 80,textAlign:"center" }}> 전체 누계 <br/>실적</th>
                            </tr>
                        </thead>
                        <tbody>
                        <tr>
                                <th>Fatality</th>
                                <td></td>
                                <td style={{backgroundColor:'#ff4433'}}>{chartData1[0] && chartData1[0].Fatality_hipo}</td>
                                <td></td>
                            </tr>
                            <tr>
                                <th>LTI</th>
                                <td style={{backgroundColor:'#cca300'}}>{chartData1[0] && chartData1[0].LTI}</td>
                                <td style={{backgroundColor:'#ff4433'}}>{chartData1[0] && chartData1[0].LTI_hipo}</td>
                                <td style={{backgroundColor:'#cca300'}}>{chartData1[0] && chartData1[0].LTI_accum}</td>
                            </tr>
                            <tr>
                                <th>MTC</th>
                                <td style={{backgroundColor:'#ffcc00'}}>{chartData1[0] && chartData1[0].MTC}</td>
                                <td style={{backgroundColor:'#ff4433'}}>{chartData1[0] && chartData1[0].MTC_hip}</td>
                                <td style={{backgroundColor:'#ffcc00'}}>{chartData1[0] && chartData1[0].MTC_accum}</td>
                            </tr>
                            <tr>
                                <th>FAC</th>
                                <td style={{backgroundColor:'#00cc00'}}>{chartData1[0] && chartData1[0].FAC}</td>
                                <td style={{backgroundColor:'#ff4433'}}> {chartData1[0] && chartData1[0].FAC_hipo}</td>
                                <td style={{backgroundColor:'#00cc00'}}> {chartData1[0] && chartData1[0].FAC_accum}</td>
                            </tr>
                            <tr>
                                <th>ADI</th>
                                <td style={{backgroundColor:'#80aaff'}}>{chartData1[0] && chartData1[0].ADI}</td>
                                <td style={{backgroundColor:'#ff4433'}}>{chartData1[0] && chartData1[0].ADI_hipo}</td>
                                <td style={{backgroundColor:'#80aaff'}}>{chartData1[0] && chartData1[0].ADI_accum}</td>
                            </tr>
                            <tr>
                                <th>
                                    <FormControl variant="standard" size='small' sx={{ m: 0, minWidth: 60, }} className='selection'>
                                        <InputLabel sx={{m:0}} id="demo-simple-select-standard-label"></InputLabel>
                                        <Select
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            value={type}
                                            onChange={handleChange}
                                            label=""
                                            sx={{m:0, fontSize:'0.7rem'}}
                                        >
                                            <MenuItem sx={{fontSize:"0.8rem"}} value="scar">SCAR</MenuItem>
                                            <MenuItem sx={{fontSize:"0.8rem"}} value="smt">개선지시서</MenuItem>
                                            <MenuItem sx={{fontSize:"0.8rem"}} value="punch">부적합 발굴</MenuItem>
                                        </Select>
                                    </FormControl>
                                </th>
                                <td style={{backgroundColor:'#4d88ff'}}>{chartData1[0] && chartData1[0].count}</td>
                                <td style={{backgroundColor:'#ff4433'}}> </td>
                                <td style={{backgroundColor:'#4d88ff'}}>{chartData1[0] && chartData1[0].count_accum}</td>
                            </tr>
                        </tbody>
                    </table>
                    </div>
                </GridLayoutItem>
                <GridLayoutItem row={1} col={2} style={{ position: 'relative' }}>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            height: 30,
                            marginTop: -18,
                            marginBottom: 0,
                        }}
                    >
                        {isEditingProject ? (
                            <DropDownList
                                style={{
                                    width: "180px",
                                    fontSize: "0.7rem",
                                    height: 30,
                                    fontWeight: 600,
                                    width: 140,
                                }}
                                data={projectList}
                                value={selectedProject}
                                onChange={handleDropDownChange}
                                onBlur={() => setIsEditingProejct(false)}
                                autoFocus
                            />
                        ) : (
                            <span
                                style={{
                                    cursor: "pointer",
                                    fontSize: 14,
                                    height: 30,
                                    fontWeight: 500,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    width: 250,
                                }}
                                onClick={handleClickProject}
                            >
                                {selectedProject} 안전 KPI 종합
                            </span>
                        )}
                    </div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                    <table className='pyramid'>
                        <thead>
                            <tr>
                                <th></th>
                                <th style={{ minWidth: 80 , padding:4}}>
                                    {isEditing2 ? (
                                        <FormControl
                                            variant="standard"
                                            size="small"
                                            sx={{ m: 1, minWidth: 60, fontSize: 11, marginBottom: 0 }}
                                        >
                                            <Select
                                                id="demo-simple-select-standard"
                                                value={selectedMonth2}
                                                onChange={handleSelectChange2}
                                                onBlur={() => setIsEditing2(false)}
                                                autoFocus
                                            >
                                                {(selectedYear === "전체"
                                                    ? monthList2
                                                    : monthList2.filter((v) =>
                                                        v.includes(selectedYear)
                                                    )
                                                ).map((v, index) => (
                                                    <MenuItem key={index} value={v}>
                                                        {v}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    ) : (
                                        <div style={{ textAlign:"center"}}>
                                            <span
                                                style={{ cursor: "pointer"}}
                                                onClick={handleClick2}
                                            >
                                                월간 {selectedMonth2 && selectedMonth2.slice(2,7)}월 <br/>실적
                                            </span>
                                        </div>
                                    )}
                                </th>
                                <th style={{ minWidth: 80, textAlign:"center"}}>HIPO 등급<br/> 발생(월간)</th>
                                <th style={{ minWidth: 80, textAlign:"center" }}> 전체 누계 <br/>실적</th>
                            </tr>
                        </thead>
                        <tbody>
                        <tr>
                                <th>Fatality</th>
                                <td></td>
                                <td style={{backgroundColor:'#ff4433'}}>{chartData2[0] && chartData2[0].Fatality_hipo}</td>
                                <td></td>
                            </tr>
                            <tr>
                                <th>LTI</th>
                                <td style={{backgroundColor:'#cca300'}}>{chartData2[0] && chartData2[0].LTI}</td>
                                <td style={{backgroundColor:'#ff4433'}}>{chartData2[0] && chartData2[0].LTI_hipo}</td>
                                <td style={{backgroundColor:'#cca300'}}>{chartData2[0] && chartData2[0].LTI_accum}</td>
                            </tr>
                            <tr>
                                <th>MTC</th>
                                <td style={{backgroundColor:'#ffcc00'}}>{chartData2[0] && chartData2[0].MTC}</td>
                                <td style={{backgroundColor:'#ff4433'}}>{chartData2[0] && chartData2[0].MTC_hip}</td>
                                <td style={{backgroundColor:'#ffcc00'}}>{chartData2[0] && chartData2[0].MTC_accum}</td>
                            </tr>
                            <tr>
                                <th>FAC</th>
                                <td style={{backgroundColor:'#00cc00'}}>{chartData2[0] && chartData2[0].FAC}</td>
                                <td style={{backgroundColor:'#ff4433'}}> {chartData2[0] && chartData2[0].FAC_hipo}</td>
                                <td style={{backgroundColor:'#00cc00'}}> {chartData2[0] && chartData2[0].FAC_accum}</td>
                            </tr>
                            <tr>
                                <th>ADI</th>
                                <td style={{backgroundColor:'#80aaff'}}>{chartData2[0] && chartData2[0].ADI}</td>
                                <td style={{backgroundColor:'#ff4433'}}>{chartData2[0] && chartData2[0].ADI_hipo}</td>
                                <td style={{backgroundColor:'#80aaff'}}>{chartData2[0] && chartData2[0].ADI_accum}</td>
                            </tr>
                            <tr>
                                <th>
                                    <FormControl variant="standard" size='small' sx={{ m: 0, minWidth: 60, }} className='selection'>
                                        <InputLabel sx={{m:0}} id="demo-simple-select-standard-label"></InputLabel>
                                        <Select
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            value={type2}
                                            onChange={handleChange2}
                                            label=""
                                            sx={{m:0, fontSize:'0.7rem'}}
                                        >
                                            <MenuItem sx={{fontSize:"0.8rem"}} value="scar">SCAR</MenuItem>
                                            <MenuItem sx={{fontSize:"0.8rem"}} value="smt">개선지시서</MenuItem>
                                            <MenuItem sx={{fontSize:"0.8rem"}} value="punch">부적합 발굴</MenuItem>
                                        </Select>
                                    </FormControl>
                                </th>
                                <td style={{backgroundColor:'#4d88ff'}}>{chartData2[0] && chartData2[0].count}</td>
                                <td style={{backgroundColor:'#ff4433'}}> </td>
                                <td style={{backgroundColor:'#4d88ff'}}>{chartData2[0] && chartData2[0].count_accum}</td>
                            </tr>
                        </tbody>
                    </table>
                    </div>
                </GridLayoutItem>
            </GridLayout>
        </>
    );
}

export default PyramidChart;
