import React from "react";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";

const InspectionApprovalCard = ({ height, type, data }) => {
  const {
    title,
    result_1,
    result_1_result,
    result_1_unit,
    result_1_info,
    result_2,
    result_2_result,
    result_2_unit,
    result_2_info,
  } = data;

  return (
    <GridLayout
      rows={[
        {
          height: height * 0.2,
        },
        {
          height: height * 0.2,
        },
        {
          height: height * 0.2,
        },
        {
          height: height * 0.2,
        },
        {
          height: height * 0.2,
        },
      ]}
      cols={[
        {
          width: "100%",
        },
      ]}
      gap={{
        rows: 0,
        cols: 0,
      }}
      style={{ position: "relative" }}
      className="inspection-approval-card-body"
    >
      <GridLayoutItem
        row={1}
        col={1}
        className="inspection-approval-card card-top bold-text"
      >
        {title}
      </GridLayoutItem>
      <GridLayoutItem
        row={2}
        col={1}
        className="inspection-approval-card card-mid bold-text"
      >
        <div style={{ display: "flex", flexDirection: "row", gap: "5px" }}>
          <div>{result_1}</div>
          <div
            style={{
              color:
                type === "합격률"
                  ? handle_approval_text_color(result_1_result)
                  : handle_duration_text_color(result_1_result),
            }}
          >
            {Number(result_1_result).toFixed(2)}
          </div>
          <div>{result_1_unit}</div>
        </div>
      </GridLayoutItem>
      <GridLayoutItem
        row={3}
        col={1}
        className="inspection-approval-card card-mid small-text"
      >
        {result_1_info}
      </GridLayoutItem>
      <GridLayoutItem
        row={4}
        col={1}
        className="inspection-approval-card card-mid bold-text"
      >
        <div style={{ display: "flex", flexDirection: "row", gap: "5px" }}>
          <div>{result_2}</div>
          <div
            style={{
              color:
                type === "합격률"
                  ? handle_approval_text_color(result_2_result)
                  : handle_duration_text_color(result_2_result),
            }}
          >
            {Number(result_2_result).toFixed(2)}
          </div>
          <div>{result_2_unit}</div>
        </div>
      </GridLayoutItem>
      <GridLayoutItem
        row={5}
        col={1}
        className="inspection-approval-card card-bottom small-text"
      >
        {result_2_info}
      </GridLayoutItem>
    </GridLayout>
  );
};

export default InspectionApprovalCard;

const handle_approval_text_color = (value) => {
  if (value >= 99.2) {
    //green
    return "#1FD12F";
  }

  if (99.2 > value && value >= 99.0) {
    //yellow
    return "#F2E85C";
  }

  if (99 > value) {
    //red
    return "#F8443E";
  }
};

const handle_duration_text_color = (value) => {
  if (value > 14) {
    //red
    return "#F8443E";
  }

  if (value > 7 && value <= 14) {
    //black
    return "#737373";
  }

  if (7 >= value) {
    //green
    return "#1FD12F";
  }
};
