import React, { useEffect, useState, useLayoutEffect } from 'react';
import _ from 'lodash';
import moment from 'moment';
import axios from "../../common/axios";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5themes_Spirited from "@amcharts/amcharts5/themes/Spirited";
import MuiToggleButton from '@mui/material/ToggleButton';
import MuiToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';


const CHART_ID = 'manchart';

const ToggleButton = styled(MuiToggleButton)(() => ({
  '&.Mui-selected, &.Mui-selected:hover': {
    color: 'white',
    backgroundColor: '#004098',
  },
  '&.MuiToggleButton-root': {
    fontSize: '0.6rem',
    fontFamily: 'Pretendard-R',
    padding: 0,
    // borderRadius: '8px 0px 0px 8px!important',
    border: '1px solid rgba(0, 0, 0, 0.12)!important',
    width: 60

  }
}));

const ToggleButtonGroup = styled(MuiToggleButtonGroup)(() => ({
  '&.MuiToggleButtonGroup-root': {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(0)
    }
  }
}));

const ToggleButton2 = styled(MuiToggleButton)(() => ({
  '&.Mui-selected, &.Mui-selected:hover': {
    color: 'white',
    backgroundColor: '#FF6F61',
  },
  '&.MuiToggleButton-root': {
    fontSize: '0.6rem',
    fontFamily: 'Pretendard-R',
    padding: 0,
    width: 60,
    borderRadius: '5px 5px 5px 5px!important',
    border: '1px solid rgba(0, 0, 0, 0.12)!important'

  }
}));

const ToggleButtonGroup2 = styled(MuiToggleButtonGroup)(() => ({
  '&.MuiToggleButtonGroup-root': {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1)
    }
  }
}));


const theme = createTheme({
  palette: {
    text: {
      primary: '#00ff00',
    },
  },
});


const colorSet = [
  "#03045E", "#023E8A", "#0077B6", "#0096C7", "#00B4D8", "#48CAE4", "#ADE8F4", "#D3F7FF"
]

const ManPower = ({ height }) => {

  const BoxHeight = 2 * (height / 6.5) - 70
  const [data, setdata] = useState([]);
  const [selectedValue, setSelectedValue] = React.useState("daily");
  const [selectedValue2, setSelectedValue2] = React.useState("프로젝트");

  const handleChange = (event, selectedValue) => {
    if (selectedValue !== null) {
      setSelectedValue(selectedValue);
    }
  };


  const handleChange2 = (event, selectedValue2) => {
    if (selectedValue2 !== null) {
      setSelectedValue2(selectedValue2);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const res = await axios.get(`/getaccessData`);
      if (res.data.success) {
        const response = res.data.data;

        const transformedData = response.map((v) => ({
          ...v,
          date: moment(v.date),
        }));

        const transformedDataWeek = transformedData.map((v) => ({
          ...v,
          date: v.date.format("YY년 WW주"),
        }));

        const transformedDataMonth = transformedData.map((v) => ({
          ...v,
          date: v.date.format("YY년 MM월"),
        }));

        const transformedDataDay = transformedData.map((v) => ({
          ...v,
          date: v.date.format("YY년 MM월 DD일"),
        }));

        const groupByDate = (data, dateFormat) => {
          const grouped = _.groupBy(data, "date");
          return Object.entries(grouped).map(([date, items]) => ({
            date,
            ...selectedValue2 === "업체별"
              ? _(items)
                .flatMap("vendors")
                .groupBy("name")
                .mapValues((v) => _.sumBy(v, "count"))
                .value()
              : _(items)
                .flatMap("projects")
                .groupBy("name")
                .mapValues((v) => _.sumBy(v, "count"))
                .value(),
            none: 0,
          }));
        };

        const groupedDataWeek = groupByDate(transformedDataWeek);
        const groupedDataMonth = groupByDate(transformedDataMonth);
        const groupedDataDay = groupByDate(transformedDataDay);

        setdata(
          selectedValue === "daily"
            ? groupedDataDay.slice(-5)
            : selectedValue === "weekly"
              ? groupedDataWeek.slice(-5)
              : groupedDataMonth.slice(-5)
        );
      }
    };
    fetchData();
  }, [selectedValue2, selectedValue]);

  function sumNumericValues(obj) {
    let sum = 0;

    for (let key in obj) {
      if (typeof obj[key] === 'number') {
        sum += obj[key];
      }
    }

    return sum;
  }


  const total_man_day = sumNumericValues(data.filter(v => v.date === moment().format("YY년 MM월 DD일"))[0])
  const total_man_week = sumNumericValues(data.filter(v => v.date === moment().format("YY년 WW주"))[0])
  const total_man_month = sumNumericValues(data.filter(v => v.date === moment().format("YY년 MM월"))[0])

  useLayoutEffect(() => {
    let root = am5.Root.new(CHART_ID);
    root.setThemes([am5themes_Animated.new(root), am5themes_Spirited.new(root)]);

    var chart = root.container.children.push(am5xy.XYChart.new(root, {
      panX: false,
      panY: false,
      wheelX: "panX",
      wheelY: "zoomX",
      layout: root.verticalLayout
    }));


    let tooltip = am5.Tooltip.new(root, {});

    chart.plotContainer.set("tooltipPosition", "pointer");
    chart.plotContainer.set("tooltipText", "a");
    chart.plotContainer.set("tooltip", tooltip);

    tooltip.label.adapters.add("text", function (text, target) {
      text = "";
      let i = 0;
      chart.series.each(function (series) {
        let tooltipDataItem = series.get("tooltipDataItem");

        if (tooltipDataItem) {
          if (i === 0) {
            text += "[bold]" + tooltipDataItem.get("categoryX") + "\n";
          }

          if (i !== 0) {
            text += "\n";
          }

          if (tooltipDataItem.get("valueY")) {
            text +=

              "[" +
              series.get("stroke") +
              "]●[/] [width:100px]" +
              series.get("name") +
              ": " +
              Number(
                (tooltipDataItem.get("valueY") ?? 0).toFixed(2)
              ).toLocaleString() +
              " 명"
          }
        }


        i++;
      });
      return text;
    });

    tooltip.get("background").setAll({
      stroke: am5.color(0x000000),
      strokeOpacity: 0.8,
      fill: am5.color(0xffffff),
      fillOpacity: 0.8,
    });

    // Add cursor
    // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
    var cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
      xAxis: xAxis
    }));
    cursor.lineY.set("visible", false);

    // add scrollbar
    var scrollbar = chart.set("scrollbarX", am5.Scrollbar.new(root, {
      orientation: "horizontal",
      maxHeight: 3
    }));

    scrollbar.startGrip.set("scale", 0.7);
    scrollbar.endGrip.set("scale", 0.7);

    chart.zoomOutButton.set("forceHidden", true);



    var xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
      categoryField: "date",
      renderer: am5xy.AxisRendererX.new(root, {
        cellStartLocation: 0.1,
        cellEndLocation: 0.9,
        minGridDistance: 30
      }),
      tooltip: am5.Tooltip.new(root, {})
    }));

    xAxis.data.setAll(data);

    xAxis.get("renderer").labels.template.setAll({
      // oversizedBehavior: "fit",
      oversizedBehavior: "wrap",
      textAlign: "center",
      fill: "#525252",
      maxWidth: 80,
      fontSize: "0.8rem",
      minScale: 0.6
    });


    var yRenderer = am5xy.AxisRendererY.new(root, {})
    yRenderer.labels.template.set('visible', false)

    var yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
      calculateTotals: true,
      min: 0,
      extraMax: 0.1,
      renderer: yRenderer,
    }));

    yAxis.get("renderer").grid.template.set("forceHidden", true);
    xAxis.get("renderer").grid.template.set("forceHidden", true);



    var legend = chart.children.push(am5.Legend.new(root, {
      x: am5.percent(0),
      centerX: am5.percent(0),
      layout: am5.GridLayout.new(root, {
        maxColumns: 3,
        fixedWidthGrid: true
      }),
    
      height: am5.percent(30),
      width:am5.percent(100),
      verticalScrollbar: am5.Scrollbar.new(root, {
        orientation: "vertical"
      })
    }));

    legend.labels.template.setAll({
      fontSize: 12,
      fontWeight: "400",
      fill: am5.color(0x000000),

    });

    legend.markers.template.setAll({
      width: 12,
      height: 12
    });


    function makeSeries(name, fieldName, showTotal, index) {
      var series = chart.series.push(am5xy.ColumnSeries.new(root, {
        name: name,
        stacked: true,
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: fieldName,
        categoryXField: "date",
        maskBullets: false,
        // legendLabelText: "[fontSize: 12px {stroke}]{name}[/] {valueY} ",


      }));

      series.columns.template.setAll({
        width: am5.percent(90),
      });

      // series.columns.template.setAll({
      //   tooltipText: "[bold]{name}[/]\n {valueY}명",
      //   tooltipY: am5.percent(10)
      // });


      series.bullets.push(function (root, series, dataItem) {
        if (dataItem.get("valueY") / sumNumericValues(dataItem.dataContext) > 0.2) {
          return am5.Bullet.new(root, {
            sprite: am5.Label.new(root, {
              text: dataItem.get("valueY") && dataItem.get("valueY").toLocaleString(),
              fill: root.interfaceColors.get("alternativeText"),
              centerX: am5.p50,
              centerY: am5.p50,
              minBulletDistance: 20
            })
          });
        }
      });


      if (showTotal) {
        series.bullets.push(function () {
          return am5.Bullet.new(root, {
            locationY: 1,
            sprite: am5.Label.new(root, {
              text: "{valueYTotal}",
              fill: am5.color(0x000000),
              centerY: am5.p100,
              centerX: am5.p50,
              populateText: true
            })
          });
        });

      }

      series.set("fill", (colorSet[index % colorSet.length]));

      series.data.setAll(data);
      series.appear();

      if (!showTotal) {
        legend.data.push(series);
      }

    }


    const keys = _(data)
      .flatMap(Object.keys)
      .uniq()
      .pull('date')
      .pull('none')
      .value();

    keys.forEach((key, index) => {
      makeSeries(key, key, false, index);
    });

    makeSeries("", "none", true);


    chart.appear(1000, 100);
    return () => {
      root && root.dispose();
    };

  }, [data]);
  return (
    <>
      {/* <div style={{fontSize: '0.9rem',top:'8px' ,color: '#F1F2F5', zIndex: '9999999' ,position: 'absolute', fontWeight: '500', right: '12px'}}>{moment().format('YYYY년 MM월 DD일')}</div> */}

      <div style={{ marginLeft: '12px', position: 'absolute', top: '50px', transform: 'translateY(-50%)' }}>
        {selectedValue === 'daily' ?
          <div><span style={{ fontSize: '0.7rem', color: '#363945', fontWeight: '600' }}> 전일 </span><span style={{ fontSize: '1.1rem', color: '#363945', fontWeight: '600' }}>{total_man_day.toLocaleString()}</span> <span style={{ fontSize: '0.7rem', color: '#363945', fontWeight: '600' }}> 명 </span></div> :
          selectedValue === 'weekly' ?
            <div><span style={{ fontSize: '0.7rem', color: '#363945', fontWeight: '600' }}> 주간 </span><span style={{ fontSize: '1.1rem', color: '#363945', fontWeight: '600' }}>{total_man_week.toLocaleString()}</span> <span style={{ fontSize: '0.7rem', color: '#363945', fontWeight: '600' }}> 명 </span></div> :
            selectedValue === 'monthly' ?
              <div><span style={{ fontSize: '0.7rem', color: '#363945', fontWeight: '600' }}> 월간 </span><span style={{ fontSize: '1.1rem', color: '#363945', fontWeight: '600' }}>{total_man_month.toLocaleString()}</span> <span style={{ fontSize: '0.7rem', color: '#363945', fontWeight: '600' }}> 명 </span></div> : ''
        }
      </div>
      <div style={{ position: 'absolute', top: '20px', right: '12px', transform: 'translateY(-50%)' }} className='manRB'>
        <ThemeProvider theme={theme}>
          <ToggleButtonGroup size="small" onChange={handleChange} value={selectedValue} exclusive aria-label="baseunit">
            <ToggleButton value="daily" aria-label='daily' >
              daily
            </ToggleButton>
            <ToggleButton value="weekly" aria-label="weekly">
              weekly
            </ToggleButton>
            <ToggleButton value="monthly" aria-label="monthly">
              monthly
            </ToggleButton>
          </ToggleButtonGroup>
        </ThemeProvider>

      </div>

      <div style={{ position: "absolute", top: "50px", right: '12px', transform: 'translateY(-50%)', zIndex: 999999 }}>
        <ThemeProvider theme={theme}>
          <ToggleButtonGroup2 size="small" onChange={handleChange2} value={selectedValue2} exclusive aria-label="baseunit">
            <ToggleButton2 value="프로젝트" aria-label='프로젝트' >
              프로젝트
            </ToggleButton2>
            <ToggleButton2 value="업체별" aria-label="업체별">
              업체별
            </ToggleButton2>
          </ToggleButtonGroup2>
        </ThemeProvider>
      </div>
      <div id={CHART_ID} style={{ width: "100%", height: BoxHeight, zIndex: '999', margin: '0 auto', marginTop: 0 }}></div>

    </>
  );
};


export default ManPower
