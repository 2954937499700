import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import 'hammerjs';
import axios from "../../../common/axios";
const selectField = "selected";
const expandField = "expanded";
const dataItemKey = "text";
const textField = "text";
const subItemsField = "items";
const fields = {
    selectField,
    expandField,
    dataItemKey,
    subItemsField,
};

const CostTile = () => {
    const { project_code } = useParams();
    const [actualpercent, setActualpercent] = useState(0);
    const [planpercent, setPlanpercent] = useState(0);


useEffect(() => {
    const fetchData = async (reportName) => {
        try {
            const res = await axios.get(`/getCostSheetsimple?project=${project_code}`);
            if(res.data.success){
                const firstData =
                _(res.data.data.filter(v => v.ITEM_TYPEID === 0))
                  .groupBy("PROJECTNAME")
                  .map((objs, key) => ({
                    PROJECTNAME: key,
                    총예산: _.sumBy(objs, "총예산"),
                    추정원가: _.sumBy(objs, "추정원가"),
                  }))
                  .value()
                  .filter(v => v.총예산 !== 0)

                setPlanpercent(_.sumBy(firstData,"총예산"))
                setActualpercent(_.sumBy(firstData,"추정원가"))
            }
  
            // setLoading(false);

        } catch (error) {
            console.error(`${reportName} 데이터 가져오기 중 오류 발생:`, error);
            return []; // 오류 발생 시 빈 배열 반환
        }
    };
    fetchData();
}, [ project_code]);

  return (
    <div style={{ position: 'relative' }}>
        <div style={{ position: 'absolute', top: '30px', left: '40px' }}>
            <div style={{ textAlign: 'center', color: '#939597', fontSize: '1rem'}}>실행예산</div>
            <div style={{textAlign: 'center' , fontSize: '1.5rem', fontWeight: 'bold' }}>  {(planpercent/100000000).toFixed(2)}억</div>
        </div>
    <div style={{ position: 'absolute', top: '30px', right: '40px' }}>
        <div style={{ textAlign: 'center', color: '#939597', fontSize: '1rem' }}>추정원가</div>
        <div style={{ textAlign: 'center', fontSize: '1.5rem', fontWeight: 'bold', color: '#525252' }}>{(actualpercent/100000000).toFixed(2)}억</div>
    </div>
    <div style={{ position: 'absolute', top: '120px', left: '50%', transform: 'translateX(-50%)' }}>
        <div style={{ textAlign: 'center', color: '#363945' }}>Dev.</div>

        <div style={{ textAlign: 'center', fontSize: '2rem', fontWeight: 'bold' }}>

                <span style={{ color:planpercent- actualpercent > 0 ?'#00539C':'#9E1030' }}>{((planpercent - actualpercent )/100000000).toFixed(2)}</span>억</div>
    </div>
        
</div>
  )
}

export default CostTile
