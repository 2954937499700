import React, { useEffect, useState } from 'react'
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { useParams } from 'react-router-dom';
import axios from "../../../common/axios"
import dayjs from 'dayjs';
import moment from 'moment';
import _ from 'lodash';

const AccessControlChart = ({ height }) => {
    const { project_code } = useParams();
    const tlist = ["출력대비 부적합", "출력대비 S-CAR", "출력대비 개선지시서", "출력대비 사고현황"];
    const [rlist, setrlist] = useState([]);
    const [tstate, settState] = React.useState(tlist[0]);
    const [rstate, setrState] = React.useState(rlist[0]);

    const [acdata, setacdata] = useState([]);
    const [spdata, setspdata] = useState([]);
    const [scardata, setscardata] = useState([]);
    const [smtdata, setsmtdata] = useState([]);
    const [irdata, setirdata] = useState([]);
    const [monthList, setMonthLIst] = useState([]);

    const [projectList, setProjectList] = useState([]);
    const [project, setProject] = React.useState("전체");



    const handleProjectChange = (event) => {
        setProject(event.target.value)
    }

    const [chartData, setChartData] = useState([]);

    const handletChange = (event) => {
        settState(event.target.value);
    };
    const handlerChange = (event) => {
        setrState(event.target.value);
    };

    useEffect(() => {
        const fetchData = async () => {

            const res = await axios.get(`/accessdata?project=${project}`)

            if(res.data.success){
                setProjectList(
                   [ "전체",...res.data.projectList]
                )

            setacdata(res.data.mpcount2)
            setspdata(res.data.safeyPunch)
            setscardata(res.data.scar)
            setsmtdata(res.data.SMTSCAR)
            setirdata(res.data.Incident)
            setMonthLIst(res.data.monthList)
            }
            // setSFData(res.data.data.data)
        }
        fetchData()
    }, [project])

    useEffect(() => {


        const dataStart = monthList.length > 0 ? (
            tstate === "출력대비 부적합" ?
              monthList.map((v) => ({ ...v, ...acdata.find(vf => vf.date === v.date), ...spdata.find(vf => vf.date === v.date) })) :
              tstate === "출력대비 S-CAR" ?
                monthList.map((v) => ({ ...v, ...acdata.find(vf => vf.date === v.date), ...scardata.find(vf => vf.date === v.date) })) :
                tstate === "출력대비 개선지시서" ?
                  monthList.map((v) => ({ ...v, ...acdata.find(vf => vf.date === v.date), ...smtdata.find(vf => vf.date === v.date) })) :
                  monthList.map((v) => ({ ...v, ...acdata.find(vf => vf.date === v.date), ...irdata.find(vf => vf.date === v.date) }))
          ) : [];

  
        setChartData(_.sortBy(dataStart,'date'))
    }, [tstate,scardata, irdata,smtdata, spdata, acdata])

    
    
    useEffect(() => {
        var root = am5.Root.new("chartdiv222");

        root.setThemes([
            am5themes_Animated.new(root)
        ]);

        const transformedData = 
        _(_.sortBy(chartData, "date"))
        .groupBy("date")
        .map((objs, key) => ({
            date: new Date(key+"-01").getTime(), 
            mpcount: isNaN((_.sumBy(objs, "mpcount")/_.sumBy(chartData, "mpcount")))?0:(_.sumBy(objs, "mpcount")/_.sumBy(chartData, "mpcount"))*100,
            spcount: isNaN((_.sumBy(objs, "spcount")/_.sumBy(chartData, "spcount")))?0: (_.sumBy(objs, "spcount")/_.sumBy(chartData, "spcount"))*100,
          }))
          .value();

        var chart = root.container.children.push(am5xy.XYChart.new(root, {
            panX: false,
            panY: false,
            paddingLeft: 0,
            wheelX: "panX",
            wheelY: "zoomX",
            layout: root.verticalLayout
        }));


        var legend = chart.children.push(
            am5.Legend.new(root, {
                centerX: am5.p50,
                x: am5.p50
            })
        );

        var cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
            xAxis: xAxis,
            behavior: "none"
          }));
          cursor.lineY.set("visible", false);

          
        var xAxis = chart.xAxes.push(am5xy.DateAxis.new(root, {
            maxDeviation: 0.2,
            baseInterval: {
              timeUnit: "month",
              count: 1
            },
            renderer: am5xy.AxisRendererX.new(root, {
              minorGridEnabled:true
            }),
            tooltip: am5.Tooltip.new(root, {})
          }));



        xAxis.data.setAll(transformedData);

        var yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
            min:0,
            // max: 100,
            strictMinMax: true,
            renderer: am5xy.AxisRendererY.new(root, {
              pan: "zoom",

            }),
            numberFormat: "#'%'"
          }));
          

        // yAxis.get("renderer").grid.template.set("forceHidden", true);
        // xAxis.get("renderer").grid.template.set("forceHidden", true);
        // Add series
        // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
        function makeSeries(name, fieldName) {

            var tooltip = am5.Tooltip.new(root, {
                getStrokeFromSprite: true,
                getFillFromSprite: false,
                labelText: "{valueY.formatNumber('[#0f0]+0.00|[#f00]0.00|[#000]0.00')}%"
              })

              tooltip.get("background").setAll({
                fill: am5.color(0xffffff)
              })

            var series = chart.series.push(am5xy.LineSeries.new(root, {
                name: name,
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: fieldName,
                valueXField: "date",
                tooltip: am5.Tooltip.new(root, {
                    pointerOrientation: "horizontal",
                    labelText: "{name}: {valueY}%"
                  })
            }));

            // series.columns.template.setAll({
            //     tooltipText: "{name}, {categoryX}:{valueY}",
            //     width: am5.percent(90),
            //     tooltipY: 0,
            //     strokeOpacity: 0
            // });

            series.data.setAll(transformedData);
            series.appear();


            legend.data.push(series);
        }

        makeSeries("출력 인원", "mpcount");
        makeSeries(tstate && tstate.split("출력대비")[1], "spcount");

        chart.appear(1000, 100);

        return () => {
            root && root.dispose();
        }
    }, [chartData, tstate])


    return (
        <>                   
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', padding: '6px', marginTop:-56 }}>
        <DropDownList
                        style={{
                            marginRight: '10px', width: "180px", float: 'right', fontSize: '0.7rem', height: 30, fontWeight: 600, 
                        }}
                        data={projectList}
                        value={project}
                        onChange={handleProjectChange}
                    />
            <DropDownList
                style={{
                    width: "180px", float: 'right', fontSize: '0.7rem', height: 30, fontWeight: 600, 
                }}
                data={tlist}
                value={tstate}
                onChange={handletChange}
            />

        
        </div>
        
        
        

            <div id='chartdiv222' style={{ height: height }}></div>
        </>
    )
}

export default AccessControlChart
