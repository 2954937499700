import React, { useState, useEffect, useMemo } from 'react';
import { TileLayout } from "@progress/kendo-react-layout";
import { DashboardLayout } from '../../../../layout/Layout';
import useBreakpoint from "use-breakpoint";
import ProgressChart from './progressTile';
import useWindowDimensions from '../../../common/useWindowDimensions';
import ActStatus from './activityStatusTile';
import VBulletPage from '../vProgress/vProgressBullet';
import axios from "../../../../common/axios";
import { useParams } from 'react-router-dom';
import BulletPage from './progressBullet';
import ProgressChartNew from './progressTileNew';
import ActStatusNew from './activityStatusTileNew';

const BREAKPOINTS = { mobile: 0, tablet: 768, desktop: 1280, };


const ProgressPage = () => {
    const [fixblock, setfixblock] = useState(false);
    const [newWBS, setNewWBS] = useState(false);
    const { height, width } = useWindowDimensions();
    const { project_code } = useParams();

    const [grid, setgrid] = useState({
      mobile: [
        {
          col: 1,
          colSpan: 12,
          rowSpan: 6,
          status: true,
        },
        {
          col: 1,
          colSpan: 12,
          rowSpan: 6,
          status: true,
        },        {
          col: 1,
          colSpan: 16,
          rowSpan: 3,
          status: true,
        },
      ]
      ,
      tablet: [
        {
          col: 1,
          colSpan: 12,
          rowSpan: 6,
          status: true,
        },
        {
          col: 13,
          colSpan: 4,
          rowSpan: 6,
          status: true,
        },        {
          col: 1,
          colSpan: 16,
          rowSpan: 3,
          status: true,
        },
      ],
      desktop: [
        {
          col: 1,
          colSpan: 11,
          rowSpan: 3,
          status: true,
        },
        {
          col: 12,
          colSpan: 5,
          rowSpan: 3,
          status: true,
        },
        {
          col: 1,
          colSpan: 16,
          rowSpan: 3,
          status: true,
        },
      ]
    });
    const { breakpoint } = useBreakpoint(BREAKPOINTS, 'desktop')

    const togglefix = () => {
      setfixblock(!fixblock);
    };

    const [positiondata, setPositionData] = useState(grid['desktop']);

    useEffect(() => {
      setPositionData(grid[breakpoint])
    }, [breakpoint]);


    useEffect(() => {
      const fetchData = async () => {
        try {
            const res = await axios.get(`/newwbs?project=${project_code}`);
            setNewWBS(res.data.newWBS)

        } catch (error) {
            console.error(`데이터 가져오기 중 오류 발생:`, error);
            return []; // 오류 발생 시 빈 배열 반환
        }
    };
    fetchData();
      }, [ project_code]);

    const tiles = [{
      header: (<div style={{ position: 'relative'}} >
          공정률
       </div>),
      body: newWBS? <ProgressChartNew height={height} width={width*12/16 -  14 * 16}/> :<ProgressChart height={height} width={width*12/16 -  14 * 16}/>,
      status: true,
      resizable: false,
      reorderable: false,
    },
    {
      header: (<div style={{ position: 'relative'}} >{newWBS? "공종별 차질 스텝 현황":"공종별 차질 액티비티 현황"}</div>),
      body:newWBS? <ActStatusNew height={height}/>:<ActStatus  height={height}/>,
      status: true,
      resizable: false,
      reorderable: false,
    },
    {
      header: (<div style={{ position: 'relative'}} >{newWBS? "소공종별 공정률":"업체별 공정율"}</div>),
      body: newWBS? <BulletPage height={height} /> :<VBulletPage height={height} />,
      status: true,
      resizable: false,
      reorderable: false,
    },
  
    ];
      const handleReposition = (e) => {
        setPositionData(e.value);
      };


    return(
        <DashboardLayout>
           
            <TileLayout
            columns={16}
            className='projectpage'

            rowHeight={height/6.5}
            positions={positiondata}
            gap={{
                rows: 10,
                columns: 10
            }}
            items={tiles}
            autoFlow="row dense" 
            onReposition={handleReposition}
            // style={{marginTop:20}}
            /> 
        </DashboardLayout>
    );

};

export default ProgressPage;

