import React, { useEffect } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5radar from "@amcharts/amcharts5/radar";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

const QualityGaugeChart = ({ id, height, pqi_value = 0 }) => {
  useEffect(() => {
    var root = am5.Root.new(id);

    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    root.setThemes([am5themes_Animated.new(root)]);

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/radar-chart/
    var chart = root.container.children.push(
      am5radar.RadarChart.new(root, {
        panX: false,
        panY: false,
        startAngle: 180,
        endAngle: 360,
        radius: am5.percent(90),
        layout: root.verticalLayout,
      })
    );

    // Colors
    var colors = am5.ColorSet.new(root, {
      step: 2,
    });

    // Measurement #1

    // Axis
    var color1 = colors.next();

    var axisRenderer1 = am5radar.AxisRendererCircular.new(root, {
      radius: -30,
      innerRadius: -50,
      stroke: color1,
      strokeOpacity: 1,
      strokeWidth: 70,
      inside: true,

      strokeGradient: am5.LinearGradient.new(root, {
        rotation: 0,
      }),
    });

    axisRenderer1.grid.template.setAll({
      forceHidden: true,
    });

    axisRenderer1.labels.template.setAll({
      radius: 15,
      inside: true,
      visible: false,
    });

    var xAxis1 = chart.xAxes.push(
      am5xy.ValueAxis.new(root, {
        maxDeviation: 0,
        min: 0,
        max: 100,
        strictMinMax: true,
        renderer: axisRenderer1,
      })
    );

    var handDataItem = xAxis1.makeDataItem({
      value: 100,
    });

    handDataItem.set("value", 100);

    // // Add clock hand
    var axisDataItem1 = xAxis1.makeDataItem({
      value: pqi_value,
      name: "Measurement #2",
    });

    let tooltip = am5.Tooltip.new(root, {});

    tooltip.get("background").setAll({
      fill: "#fff",
      stroke: "black",
      width: "100px",
    });

    tooltip.label.setAll({
      fill: am5.color("#333"), // 글자 색상을 여기서 변경하세요.
    });

    var clockHand1 = am5radar.ClockHand.new(root, {
      pinRadius: 12,
      radius: am5.percent(70),
      bottomWidth: 10,
      tooltipText: pqi_value.toFixed(1) + " 점",
      tooltipX: am5.p100,
      tooltip: tooltip, // 이렇게 설정한 툴팁을 사용합니다.
    });

    clockHand1.set("tooltip", tooltip);

    clockHand1.pin.setAll({
      fill: "#333",
    });

    clockHand1.hand.setAll({
      fill: "#333",
    });

    axisDataItem1.set(
      "bullet",
      am5xy.AxisBullet.new(root, {
        sprite: clockHand1,
      })
    );

    xAxis1.createAxisRange(axisDataItem1);

    // axisDataItem1.get("grid").set("forceHidden", true);
    // axisDataItem1.get("tick").set("forceHidden", true);

    // Axis
    var color2 = colors.next();

    var axisRenderer2 = am5radar.AxisRendererCircular.new(root, {
      innerRadius: -25,
      stroke: color2,
      strokeOpacity: 0,
      strokeWidth: 20,
    });

    axisRenderer2.grid.template.setAll({
      forceHidden: true,
    });

    axisRenderer2.ticks.template.setAll({
      stroke: color2,
      visible: false,
      length: 10,
      strokeOpacity: 1,
    });

    axisRenderer2.labels.template.setAll({
      visible: false,
    });

    var xAxis2 = chart.xAxes.push(
      am5xy.ValueAxis.new(root, {
        maxDeviation: 0,
        min: 0,
        max: 100,
        // strictMinMax: true,
        renderer: axisRenderer2,
      })
    );

    function createRange1(start, end, color, label) {
      var rangeDataItem = xAxis1.makeDataItem({
        value: start,
        endValue: end,
      });

      xAxis1.createAxisRange(rangeDataItem);

      rangeDataItem.get("axisFill").setAll({
        visible: true,
        fill: color,
        fillOpacity: 0,
      });

      rangeDataItem.get("tick").setAll({
        visible: false,
      });
    }

    createRange1(0, 50, "#FF0000", "F");
    createRange1(50, 70, "#FFCF00", "D");
    createRange1(70, 80, "#E6EB37", "C");
    createRange1(80, 90, "#92D050", "B");
    createRange1(90, 100, "#00B050", "A");

    function createRange2(start, end, color, label) {
      var rangeDataItem = xAxis2.makeDataItem({
        value: start,
        endValue: end,
      });

      xAxis2.createAxisRange(rangeDataItem);

      rangeDataItem.get("axisFill").setAll({
        visible: true,
        fill: color,
        fillOpacity: 0.8,
      });

      rangeDataItem.get("tick").setAll({
        visible: false,
      });

      rangeDataItem.get("label").setAll({
        visible: true,
        text: label,
        inside: true,
        radius: 8,
        fontSize: "0.9em",
        centerY: am5.percent(50),
        fill: am5.color(0xffffff),
      });
    }

    createRange2(0, 50, "#585858", "F");
    createRange2(50, 70, "#585858", "D");
    createRange2(70, 80, "#585858", "C");
    createRange2(80, 90, "#585858", "B");
    createRange2(90, 100, "#585858", "A");

    // Make stuff animate on load
    chart.appear(1000, 100);

    return () => {
      root && root.dispose();
    };
  }, [pqi_value, id]);

  return (
    <div>
      <div id={id} style={{ height: height, marginTop: "-10px" }}></div>
    </div>
  );
};

export default QualityGaugeChart;
