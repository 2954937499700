import React, { useEffect, useLayoutEffect } from 'react'
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

const SFBarChart = ({ id, height, data, maxWidth }) => {

  useEffect(() => {
    var root = am5.Root.new(id);

    root.setThemes([
      am5themes_Animated.new(root)
    ]);

    var chart = root.container.children.push(am5xy.XYChart.new(root, {
      panX: false,
      panY: false,
      paddingLeft: 0,
      wheelX: "panX",
      // wheelY: "zoomX",
      layout: root.verticalLayout
    }));

    chart.set("cursor", am5xy.XYCursor.new(root, {}));

    var xRenderer = am5xy.AxisRendererX.new(root, {
      minGridDistance: 30,
      minorGridEnabled: true
    });



    xRenderer.grid.template.setAll({
      location: 1
    })

    var xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
      maxDeviation: 0.3,
      categoryField: "id",
      renderer: xRenderer,
      tooltip: am5.Tooltip.new(root, {})
    }));


    xAxis.get("renderer").labels.template.setAll({
      oversizedBehavior: "wrap",
      visible:true,
      maxWidth: maxWidth,
      strokeOpacity: 1,
      textAlign: "center",
      fontSize: "0.8rem",

  });

    

    var yRenderer = am5xy.AxisRendererY.new(root, {
      strokeOpacity: 0
    })

    var yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
      maxDeviation: 0.3,
      renderer: yRenderer
    }));

    yAxis.get("renderer").labels.template.setAll({
      strokeOpacity: 0,
      visible: false
  });



    yAxis.get("renderer").grid.template.set("forceHidden", true);
    xAxis.get("renderer").grid.template.set("forceHidden", true);
    // Create series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    var series = chart.series.push(am5xy.ColumnSeries.new(root, {
      name: "Series 1",
      xAxis: xAxis,
      yAxis: yAxis,
      valueYField: "value",
      sequencedInterpolation: true,
      categoryXField: "id",
      tooltip: am5.Tooltip.new(root, {
        labelText: "{valueY}"
      })
    }));

    series.bullets.push(function () {
      return am5.Bullet.new(root, {
        locationY: 0.5,
        sprite: am5.Label.new(root, {
          text: "{valueY}",
          fill: "black",
          fontSize:"10px",
          centerY: am5.p100,
          centerX: am5.p50,
          populateText: true
        })
      });
    });

    series.columns.template.setAll({ cornerRadiusTL: 5, cornerRadiusTR: 5, strokeOpacity: 0 });
    series.columns.template.adapters.add("fill", function (fill, target) {
      return chart.get("colors").getIndex(series.columns.indexOf(target));
    });

    series.columns.template.adapters.add("stroke", function (stroke, target) {
      return chart.get("colors").getIndex(series.columns.indexOf(target));
    });



    xAxis.data.setAll(data);
    series.data.setAll(data);


    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    series.appear(1000);
    chart.appear(1000, 100);
    return () => {
      root && root.dispose();
    };
  }, [data])

  return (
    <div>
      <div id={id} style={{ height: height }}></div>
    </div>
  )
}

export default SFBarChart
