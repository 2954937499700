import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from "../../../common/axios";
import dayjs from 'dayjs';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import _ from 'lodash';

const filePath = process.env.REACT_APP_FILEPATH

const LTIR = ({height}) => {  

    const [tabledata, settabledata] = useState([]);
    const { project_code } = useParams();

    useEffect(() => {
        const fetchData = async () => {
          const body ={
            "bpname" : "LTIR 목표",
            "lineitem" : "yes",
            "filter_criteria":{
              "join":"AND",
              "filter":[
                {
                  "field":"vpeYearText",
                  "value":dayjs().format("YYYY"),
                  "condition_type":"eq"
                },
              ]
            }
          }
          const res = await axios.post(`/getbprecordsdash?path=AP`, body)


          const lineData = res.data?.data?.data?.[0]?._bp_lineitems ?? [];

          const res2 = await axios.get(`/getJSON?path=${filePath}/files/lti/accumlateltir.json`)

          const ltir = res2.data.filter(v=>v.year === dayjs().format("YYYY"))[0].data


          var ltirgroup =
          _(ltir)
            .groupBy('project')
            .map((objs, key) => ({
              'project': key,
              'LTIF':(_.sumBy(objs, "accidentCount") / _.sumBy(objs, "accessTime") * 1000000).toFixed(2),
             
            }))
            .value();

            console.log(res2.data)
            console.log(ltir)
            console.log(ltirgroup)
    
          settabledata(ltirgroup.map((v)=>({...v,...lineData.find(vf=>vf.ugenProjectName === v.project) })).filter(v=>v.project !== '종합'))
        };
        fetchData();
    
      }, [project_code]);



    return(
<>
<List sx={{ height:height/4-20, width: '100%', maxWidth: 360, bgcolor: 'background.paper' , overflow:'auto'}}>
{tabledata.map((v, index)=>(
  <>
      <ListItem alignItems="flex-start" key={index}>
      <ListItemText
        primary= {v.project}
        secondary={`LTIF     ${v.LTIRTarget} / ${v.LTIF}`}

      />
    </ListItem>
    {/* <Divider variant="inset" component="li"  key={index}/> */}
    </>
))
}
</List>
</>
    );


};

export default LTIR;
