import React, { useState, useEffect, useMemo } from 'react';
import { TileLayout } from "@progress/kendo-react-layout";
import useBreakpoint from "use-breakpoint";
import useWindowDimensions from '../common/useWindowDimensions';
import axios from "../../common/axios";
import { useParams } from 'react-router-dom';
import SafetyBarChart from './accidentType/accidentTypeBarChart';
import AccidentReport from './workPermitStatus/workPermitStatus';
import AccessControlChart from './accessControl/AccessControlChart';
import SafetyFailure from './safetyFailure/safetyFailure';
import DisasterStatus from './disasterStatus/disasterStatus';
import AccidentTypeStackChart from './accidentType/accidentTypeStackChart';
import LTIR from './ltir/ltir';
import { DashboardLayoutTotalT } from '../../layout/LayoutTotalT';
import PyramidChart from './totalkpi/pyramid';
import LTIChart from './totalkpi/ltif';

const BREAKPOINTS = { mobile: 0, tablet: 768, desktop: 1280, };

const SafetyPage = () => {
  const [fixblock, setfixblock] = useState(false);
  const { height, width } = useWindowDimensions();
  const [grid, setgrid] = useState({
    mobile:
      [

        {
          col: 1,
          colSpan: 16,
          rowSpan: 6,
          status: true,
        },
        {
          col: 1,
          colSpan: 16,
          status: true,
          rowSpan: 2,
        },
        {
          col: 1,
          colSpan: 16,
          status: true,
          rowSpan: 2,
        },
        {
          col: 1,
          colSpan: 16,
          status: true,
          rowSpan: 2,
        },

        {
          col: 1,
          colSpan: 16,
          rowSpan: 2,
          status: true,
        },


        {
          col: 1,
          colSpan: 16,
          rowSpan: 2,
          status: true,
        },


        {
          col: 1,
          colSpan: 16,
          rowSpan: 2,
          status: true,
        },
        {
          col: 1,
          colSpan: 16,
          rowSpan: 2,
          status: true,
        },
        {
          col: 1,
          colSpan: 16,
          rowSpan: 2,
          status: true,
        },
        {
          col: 9,
          colSpan: 16,
          rowSpan: 2,
          status: true,
        },
        {
          col: 1,
          colSpan: 16,
          rowSpan: 6,
          status: true,
        },
        {
          col: 9,
          colSpan: 16,
          rowSpan: 6,
          status: true,
        },


      ]
    ,
    tablet:
      [

        {
          col: 1,
          colSpan: 4,
          rowSpan: 6,
          status: true,
        },
        {
          col: 5,
          colSpan: 12,
          status: true,
          rowSpan: 2,
        },



        {
          col: 5,
          colSpan: 4,
          rowSpan: 2,
          status: true,
        },
        {
          col: 9,
          colSpan: 4,
          status: true,
          rowSpan: 2,
        },
        {
          col: 13,
          colSpan: 4,
          status: true,
          rowSpan: 2,
        },
        {
          col: 5,
          colSpan: 3,
          status: true,
          rowSpan: 2,
        },
        {
          col: 8,
          colSpan: 3,
          status: true,
          rowSpan: 2,
        },

        {
          col: 11,
          colSpan: 6,
          rowSpan: 2,
          status: true,
        },
        {
          col: 1,
          colSpan: 8,
          rowSpan: 2,
          status: true,
        },
        {
          col: 9,
          colSpan: 8,
          rowSpan: 2,
          status: true,
        },
        {
          col: 1,
          colSpan: 8,
          rowSpan: 6,
          status: true,
        },
        {
          col: 9,
          colSpan: 8,
          rowSpan: 6,
          status: true,
        },
      ]
    ,
    desktop:
      [

        {
          col: 1,
          colSpan: 12,
          rowSpan: 2,
          status: true,
        },
        {
          col: 13,
          colSpan: 4,
          rowSpan: 2,
          status: true,
        },
        {
          col: 1,
          colSpan: 8,
          rowSpan: 2,
          status: true,
        },
        {
          col: 9,
          colSpan: 8,
          rowSpan: 2,
          status: true,
        },
        {
          col: 1,
          colSpan: 8,
          rowSpan: 2,
          status: true,
        },
        {
          col: 9,
          colSpan: 8,
          rowSpan: 2,
          status: true,
        },
        {
          col: 1,
          colSpan: 8,
          rowSpan: 2,
          status: true,
        },
        {
          col: 9,
          colSpan: 8,
          rowSpan: 2,
          status: true,
        },
        {
          col: 1,
          colSpan: 8,
          rowSpan: 2,
          status: true,
        },
        {
          col: 9,
          colSpan: 8,
          rowSpan: 2,
          status: true,
        },

        {
          col: 1,
          colSpan: 8,
          rowSpan: 6,
          status: true,
        },
        {
          col: 9,
          colSpan: 8,
          rowSpan: 6,
          status: true,
        },
      ]
  });
  const { breakpoint } = useBreakpoint(BREAKPOINTS, 'desktop')

  const togglefix = () => {
    setfixblock(!fixblock);
  };
  const defaultvalue = new Date()
  const [positiondata, setPositionData] = useState(grid['desktop']);


  const [datevalue, setValue] = useState(defaultvalue);
  const [richData, setRichData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {

        const res = await axios.get(`/dashdata`);
        if (res.data.success) {
          setRichData(res.data.data)
        }
      } catch (error) {
        console.error('데이터 가져오기 중 오류 발생:', error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    setPositionData(grid[breakpoint])
  }, [breakpoint]);

  const tiles = [
    {
      defaultPosition: {
        col: 1,
        colSpan: 12,
        rowSpan: 2,
      },
      header: "작업허가 현황",
      body: <AccidentReport height={(2 * height / 6.5) - 50} />,
      resizable: fixblock,
      reorderable: fixblock,

    },
    {
      defaultPosition: {
        col: 13,
        colSpan: 4,
        rowSpan: 2,
      },
      header: "Ph 별 안전지표",
      body: <LTIR height={height} />,
      resizable: fixblock,
      reorderable: fixblock,

    },
    {
      defaultPosition: {
        col: 1,
        colSpan: 8,
        rowSpan: 3,
      },
      header: "출력 인원 대비 발생 현황",
      body: <AccessControlChart height={(2 * height / 6.5) - 50} />,
      resizable: fixblock,
      reorderable: fixblock,
      className: 'milestone'
    },
    {
      defaultPosition: {
        col: 9,
        colSpan: 8,
        rowSpan: 3,
      },
      header: "S-CAR 유형별 사고발생 추이",
      body: <AccidentTypeStackChart height={(2 * height / 6.5) - 50} />,
      resizable: fixblock,
      reorderable: fixblock,
      className: 'milestone'
    },
    {
      defaultPosition: {
        col: 1,
        colSpan: 8,
        rowSpan: 2,
      },
      header: "안전 부적합 현황",
      body: <SafetyFailure height={(2 * height / 6.5) - 50} />,
      resizable: fixblock,
      reorderable: fixblock,
      className: 'milestone'
    },
    {
      defaultPosition: {
        col: 9,
        colSpan: 8,
        rowSpan: 2,
      },
      header: "재해 발생 현황",
      body: <DisasterStatus height={(2 * height / 6.5) - 50} />,
      resizable: fixblock,
      reorderable: fixblock,
      className: 'milestone'
    },
    {
      defaultPosition: {
        col: 1,
        colSpan: 8,
        rowSpan: 2,
      },
      header: "안전 KPI 종합실적",
      body: <PyramidChart height={(2 * height / 6.5) - 50} />,
      resizable: fixblock,
      reorderable: fixblock,
      className: 'milestone'
    },
    {
      defaultPosition: {
        col: 11,
        colSpan: 8,
        rowSpan: 2,
      },
      header: "LTIF/TRCF 월간 지표",
      body: <LTIChart height={(2 * height / 6.5) - 50} />,
      resizable: fixblock,
      reorderable: fixblock,
      className: 'milestone'
    },
    {
      defaultPosition: {
        col: 1,
        colSpan: 8,
        rowSpan: 2,
      },
      header: "사고발생 현황",
      body: <div>
        {richData && richData.IncidientRichTxt ? (
          <div
            style={{
              overflowY: "auto",
              height: (2 * height / 6.5) - 80,
              borderBottom: 'none'
            }}
            dangerouslySetInnerHTML={{ __html: richData.IncidientRichTxt }}
          ></div>
        ) : (
          <div>데이터가 없습니다</div>
        )}
      </div>,
      resizable: fixblock,
      reorderable: fixblock,
      className: 'milestone'
    },
    {
      defaultPosition: {
        col: 11,
        colSpan: 8,
        rowSpan: 2,
      },
      header: "안전 공지사항",
      body:
        <div>
          {richData && richData.SafetyAnnounceRich ? (
            <div
              style={{
                overflowY: "auto",
                height: (2 * height / 6.5) - 80,
                borderBottom: 'none'
              }}
              dangerouslySetInnerHTML={{ __html: richData.SafetyAnnounceRich }}
            ></div>
          ) : (
            <div>데이터가 없습니다</div>
          )}
        </div>,

      resizable: fixblock,
      reorderable: fixblock,
      className: 'milestone'
    },

    {
      defaultPosition: {
        col: 1,
        colSpan: 8,
        rowSpan: 6,
      },
      header: "기타 안전 KPI 지표",
      body:
        <div>
          {richData && richData.imageKPIRich ? (
            <div
              style={{
                overflowY: "auto",
                height: (2 * height / 6.5) - 80,
                borderBottom: 'none'
              }}
              dangerouslySetInnerHTML={{ __html: richData.imageKPIRich }}
            ></div>
          ) : (
            <div>데이터가 없습니다</div>
          )}
        </div>,
      resizable: fixblock,
      reorderable: fixblock,
      className: 'milestone'
    },
    {
      defaultPosition: {
        col: 11,
        colSpan: 8,
        rowSpan: 6,
      },
      header: "프로젝트 주요일정",
      body:


        <>

          <div>
            {richData && richData.safetycalendarRich ? (
              <div
                style={{
                  overflowY: "auto",
                  height: (2 * height / 6.5) - 80,
                  borderBottom: 'none'
                }}
                dangerouslySetInnerHTML={{ __html: richData.safetycalendarRich }}
              ></div>
            ) : (
              <div>데이터가 없습니다</div>
            )}
          </div>,
          <br />

          <div>
            {richData && richData.calendarIMGRich ? (
              <div
                style={{
                  overflowY: "auto",
                  height: (2 * height / 6.5) - 80,
                  borderBottom: 'none'
                }}
                dangerouslySetInnerHTML={{ __html: richData.calendarIMGRich }}
              ></div>
            ) : (
              <div>데이터가 없습니다</div>
            )}
          </div>,
        </>,
      resizable: fixblock,
      reorderable: fixblock,
      className: 'milestone'
    },
  ];
  const handleReposition = (e) => {
    setPositionData(e.value);
  };

  return (
    <DashboardLayoutTotalT>

      <TileLayout
        className='totalpage'
        columns={16}
        rowHeight={height / 6.5}
        positions={positiondata}
        gap={{
          rows: 10,
          columns: 10
        }}
        items={tiles}
        autoFlow="row dense"
        onReposition={handleReposition}
      // style={{marginTop:20}}
      />
    </DashboardLayoutTotalT>
  );

};

export default SafetyPage
