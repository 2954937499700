import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { Navigation } from "react-minimal-side-navigation";
import "react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css";
import useDocumentTitle from '../common/useDocumentTitle';
import axios from '../common/axios';
import "./nav.css";
import { useLogout } from "../common/logout";
import { Button } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';

const NavSidebar = () => {

  let navigate = useNavigate();
  const location = useLocation();
  const { project_code } = useParams();
  const logout = useLogout();
  const [projectName, setprojectName] = useState("");
  const [userStatus, setUserStatus] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      const res = await axios.get(`/getproject?path=${project_code}`)
      setprojectName(res.data.data.projectname)
    }
    fetchData();

  }, [project_code]);



  useDocumentTitle("삼성중공업- " + projectName + " Dashboard");

  function Copyright(props) {
    return (
      <span style={{ color: "snow", fontSize: '0.6rem' }} {...props}>
        {'Copyright © '}
        <a style={{ color: "snow", textDecoration: "none" }} href="http://www.shi.samsung.co.kr/">
          삼성중공업
        </a>{' '}
        {new Date().getFullYear()}
        {'.'}
      </span>
    );
  }

  useEffect(() => {
    try {
      const fetchData = async () => {
        const res = await axios.get(`/dashcurrent_user`);
        const text = res.data.name + "님이 " + res.data.time + "에 접속하셨습니다."
        setUserStatus(text)
      };
      fetchData();
    } catch (error) {
      console.error('데이터 가져오기 중 오류 발생:', error);
    }

  }, []);

  return (
    <>



      <div style={{ position: 'sticky', width: '16rem', height: "100hv" }}>
        <div className="flex flex-col items-center justify-center text-center py-6" style={{ marginTop: 60, marginBottom: 40 }}>
          <img src='/images/shilogo.png' alt='삼중로고' width={100} />
          <div style={{ marginTop: 30, fontSize: '1rem', color: 'snow', fontWeight: 600 }}>
            {projectName}
          </div>
        </div>

        <Navigation
          activeItemId={location.pathname}
          onSelect={({ itemId }) => {
            if (itemId === '/overview' || itemId === '/safety' || itemId === '/quality' || itemId === undefined) {
              navigate(itemId);
            } else if (itemId === '/productivity') {
              window.open('https://new-productivity.dtsolution.io', '_blank');
            } else {
              window.open(itemId, '_blank');
            }
          }}
          items={[
            {
              title: '프로젝트 개요',
              itemId: `/${project_code}/overview`,
            },
            // {
            //   title: '원가',
            //   itemId: `/${project_code}/cost`,
            // },
            {
              title: '공정률',
              itemId: `/${project_code}/progress`,
              subNav: [
                { title: "공정률 상세", itemId: `/${project_code}/progressd` },
                { title: "업체별 공정률", itemId: `/${project_code}/progressv` }
              ]
            },
            {
              title: '인원/장비 현황',
              itemId: `/${project_code}/mp`,
            },
            {
              title: '자재',
              itemId: `/${project_code}/material`,
            },
            {
              title: "생산성",
              itemId: '/productivity'
            },
            // {
            //   title: 'DP',
            //   itemId: `/${project_code}/dp`,
            // },
          ]}
        />
        <div className="absolute bottom-0" style={{
          marginBottom: "5rem",
          display: "flex", // Flex 컨테이너 사용
          justifyContent: "center", // 가로 방향에서 중앙 정렬
          width: "16rem",
          fontSize: '0.8rem', color: 'snow', fontWeight: 400,
          textAlign: "center",
          paddingLeft: "2rem",
          paddingRight: '2rem'

        }}>
          {userStatus}

        </div>
        <div className="absolute bottom-0" style={{
          marginBottom: "2rem",
          display: "flex", // Flex 컨테이너 사용
          justifyContent: "center", // 가로 방향에서 중앙 정렬
          width: "16rem"

        }}>
          <Button
            onClick={logout}
            style={{
              margin: '10px auto',
              padding: '5px 15px',
              cursor: 'pointer',
              border: "1.5px solid #FFFFFF",
              borderRadius: 4,
              backgroundColor: "transparent",
              width: 160,
              height: 32,
              color: "#FFFFFF",
              fontSize: 14,
              lineHeight: 'normal'

            }}
            startIcon={<LogoutIcon sx={{ fontSize: 20 }} />}
          >
            로그아웃
          </Button>
        </div>
        <div className="absolute bottom-0" style={{
          marginBottom: "1rem",
          display: "flex", // Flex 컨테이너 사용
          justifyContent: "center", // 가로 방향에서 중앙 정렬
          width: '16rem'
        }}>
          <Copyright />
        </div>

      </div>
    </>
  );
}

export default NavSidebar;
