import React from "react";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";

const NCRRateQuarterTable = ({ height, data = sample_data }) => {
  return (
    <GridLayout
      rows={[
        {
          height: height * 0.25,
        },
        {
          height: height * 0.25,
        },
        {
          height: height * 0.25,
        },
        {
          height: height * 0.25,
        },
      ]}
      cols={[
        {
          width: 120,
        },
        {
          width: "calc((100% - 120px) * 0.25)",
        },
        {
          width: "calc((100% - 120px) * 0.25)",
        },
        {
          width: "calc((100% - 120px) * 0.25)",
        },
        {
          width: "calc((100% - 120px) * 0.25)",
        },
      ]}
      gap={{
        rows: 0,
        cols: 0,
      }}
      className="ncr-rate-quarter-table-body"
    >
      <GridLayoutItem
        row={1}
        col={1}
        colSpan={5}
        className="ncr-rate-quarter-table-item quality-table-border-bottom"
      />
      <GridLayoutItem
        row={2}
        col={1}
        colSpan={5}
        className="ncr-rate-quarter-table-item quality-table-border-bottom"
      />
      <GridLayoutItem
        row={3}
        col={1}
        colSpan={5}
        className="ncr-rate-quarter-table-item quality-table-border-bottom"
      />

      <GridLayoutItem
        row={1}
        col={1}
        className="ncr-rate-quarter-table-item bold-text item-top"
      >
        구분
      </GridLayoutItem>
      <GridLayoutItem
        row={2}
        col={1}
        className="ncr-rate-quarter-table-item bold-text"
      >
        삼성중공업
      </GridLayoutItem>
      <GridLayoutItem
        row={3}
        col={1}
        className="ncr-rate-quarter-table-item bold-text"
      >
        C사
      </GridLayoutItem>
      <GridLayoutItem
        row={4}
        col={1}
        className="ncr-rate-quarter-table-item bold-text"
      >
        E사
      </GridLayoutItem>

      {data.map((c, i) => {
        const { cate, shi, cnt, ena } = c;
        return (
          <React.Fragment key={i}>
            <GridLayoutItem
              row={1}
              col={i + 2}
              className="ncr-rate-quarter-table-item bold-text item-top"
            >
              {cate}
            </GridLayoutItem>
            <GridLayoutItem
              row={2}
              col={i + 2}
              className="ncr-rate-quarter-table-item"
            >
              {shi}
            </GridLayoutItem>
            <GridLayoutItem
              row={3}
              col={i + 2}
              className="ncr-rate-quarter-table-item"
            >
              {cnt}
            </GridLayoutItem>
            <GridLayoutItem
              row={4}
              col={i + 2}
              className="ncr-rate-quarter-table-item"
            >
              {ena}
            </GridLayoutItem>
          </React.Fragment>
        );
      })}
    </GridLayout>
  );
};

export default NCRRateQuarterTable;

const sample_data = [
  { cate: "24년 2분기", shi: 100, cnt: 100, ena: 100 },
  { cate: "24년 02월", shi: 10, cnt: 20, ena: 30 },
  { cate: "24년 03월", shi: 10, cnt: 20, ena: 30 },
  { cate: "24년 04월", shi: 10, cnt: 20, ena: 30 },
];
