import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';
import {
  GridLayout,
  GridLayoutItem,
} from "@progress/kendo-react-layout";
import MuiToggleButton from '@mui/material/ToggleButton';
import MuiToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import dayjs from 'dayjs';
import PieChart from '../manpower/manPowerPieChart';

const ToggleButton2 = styled(MuiToggleButton)(() => ({
  '&.Mui-selected, &.Mui-selected:hover': {
    color: 'white',
    backgroundColor: '#FF6F61',
  },
  '&.MuiToggleButton-root': {
    fontSize: '0.6rem',
    fontFamily: 'Pretendard-R',
    padding: 0,
    width: 60,
    borderRadius: '5px 5px 5px 5px!important',
    border: '1px solid rgba(0, 0, 0, 0.12)!important'

  }
}));

const ToggleButtonGroup2 = styled(MuiToggleButtonGroup)(() => ({
  '&.MuiToggleButtonGroup-root': {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1)
    }
  }
}));


const theme = createTheme({
  palette: {
    text: {
      primary: '#00ff00',
    },
  },
});

const Man = ({ height, drdata }) => {

  const location = useLocation();
  const pathname = location.pathname.split('/').pop();

  const [selectedValue2, setSelectedValue2] = React.useState("업체별");

  const [mandata, setManData] = useState([])
  const [dateValue1, setDateValue1] = useState(new Date(dayjs().add(-1, 'days')));



  useEffect(() => {
    if (drdata.length > 0) {
      const manline = drdata
        .filter(v => v._bp_lineitems !== undefined)
        .reduce((c, v) => c.concat(v._bp_lineitems.map(o => ({ ...o, ReportingDO: v.ReportingDO, PALContractorBPK: v.PALContractorBPK }))), [])
        .filter(v => v.uuu_tab_id === '투입인원');

      const latestDate1 = _.sortBy(manline, dr => new Date(dr.ReportingDO))[manline.length - 1].ReportingDO;

      setDateValue1(new Date(latestDate1));
    }
  }, [drdata]);

  const compareDate1 = new Date(dateValue1);
  compareDate1.setHours(0, 0, 0, 0);


  useEffect(() => {
    const linedata = drdata.filter(v=>v._bp_lineitems !== undefined).reduce((c, v) => c.concat(v._bp_lineitems.map(o => Object.assign(o, { ReportingDO: v.ReportingDO,PALContractorBPK:v.PALContractorBPK }))), [])
    .filter(v=>v.uuu_tab_id ==='투입인원').map((v)=>({...v, uniq1:v.DRJobTitleBPK+"//"+v.ReportingDO, uniq2:v.PALContractorBPK+"//"+v.ReportingDO}))

    const chartdata = 
    selectedValue2 === '직종' ?
    _(linedata)
      .groupBy("uniq1")
      .map((objs, key) => ({
        date: new Date(key.split('//')[1].slice(0, 10)),
        xtype: key.split("//")[0].split(' | ')[1],
        count: _.sumBy(objs, "DRResAssignDayDA"),
      }))
      .value() :

      _(linedata)
      .groupBy("uniq2")
      .map((objs, key) => ({
        date: new Date(key.split('//')[1].slice(0, 10)),
        xtype: key.split("//")[0],
        count: _.sumBy(objs, "DRResAssignDayDA"),
      }))
      .value()

    const man_result = _.sortBy(
      chartdata.map(function (obj) {
        var index = obj.xtype;
        obj[index] = obj.count;
        return obj;
      }),
      "date"
    );
   
    setManData(man_result)

  }, [drdata, selectedValue2]);


  const filteredData1 = _.filter(mandata, (data) => {
    const dataDate = new Date(data.date);
    dataDate.setHours(0, 0, 0, 0);
    return dataDate.getTime() === compareDate1.getTime();
  });
  const manPieData = filteredData1
  .map(item => {
    const date = item.date;
    const newData = Object.keys(item)
      .filter(key => key !== "date" && key !== "none" && key !== "_date_date" && key !== "count" && item[key] > 0)
      .map(key => ({ date, id: key, value: item[key] }));
    return newData;
  })
  .flat();

  const totalSum1 = _.sumBy(manPieData, "value");


  const handleChange2 = (event, selectedValue2) => {
    if (selectedValue2 !== null) {
      setSelectedValue2(selectedValue2);
    }
  };



  return (
    <>
      <GridLayout style={{ height: height }}
        rows={[
          {
            height: '100%',
          },
        ]}
        cols={[
          {
            width: '100%',
          }
        ]}
        gap={{
          rows: 10,
          cols: 0,
        }}>

        <GridLayoutItem row={1} col={1} style={{ position: 'relative' }}>
          {pathname === "overview" ? (
            <>
              <div style={{ position: 'absolute', transform: 'translateY(-50%)', zIndex: 100 }} className='manRB' >
                <ThemeProvider theme={theme}>
                  <ToggleButtonGroup2 size="small" onChange={handleChange2} value={selectedValue2} exclusive aria-label="baseunit">
                    <ToggleButton2 value="직종" aria-label='직종' >
                      직종
                    </ToggleButton2>
                    <ToggleButton2 value="업체별" aria-label="업체별">
                      업체별
                    </ToggleButton2>
                  </ToggleButtonGroup2>
                </ThemeProvider>
              </div>
            </>) : (
            <>
              <div style={{ position: 'absolute', transform: 'translateY(-50%)', zIndex: 100 }} className='manRB'>
                <ThemeProvider theme={theme}>
                  <ToggleButtonGroup2 size="small" onChange={handleChange2} value={selectedValue2} exclusive aria-label="baseunit">
                    <ToggleButton2 value="직종" aria-label='직종' >
                      직종
                    </ToggleButton2>
                    <ToggleButton2 value="업체별" aria-label="업체별">
                      업체별
                    </ToggleButton2>
                  </ToggleButtonGroup2>
                </ThemeProvider>
              </div>
            </>
          )}
          <PieChart data={manPieData} text="명" totalSum={totalSum1} dateValue={dateValue1} setDateValue={setDateValue1} margin={0} fontSize={14}/>
        </GridLayoutItem>

      </GridLayout>

    </>
  );

}

export default Man
