import React, { useState, useEffect, useMemo } from 'react';
import { DashboardLayoutTotalT } from '../../layout/LayoutTotalT';
import { useBreakpoint } from 'use-breakpoint';
import useWindowDimensions from '../common/useWindowDimensions';
import { TileLayout } from "@progress/kendo-react-layout";
import PJTStatus from './progress'
import ManPower from './manpower';
import SafetyTotal from './safety';
import QualityTotal from './quality';
import ProjectKPIAmChart from './kpi';
import ProjectStockStatus from './material';
import CostTotal from './cost';
import TargetStatus from "./targetStatus/TargetStatus";
import axios from '../../common/axios';
import QualityRate1 from '../quality/qualityRate1/qualityRate1';

const BREAKPOINTS = { mobile: 0, tablet: 768, desktop: 1280, };

const OverviewT = () => {

  const [userType, setUserType] = useState("Yes")

  useEffect(() => {
    const fetchData = async () => {
        try {  

          const res = await axios.get(`/checkuserType`);
          if (res.data.success) {
              setUserType(res.data.data)
          }
      } catch (error) {
          console.error('데이터 가져오기 중 오류 발생:', error);
      }
  };
  fetchData();
}, []);

  const [fixblock, setfixblock] = useState(false);
  const { height, width } = useWindowDimensions();
  const [tiles, setTiles] = useState([])
  const [grid, setGrid] = useState({
    mobile:
      [

        {
          col: 1,
          colSpan: 16,
          rowSpan: 6,
          status: true,
        },
        {
          col: 1,
          colSpan: 16,
          status: true,
          rowSpan: 2,
        },
        {
          col: 1,
          colSpan: 16,
          status: true,
          rowSpan: 2,
        },
        {
          col: 1,
          colSpan: 16,
          status: true,
          rowSpan: 2,
        },

        {
          col: 1,
          colSpan: 16,
          rowSpan: 2,
          status: true,
        },


        {
          col: 1,
          colSpan: 16,
          rowSpan: 2,
          status: true,
        },


        {
          col: 1,
          colSpan: 16,
          rowSpan: 2,
          status: true,
        },
        {
          col: 1,
          colSpan: 16,
          rowSpan: 2,
          status: true,
        }



      ]
    ,
    tablet:
      [

        {
          col: 1,
          colSpan: 6,
          rowSpan: 2,
          status: true,
        },
        {
          col: 7,
          colSpan: 6,
          rowSpan: 2,
          status: true,
        },
        {
          col: 13,
          colSpan: 6,
          rowSpan: 2,
          status: true,
        },
        {
          col: 1,
          colSpan: 9,
          rowSpan: 2,
          status: true,
        },
        {
          col: 10,
          colSpan: 9,
          rowSpan: 2,
          status: true,
        },
        {
          col: 1,
          colSpan: 6,
          rowSpan: 2,
          status: true,
        },
        {
          col: 7,
          colSpan: 6,
          rowSpan: 2,
          status: true,
        },
        {
          col: 13,
          colSpan: 6,
          rowSpan: 2,
          status: true,
        },
      ]
    ,
    desktop:
      [

        {
          col: 1,
          colSpan: 6,
          rowSpan: 2,
          status: true,
        },
        {
          col: 7,
          colSpan: 6,
          rowSpan: 2,
          status: true,
        },
        {
          col: 13,
          colSpan: 6,
          rowSpan: 2,
          status: true,
        },
        {
          col: 1,
          colSpan: 9,
          rowSpan: 3,
          status: true,
        },
        {
          col: 10,
          colSpan: 9,
          rowSpan: 3,
          status: true,
        },
        {
          col: 1,
          colSpan: 6,
          rowSpan: 2,
          status: true,
        },
        {
          col: 7,
          colSpan: 6,
          rowSpan: 2,
          status: true,
        },
        {
          col: 13,
          colSpan: 6,
          rowSpan: 2,
          status: true,
        },
      ]
  });

  useEffect(() => {
    updateLayout(userType);
}, [userType]);

const updateLayout = (userType) => {
  let newGrid, newTiles;
  if (userType === "Yes") {
      newGrid =         {
        mobile:
          [
    
            {
              col: 1,
              colSpan: 16,
              rowSpan: 6,
              status: true,
            },
            {
              col: 1,
              colSpan: 16,
              status: true,
              rowSpan: 2,
            },
            {
              col: 1,
              colSpan: 16,
              status: true,
              rowSpan: 2,
            },
            {
              col: 1,
              colSpan: 16,
              status: true,
              rowSpan: 2,
            },
    
            {
              col: 1,
              colSpan: 16,
              rowSpan: 2,
              status: true,
            },
    
    
            {
              col: 1,
              colSpan: 16,
              rowSpan: 2,
              status: true,
            },
    
    
            {
              col: 1,
              colSpan: 16,
              rowSpan: 2,
              status: true,
            },
            {
              col: 1,
              colSpan: 16,
              rowSpan: 2,
              status: true,
            }
    
    
    
          ]
        ,
        tablet:
          [
    
            {
              col: 1,
              colSpan: 6,
              rowSpan: 2,
              status: true,
            },
            {
              col: 7,
              colSpan: 6,
              rowSpan: 2,
              status: true,
            },
            {
              col: 13,
              colSpan: 6,
              rowSpan: 2,
              status: true,
            },
            {
              col: 1,
              colSpan: 9,
              rowSpan: 2,
              status: true,
            },
            {
              col: 10,
              colSpan: 9,
              rowSpan: 2,
              status: true,
            },
            {
              col: 1,
              colSpan: 6,
              rowSpan: 2,
              status: true,
            },
            {
              col: 7,
              colSpan: 6,
              rowSpan: 2,
              status: true,
            },
            {
              col: 13,
              colSpan: 6,
              rowSpan: 2,
              status: true,
            },
          ]
        ,
        desktop:
          [
    
            {
              col: 1,
              colSpan: 6,
              rowSpan: 2,
              status: true,
            },
            {
              col: 7,
              colSpan: 6,
              rowSpan: 2,
              status: true,
            },
            {
              col: 13,
              colSpan: 6,
              rowSpan: 2,
              status: true,
            },
            {
              col: 1,
              colSpan: 9,
              rowSpan: 3,
              status: true,
            },
            {
              col: 10,
              colSpan: 9,
              rowSpan: 3,
              status: true,
            },
            {
              col: 1,
              colSpan: 6,
              rowSpan: 2,
              status: true,
            },
            {
              col: 7,
              colSpan: 6,
              rowSpan: 2,
              status: true,
            },
            {
              col: 13,
              colSpan: 6,
              rowSpan: 2,
              status: true,
            },
          ]
      };
      newTiles = [
        {

          header: "공정률 현황",
          body: <PJTStatus height={height} />,
          resizable: fixblock,
          reorderable: fixblock,
    
        },
        {

          header: "목표 달성 현황",
          body: <TargetStatus height={height} />,
          resizable: fixblock,
          reorderable: fixblock,
    
        },
        {

          header: "인원 투입 현황",
          body: <ManPower height={height} />,
          resizable: fixblock,
          reorderable: fixblock,
          className: 'milestone'
        },
        {

          header: "원가 현황",
          body: <CostTotal height={height} />,
          resizable: fixblock,
          reorderable: fixblock,
          className: 'milestone'
        },
        {

          header: "프로젝트별 KPI",
          body: <ProjectKPIAmChart height={height} />,
          resizable: fixblock,
          reorderable: fixblock,
          className: 'milestone'
        },
        {

          header: "자재 현황",
          body: <ProjectStockStatus height={height} />,
          resizable: fixblock,
          reorderable: fixblock,
          className: 'milestone'
        },
        {

          header: "안전 현황",
          body: <SafetyTotal height={height} />,
          resizable: fixblock,
          reorderable: fixblock,
          className: 'milestone'
        },
        {

          header: "품질 현황",
          body: <QualityRate1 height={((height - 42) / 14) * 7 - 32 - 180}  />,
          // body: <QualityTotal height={height} />,
          resizable: fixblock,
          reorderable: fixblock,
          className: 'milestone'
        },
    
      ];
  } else {
      newGrid =         {
        mobile:
        [
    
          {
            col: 1,
            colSpan: 18,
            rowSpan: 2,
            status: true,
          },
          {
            col: 1,
            colSpan: 18,
            rowSpan: 2,
            status: true,
          },

          {
            col: 1,
            colSpan: 18,
            rowSpan: 2,
            status: true,
          },
          {
            col: 1,
            colSpan: 18,
            rowSpan: 2,
            status: true,
          },
          {
            col: 1,
            colSpan: 18,
            rowSpan: 2,
            status: true,
          },
        ]
        ,
        tablet:
        [
    
          {
            col: 1,
            colSpan: 6,
            rowSpan: 2,
            status: true,
          },
          {
            col: 7,
            colSpan: 12,
            rowSpan: 2,
            status: true,
          },

          {
            col: 1,
            colSpan: 6,
            rowSpan: 2,
            status: true,
          },
          {
            col: 7,
            colSpan: 6,
            rowSpan: 2,
            status: true,
          },
          {
            col: 13,
            colSpan: 6,
            rowSpan: 2,
            status: true,
          },
        ]
        ,
        desktop:
          [
    
            {
              col: 1,
              colSpan: 6,
              rowSpan: 2,
              status: true,
            },
            {
              col: 7,
              colSpan: 12,
              rowSpan: 2,
              status: true,
            },

            {
              col: 1,
              colSpan: 6,
              rowSpan: 2,
              status: true,
            },
            {
              col: 7,
              colSpan: 6,
              rowSpan: 2,
              status: true,
            },
            {
              col: 13,
              colSpan: 6,
              rowSpan: 2,
              status: true,
            },
          ]
      };
      newTiles = [
        {
       
          header: "공정률 현황",
          body: <PJTStatus height={height} />,
          resizable: fixblock,
          reorderable: fixblock,
    
        },

        {
         
          header: "인원 투입 현황",
          body: <ManPower height={height} />,
          resizable: fixblock,
          reorderable: fixblock,
          className: 'milestone'
        },

        {
       
          header: "자재 현황",
          body: <ProjectStockStatus height={height} />,
          resizable: fixblock,
          reorderable: fixblock,
          className: 'milestone'
        },
        {
      
          header: "안전 현황",
          body: <SafetyTotal height={height} />,
          resizable: fixblock,
          reorderable: fixblock,
          className: 'milestone'
        },
        {
       
          header: "품질 현황",
          body: <QualityRate1 height={((height - 42) / 14) * 7 - 32 - 180}  />,
          resizable: fixblock,
          reorderable: fixblock,
          className: 'milestone'
        },
    
      ];
  }
  setGrid(newGrid);
  setTiles(newTiles);
};


  const { breakpoint } = useBreakpoint(BREAKPOINTS, 'desktop')

  const togglefix = () => {
    setfixblock(!fixblock);
  };

  const [positiondata, setPositionData] = useState(grid['desktop']);

  useEffect(() => {
    setPositionData(grid[breakpoint])
  }, [breakpoint, grid]);

  const handleReposition = (e) => {
    setPositionData(e.value);
  };
  return (
    <DashboardLayoutTotalT>

      <TileLayout
        className='totalpage'
        columns={18}
        rowHeight={height / 6.5}
        positions={positiondata}
        gap={{
          rows: 10,
          columns: 10
        }}
        items={tiles}
        autoFlow="row dense"
        onReposition={handleReposition}
      // style={{marginTop:20}}
      />

    </DashboardLayoutTotalT>
  );

};

export default OverviewT;

