import React, {  useState,useEffect } from 'react';
import axios from '../common/axios';
import { Link as RouterLink,useNavigate ,useLocation} from 'react-router-dom';


export default function NewPW() {
	const [password, setPassword] = useState("");
	const [confirmpassword, setConfirmPassword] = useState("");
	const [strength, setStrength] = useState(0);
    const [message, setMessage] = useState('');
    const [isValid, setIsValid] = useState(true);
	// const history = useHistory();
	const navigate = useNavigate(); // useNavigate 사용

	const search = useLocation().search;
    const token = new URLSearchParams(search).get('token');
    const username = new URLSearchParams(search).get('username');

	useEffect(() => {
		if (password === "") {
			setIsValid(true);
		} else if (strength !== 5) {
			setIsValid(true);
		} else if (confirmpassword === "") {
			setIsValid(true);
		} else if (password !== confirmpassword) {
			setIsValid(true);
		
		} else {
			setIsValid(false);
		}
	}, [confirmpassword, password, strength]);
	

	// useEffect(() => {
	// 	setIsValid(strength ===5?false:true) 
	//   },[strength])


	function isValidPassword(password) {
		const upperCaseRegex = /[A-Z]/;
		const lowerCaseRegex = /[a-z]/;
		const numberRegex = /\d/;
		const specialCharRegex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
	  
		const checks = [
			upperCaseRegex.test(password),
			lowerCaseRegex.test(password),
			numberRegex.test(password),
			specialCharRegex.test(password)
		];
	  
		const trueCounts = checks.filter(Boolean).length;
	  
		if (trueCounts >= 3 && password.length >= 8) return true;
		if (trueCounts >= 2 && password.length >= 10) return true;
	  
		return false;
	  }

	// 제약 조건을 검사하는 Helper 함수들
	const hasSequentialCharacters = str => /(012|123|234|345|456|567|678|789|890|abc|bcd|cde|def|efg|fgh|ghi|hij|ijk|jkl|klm|lmn|mno|nop|opq|pqr|qrs|rst|stu|tuv|uvw|vwx|wxy|xyz|qwe|wer|ert|rty|tyu|yui|uio|iop|asd|sdf|dfg|fgh|ghj|hjk|jkl|zxc|xcv|cvb|vbn|bnm)/i.test(str);

	const hasRepeatedCharacters = str => /(.)\1\1/.test(str);

	const isOnlyNumericOrAlpha = str => /^[0-9]+$|^[a-zA-Z]+$/.test(str);

	const validatePassword = (passwordValue) => {
		if (passwordValue.includes(username)) return "패스워드가 사용자 계정과 동일합니다.";  // 1번 제약 조건
		if (hasRepeatedCharacters(passwordValue)) return "동일한 문자 또는 숫자가 3자 이상 사용되었습니다.";  // 2번 제약 조건
		if (hasSequentialCharacters(passwordValue)) return "주기성 문자나 키보드상의 연속된 배열이 3개 이상 사용되었습니다.";  // 3번 제약 조건
		// 4번 제약 조건은 추가 정보(전화번호, 생년월일 등)가 필요하여 여기서 처리하지 않음
		if (isOnlyNumericOrAlpha(passwordValue)) return "문자 또는 숫자만으로 구성된 패스워드는 허용되지 않습니다.";  // 6번 제약 조건
		if (passwordValue === '') return "Null 패스워드는 허용되지 않습니다.";  // 7번 제약 조건

		if (!isValidPassword(passwordValue)) {
		return "패스워드는 다음의 문자 종류 중 2종류 이상을 조합하여 최소 10자리 이상 또는 3종류 이상을 조합하여 최소 8자리 이상의 길이로 작성하여야 합니다. * 영문 대문자(26개), 영문 소문자(26개), 숫자(10개), 특수문자(32개)";
		}
		return null;  // 유효한 패스워드
	}

	const handlePasswordChange = e => {
		const newPassword = e.target.value;
		setPassword(newPassword);
		const validationResult = validatePassword(newPassword);
	
		if (validationResult === null) {
			setMessage("사용가능한 비밀번호입니다.");
		} else {
		  setMessage(validationResult); 
		}
	};
	

	const handleConfirmPasswordChange = e => {
		const newPassword = e.target.value;
		setConfirmPassword(newPassword);
	
		if (password === newPassword) {
			setMessage('비밀번호가 서로 일치합니다.');
		} else {
			setMessage('비밀번호가 서로 일치하지 않습니다.');
		}
	};
	

	function getPasswordStrength(password) {
		let score = 0;
		const consecutivePattern = /(.)\1\1+/; // 3번 이상 연속되는 문자
		
		if (password.length >= 10) score++;
		if (/[^a-zA-Z0-9]/.test(password)) score++;
		if (/[0-9]/.test(password)) score++;
		if (/[a-z]/.test(password)) score++;
		if (/[A-Z]/.test(password)) score++;
		if (consecutivePattern.test(password)) score = 0; // 연속된 문자가 있다면 점수를 0으로 초기화
		
		return score;
	  }

	  useEffect(() => {
		setStrength(getPasswordStrength(password));
	  }, [password]);


	  const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			const response = await axios.post(`/dashnew-password`, { token, password });
			// 성공 응답 처리
			if (response.data.success) {
				setMessage(response.data.message); // 성공 메시지 설정
				navigate('/dashpassword-confirmation');
			} else {
				// 예상치 못한 응답 처리
				setMessage('비밀번호 재설정에 문제가 발생했습니다. 시스템 관리자에게 연락하시기 바랍니다.');
			}
		} catch (err) {
			console.log(err)
			if (err.response && err.response.data && err.response.data.error) {
				// 서버에서 반환하는 에러 메시지를 사용자에게 보여줍니다.
				setMessage(err.response.data.error);
			} else {
				setMessage('비밀번호 재설정에 문제가 발생했습니다. 시스템 관리자에게 연락하시기 바랍니다.');
			}
		}
	};
	

    return (
        <>
		<div style={{backgroundImage: 'url(/images/bg4.jpg)', backgroundSize: 'cover', 
         backgroundPosition: 'center'}}  data-bs-theme="light">
		<div className="d-flex flex-column flex-root" style={{  minHeight:"100vh"}}>
		<div className="d-flex flex-column flex-column-fluid flex-lg-row">
				<div className="d-flex flex-center w-lg-50 pt-15 pt-lg-0 px-10">
					<div className="d-flex flex-center flex-lg-start flex-column">
						<a href="http://www.samsungshi.com/kor/default.aspx" className="mb-7">
							<img alt="Logo" src='/images/g1313.png'  style={{width:250}} />
						</a>
						<h2 className="text-white fw-normal m-0">삼성중공업 하이테크 부문 대시보드</h2>
					</div>
				</div>
				<div className="d-flex flex-column-fluid flex-lg-row-auto justify-content-center justify-content-lg-end p-12 p-lg-20">
					<div className="bg-body d-flex flex-column align-items-stretch flex-center rounded-4 w-md-600px p-20">
					<div className="d-flex flex-center flex-column flex-column-fluid px-lg-10 pb-15 pb-lg-20">
							<form className="form w-100" novalidate="novalidate">
								<div className="text-center mb-10">
									<h1 className="text-dark fw-bolder mb-3">새 비밀번호 설정</h1>
									<div className="text-gray-500 fw-semibold fs-6">이미 비밀번호를 새로 설정하셨나요?
									<span className="link-primary fw-bold" onClick={() => navigate('/login')}>로그인</span></div>
								</div>
								<div className="fv-row mb-8">
									<div className="mb-1">
										<div className="position-relative mb-3">
											<input className="form-control bg-transparent" type="password" placeholder="비밀번호" name="password" autoComplete="off" onChange={handlePasswordChange}/>
											<span className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2" >
												<i className="ki-duotone ki-eye-slash fs-2"></i>
												<i className="ki-duotone ki-eye fs-2 d-none"></i>
											</span>
										</div>
										<div className="d-flex align-items-center mb-3">
											<div className={`flex-grow-1 bg-secondary ${strength > 0 ? 'bg-active-success active' : 'bg-active-success'} rounded h-5px me-2`}></div>
											<div className={`flex-grow-1 bg-secondary ${strength > 1 ? 'bg-active-success active' : 'bg-active-success'} rounded h-5px me-2`}></div>
											<div className={`flex-grow-1 bg-secondary ${strength > 2 ? 'bg-active-success active' : 'bg-active-success'} rounded h-5px me-2`}></div>
											<div className={`flex-grow-1 bg-secondary ${strength > 3 ? 'bg-active-success active' : 'bg-active-success'} rounded h-5px me-2`}></div>
											<div className={`flex-grow-1 bg-secondary ${strength > 4 ? 'bg-active-success active' : 'bg-active-success'} rounded h-5px`}></div>
										</div>
									</div> 
									<div className="text-muted">최소 10글자 이상의 비밀번호를 사용해주세요. 비밀번호에는 대문자, 소문자, 특수문자 등이 포함되어야하며, 3개 이상의 연속된 문자를 사용할 수 없습니다.</div>
								</div>
								<div className="fv-row mb-8">
									<input type="password" placeholder="비밀번호 재입력" name="confirm-password" autoComplete="off" className="form-control bg-transparent" onChange={handleConfirmPasswordChange}/>
								</div>
								<div className="fv-row mb-8">
									<label className="form-check form-check-inline">
										<span className="form-check-label fw-semibold text-gray-700 fs-6 ms-1">
											{message}
										</span>
									</label>
								</div>
								<div className="d-grid mb-10">
									<button type="button" className="btn btn-primary" disabled={isValid} onClick={handleSubmit}>
										<span className="indicator-label">제출</span>
										<span className="indicator-progress">잠시만 기다려주세요...
										<span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
									</button>
								</div>
							</form>
						</div>
						<div className="d-flex flex-stack px-lg-10">
							<div className="me-0">

							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		</div>
        </>
    );
}