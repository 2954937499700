import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import {
    Card,
    CardTitle,
    CardBody,
    GridLayout, GridLayoutItem
} from "@progress/kendo-react-layout";
import moment from 'moment';
import { Sparkline, ChartValueAxis, ChartValueAxisItem, ChartTooltip } from '@progress/kendo-react-charts';
import { Tooltip } from "@progress/kendo-react-tooltip";
import axios from "../../common/axios";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';



const PJTStatus = ({ height }) => {

    const BoxHeight = 2 * (height / 6.5) - 70

    const [data, setdata] = useState([]);

    useEffect(() => {
        const fetchData = async () => {

            const res = await axios.get(`/getprogressall`)
            if (res.data.success) {
                setdata(res.data.data)
            }
        };
        fetchData();

    }, []);


    const bulletValueAxis = {
        min: 0,
        max: 100,
        plotBands: [{
            from: 0,
            to: 100,
            color: '#787878',
            opacity: 0.15
        }]
    };

    const tooltipRender = (props) => {
        console.log(props)
        return (
            <span>
                계획: {props.points[0].value.target && props.points[0].value.target.toFixed(1)}%
                <br />
                실적: {props.points[0].value.current && props.points[0].value.current.toFixed(1)}%
                <br />
                차이: {(parseFloat(props.points[0].value.current) - parseFloat(props.points[0].value.target)).toFixed(1)}%
            </span>
        );
    };

    return (

        <div style={{ marginTop:-15 ,display: "flex", padding: 15, flexDirection: "column", justifyContent: "center", alignItems: "flex-start", gap: 20, flex: "1 0 0", alignSelf: "stretch" }}>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "flex-start", gap: 30, alignSelf: "stretch" }}>
                <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", gap: 6 }}>
                    <div style={{ display: "flex", alignItems: "center", gap: "6px" }}>
                        <FiberManualRecordIcon sx={{ fontSize: 16, color: "#F8443E" }} />
                        <span>Behind</span>
                    </div>
                    <div style={{ color: "#F8443E", fontSize: 24, fontWeight: 700 }}>
                        {_.filter(data, function (o) { return o.차이 <= -3 }).length}
                    </div>
                </div>
                <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", gap: 6 }}>
                    <div style={{ display: "flex", alignItems: "center", gap: "6px" }}>
                        <FiberManualRecordIcon sx={{ fontSize: 16, color: "#FFB83F" }} />
                        <span>In Progress</span>
                    </div>
                    <div style={{ color: "#FFB83F", fontSize: 24, fontWeight: 700 }}>
                        {_.filter(data, function (o) { return o.차이 < 3 && o.차이 > -3 }).length}
                    </div>
                </div>

                <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", gap: 6 }}>
                    <div style={{ display: "flex", alignItems: "center", gap: "6px" }}>
                        <FiberManualRecordIcon sx={{ fontSize: 16, color: "#176FE8" }} />
                        <span>Ahead</span>
                    </div>
                    <div style={{ color: "#176FE8", fontSize: 24, fontWeight: 700 }}>
                        {_.filter(data, function (o) { return o.차이 >= 3 }).length}
                    </div>
                </div>


            </div>

            <div style={{ display: "flex", justifyContent: 'center', textAlign: "center", height: BoxHeight - 95, width: "100%",overflowY: 'auto', }}>

                <ul style={{ padding: '0', listStyle: 'none', margin: '0 auto',  width: '100%', height: BoxHeight - 95 }}>
                    {_.sortBy(data, "project").map((v, index) => (
                        <Tooltip key={index} anchorElement="target" position="top">

                            <li >
                                <span title={v.project} style={{ width: '100px', display: 'inline-block', textAlign: 'right', fontSize: '0.7rem', padding: 6, whiteSpace: 'nowrap', verticalAlign: 'top' }}>{v.project}</span>
                                <Sparkline data={v.bulletdata} type="bullet"
                                    seriesColors={[v.color]}
                                    className='bspark'>
                                    <ChartValueAxis>
                                        <ChartValueAxisItem {...bulletValueAxis} />
                                    </ChartValueAxis>
                                    {/* <ChartTooltip render={tooltipRender} /> */}
                                </Sparkline>
                                <span style={{textAlign:'left', width: '100px', display: 'inline-block', color: v.color, fontSize: '0.7rem', padding: 6, verticalAlign: 'top', whiteSpace: 'nowrap' }}>{v.실적 && v.실적.toFixed(1)}% ({(v.차이).toFixed(1)}%)</span>
                            </li>
                        </Tooltip>

                    ))}
                </ul>
            </div>
        </div>


    );
}

export default PJTStatus
