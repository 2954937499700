import React, { useState, useMemo } from "react";
import { TileLayout } from "@progress/kendo-react-layout";
import { useRecoilValue } from "recoil";
import { QualityProjectList } from "../../atom/qualityState";
import useWindowDimensions from "../common/useWindowDimensions";
import axios from "../../common/axios";
import dayjs from "dayjs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { DashboardLayoutTotalT } from "../../layout/LayoutTotalT";
import DataRangeFilter from "./public/DataRangeFilter";
import InspectionStatus from "./Detail/InspectionStatus/InspectionStatus";
import SelfAssessment from "./Detail/SelfAssessment/SelfAssessment";
import IRPerformance from "./Detail/IRPerformance/IRPerformance";
import InspectionCadastralStatus from "./Detail/InspectionCadastralStatus/InspectionCadastralStatus";
import InspectionProgressStatus from "./Detail/InspectionProgressStatus/InspectionProgressStatus";
import PageChangeBtn from "./public/PageChangeBtn";
import "./qualitytable.css";

const customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);

const QualityDetail = () => {
  const { height } = useWindowDimensions();

  const fixblock = false;

  const projectList = useRecoilValue(QualityProjectList);

  const [dateValue, setDateValue] = useState([
    dayjs().subtract(1, "year"),
    dayjs(),
  ]);
  const [discValue, setDiscValue] = useState("전체");

  const [targetProject, setTargetProject] = useState({
    label: "전체",
    value: "all",
  });

  const [totalVendorList, setTotalVendorList] = useState([]);

  const [vendorList, setVendorList] = useState([]);

  const [vendorValue, setVendorValue] = useState(null);

  const [positiondata, setPositionData] = useState(grid["desktop"]);

  const tiles = useMemo(
    () => [
      {
        header: "시공검사 진행 현황",
        body: (
          <InspectionProgressStatus
            height={((height - 42) / 6) * 2 - 32 - 44}
            targetProject={targetProject}
            targetDisc={discValue}
            targetVendor={vendorValue}
          />
        ),
        resizable: fixblock,
        reorderable: fixblock,
      },
      {
        header: "품질 수준 현황",
        body: (
          <SelfAssessment
            height={((height - 42) / 6) * 2 - 32 - 44}
            targetProject={targetProject}
            targetVendor={vendorValue}
          />
        ),
        resizable: fixblock,
        reorderable: fixblock,
      },
      {
        header: "검측실적요약",
        body: (
          <InspectionStatus
            height={((height - 42) / 6) * 4 - 32 - 44}
            targetProject={targetProject}
            targetVendor={vendorValue}
            targetDisc={discValue}
            dateValue={dateValue}
          />
        ),
        resizable: fixblock,
        reorderable: fixblock,
      },
      {
        header: "IR 실적",
        body: (
          <IRPerformance
            height={((height - 42) / 6) * 2 - 32 - 44}
            targetProject={targetProject}
            targetVendor={vendorValue}
            dateValue={dateValue}
          />
        ),
        resizable: fixblock,
        reorderable: fixblock,
      },
      {
        header: "점검지적현황",
        body: (
          <InspectionCadastralStatus
            height={((height - 42) / 6) * 2 - 32 - 44}
            targetProject={targetProject}
            targetVendor={vendorValue}
            dateValue={dateValue}
          />
        ),
        resizable: fixblock,
        reorderable: fixblock,
      },
    ],
    [fixblock, height, targetProject, vendorValue, discValue, dateValue]
  );

  const handleReposition = (e) => {
    setPositionData(e.value);
  };

  const selectTargetProject = async (v) => {
    const { value } = v;

    setVendorValue(null);
    setVendorList([]);
    setTotalVendorList([]);

    const request_vendor_list = await axios
      .get(`/getQualityVendorList?project_code=${value}`)
      .then((data) => data.data.data)
      .catch([]);

    setTotalVendorList(request_vendor_list);
    setVendorList(request_vendor_list);

    setTargetProject(v);
  };

  const selectTargetDisc = (v) => {
    setVendorValue(null);
    setVendorList([]);

    if (v === "전체") {
      setVendorList(totalVendorList);
    } else {
      setVendorList(totalVendorList.filter((c) => c.disc === v));
    }

    setDiscValue(v);
  };

  return (
    <DashboardLayoutTotalT>
      <div className="quality-detail-top-container">
        <div className="quality-detail-filter-container">
          <div className="quality-detail-filter-box">
            <div className="quality-detail-filter-text">프로젝트:</div>
            <DropDownList
              className="quality-detail-filter-drop-down-list short2"
              data={[{ label: "전체", value: "all" }, ...projectList]}
              value={targetProject}
              textField="label"
              dataItemKey="value"
              onChange={(e) => selectTargetProject(e.value)}
            />
          </div>
          <div className="quality-detail-filter-box">
            <div className="quality-detail-filter-text">공종:</div>
            <DropDownList
              className="quality-detail-filter-drop-down-list short"
              data={discipline}
              value={discValue}
              onChange={(e) => selectTargetDisc(e.value)}
            />
          </div>
          <div className="quality-detail-filter-box">
            <div className="quality-detail-filter-text">협력업체:</div>
            <ComboBox
              className="quality-detail-filter-drop-down-list short2"
              data={vendorList}
              value={vendorValue}
              textField="label"
              dataItemKey="label"
              onChange={(e) => setVendorValue(e.value)}
              disabled={targetProject.value === "all"}
            />
          </div>
          <div className="quality-detail-filter-box">
            <div className="quality-detail-filter-text">기간:</div>
            <DataRangeFilter
              dateValue={dateValue}
              setDateValue={setDateValue}
            />
          </div>
        </div>
        <PageChangeBtn />
      </div>
      <TileLayout
        className="quality-tile-body"
        columns={16}
        rowHeight={(height - 42) / 6}
        positions={positiondata}
        gap={{
          rows: 10,
          columns: 10,
        }}
        items={tiles}
        autoFlow="row dense"
        onReposition={handleReposition}
        style={{ height: height - 42 }}
      />
    </DashboardLayoutTotalT>
  );
};

export default QualityDetail;

const discipline = ["전체", "설비", "건축", "전기", "제어"];

const grid = {
  mobile: [
    {
      col: 1,
      colSpan: 4,
      rowSpan: 3,
      status: true,
    },
    {
      col: 6,
      colSpan: 10,
      rowSpan: 3,
      status: true,
    },
    {
      col: 1,
      colSpan: 8,
      rowSpan: 3,
      status: true,
    },
    {
      col: 9,
      colSpan: 8,
      rowSpan: 3,
      status: true,
    },
  ],
  tablet: [
    {
      col: 1,
      colSpan: 4,
      rowSpan: 3,
      status: true,
    },
    {
      col: 7,
      colSpan: 3,
      rowSpan: 3,
      status: true,
    },
    {
      col: 1,
      colSpan: 8,
      rowSpan: 3,
      status: true,
    },
    {
      col: 9,
      colSpan: 8,
      rowSpan: 3,
      status: true,
    },
  ],
  desktop: [
    {
      col: 1,
      colSpan: 10,
      rowSpan: 2,
      status: true,
    },
    {
      col: 11,
      colSpan: 6,
      rowSpan: 2,
      status: true,
    },
    {
      col: 1,
      colSpan: 10,
      rowSpan: 4,
      status: true,
    },
    {
      col: 11,
      colSpan: 6,
      rowSpan: 2,
      status: true,
    },
    {
      col: 11,
      colSpan: 6,
      rowSpan: 2,
      status: true,
    },
  ],
};
