import React, { useEffect, useState } from 'react';
import { ResponsivePie } from '@nivo/pie';
import _ from 'lodash';

const PieChart = ({ chartdata, totalSum }) => {
  return (
    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
      <ResponsivePie
        colors={{ datum: 'data.color' }}
        className='pie'
        data={chartdata}
        margin={{ top: 20, right: 80, bottom: 20, left: 80 }}
        valueFormat=" >-"
        cornerRadius={2}
        innerRadius={0.7}
        activeOuterRadiusOffset={8}
        borderWidth={1}
        borderColor={{
          from: 'color',
          modifiers: [['darker', 0.2]]
        }}
        arcLinkLabel={d => (
          <>
            <tspan style={{ fontSize: 12 }} x="0" dy="-0.6em">{d.id}</tspan>
            <tspan style={{ fontSize: 12 }} x="0" dy="1.2em">
              {`(${((d.value / _.sumBy(chartdata, 'value')) * 100).toFixed(0)}%)`}
            </tspan>
          </>
        )}
        arcLinkLabelsSkipAngle={10}
        arcLinkLabelsTextOffset={5}
        arcLinkLabelsTextColor="#333333"
        arcLinkLabelsDiagonalLength={5}
        arcLinkLabelsStraightLength={10}
        arcLinkLabelsColor={{ from: 'color' }}
        enableArcLabels={true}
        arcLabel={e => e.value}
        arcLabelsRadiusOffset={0.5}
        arcLabelsSkipAngle={0}
        arcLabelsTextColor={{
          from: 'color',
          modifiers: [['darker', 2]]
        }}
        legends={[]}
      />
      {totalSum && (
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            fontSize: '14px',
            fontWeight: 'bold',
          }}
        >
          {totalSum}
        </div>
      )}
    </div>
  );
};

export default PieChart;