import { atom } from 'recoil';

export const progressState = atom({
  key: 'progressState', // unique ID
  default: null, // default value
});

export const actualPercentState = atom({
  key: 'actualPercentState', // unique ID
  default: null, // default value
});


export const planPercentState = atom({
  key: 'planPercentState', // unique ID
  default: null, // default value
});

export const value3State = atom({
  key: 'value3State',
  default: null,
});

export const value2State = atom({
  key: 'value2State',
  default: null,
});

export const value1State = atom({
  key: 'value1State',
  default: null,
});