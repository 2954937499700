import React, { useEffect, useMemo, useState } from "react";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import axios from "../../../../common/axios";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import MuiToggleButton from "@mui/material/ToggleButton";
import MuiToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import dayjs from "dayjs";
import "./iRPerformance.css";

const customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);

const IRPerformance = ({ height, targetProject, dateValue, targetVendor }) => {
  const tile_height = height;

  const [iRData, setIRData] = useState([]);
  const [siteShopOption, setSiteShopOption] = useState("전체");

  useEffect(() => {
    setIRData([]);

    const fetch_data = async () => {
      if (
        targetProject.value !== "all" &&
        targetVendor?.disc &&
        targetVendor?.disc !== "건축" &&
        targetVendor?.disc !== "전기"
      ) {
        const request_ir_data = await axios
          .post(`/getIRPerformaceData?project_code=${targetProject.value}`, {
            vendor_name: targetVendor.label,
          })
          .then((data) => data.data.data)
          .catch([]);

        setIRData(request_ir_data);

        return;
      }

      return;
    };

    fetch_data();
  }, [targetProject, targetVendor]);

  const data_boolean = useMemo(() => {
    return targetProject.value !== "all" &&
      targetVendor?.disc &&
      targetVendor.disc !== "건축" &&
      targetVendor.disc !== "전기"
      ? true
      : false;
  }, [targetProject, targetVendor]);

  const table_data = useMemo(() => {
    if (!data_boolean) {
      return { ...initial_data };
    }

    let copy_ir_data = [...iRData];

    if (siteShopOption !== "전체") {
      copy_ir_data = copy_ir_data.filter(
        (c) => c.WIRSiteShopPD === siteShopOption
      );
    }

    const [startDate, endDate] = dateValue;

    if (startDate && endDate) {
      copy_ir_data = copy_ir_data.filter((c) => {
        const { WIRCategoryRB, record_no } = c;

        const target_field = c?.[handle_field_name(WIRCategoryRB)];

        if (!target_field) {
          return false;
        }

        const target_field_dayjs = dayjs(target_field, "MM-DD-YYYY");

        return startDate <= target_field_dayjs && target_field_dayjs <= endDate;
      });
    }

    const result = {};

    filed_list.forEach((c) => {
      const { unifier_field, field } = c;

      const total_arr = copy_ir_data.filter(
        (c2) =>
          c2.WIRCategoryRB === unifier_field &&
          c2.WIRInspectionResult !== "불합격"
      );

      const reject_arr = copy_ir_data.filter(
        (c2) =>
          c2.WIRCategoryRB === unifier_field &&
          c2.WIRInspectionResult === "불합격"
      );

      result[field] = {
        total: total_arr.length,
        reject: reject_arr.length,
        percent: returnZeroIfInvalid(
          (reject_arr.length / total_arr.length) * 100
        ),
      };
    });

    return { ...result };
  }, [data_boolean, iRData, siteShopOption, dateValue]);

  const handleChange = (event) => {
    setSiteShopOption(event.target.value);
  };

  if (!data_boolean) {
    return (
      <div style={{ fontFamily: "Inter" }}>
        협력업체 선택 및 대상 협력업체(건축, 제어 제외)의 공종을 다시 한번 확인
        해주세요.
      </div>
    );
  }

  return (
    <GridLayout
      style={{ height: tile_height }}
      rows={[
        {
          height: tile_height / 6,
        },
        {
          height: tile_height / 6,
        },
        {
          height: tile_height / 6,
        },
        {
          height: tile_height / 6,
        },
        {
          height: tile_height / 6,
        },
        {
          height: tile_height / 6,
        },
      ]}
      cols={[
        {
          width: "25%",
        },
        {
          width: "25%",
        },
        {
          width: "25%",
        },
        {
          width: "25%",
        },
      ]}
      gap={{
        rows: 0,
        cols: 0,
      }}
      className="quality-ir-performance-item-box"
    >
      <div className="quality-ir-performance-filter-box">
        <ThemeProvider theme={theme}>
          <ToggleButtonGroup2
            size="small"
            onChange={handleChange}
            value={siteShopOption}
            exclusive
            aria-label="baseunit"
          >
            <ToggleButton2 value="전체" aria-label="전체">
              전체
            </ToggleButton2>
            <ToggleButton2 value="Shop" aria-label="Shop">
              Shop
            </ToggleButton2>
            <ToggleButton2 value="현장" aria-label="현장">
              현장
            </ToggleButton2>
          </ToggleButtonGroup2>
        </ThemeProvider>
      </div>
      {/* Header */}
      <GridLayoutItem
        row={1}
        col={1}
        className="quality-ir-performance-item header-cell"
      >
        <div className="quality-bold-text">구분</div>
      </GridLayoutItem>
      <GridLayoutItem
        row={1}
        col={2}
        className="quality-ir-performance-item header-cell"
      >
        <div className="quality-bold-text">전체</div>
      </GridLayoutItem>
      <GridLayoutItem
        row={1}
        col={3}
        className="quality-ir-performance-item header-cell"
      >
        <div className="quality-bold-text">불합격</div>
      </GridLayoutItem>
      <GridLayoutItem
        row={1}
        col={4}
        className="quality-ir-performance-item header-cell"
      >
        <div className="quality-bold-text">불량률</div>
      </GridLayoutItem>

      {filed_list.map((c) => {
        const { field, title, row } = c;

        return create_performance_items(title, row, table_data[field]);
      })}
    </GridLayout>
  );
};

export default IRPerformance;

const create_performance_items = (title, row, data) => {
  return (
    <React.Fragment key={title}>
      <GridLayoutItem row={row} col={1} className="quality-ir-performance-item">
        <div className="quality-bold-text">{title}</div>
      </GridLayoutItem>
      <GridLayoutItem row={row} col={2} className="quality-ir-performance-item">
        <div className="quality-normal-text">
          {Number(data.total).toLocaleString()} 건
        </div>
      </GridLayoutItem>
      <GridLayoutItem row={row} col={3} className="quality-ir-performance-item">
        <div className="quality-normal-text">
          {Number(data.reject).toLocaleString()} 건
        </div>
      </GridLayoutItem>
      <GridLayoutItem row={row} col={4} className="quality-ir-performance-item">
        <div className="quality-normal-text">
          {Number(data.percent).toFixed(2)} %
        </div>
      </GridLayoutItem>
    </React.Fragment>
  );
};

const initial_data = {
  wir: {
    total: 0,
    reject: 0,
    percent: 0,
  },
  fir: {
    total: 0,
    reject: 0,
    percent: 0,
  },
  bir: {
    total: 0,
    reject: 0,
    percent: 0,
  },
  fm_pvc: {
    total: 0,
    reject: 0,
    percent: 0,
  },
  tir: {
    total: 0,
    reject: 0,
    percent: 0,
  },
};

const filed_list = [
  { field: "wir", title: "WIR", unifier_field: "WIR", row: 2 },
  { field: "fir", title: "FIR", unifier_field: "FIR", row: 3 },
  { field: "bir", title: "BIR", unifier_field: "BIR", row: 4 },
  { field: "fm_pvc", title: "FM PVC", unifier_field: "FM PVC WIR", row: 5 },
  { field: "tir", title: "TIR", unifier_field: "TIR", row: 6 },
];

const handle_field_name = (name) => {
  switch (name) {
    case "WIR":
      return "WIRVSConDate";
    case "FIR":
      return "WIRFInspDate";
    case "BIR":
      return "WIRVSConDate";
    case "FM PVC WIR":
      return "WIRVSConDate";
    case "TIR":
      return "WIRTInspDate";

    default:
      return "";
  }
};

const returnZeroIfInvalid = (value) => {
  if (value === Infinity || isNaN(value) || value === false) {
    return 0;
  }
  return value;
};

const ToggleButton2 = styled(MuiToggleButton)(() => ({
  "&.MuiToggleButton-root": {
    fontSize: "12px",
    fontFamily: "Inter",
    fontWeight: 500,
    padding: "2px",
    width: 80,
    color: "#3d3d3d",
    borderRadius: "none",
    border: "1px solid #d1d1d1 !important",
    backgroundColor: "#fff",
    transition: "all 0.3s ease-out",
  },
  "&.Mui-selected, &.MuiToggleButton-root:hover": {
    color: "white",
    backgroundColor: "#004098",
  },
}));

const ToggleButtonGroup2 = styled(MuiToggleButtonGroup)(() => ({
  "&.MuiToggleButtonGroup-root": {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
}));

const theme = createTheme({
  palette: {
    text: {
      primary: "#00ff00",
    },
  },
});
