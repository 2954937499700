import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import 'hammerjs';
import axios from "../../../common/axios";
import { GridLayout, GridLayoutItem } from '@progress/kendo-react-layout';
import dayjs from 'dayjs';
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { DropDownTree } from '@progress/kendo-react-dropdowns';
import { processTreeData, expandedState } from '../../common/tree-data-opreation';
import { DatePicker } from "@progress/kendo-react-dateinputs";
import {
    IntlProvider,
    LocalizationProvider,
    loadMessages,
} from "@progress/kendo-react-intl";
import loadCldrData from "../../common/loadCldrData";
import krMessages from "../../common/kr.json";
import { Circles } from 'react-loader-spinner';
import { ToggleButton, ToggleButtonGroup } from '../../common/CustomToggleButton';
const selectField = "selected";
const expandField = "expanded";
const dataItemKey = "text";
const textField = "text";
const subItemsField = "items";
const fields = {
    selectField,
    expandField,
    dataItemKey,
    subItemsField,
};

const defaultvalue = new Date()
const Progress = () => {
    const { project_code } = useParams();
    const [progressData, setProgressData] = useState({});
    const [actualpercent, setActualpercent] = useState(0);
    const [planpercent, setPlanpercent] = useState(0);
    const [checked, setChecked] = React.useState("월간");
    const [value_area, setValue2] = useState({ text: '' });
    const [value, setValue] = useState({ text: '' });
    const [value3, setValue3] = useState(null);
    const [expanded, setExpanded] = useState(['']);
    const [datevalue, setValueDate] = useState(defaultvalue);
    const [loading, setLoading] = useState(true);




    const selectdata = progressData &&progressData.selectdata
    const selectdata2 = progressData &&progressData.selectdata2


    var dataarea = selectdata && selectdata, keys = ['Area'], gresult = [], temp = { _: gresult };
    dataarea && dataarea.forEach(function (a) {
        keys.reduce(function (r, k) {
            if (!r[a[k]]) {
                r[a[k]] = { _: [] };
                r._.push({ text: a['Area'], items: r[a[k]]._ });
            }
            return r[a[k]];
        }, temp)._.push({ text:a.Subarea });
    });

    var datad = selectdata2 && selectdata2, keys = ['Discipline'], gresult2 = [], temp = { _: gresult2 };
    datad && datad.forEach(function (a) {
        keys.reduce(function (r, k) {
            if (!r[a[k]]) {
                r[a[k]] = { _: [] };
                r._.push({ text: a['Discipline'], items: r[a[k]]._ });
            }
            return r[a[k]];
        }, temp)._.push({ text: a.SubDiscipline });
    });





useEffect(() => {
    const fetchData = async (reportName) => {
        try {
            const res = await axios.get(`/progress1?project=${project_code}&value_area=${value_area === null ? 'deselect':value_area.text }&value=${value === null ? 'deselect':value.text}&value3=${value3}&datevalue=${datevalue}`);
            setProgressData(res.data);
            setPlanpercent(res.data.planpercent)
            setActualpercent(res.data.actualpercent)
            setLoading(false);

        } catch (error) {
            console.error(`${reportName} 데이터 가져오기 중 오류 발생:`, error);
            return []; // 오류 발생 시 빈 배열 반환
        }
    };
    fetchData();
}, [ project_code, value3, value_area, value,datevalue]);

  return (
    <div style={{ position: 'relative' }}>
        <div style={{ position: 'absolute', top: '30px', left: '40px' }}>
            <div style={{ textAlign: 'center', color: '#939597', fontSize: '1rem'}}>Plan</div>
            <div style={{textAlign: 'center' , fontSize: '1.5rem', fontWeight: 'bold' }}>  {planpercent.toFixed(2)}%</div>
        </div>
    <div style={{ position: 'absolute', top: '30px', right: '40px' }}>
        <div style={{ textAlign: 'center', color: '#939597', fontSize: '1rem' }}>Actual</div>
        <div style={{ textAlign: 'center', fontSize: '1.5rem', fontWeight: 'bold', color: '#525252' }}>{actualpercent.toFixed(2)}%</div>
    </div>
    <div style={{ position: 'absolute', top: '120px', left: '50%', transform: 'translateX(-50%)' }}>
        <div style={{ textAlign: 'center', color: '#363945' }}>Dev.</div>

        <div style={{ textAlign: 'center', fontSize: '2rem', fontWeight: 'bold' }}>

                <span style={{ color: actualpercent - planpercent > 0 ?'#00539C':'#9E1030' }}>{(actualpercent - planpercent).toFixed(2)}</span>%</div>
    </div>
        
</div>
  )
}

export default Progress
