import React, { useEffect, useState, useLayoutEffect } from 'react';
import _ from 'lodash';
import {
  Card,
  CardTitle,
  CardBody,
  GridLayout, GridLayoutItem
} from "@progress/kendo-react-layout";
import moment from 'moment';
import axios from "../../common/axios";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'; // isSameOrAfter 플러그인을 가져옵니다.
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'; //
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';
import * as am5percent from "@amcharts/amcharts5/percent";
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import MuiToggleButton from '@mui/material/ToggleButton';
import MuiToggleButtonGroup from '@mui/material/ToggleButtonGroup';

const ToggleButton2 = styled(MuiToggleButton)(() => ({
  '&.Mui-selected, &.Mui-selected:hover': {
    color: 'white',
    backgroundColor: '#FF6F61',
  },
  '&.MuiToggleButton-root': {
    fontSize: '0.6rem',
    fontFamily: 'Pretendard-R',
    padding: 0,
    width: 50,
    borderRadius: '5px 5px 5px 5px!important',
    border: '1px solid rgba(0, 0, 0, 0.12)!important'

  }
}));

const ToggleButtonGroup2 = styled(MuiToggleButtonGroup)(() => ({
  '&.MuiToggleButtonGroup-root': {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1)
    }
  }
}));


const theme = createTheme({
  palette: {
    text: {
      primary: '#00ff00',
    },
  },
});

const CostTotal = ({ height }) => {
  const [totalChartData, setTotalChartData] = useState([]);
  const [chartData, setData] = useState([]);
  const [selectedValue, setSelectedValue] = React.useState("전체");
  const [projects, setProjects] = React.useState([]);
  const [type, setType] = useState("총예산");
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  console.log(chartData)

  const handleTypeChange = (event, type) => {
    if (type !== null) {
      setType(type);
    }
  };

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  function processProjects(projects) {
    const result = [];
    let subData = [];
    let startObject = null; // count가 0인 시작 객체를 저장할 변수

    projects.forEach((project, index) => {
      if (project.count === 0) {
        if (startObject) {
          // 이미 수집을 시작한 상태라면, 이전 데이터를 처리
          startObject.subData = subData;
          result.push(startObject);
          subData = []; // subData 초기화
        }
        startObject = { ...project }; // 새로운 시작 객체 복사
      } else if (project.count === 4 && startObject) {
        // 수집 중이고 count가 4인 경우 subData에 추가
        subData.push(project);
      }
    });

    // 마지막으로 수집된 데이터 추가
    if (startObject) {
      startObject.subData = subData;
      result.push(startObject);
    }

    return result;
  }


  useEffect(() => {
    const fetchData = async () => {
      const res2 = await axios.get(`/getprojectlist`)
      if (res2.data.success) {
        setProjects(["전체", ...res2.data.data.map(v => v.projectname)])
      }
    };
    fetchData();
  }, []);

  function countLeadingSpaces(str) {
    // ^는 문자열의 시작을 나타내고, \s*는 0개 이상의 공백 문자를 의미합니다.
    const match = str.match(/^\s*/);
    return match ? match[0].length : 0;
  }

  useEffect(() => {
    const fetchData = async () => {
      const res = await axios.get(`/getCostSheet`)
      if (res.data.success) {
        const firstData =
          _(res.data.data.filter(v => v.ITEM_TYPEID === 0))
            .groupBy("PROJECTNAME")
            .map((objs, key) => ({
              PROJECTNAME: key,
              총예산: _.sumBy(objs, "총예산"),
              추정원가: _.sumBy(objs, "추정원가"),
            }))
            .value()
            .filter(v => v.총예산 !== 0)

        setTotalChartData(firstData)


        const pieData = res.data.data.filter(v =>v.총예산 !== 0 && v.PROJECTNAME === selectedValue)
          .map((v) => ({ ...v, category: v.cbs.trim(), count: countLeadingSpaces(v.cbs) }))


        setData(selectedValue === "전체" ? [] : processProjects(pieData))
      }
    };
    fetchData();

  }, [selectedValue]);


  const initializeXYChart = (chartId, chartData) => {
    let root = am5.Root.new(chartId);

    root.setThemes([
      am5themes_Animated.new(root)
    ]);

    root.numberFormatter.setAll({
      numberFormat: "#.0a",
      // Group only into M (millions), and B (billions)
      bigNumberPrefixes: [
        { "number": 1e+8, "suffix": "억" },
      ],

      // Do not use small number prefixes at all
      smallNumberPrefixes: []
    });

    var chart = root.container.children.push(am5xy.XYChart.new(root, {
      panX: false,
      panY: false,
      wheelX: "panX",
      wheelY: "zoomX",
      paddingLeft: 0,
      layout: root.verticalLayout
    }));

    var legend = chart.children.push(am5.Legend.new(root, {
      centerX: am5.p50,
      x: am5.p50
    }))

    var yAxis = chart.yAxes.push(am5xy.CategoryAxis.new(root, {
      categoryField: "PROJECTNAME",
      renderer: am5xy.AxisRendererY.new(root, {
        inversed: true,
        cellStartLocation: 0.1,
        cellEndLocation: 0.9,
        minorGridEnabled: true,

      })
    }));

    yAxis.data.setAll(chartData);
    
    console.log(chartData)

    const maxValue =  chartData.reduce((max, project) => {
      const maxProjectValue = Math.max(project["총예산"], project["추정원가"]);
      return Math.max(max, maxProjectValue);
  }, 0);

    var xAxis = chart.xAxes.push(am5xy.ValueAxis.new(root, {
      renderer: am5xy.AxisRendererX.new(root, {
        strokeOpacity: 0.1,
        minorGridEnabled: false,
      }),
      min: 0,
      max:maxValue*1.05
    }));

    xAxis.hide();

    function createSeries(field, name) {
      var series = chart.series.push(am5xy.ColumnSeries.new(root, {
        name: name,
        xAxis: xAxis,
        yAxis: yAxis,
        valueXField: field,
        categoryYField: "PROJECTNAME",
        sequencedInterpolation: true,
        tooltip: am5.Tooltip.new(root, {
          pointerOrientation: "horizontal",
          labelText: "[bold]{name}[/]\n{categoryY}: {valueX.formatNumber('#.0a')}"
        })
      }));

      series.columns.template.setAll({
        height: am5.p100,
        strokeOpacity: 0
      });


      series.bullets.push(function () {
        return am5.Bullet.new(root, {
          locationX: 1,
          locationY: 0.5,
          sprite: am5.Label.new(root, {
            centerY: am5.p50,
            text: "{valueX}",
            populateText: true
          })
        });
      });

      series.bullets.push(function () {
        return am5.Bullet.new(root, {
          locationX: 1,
          locationY: 0.5,
          sprite: am5.Label.new(root, {
            centerX: am5.p100,
            centerY: am5.p50,
            text: "{name}",
            fill: am5.color(0xffffff),
            populateText: true
          })
        });
      });

      series.data.setAll(chartData);
      series.appear();

      return series;
    }

    createSeries("총예산", "총예산");
    createSeries("추정원가", "추정원가");

    var legend = chart.children.push(am5.Legend.new(root, {
      centerX: am5.p50,
      x: am5.p50
    }));

    legend.data.setAll(chart.series.values);

    // Add cursor
    // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
    var cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
      behavior: "zoomY"
    }));
    cursor.lineY.set("forceHidden", true);
    cursor.lineX.set("forceHidden", true);


    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    chart.appear(1000, 100);

    return () => {
      root.dispose();
    };
  };

  const initializePieChart = (chartId, chartData, type) => {
    let root = am5.Root.new(chartId);
    root.setThemes([am5themes_Animated.new(root)]);

    
    root.numberFormatter.setAll({
      numberFormat: "#.0a",
      // Group only into M (millions), and B (billions)
      bigNumberPrefixes: [
        { "number": 1e+8, "suffix": "억" },
      ],

      // Do not use small number prefixes at all
      smallNumberPrefixes: []
    });

    var container = root.container.children.push(
      am5.Container.new(root, {
        width: am5.p100,
        height: am5.p100,
        layout: root.horizontalLayout
      })
    );
    var chart = container.children.push(
      am5percent.PieChart.new(root, {
        tooltip: am5.Tooltip.new(root, {})
      })
    );
    var tooltip = am5.Tooltip.new(root, {
      labelText: "[bold]{category}[/]: {valuePercentTotal.formatNumber('0.00')}%[/] \n{value.formatNumber('#.0a')}"
    });

    // Create series
    // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Series
    var series = chart.series.push(
      am5percent.PieSeries.new(root, {
        valueField: type,
        categoryField: "category",
        alignLabels: false,
        tooltip: tooltip
      })
    );

    series.labels.template.setAll({
      textType: "circular",
      radius: 4
    });
    series.labels.template.adapters.add("visible", function(visible, target) {
      let dataItem = target.dataItem;
      if (dataItem) {
        let slice = dataItem.get("slice");
        if (slice) {
          let arc = slice.get("arc");
          // 예를 들어, 10도 미만의 슬라이스 라벨은 숨깁니다.
          if (arc < 10) {
            return false;
          }
        }
      }
      return visible;
    });
    
    series.ticks.template.set("visible", false);
    series.slices.template.set("toggleKey", "none");

    // add events
    series.slices.template.events.on("click", function (e) {
      selectSlice(e.target);
    });

    // Create sub chart
    // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/
    var subChart = container.children.push(
      am5percent.PieChart.new(root, {
        radius: am5.percent(50),
        tooltip: am5.Tooltip.new(root, {})
      })
    );

    // Create sub series
    // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Series
    var subSeries = subChart.series.push(
      am5percent.PieSeries.new(root, {
        valueField: type,
        categoryField: "category",
        tooltip: tooltip
      })
    );

    subSeries.data.setAll([
      { category: "A", 총예산: 0 , 추정원가:0},
      { category: "B", 총예산: 0 , 추정원가:0},
      { category: "C", 총예산: 0 , 추정원가:0},
      { category: "D", 총예산: 0 , 추정원가:0},
      { category: "E", 총예산: 0 , 추정원가:0},
      { category: "F", 총예산: 0 , 추정원가:0},
      { category: "G", 총예산: 0 , 추정원가:0}
    ]);
    subSeries.slices.template.set("toggleKey", "none");
    subSeries.labels.template.setAll({
      maxWidth: 120,
      fontSize: 10,
      oversizedBehavior: "wrap" // to truncate labels, use "truncate"
    });

    var selectedSlice;

    series.on("startAngle", function () {
      updateLines();
    });

    container.events.on("boundschanged", function () {
      root.events.once("frameended", function () {
        updateLines();
      })
    });

    function updateLines() {
      if (selectedSlice && subSeries.slices.length > 0 && subSeries.slices.getIndex(0)) {
        var startAngle = selectedSlice.get("startAngle");
        var arc = selectedSlice.get("arc");
        var radius = selectedSlice.get("radius");

        var x00 = radius * am5.math.cos(startAngle);
        var y00 = radius * am5.math.sin(startAngle);

        var x10 = radius * am5.math.cos(startAngle + arc);
        var y10 = radius * am5.math.sin(startAngle + arc);

        var subSlice = subSeries.slices.getIndex(0);
        if (subSlice) {
          var subRadius = subSlice.get("radius");
          var x01 = 0;
          var y01 = -subRadius;
          var x11 = 0;
          var y11 = subRadius;

          var point00 = series.toGlobal({ x: x00, y: y00 });
          var point10 = series.toGlobal({ x: x10, y: y10 });

          var point01 = subSeries.toGlobal({ x: x01, y: y01 });
          var point11 = subSeries.toGlobal({ x: x11, y: y11 });

          line0.set("points", [point00, point01]);
          line1.set("points", [point10, point11]);
        }
      }
    }


    // lines
    var line0 = container.children.push(
      am5.Line.new(root, {
        position: "absolute",
        stroke: root.interfaceColors.get("text"),
        strokeDasharray: [2, 2]
      })
    );
    var line1 = container.children.push(
      am5.Line.new(root, {
        position: "absolute",
        stroke: root.interfaceColors.get("text"),
        strokeDasharray: [2, 2]
      })
    );

    series.labels.template.adapters.add("y", function(y, target) {
      let dataItem = target.dataItem;
      if (dataItem) {
        let tick = dataItem.get("tick");
        if (tick) {
          if (dataItem.get("valuePercentTotal") < 1) {
            target.set("forceHidden", true);
            tick.set("forceHidden", true);
          }
          else {
            target.set("forceHidden", false);
            tick.set("forceHidden", false);
          }
        }
        return y;
      }
    });

  

    
    series.data.setAll(chartData)

    function selectSlice(slice) {
      selectedSlice = slice;
      var dataItem = slice.dataItem;
      var dataContext = dataItem.dataContext;

      if (dataContext) {
        var i = 0;
        subSeries.data.each(function (dataObject) {
          var dataObj = dataContext.subData[i];
          if (dataObj) {
            if (!subSeries.dataItems[i].get("visible")) {
              subSeries.dataItems[i].show();
            }
            subSeries.data.setIndex(i, dataObj);
          }
          else {
            subSeries.dataItems[i].hide();
          }

          i++;
        });
      }

      var middleAngle = slice.get("startAngle") + slice.get("arc") / 2;
      var firstAngle = series.dataItems[0].get("slice").get("startAngle");

      series.animate({
        key: "startAngle",
        to: firstAngle - middleAngle,
        duration: 1000,
        easing: am5.ease.out(am5.ease.cubic)
      });
      series.animate({
        key: "endAngle",
        to: firstAngle - middleAngle + 360,
        duration: 1000,
        easing: am5.ease.out(am5.ease.cubic)
      });
    }

    container.appear(1000, 10);

    series.events.on("datavalidated", function () {
      selectSlice(series.slices.getIndex(0));
    });

    return () => {
      root && root.dispose();
    };
  };

  useLayoutEffect(() => {
    if (selectedValue === "전체") {
      const dispose1 = initializeXYChart("costTotal", totalChartData);
      return () => dispose1();
    } else if (chartData.length > 0) {
      const dispose2 = initializePieChart("costTotal2", chartData, type);
      return () => dispose2();
    }
  }, [selectedValue, totalChartData, chartData, type]);


  return (
    <>
      <GridLayout style={{ height: 2 * (height / 6.5) - 70 }}
        rows={[
          {
            height: "10%",
          },
          {
            height: "90%",
          },
        ]}
        cols={[
          {
            width: '50%',
          },
          {
            width: '50%',
          },
        ]}
        gap={{
          rows: 10,
          cols: 0,
        }}>

        <div style={{ position: 'absolute', top: 16, right: 230, float: 'right', display:selectedValue === "전체"?'none':'block' }}>
          <ThemeProvider theme={theme}>
            <ToggleButtonGroup2 size="small" onChange={handleTypeChange} value={type} exclusive aria-label="baseunit">
              <ToggleButton2 value="총예산" aria-label='총예산' >
                총예산
              </ToggleButton2>
              <ToggleButton2 value="추정원가" aria-label="추정원가">
                추정원가
              </ToggleButton2>
            </ToggleButtonGroup2>
          </ThemeProvider>
        </div>
        {/* 프로젝트 선택기 */}
        <FormControl size="small" sx={{
          m: 1,
          minWidth: 120,
          position: 'absolute', // Use relative positioning
          top: 6,
          right: 10,
          float: 'right', // Align to the right
        }}>
          <InputLabel id="demo-select-small-label">프로젝트</InputLabel>
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small"
            value={selectedValue}
            label="프로젝트"
            onChange={handleChange}
          >
            {projects && projects.map((v, index) => (
              <MenuItem key={index} value={v}>{v}</MenuItem>
            ))}
          </Select>
        </FormControl>



        {/* 차트를 중앙에 배치 */}
        <GridLayoutItem row={2} col={1} colSpan={2}>
          {selectedValue === "전체" ?
            <div id="costTotal" style={{ width: "100%", height: (3 * (height / 6.5)) * 0.8, zIndex: '999' }}></div>
            :
            <div id="costTotal2" style={{ width: "100%", height: (3 * (height / 6.5)) * 0.7, zIndex: '999' }}></div>

          }

        </GridLayoutItem>

      </GridLayout>



    </>);
}

export default CostTotal
