import React, { useState, useMemo, useEffect } from "react";
import { TileLayout } from "@progress/kendo-react-layout";
import { useRecoilValue } from "recoil";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import _ from "lodash";
import { QualityProjectList } from "../../atom/qualityState";
import useWindowDimensions from "../common/useWindowDimensions";
import QualityRate1 from "./qualityRate1/qualityRate1";
import InspectionApproval from "./Main/InspectionApproval/InspectionApproval";
import IndicatorK from "./Main/IndicatorK/IndicatorK";
import PPMStatus from "./Main/PPMStatus/PPMStatus";
import { DashboardLayoutTotalT } from "../../layout/LayoutTotalT";

import "./qualitytable.css";
import PageChangeBtn from "./public/PageChangeBtn";

const QualityMain = () => {
  const fixblock = false;

  const { height } = useWindowDimensions();
  const projectList = useRecoilValue(QualityProjectList);

  const [positiondata, setPositionData] = useState(grid["desktop"]);

  const [targetPhase, setTargetPhase] = useState({
    label: "전체",
    value: "all",
  });

  const [targetProject, setTargetProject] = useState(null);

  const phase_project_list = useMemo(() => {
    return projectList.map((c) => {
      const { label } = c;
      return { ...c, phase: label ? label.slice(0, 2) : "" };
    });
  }, [projectList]);

  const phase_list = useMemo(() => {
    return _.uniqBy(phase_project_list, "phase").map((c) => {
      const { phase, value } = c;

      return {
        label: phase,
        value,
      };
    });
  }, [phase_project_list]);

  const project_list = useMemo(() => {
    if (targetPhase.value === "all") {
      return [];
    }

    return phase_project_list.filter((c) => c.phase === targetPhase.label);
  }, [targetPhase, phase_project_list]);

  useEffect(() => {
    setTargetProject(null);
  }, [targetPhase]);

  const tiles = useMemo(
    () => [
      {
        header: "품질 검사 합격률",
        body: (
          <InspectionApproval
            height={((height - 42) / 14) * 4 - 32 - 44}
            targetPhase={targetPhase}
            targetProject={targetProject}
          />
        ),
        resizable: fixblock,
        reorderable: fixblock,
      },
      {
        header: "품질 수준 현황 (시공사 검사)",
        body: (
          <QualityRate1
            height={((height - 42) / 14) * 7 - 32 - 44}
            targetPhase={targetPhase}
            mainTargetProject={targetProject}
          />
        ),

        resizable: fixblock,
        reorderable: fixblock,
      },
      {
        header: "부적합률",
        body: (
          <PPMStatus
            height={((height - 42) / 14) * 10 - 32 - 44}
            targetPhase={targetPhase}
            // targetProject={targetProject}
          />
        ),
        resizable: fixblock,
        reorderable: fixblock,
      },
      {
        header: "지표 K",
        body: (
          <IndicatorK
            height={((height - 42) / 14) * 7 - 32 - 44}
            targetPhase={targetPhase}
            targetProject={targetProject}
          />
        ),
        resizable: fixblock,
        reorderable: fixblock,
      },
    ],
    [fixblock, height, targetPhase, targetProject]
  );

  const handleReposition = (e) => {
    setPositionData(e.value);
  };

  return (
    <DashboardLayoutTotalT>
      <div className="quality-main-top-container">
        <div className="quality-detail-filter-container">
          <div className="quality-detail-filter-box">
            <div className="quality-detail-filter-text">건물명:</div>
            <DropDownList
              className="quality-detail-filter-drop-down-list short2"
              data={[
                {
                  label: "전체",
                  value: "all",
                },
                ...phase_list,
              ]}
              value={targetPhase}
              textField="label"
              dataItemKey="value"
              onChange={(e) => setTargetPhase(e.value)}
            />
          </div>
          <div className="quality-detail-filter-box">
            <div className="quality-detail-filter-text">프로젝트:</div>
            <DropDownList
              className="quality-detail-filter-drop-down-list short2"
              data={project_list}
              value={targetProject}
              textField="label"
              dataItemKey="value"
              onChange={(e) => setTargetProject(e.value)}
            />
          </div>
        </div>
        <PageChangeBtn />
      </div>
      <TileLayout
        className="quality-tile-body"
        columns={16}
        rowHeight={(height - 42) / 14}
        positions={positiondata}
        gap={{
          rows: 10,
          columns: 10,
        }}
        items={tiles}
        autoFlow="row dense"
        onReposition={handleReposition}
        style={{ height: height - 42 }}
      />
    </DashboardLayoutTotalT>
  );
};

export default QualityMain;

const grid = {
  mobile: [
    {
      col: 1,
      colSpan: 10,
      rowSpan: 4,
      status: true,
    },
    {
      col: 11,
      colSpan: 6,
      rowSpan: 7,
      status: true,
    },
    {
      col: 1,
      colSpan: 10,
      rowSpan: 10,
      status: true,
    },
    {
      col: 11,
      colSpan: 6,
      rowSpan: 7,
      status: true,
    },
  ],
  tablet: [
    {
      col: 1,
      colSpan: 10,
      rowSpan: 4,
      status: true,
    },
    {
      col: 11,
      colSpan: 6,
      rowSpan: 7,
      status: true,
    },
    {
      col: 1,
      colSpan: 10,
      rowSpan: 10,
      status: true,
    },
    {
      col: 11,
      colSpan: 6,
      rowSpan: 7,
      status: true,
    },
  ],
  desktop: [
    {
      col: 1,
      colSpan: 10,
      rowSpan: 4,
      status: true,
    },
    {
      col: 11,
      colSpan: 6,
      rowSpan: 7,
      status: true,
    },
    {
      col: 1,
      colSpan: 10,
      rowSpan: 10,
      status: true,
    },
    {
      col: 11,
      colSpan: 6,
      rowSpan: 7,
      status: true,
    },
  ],
};
