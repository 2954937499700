import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { TileLayout } from "@progress/kendo-react-layout";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { DashboardLayout } from '../../../../layout/Layout';
import useBreakpoint from "use-breakpoint";
import useWindowDimensions from '../../../common/useWindowDimensions';
import axios from '../../../../common/axios';
import { useParams } from 'react-router-dom';
import ProgressBody from './dPregressTile';
import { useRecoilValue } from 'recoil';
import { areaState } from "../../../../atom/areaState";
import { actualPercentState, planPercentState } from "../../../../atom/progressState";
import { FaCircle } from "react-icons/fa";
import ProgressSchedule from './dPregressTileSchedule';
import ProgressDetail1 from './dPregressTile1';
import ProgressDetail2 from './dPregressTile2';
import ProgressDetail3 from './dPregressTile3';

const BREAKPOINTS = { mobile: 0, tablet: 768, desktop: 1280 };

const DProgressPage = () => {
  const { project_code } = useParams();
  const { height, width } = useWindowDimensions();
  const { breakpoint } = useBreakpoint(BREAKPOINTS, 'desktop');
  const area = useRecoilValue(areaState);
  const actualValue = useRecoilValue(actualPercentState);
  const planValue = useRecoilValue(planPercentState);
  const [tableData, setTableData] = useState([]);
  const [tableData1, setTableData1] = useState([]);
  const [tableData2, setTableData2] = useState([]);
  const [tableData3, setTableData3] = useState([]);
  const [cutoffList, setCutoffList] = useState([]);
  const [selectedCutoff, setSelectedCutoff] = useState(null);

  const [selectedCutoff1, setSelectedCutoff1] = useState(null);
  const [selectedCutoff2, setSelectedCutoff2] = useState(null);
  const [selectedCutoff3, setSelectedCutoff3] = useState(null);

  const [state, setState] = useState({
    newWBS: false,
    positionData: [],
    error: null,
  });

  const getGridConfig = (isNewWBS) => {
    if (isNewWBS) {
      return {
        mobile: [
          { col: 1, colSpan: 10, rowSpan: 6, status: true },
          { col: 1, colSpan: 6, rowSpan: 2, status: true },
          { col: 1, colSpan: 6, rowSpan: 2, status: true },
          { col: 1, colSpan: 6, rowSpan: 2, status: true },],
        tablet: [{ col: 1, colSpan: 10, rowSpan: 6, status: true },
        { col: 11, colSpan: 6, rowSpan: 2, status: true },
        { col: 11, colSpan: 6, rowSpan: 2, status: true },
        { col: 11, colSpan: 6, rowSpan: 2, status: true },],
        desktop: [
          { col: 1, colSpan: 10, rowSpan: 6, status: true },
          { col: 11, colSpan: 6, rowSpan: 2, status: true },
          { col: 11, colSpan: 6, rowSpan: 2, status: true },
          { col: 11, colSpan: 6, rowSpan: 2, status: true },
        ],
      };
    } else {
      return {
        mobile: [{ col: 1, colSpan: 16, rowSpan: 6, status: true }],
        tablet: [{ col: 1, colSpan: 16, rowSpan: 6, status: true }],
        desktop: [{ col: 1, colSpan: 16, rowSpan: 6, status: true }],
      };
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`/newwbs?project=${project_code}`);
        const newWBS = res.data.newWBS;
        const gridConfig = getGridConfig(newWBS);
        setState(prevState => ({
          ...prevState,
          newWBS: newWBS,
          positionData: gridConfig[breakpoint] || [],
        }));

        if (newWBS) {
          const res = await axios.get(`/getspotfire?project=${project_code}`);

          setTableData(res.data.scheduleData.map((v, index) => ({
            ...v, id: index,

            'planStart': v.planStart ? new Date(v.planStart.slice(0, 10)) : null,
            'planFinish': v.planFinish ? new Date(v.planFinish.slice(0, 10)) : null,
            'actualStart': v.actualStart ? new Date(v.actualStart.slice(0, 10)) : null,
            'actualFinish': v.actualFinish ? new Date(v.actualFinish.slice(0, 10)) : null,
            'forecastStart': v.forecastStart ? new Date(v.forecastStart.slice(0, 10)) : null,
            'forecastFinish': v.forecastFinish ? new Date(v.forecastFinish.slice(0, 10)) : null,

          })))

          const resCutoff = await axios.get(`/getcutoff?project=${project_code}`);

          setCutoffList(resCutoff.data.cutoffData);
          const today = new Date();
          const dayOfWeek = today.getDay(); // 0: 일요일, 1: 월요일, ..., 6: 토요일
          const daysToFriday = dayOfWeek >= 5 ? dayOfWeek - 5 : (dayOfWeek - 5 + 7) % 7;
          const recentFriday = new Date(today.getTime() - daysToFriday * 24 * 60 * 60 * 1000);
          const recentFridayStr = recentFriday.toISOString().split('T')[0];
          
          // 가장 가까운 지난 금요일에 해당하는 cutoff 값 선택
          const selectedCutoff = resCutoff.data.cutoffData.find(cutoff => cutoff.includes(recentFridayStr));
          setSelectedCutoff1(selectedCutoff || resCutoff.data.cutoffData[0]);
          setSelectedCutoff2(selectedCutoff || resCutoff.data.cutoffData[0]);
          setSelectedCutoff3(selectedCutoff || resCutoff.data.cutoffData[0]);
        }

      } catch (error) {
        console.error(`데이터 가져오기 중 오류 발생:`, error);
        setState(prevState => ({
          ...prevState,
          error: '데이터를 불러오는 중 오류가 발생했습니다.',
        }));
      }
    };
    fetchData();
  }, [project_code, breakpoint]);

  useEffect(() => {
    const fetchProgressData1 = async () => {
      if (selectedCutoff1) {
        const cutoffDate1 = selectedCutoff1.split(":")[1].trim();
        const res1 = await axios.get(`/getspotfireprogress1?project=${project_code}&cutoff=${cutoffDate1}`);
        setTableData1(
          res1.data.progressData
            .map((v, index) => ({
              ...v,
              id: index,
              wbs1: v.key.split("//")[0],
              wbs2: v.key.split("//")[1],
              wbs3: v.key.split("//")[2],
            }))
        );
      }
    };
    fetchProgressData1();
  }, [project_code, selectedCutoff1]);

  useEffect(() => {
    const fetchProgressData2 = async () => {
      if (selectedCutoff2) {
        const cutoffDate2 = selectedCutoff2.split(":")[1].trim();
        const res2 = await axios.get(`/getspotfireprogress2?project=${project_code}&cutoff=${cutoffDate2}`);
        setTableData2(
          res2.data.progressData
            .map((v, index) => ({
              ...v,
              id: index,
              wbs1: v.key.split("//")[0],
              wbs2: v.key.split("//")[1],
              wbs3: v.key.split("//")[2],
            }))
        );
      }
    };
    fetchProgressData2();
  }, [project_code, selectedCutoff2]);

  useEffect(() => {
    const fetchProgressData3 = async () => {
      if (selectedCutoff3) {
        const cutoffDate3 = selectedCutoff3.split(":")[1].trim();
        const res3 = await axios.get(`/getspotfireprogress3?project=${project_code}&cutoff=${cutoffDate3}`);
        setTableData3(
          res3.data.progressData
            .map((v, index) => ({
              ...v,
              id: index,
              wbs1: v.key.split("//")[0],
              wbs2: v.key.split("//")[1],
              wbs3: v.key.split("//")[2],
            }))
        );
      }
    };
    fetchProgressData3();
  }, [project_code, selectedCutoff3]);

  const getTiles = (isNewWBS) => {
    if (isNewWBS) {
      return [
        { header: "Schedule" , body: <ProgressSchedule height={height} tableData={tableData} /> },
        { header: <ProgressHeader title="공정률 - 시공사(Contractor)별" cutoffList={cutoffList} selectedCutoff={selectedCutoff1} setSelectedCutoff={setSelectedCutoff1} />, body: <ProgressDetail1 height={height} tableData={tableData1} /> },
        { header: <ProgressHeader title="공정률 - 업체(Sub-Contractor)별" cutoffList={cutoffList} selectedCutoff={selectedCutoff2} setSelectedCutoff={setSelectedCutoff2} />, body: <ProgressDetail2 height={height} tableData={tableData2} /> },
        { header: <ProgressHeader title="공정률 - Room 별" cutoffList={cutoffList} selectedCutoff={selectedCutoff3} setSelectedCutoff={setSelectedCutoff3} />, body: <ProgressDetail3 height={height} tableData={tableData3} /> },
      ].map(tile => ({
        ...tile,
        status: true,
        resizable: false,
        reorderable: false,
      }));
    } else {
      return [{
        header: <div
          areaName={area?.name}
          actualValue={actualValue}
          planValue={planValue}
        >공정률 </div>,
        body: <ProgressBody height={height} />,
        status: true,
        resizable: false,
        reorderable: false,
      }];
    }
  };

  const handleReposition = (e) => {
    setState(prevState => ({ ...prevState, positionData: e.value }));
  };

  if (state.error) {
    return <div>{state.error}</div>;
  }

  return (
    <DashboardLayout>
      {state.positionData.length > 0 ? (
        <TileLayout
          className='projectpage'
          columns={16}
          rowHeight={height / 6.5}
          positions={state.positionData}
          gap={{ rows: 10, columns: 10 }}
          items={getTiles(state.newWBS)}
          autoFlow="row dense"
          onReposition={handleReposition}
        />
      ) : (
        <div>로딩 중...</div>
      )}
    </DashboardLayout>
  );
};

const ProgressHeader = ({ title, areaName, actualValue, planValue, cutoffList, selectedCutoff, setSelectedCutoff }) => {
  const headerStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  };
  const titleStyle = {
    display: 'flex',
    alignItems: 'center',
  };

  const comboBoxStyle = {
    width: '200px',
  };

  const getStatusColor = (diff) => {
    if (diff < -3) return '#E13D43';
    if (diff >= 0) return '#00A170';
    return '#F49B42';
  };

  const difference = actualValue - planValue;

  const handleCutoffChange = (e) => {
    setSelectedCutoff(e.target.value);
  };

  return (
    <div style={headerStyle}>
      <div style={titleStyle}>
        {title}
        {areaName && (
          <>
            &nbsp;
            <FaCircle color={getStatusColor(difference)} />
            <span style={{ fontSize: 'smaller', marginLeft: '4px' }}>
              &nbsp; (계획: {planValue.toFixed(2)}% 실적: {actualValue.toFixed(2)}% 차이: {difference.toFixed(2)}%)
            </span>
          </>
        )}
      </div>
      <div>
        {cutoffList &&cutoffList.length > 0 && (
          <ComboBox
            data={cutoffList}
            value={selectedCutoff}
            onChange={handleCutoffChange}
            style={comboBoxStyle}
          />
        )}
      </div>
    </div>
  );
};

ProgressHeader.propTypes = {
  title: PropTypes.string,
  areaName: PropTypes.string,
  actualValue: PropTypes.number,
  planValue: PropTypes.number,
  cutoffList: PropTypes.array.isRequired,
  selectedCutoff: PropTypes.string,
  setSelectedCutoff: PropTypes.func.isRequired,
};

export default DProgressPage;