import * as React from "react";
import { DatePicker } from "@progress/kendo-react-dateinputs";

const DayPicker = ({ value, setValue, date }) => {
  const handleChange = (e) => {
    setValue(e.value);
  };

  return (
    <div>
      <DatePicker
        value={value}
        onChange={handleChange}
        data={date}
        format="yyyy년 MM월 dd일 기준"
        size={"small"}
      />
    </div>
  );
};

export default DayPicker;
