import React, { useState, useEffect } from 'react';
import { TileLayout } from "@progress/kendo-react-layout";
import { DashboardLayout } from '../../../layout/Layout';
import useBreakpoint from "use-breakpoint";
import useWindowDimensions from '../../common/useWindowDimensions';
import ManPower from './manPeriod';
import Equip from './equipPeriod';
import axios from "../../../common/axios";
import { useParams } from 'react-router-dom';
import PieChart from './manPowerPieChart';
import _ from 'lodash';

const BREAKPOINTS = { mobile: 0, tablet: 768, desktop: 1280 };

const ManPowerPage = () => {
  const { project_code } = useParams();
  const [fixblock, setfixblock] = useState(false);
  const { height } = useWindowDimensions();
  const { breakpoint } = useBreakpoint(BREAKPOINTS, 'desktop');

  const [grid, setgrid] = useState({
    mobile: [
      { col: 1, colSpan: 16, rowSpan: 6, status: true },
      { col: 1, colSpan: 16, status: true, rowSpan: 2 },
      { col: 1, colSpan: 16, status: true, rowSpan: 2 },
      { col: 1, colSpan: 16, status: true, rowSpan: 2 },
      { col: 1, colSpan: 16, rowSpan: 2, status: true },
      { col: 1, colSpan: 16, rowSpan: 2, status: true },
      { col: 1, colSpan: 16, rowSpan: 2, status: true },
      { col: 1, colSpan: 16, rowSpan: 2, status: true }
    ],
    tablet: [
      { col: 1, colSpan: 4, rowSpan: 6, status: true },
      { col: 5, colSpan: 12, status: true, rowSpan: 2 },
      { col: 5, colSpan: 4, rowSpan: 2, status: true },
      { col: 9, colSpan: 4, status: true, rowSpan: 2 },
      { col: 13, colSpan: 4, status: true, rowSpan: 2 },
      { col: 5, colSpan: 3, status: true, rowSpan: 2 },
      { col: 8, colSpan: 3, status: true, rowSpan: 2 },
      { col: 11, colSpan: 6, rowSpan: 2, status: true }
    ],
    desktop: [
      { col: 1, colSpan: 5, rowSpan: 3, status: true },
      { col: 6, colSpan: 12, status: true, rowSpan: 3 },
      { col: 1, colSpan: 5, rowSpan: 3, status: true },
      { col: 6, colSpan: 12, status: true, rowSpan: 3 }
    ]
  });

  const [positiondata, setPositionData] = useState(grid['desktop']);
  const [drdata, setdrdata] = useState([]);
  const [mandata, setMandata] = useState([]);
  const [equipdata, setEquipdata] = useState([]);
  const [dateValue1, setDateValue1] = useState(new Date());
  const [dateValue2, setDateValue2] = useState(new Date());


  useEffect(() => {
    setPositionData(grid[breakpoint]);
  }, [breakpoint]);

  useEffect(() => {
    const fetchData = async () => {

      const res = await axios.get(`/getdailyReport?project=${project_code}`);
      setdrdata(res.data.data);
    };
    fetchData();
  }, [project_code]);

  useEffect(() => {
    if (drdata.length > 0) {
      const manline = drdata
        .filter(v => v._bp_lineitems !== undefined)
        .reduce((c, v) => c.concat(v._bp_lineitems.map(o => ({ ...o, ReportingDO: v.ReportingDO, PALContractorBPK: v.PALContractorBPK }))), [])
        .filter(v => v.uuu_tab_id === '투입인원');

      const equipline = drdata
        .filter(v => v._bp_lineitems !== undefined)
        .reduce((c, v) => c.concat(v._bp_lineitems.map(o => ({ ...o, ReportingDO: v.ReportingDO, PALContractorBPK: v.PALContractorBPK }))), [])
        .filter(v => v.uuu_tab_id === '투입장비');

      const latestDate1 = _.sortBy(manline, dr => new Date(dr.ReportingDO))[manline.length - 1].ReportingDO;
      const latestDate2 = _.sortBy(equipline, dr => new Date(dr.ReportingDO))[equipline.length - 1].ReportingDO;

      setDateValue1(new Date(latestDate1));
      setDateValue2(new Date(latestDate2));
    }
  }, [drdata]);

  const compareDate1 = new Date(dateValue1);
  const compareDate2 = new Date(dateValue2);
  compareDate1.setHours(0, 0, 0, 0);
  compareDate2.setHours(0, 0, 0, 0);

  const filteredData1 = _.filter(mandata, (data) => {
    const dataDate = new Date(data.date);
    dataDate.setHours(0, 0, 0, 0);
    return dataDate.getTime() === compareDate1.getTime();
  });
  const manPieData = filteredData1
  .map(item => {
    const date = item.date;
    const newData = Object.keys(item)
      .filter(key => key !== "date" && key !== "none" && key !== "_date_date" && key !== "count" && item[key] > 0)
      .map(key => ({ date, id: key, value: item[key] }));
    return newData;
  })
  .flat();

  const totalSum1 = _.sumBy(manPieData, "value");

  const filterdData2 = _.filter(equipdata, (data) => {
    const dataDate = new Date(data.date);
    dataDate.setHours(0, 0, 0, 0);
    return dataDate.getTime() === compareDate2.getTime();
  });

  const equipPieData = _.sortBy(filterdData2.map((c) => ({
    date: c.date.toLocaleDateString('ko-KR', { year: '2-digit', month: '2-digit', day: '2-digit' }),
    id: c.xtype,
    value: c.count
  })).filter(item => item.id !== "_date_date" && item.id !== "count"), "date").flat();

  const totalSum2 = _.sumBy(equipPieData, "value");

  const tiles = [
    {
      defaultPosition: { col: 1, colSpan: 4, rowSpan: 3 },
      header: "인원 현황",
      body: <PieChart data={manPieData} text="명" totalSum={totalSum1} dateValue={dateValue1} setDateValue={setDateValue1} color={mancolor}  margin={120} fontSize={24} />,
      resizable: fixblock,
      reorderable: fixblock,
    },
    {
      defaultPosition: { col: 5, colSpan: 12, rowSpan: 3 },
      header: "기간별 인원 현황",
      body: <ManPower drdata={drdata} BoxHeight={3 * height / 6.5 - 80} datevalue={dateValue1} setManData={setMandata} />,
      resizable: fixblock,
      reorderable: fixblock,
      className: 'milestone'
    },
    {
      defaultPosition: { col: 1, colSpan: 4, rowSpan: 3 },
      header: "장비 현황",
      body: <PieChart data={equipPieData} text="대" totalSum={totalSum2} dateValue={dateValue2} setDateValue={setDateValue2} color={equipcolor}  margin={120} fontSize={24}/>,
      resizable: fixblock,
      reorderable: fixblock,
    },
    {
      defaultPosition: { col: 5, colSpan: 12, rowSpan: 3 },
      header: "기간별 장비 현황",
      body: <Equip BoxHeight={3 * height / 6.5 - 80} drdata={drdata} datevalue={dateValue2} setEquipdata={setEquipdata} />,
      resizable: fixblock,
      reorderable: fixblock,
    },
  ];

  const handleReposition = (e) => {
    setPositionData(e.value);
  };

  return (
    <DashboardLayout>
      <TileLayout
        className='projectpage'
        columns={16}
        rowHeight={height / 6.5}
        positions={positiondata}
        gap={{ rows: 10, columns: 10 }}
        items={tiles}
        autoFlow="row dense"
        onReposition={handleReposition}
      />
    </DashboardLayout>
  );
};

export default ManPowerPage;

const mancolor = [
  "#67738F",
  "#766C92",
  "#755670",
  "#503B59",
  "#7C3A3C",
  "#B65D50",
  "#E78A76",
  "#F6CC8A",
  "#E59F56"
];

const equipcolor = [
  "#F8A400",
  "#67C6A9",
  "#76A0DA",
  "#E3A797",
  "#D7CE47",
  "#CD9C96",
  "#E9DA47",
  "#D0C29B",
  "#EFEAD5",
  "#D19B1F",
  "#FBD558"
];