import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import 'hammerjs';
import axios from "../../../../common/axios";
import SCurveChart from '../../../common/SCurveChart';
import { GridLayout, GridLayoutItem } from '@progress/kendo-react-layout';
import dayjs from 'dayjs';
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { DropDownTree } from '@progress/kendo-react-dropdowns';
import { processTreeData, expandedState } from '../../../common/tree-data-opreation';
import { DatePicker } from "@progress/kendo-react-dateinputs";
import {
    IntlProvider,
    LocalizationProvider,
    loadMessages,
} from "@progress/kendo-react-intl";
import loadCldrData from "../../../common/loadCldrData";
import krMessages from "../../../common/kr.json";
import { Circles } from 'react-loader-spinner';
import { ToggleButton, ToggleButtonGroup } from '../../../common/CustomToggleButton';

loadCldrData();
loadMessages(krMessages, "ko-KR");
const selectField = "selected";
const expandField = "expanded";
const dataItemKey = "text";
const textField = "text";
const subItemsField = "items";
const fields = {
    selectField,
    expandField,
    dataItemKey,
    subItemsField,
};

const defaultvalue = new Date()

const ProgressChart = ({height, width}) => {

    const { project_code } = useParams();
    const [progressData, setProgressData] = useState({});
    const [actualpercent, setActualpercent] = useState(0);
    const [planpercent, setPlanpercent] = useState(0);
    const [checked, setChecked] = React.useState("월간");
    const [selectMessage, setSelectMessage] = React.useState("");
    const [value_area, setValue2] = useState({ text: '' });
    const [value, setValue] = useState({ text: '' });
    const [value3, setValue3] = useState(null);
    const [expanded, setExpanded] = useState(['']);
    const [datevalue, setValueDate] = useState(defaultvalue);
    const [loading, setLoading] = useState(true);


    const handleDateChange = (event) => {
        setValueDate(event.target.value);
    };
    

    const handleChange3 = (event) => {
        setValue3(event.target.value);
    };

    const selectdata = progressData &&progressData.selectdata
    const selectdata2 = progressData &&progressData.selectdata2
    const selectdata3 = progressData &&progressData.selectdata3


    var dataarea = selectdata && selectdata, keys = ['Area'], gresult = [], temp = { _: gresult };
    dataarea && dataarea.forEach(function (a) {
        keys.reduce(function (r, k) {
            if (!r[a[k]]) {
                r[a[k]] = { _: [] };
                r._.push({ text: a['Area'], items: r[a[k]]._ });
            }
            return r[a[k]];
        }, temp)._.push({ text:a.Subarea });
    });

    var datad = selectdata2 && selectdata2, keys = ['Discipline'], gresult2 = [], temp = { _: gresult2 };
    datad && datad.forEach(function (a) {
        keys.reduce(function (r, k) {
            if (!r[a[k]]) {
                r[a[k]] = { _: [] };
                r._.push({ text: a['Discipline'], items: r[a[k]]._ });
            }
            return r[a[k]];
        }, temp)._.push({ text: a.SubDiscipline });
    });



    const [filter, setFilter] = React.useState({
        value: "",
        operator: "contains",
    });

    const onChange = (event) => setValue2(event.value);
    const onChange2 = (event) => setValue(event.value);

    const onExpandChange = React.useCallback(
        (event) => setExpanded(expandedState(event.item, dataItemKey, expanded)),
        [expanded]
    );


    const treeData = React.useMemo(
        () =>
            processTreeData(
                gresult,
                {
                    expanded,
                    value_area,
                    filter
                },
                fields
            ),
        [expanded, value_area, gresult, filter]
    );


    const treeData2 = React.useMemo(
        () =>
            processTreeData(
                gresult2,
                {
                    expanded,
                    value,
                    filter
                },
                fields
            ),
        [expanded, value, gresult2, filter]
    );


const onFilterChange = (event) => setFilter(event.filter);


useEffect(() => {
    const fetchData = async (reportName) => {
        try {
            const res = await axios.get(`/progress1?project=${project_code}&value_area=${value_area === null ? 'deselect':value_area.text }&value=${value === null ? 'deselect':value.text}&value3=${value3}&datevalue=${datevalue}`);
            setProgressData(res.data);
            setPlanpercent(res.data.planpercent)
            setActualpercent(res.data.actualpercent)
            setLoading(false);
            setSelectMessage(res.data.oldproject?"성상을 고르세요":"소공종을 고르세요")

        } catch (error) {
            console.error(`${reportName} 데이터 가져오기 중 오류 발생:`, error);
            return []; // 오류 발생 시 빈 배열 반환
        }
    };
    fetchData();
}, [ project_code, value3, value_area, value,datevalue]);

const result_last = checked === '월간' ? 
progressData&&progressData.month : 
progressData&&progressData.week

  const handleChange =  (event, selectedValue) => {
    if (selectedValue !== null) {
        setChecked(selectedValue);
    }
};



  const columnItems = [
    {
      name: checked ==="월간"?"월간 계획":"주간 계획",
      field_name: "PV2",
      color: "#EB7A25",
    },
    {
      name:  checked ==="월간"?"월간 실적":"주간 실적",
      field_name: "EV2",
      color: "rgb(103, 148, 220)",
    },
    
  ];
   
  const lineItems = [
    {
      name: "누적 계획",
      field_name: "CC_PV",
      color: "#EB7A25",
    },
    {
      name: "누적 실적",
      field_name: "CC_EV",
      color: "rgb(103, 148, 220)",
    },
];    

const maxWidth =result_last &&  width*0.6/result_last.length;
const tooltiptitle = `${dayjs(datevalue).format('YYYY년 MM월 DD일')}까지 일할 계산된 계획값입니다.`
const tooltiptitle2 = `실적은 최신 실적(주로 전일 작업일보)을 한시간마다 집계합니다.`

if (loading) {
    return <div className="loadingContainer">
    <Circles ariaLabel="loading" color="#00BFFF" height={100} width={100} />
  </div>;;
  }

    return (
        <>

        <GridLayout style={{paddingTop:25}}
              rows={[
                {
                    height: '45%',
                    height: '55%',
                },

            ]}

            cols={[
                {
                    width: '30%',
                }, {
                    width: '70%',
                },
            ]}
            gap={{
                rows: 0,
                cols: 0,
            }}>

                <div style={{ position: 'absolute', top: '70px', right: '12px', transform: 'translateY(-50%)', zIndex: '99999999' }} >
                        <ToggleButtonGroup size="small" onChange={handleChange} value={checked} exclusive aria-label="baseunit">

                            <ToggleButton value="월간" aria-label="weekly">
                                월간
                            </ToggleButton>
                            <ToggleButton value="주간" aria-label="monthly">
                                주간
                            </ToggleButton>
                        </ToggleButtonGroup>
                </div>

            <div style={{ position: 'absolute', right: 16, fontSize: '0.8rem', top:8 }}>
            <LocalizationProvider language="ko-KR">
                <IntlProvider locale="ko-KR">
                <DatePicker format={"yyyy년 MM월 dd일 EEEE"} onChange={handleDateChange} value={datevalue} />
                </IntlProvider>
            </LocalizationProvider>
            </div>
            <GridLayoutItem col={1} row={1} style={{minHeight:180}}>
                <div style={{ position: 'relative' }}>
                    <div style={{ position: 'absolute', top: '50px', left: '50%', transform: 'translateX(-50%)' }}>
                        <div style={{ textAlign: 'center', color: '#363945' }}>Dev.</div>

                        <div style={{ textAlign: 'center', fontSize: '2rem', fontWeight: 'bold' }}>

                                <span style={{ color: actualpercent - planpercent > 0 ?'#00539C':'#9E1030' }}>{(actualpercent - planpercent).toFixed(2)}</span>%</div>
                    </div>
                        <div style={{ position: 'absolute', top: '120px', left: '8px' }}>
                            <div style={{ color: '#939597', fontSize: '1rem', float: 'left' }} title={tooltiptitle}>Plan</div>
                            <div style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>  {planpercent.toFixed(2)}%</div>
                        </div>
                    <div style={{ position: 'absolute', top: '120px', right: '8px' }}>
                        <div style={{ textAlign: 'center', color: '#939597', fontSize: '1rem' }}title={tooltiptitle2}>Actual</div>
                        <div style={{ textAlign: 'center', fontSize: '1.5rem', fontWeight: 'bold', color: '#525252' }}>{actualpercent.toFixed(2)}%</div>
                    </div>
                </div>
            </GridLayoutItem>
            <GridLayoutItem col={1} row={2}>

                    <div>
                        {/* <div style={{ marginLeft: '5px' }}>Area</div> */}
                        <DropDownTree
                            style={{
                                width: "100%", marginRight: '5px', marginTop:18
                            }}
                            data={treeData}
                            value={value_area}
                            onChange={onChange}
                            placeholder="Area를 선택하세요"
                            textField={textField}
                            dataItemKey={dataItemKey}
                            selectField={selectField}
                            expandField={expandField}
                            onExpandChange={onExpandChange}
                            filterable={true}
                            onFilterChange={onFilterChange}
                            filter={filter.value}
                        />
                    </div>

                    <div>
                        <div style={{ marginLeft: '5px' }}>
                            {/* 공종 */}
                        </div>

                        <DropDownTree
                            style={{
                                width: "100%", marginRight: '5px', marginTop:18
                            }}
                            data={treeData2}
                            value={value}
                            onChange={onChange2}
                            placeholder="공종을 선택하세요"
                            textField={textField}
                            dataItemKey={dataItemKey}
                            selectField={selectField}
                            expandField={expandField}
                            onExpandChange={onExpandChange}
                            filterable={true}
                            onFilterChange={onFilterChange}
                            filter={filter.value}
                        />
                    </div>
                    <div>
                        {/* <div style={{ marginLeft: '5px' }}>성상</div> */}
                        <ComboBox
                            style={{
                                width: "100%", marginRight: '5px', marginTop:18
                            }}
                            data={selectdata3}
                            placeholder={selectMessage}
                            value={value3}
                            onChange={handleChange3}
                            className='disc'
                        />
                    </div>
            </GridLayoutItem>
            <GridLayoutItem col={2} row={1} rowSpan={2}>
            {result_last && (
                <SCurveChart data={result_last} maxWidth={maxWidth} columnItems={columnItems} lineItems={lineItems}chart_id={"scurve"} height={3*height/6.5-80}category={"date"}lineLabel={true}  />
            )}
                </GridLayoutItem>
        </GridLayout>
        </>
    );
};

export default ProgressChart

