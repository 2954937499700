import React, { useEffect, useState, useLayoutEffect } from 'react';
import _ from 'lodash';
import {
  Card,
  CardTitle,
  CardBody,
  GridLayout, GridLayoutItem
} from "@progress/kendo-react-layout";
import axios from "../../../common/axios";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import * as am5 from "@amcharts/amcharts5";
import * as am5radar from "@amcharts/amcharts5/radar";
import * as am5xy from "@amcharts/amcharts5/xy";
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'; // isSameOrAfter 플러그인을 가져옵니다.
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'; //
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';
import * as am5percent from "@amcharts/amcharts5/percent";
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import MuiToggleButton from '@mui/material/ToggleButton';
import MuiToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import PieChart from '../../common/nivopie';
import { useParams } from 'react-router-dom';


const QualityTile = ({ height }) => {
  const [chartData1, setData1] = useState([]);
  const [chartData2, setData2] = useState([]);
  const { project_code } = useParams();


  const defaultvalue = new Date();
  const [datevalue, setDateValue] = React.useState([
    dayjs(dayjs(defaultvalue).subtract(1, 'years').format("YYYY-MM-DD")),
    dayjs(dayjs(defaultvalue).format("YYYY-MM-DD")),
  ]);

  const startDate = dayjs(datevalue[0]).format("MM-DD-YYYY");
  const endDate = dayjs(datevalue[1]).format("MM-DD-YYYY");





  useEffect(() => {
    const fetchData = async () => {
      const res3 = await axios.get(`/getprojectlist`)

      const project_name = res3.data.data.find(vf => vf.projectnumber === project_code).projectname

      const res = await axios.get(`/getqData?start=${startDate}&finish=${endDate}&type="시공"&project=${project_name}`)
      const res2 = await axios.get(`/getqData?start=${startDate}&finish=${endDate}&type="감리"&project=${project_name}`)

      if (res.data.success) {
        setData1(res.data.data.map((v) => ({ ...v, color: v.id === "합격건수" ? "#1FD12F" : "#E0E0E0" })))
        setData2(res2.data.data.map((v) => ({ ...v, color: v.id === "합격건수" ? "#1FD12F" : "#E0E0E0" })))
      } else {
        setData1([])
        setData2([])
      }
    };
    fetchData();

  }, [project_code, startDate, endDate]);




  return (
    <>
      <GridLayout style={{ height: 2 * (height / 6.5) - 70 }}
        rows={[
          {
            height: "10%",
          },
          {
            height: "90%",
          },
        ]}
        cols={[
          {
            width: '50%',
          },
          {
            width: '50%',
          },
        ]}
        gap={{
          rows: 10,
          cols: 0,
        }}>



        {/* 날짜 선택기 */}
        <div style={{
          position: 'absolute', // Use relative positioning
          top: 8,
          right: 8,
          float: 'right',
        }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['SingleInputDateRangeField']}>
              <div style={{ minWidth: 200 }}>
                <DateRangePicker
                  slots={{ field: SingleInputDateRangeField }}
                  name="allowedRange"
                  format='YYYY-MM-DD'
                  value={datevalue}
                  onChange={(newValue) => setDateValue(newValue)}
                  className='daterange'
                />
              </div>
            </DemoContainer>
          </LocalizationProvider>
          {/* 프로젝트 선택과 날짜 선택기를 상단 왼쪽에 배치 */}

        </div>


        <GridLayoutItem row={2} col={1} colSpan={1}>
          <div style={{ textAlign: 'center', marginBottom: -20 }}>시공 품질활동</div>

          <PieChart
            chartdata={chartData1}
            totalSum={_.sumBy(chartData1, 'value')}
          />

        </GridLayoutItem>
        <GridLayoutItem row={2} col={2} colSpan={1}>
          <div style={{ textAlign: 'center', marginBottom: -20 }}>감리 품질활동</div>



          <PieChart
            chartdata={chartData2}
            totalSum={_.sumBy(chartData2, 'value')}
          />
        </GridLayoutItem>

      </GridLayout>



    </>);
}

export default QualityTile
