import React, { useEffect, useMemo, useState } from "react";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import axios from "../../../common/axios";
import QualityGaugeChart from "./qualityGaugeChart";

const QualityRate1 = ({
  height,
  targetPhase = undefined,
  mainTargetProject = undefined,
}) => {
  const [projectList, setProjectList] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const request_project_list = await axios
        .get("/getQualityPjtList")
        .then((data) =>
          data.data.data.map((c) => {
            const { projectnumber, projectname, contract_data } = c;

            return { label: projectname, value: projectnumber, contract_data };
          })
        )
        .catch([]);

      setProjectList(request_project_list);

      const request_pqi_data = await axios
        .get(`/getQualityPQIData`)
        .then((data) => data.data.data)
        .catch([]);

      setData(request_pqi_data);
    };

    fetchData();
  }, []);

  const [targetProject, setTargetProject] = useState({
    label: "전체",
    value: "all",
  });

  const filterPqiData = useMemo(() => {
    const result = {
      ncr: [],
      inspection_log: [],
      factory_inspection: [],
      material_inspection: [],
      inspection_request: [],
      completion_inspection: [],
    };

    let copy_data = [...data];

    if (targetPhase === undefined) {
      if (targetProject.value !== "all") {
        const find_target_data = data.find(
          (c) => c.projectnumber === targetProject.value
        );

        if (!find_target_data) {
          return {
            ...result,
          };
        }

        return find_target_data.data;
      }
    }

    if (targetPhase !== undefined) {
      if (
        targetPhase?.value &&
        targetPhase?.value !== "all" &&
        mainTargetProject === null
      ) {
        copy_data = copy_data.filter((c) => {
          const { projectname, phase, projectnumber } = c;
          return targetPhase.label === phase;
        });
      }

      if (
        targetPhase?.value &&
        targetPhase?.value !== "all" &&
        mainTargetProject !== null
      ) {
        copy_data = copy_data.filter((c) => {
          const { projectname, phase, projectnumber } = c;
          return mainTargetProject.value === projectnumber;
        });
      }
    }

    copy_data.forEach((c) => {
      const {
        data: {
          ncr,
          inspection_log,
          factory_inspection,
          material_inspection,
          inspection_request,
          completion_inspection,
        },
      } = c;

      result.ncr.push(...ncr);
      result.inspection_log.push(...inspection_log);
      result.factory_inspection.push(...factory_inspection);
      result.material_inspection.push(...material_inspection);
      result.inspection_request.push(...inspection_request);
      result.completion_inspection.push(...completion_inspection);
    });

    return result;
  }, [targetProject, data, targetPhase, mainTargetProject]);

  const pqi_value = useMemo(() => {
    const {
      ncr,
      inspection_log,
      factory_inspection,
      material_inspection,
      inspection_request,
      completion_inspection,
    } = filterPqiData;

    const inspection_actual = inspection_log.filter(
      (c) => c.PreviousAction === "시공사 검사결과 입력 요청"
    ).length;

    const inspection_approval = inspection_log.filter(
      (c) => c.PreviousAction === "시공사 합격"
    ).length;

    const inspection_point2 = returnZeroIfInvalid(
      (inspection_approval / inspection_actual) * 100
    );

    const inspection_point = adjust_inspection_approval_point(
      returnZeroIfInvalid(inspection_point2)
    );

    const ncr_accrual_average_actual = [
      ...factory_inspection,
      ...material_inspection,
      ...inspection_request,
      ...completion_inspection,
    ].length;

    const ncr_accrual_average_value = returnZeroIfInvalid(
      ncr.length / ncr_accrual_average_actual
    );

    const ncr_accrual_average_point = pointRange(
      "ncr_accrual_average",
      25 - 25 * ncr_accrual_average_value
    );

    const action_on_time_count = ncr.filter(
      (c) => c.NCRActionDuration <= 5
    ).length;

    const action_on_time_acutal = ncr.length;

    const action_on_time_point = adjust_action_on_time_point(
      returnZeroIfInvalid((action_on_time_count / action_on_time_acutal) * 100)
    );

    const ncr_count_point = adjust_ncr_count_point(
      ncr.filter((c) => c.NCRCategoryTxt === "검사 외").length
    );

    return (
      inspection_point +
      ncr_accrual_average_point +
      action_on_time_point +
      ncr_count_point
    );
  }, [filterPqiData]);

  return (
    <GridLayout
      rows={[
        {
          height: height - 60,
        },
        {
          height: 60,
        },
      ]}
      cols={[
        {
          width: "100%",
        },
      ]}
      gap={{
        rows: 0,
        cols: 0,
      }}
      style={{ position: "relative" }}
    >
      {targetPhase === undefined && (
        <div className="quality-filter-btn-box">
          <DropDownList
            className="quality-detail-filter-drop-down-list"
            data={[
              {
                label: "전체",
                value: "all",
              },
              ...projectList,
            ]}
            value={targetProject}
            textField="label"
            dataItemKey="value"
            onChange={(e) => setTargetProject(e.value)}
          />
        </div>
      )}
      <GridLayoutItem row={1} col={1} style={{ position: "relative" }}>
        <QualityGaugeChart
          id={"id"}
          height={height - 60}
          pqi_value={pqi_value}
        />
      </GridLayoutItem>
      <GridLayoutItem row={2} col={1} style={{ position: "relative" }}>
        <PointLegendTable />
      </GridLayoutItem>
    </GridLayout>
  );
};

export default QualityRate1;

// function calculateGrade(score) {
//   if (score >= 90) {
//     return "A+";
//   } else if (score >= 85) {
//     return "A";
//   } else if (score >= 75) {
//     return "B";
//   } else if (score >= 70) {
//     return "C";
//   } else if (score >= 50) {
//     return "D";
//   } else if (score >= 25) {
//     return "F";
//   } else {
//     return "FF";
//   }
// }

const pointRange = (name, value) => {
  if (value < 0) {
    return 0;
  }

  if (name === "inspection_approval") {
    if (value >= 40) {
      return 40;
    }
  }

  if (name === "ncr_accrual_average") {
    if (value >= 25) {
      return 25;
    }
  }

  if (name === "action_on_time") {
    if (value >= 30) {
      return 30;
    }
  }

  if (name === "ncr_count") {
    if (value >= 5) {
      return 5;
    }
  }

  return value;
};

const returnZeroIfInvalid = (value) => {
  if (value === Infinity || isNaN(value) || value === false) {
    return 0;
  }
  return value;
};

const adjust_inspection_approval_point = (value) => {
  if (value >= 99) {
    return 40;
  }

  if (value < 70) {
    return 10;
  }

  return value - 60;
};

const adjust_action_on_time_point = (value) => {
  if (value < 90) {
    return 0;
  }

  return 30 - (100 - value);
};

const adjust_ncr_count_point = (value) => {
  const value2 = 5 - value;

  if (value2 <= -5) {
    return -5;
  }

  return value2;
};

const PointLegendTable = () => {
  return (
    <table className="point-legend-table">
      <tbody>
        <tr>
          <td className="point-legend-table-top">
            <div className="point-legend-table-top-box">
              <div
                className="point-legend-point-label"
                style={{ backgroundColor: "#00B050" }}
              />
              <div>A</div>
            </div>
          </td>
          <td className="point-legend-table-top">
            <div className="point-legend-table-top-box">
              <div
                className="point-legend-point-label"
                style={{ backgroundColor: "#92D050" }}
              />
              <div>B</div>
            </div>
          </td>
          <td className="point-legend-table-top">
            <div className="point-legend-table-top-box">
              <div
                className="point-legend-point-label"
                style={{ backgroundColor: "#E6EB37" }}
              />
              <div>C</div>
            </div>
          </td>
          <td className="point-legend-table-top">
            <div className="point-legend-table-top-box">
              <div
                className="point-legend-point-label"
                style={{ backgroundColor: "#FFCF00" }}
              />
              <div>D</div>
            </div>
          </td>
          <td className="point-legend-table-top">
            <div className="point-legend-table-top-box">
              <div
                className="point-legend-point-label"
                style={{ backgroundColor: "#FF0000" }}
              />
              <div>F</div>
            </div>
          </td>
        </tr>
        <tr>
          <td className="point-legend-table-bottom">
            <div className="point-legend-table-bottom-box">
              <div>K &#62;&#61; 90</div>
            </div>
          </td>
          <td className="point-legend-table-bottom">
            <div className="point-legend-table-bottom-box">
              <div>90 &#62; K &#62;&#61; 80</div>
            </div>
          </td>
          <td className="point-legend-table-bottom">
            <div className="point-legend-table-bottom-box">
              <div>80 &#62; K &#62;&#61; 70</div>
            </div>
          </td>
          <td className="point-legend-table-bottom">
            <div className="point-legend-table-bottom-box">
              <div>70 &#62; K &#62;&#61; 50</div>
            </div>
          </td>
          <td className="point-legend-table-bottom">
            <div className="point-legend-table-bottom-box">
              <div>50 &#62; K</div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );
};
