import React, { useState, useEffect } from 'react';
import {
    GridLayout,
    GridLayoutItem,
} from "@progress/kendo-react-layout";
import _ from 'lodash';
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { DropDownList, MultiSelect } from "@progress/kendo-react-dropdowns";
import axios from '../../../../common/axios';
import { useParams } from 'react-router-dom';
import { FaCircle } from "react-icons/fa";
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { actualPercentState, planPercentState } from "../../../../atom/progressState";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import {
    IntlProvider,
    LocalizationProvider,
    loadMessages,
} from "@progress/kendo-react-intl";
import loadCldrData from "../../../common/loadCldrData";
import krMessages from "../../../common/kr.json";
import moment from 'moment';
import { orderBy } from "@progress/kendo-data-query";
import "../table.css";
import { Chart, ChartCategoryAxisItem, ChartCategoryAxis, ChartArea, ChartLegend, ChartSeries, ChartSeriesItem, ChartValueAxis, ChartValueAxisItem, ChartTooltip, ChartNavigatorSeriesNotesLabel } from "@progress/kendo-react-charts";
import { Circles } from 'react-loader-spinner';

loadCldrData();
loadMessages(krMessages, "ko-KR");

const defaultvalue = new Date()

const ProgressBody = ({ height }) => {

    const { project_code } = useParams();
    const [areaName, setAreaName] = useState([]);
    const [disciplineName, setDisciplineName] = useState([]);
    const [disciplineValue, setDisciplineValue] = useState("건축");
    const [datevalue, setValueDate] = useState(defaultvalue);
    const [actData, setActData] = useState([]);
    const [tableData, setTabledata] = useState([]);
    const [actData2, setActData2] = useState([]);
    const [areaValue, setAreaValue] = useState(null);
    const setPlantPercent = useSetRecoilState(planPercentState);
    const setActualtPercent = useSetRecoilState(actualPercentState);
    const [actualPercentDisc, setActualPercentDisc] = useState(0);
    const [planPercentDisc, setPlanPercentDisc] = useState(0);
    const [issuedata, setIssuedata] = useState([]);
    const [multiValue, setMultiValue] = useState([]);
    const [weekData, setWeekData] = useState([]);
    const [loading, setLoading] = useState(true);

    // const areaValue = useRecoilValue(areaState)
    

    useEffect(() => {
        const fetchData = async () => {

            const wbs2 = areaValue && areaValue.name.split('-')[0] || '';
            const wbs3 = areaValue && areaValue.name.split('-')[1] || '';

            try {
                const [actResponse, areaResponse, disciplineResponse, progressBaseResponse] = await Promise.all([
                    axios.get(`/getactdata?project=${project_code}&wbs2=${wbs2}&wbs3=${wbs3}`),
                    axios.get(`/getarea?project=${project_code}`),
                    axios.get(`/getdiscipline?project=${project_code}`),
                    axios.post(`/getbprecordsdash?path=${project_code}`, { "bpname": "Project Information" })
                ]);

                const progressBase = progressBaseResponse.data.data[0] && progressBaseResponse.data.data[0]['ProgressBaseType'];

                if (actResponse.data.success) {
                    const actDataFetched = actResponse.data.data
                        .filter(v => v._bp_lineitems !== undefined)
                        .map(v => ({
                            Activity: v.uuu_P6ActivityId + v.PALStepNM,
                            Type: v.WBSCodeNMLv6,
                            uniq: v.WBSCodeNMLv4 + "//" + v.WBSCodeNMLv6,
                            disc: v.WBSCodeNMLv4,
                            Area: v.WBSCodeNMLv2,
                            Subarea: v.WBSCodeNMLv3,
                            PALStepPlanStart: v.PALStepPlanStart,
                            PALStepPlanFinish: v.PALStepPlanFinish,
                            WeightValue: v.WeightValue,
                            curve: v.ResourceCurveName,
                            PALStepActualStart: v.PALStepActualStart,
                            PALStepActualFinish: v.PALStepActualFinish,
                            PALPlanQty: progressBase === '예상물량' && v.PALForecastQty !== 0 && v.PALPlanQty !== v.PALForecastQty ? v.PALForecastQty : v.PALPlanQty,
                            plandateList: v._bp_lineitems.filter(f => f.uuu_tab_id === '일별계획물량').map(f => ({
                                date: f.ActLinePlanDO.slice(0, 10),
                                dayWV: v.WeightValue * (f.ActPlanDailyQty / v.PALPlanQty)
                            })),
                            actualdateList: v._bp_lineitems.filter(f => f.uuu_tab_id === '일별실적물량').map(f => ({
                                date: f.ActLineActualDO.slice(0, 10),
                                dayEV: v.WeightValue * (f.ActActualDailyQty / v.PALPlanQty),
                            })),
                            rate: v.PALActualProgress,
                            Evalue: v.PALActualProgress * v.WeightValue / 100,
                            Pvalue: v.PALPlanProgress * v.WeightValue / 100,
                        }));
                    setActData(actDataFetched);
                }
                if (areaResponse.data.success) {
                    const areaData = areaResponse.data.data;
                    setAreaName(areaData);
                    if (areaValue === null) {
                        setAreaValue(areaData[0] || null);
                    }
                }

                if (disciplineResponse.data.success) {
                    const disciplineData = disciplineResponse.data.data;
                    setDisciplineName(disciplineData);
                }

                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);
            }
        };

        fetchData();
    }, [project_code, areaValue]);


    useEffect(() => {
        const actualpercent = _.sumBy(actData, v => _.sumBy(v.actualdateList, 'dayEV')) / _.sumBy(actData, 'WeightValue') * 100;
        const planpercent = _.sumBy(actData, v => _.sumBy(v.plandateList?.filter(v => Number(moment(v.date).format('YYYYMMDD')) <= Number(moment(datevalue).format('YYYYMMDD'))), 'dayWV')) / _.sumBy(actData, 'WeightValue') * 100;

        if (!isNaN(actualpercent)) {
            setActualtPercent(actualpercent);
        }
        if (!isNaN(planpercent)) {
            setPlantPercent(planpercent);
        }
    }, [actData, datevalue]);

    useEffect(() => {
        const filteredData = actData.filter(v => v.disc === disciplineValue);
        setActData2(filteredData);

        const actualPercentDisc = _.sumBy(filteredData, v => _.sumBy(v.actualdateList, 'dayEV')) / _.sumBy(filteredData, 'WeightValue') * 100;
        const planPercentDisc = _.sumBy(filteredData, v => _.sumBy(v.plandateList.filter(v => Number(moment(v.date).format('YYYYMMDD')) <= Number(moment(datevalue).format('YYYYMMDD'))), 'dayWV')) / _.sumBy(filteredData, 'WeightValue') * 100;

        setActualPercentDisc(actualPercentDisc);
        setPlanPercentDisc(planPercentDisc);

        // Transformation for daily_e and daily_p
        const transformData = (data, key) => {
            const result = new Map();
            data.forEach(item => {
                if (item[key]) {
                    item[key].forEach(subItem => {
                        const uniqKey = item.Activity + '//' + subItem.date;
                        result.set(uniqKey, Object.assign(subItem, {
                            [key === 'actualdateList' ? 'dayEV' : 'dayWV']: subItem[key === 'actualdateList' ? 'dayEV' : 'dayWV'],
                            uniq: uniqKey,
                            [key === 'actualdateList' ? 'actualdate' : 'plandate']: subItem.date
                        }));
                    });
                }
            });
            return result;
        };

        const dailyEMap = transformData(filteredData, 'actualdateList');
        const dailyPMap = transformData(filteredData, 'plandateList');
        const actinfoMap = new Map(actData.map(item => [item.Activity, item]));

        const daily_uniq_list = Array.from(new Set([...dailyEMap.keys(), ...dailyPMap.keys()]));
        const daily_list = daily_uniq_list.map(uniq => {
            const [activity, date] = uniq.split('//');
            const item = {
                Activity: activity,
                date: date,
                ...(dailyEMap.get(uniq) || {}),
                ...(dailyPMap.get(uniq) || {}),
                ...actinfoMap.get(activity),
                weekType: moment(date).format("YY년 WW주") + "//" + (actinfoMap.get(activity)?.Type || ''),
                order: moment(date).format('YYYYMMDD')
            };
            return item;
        });

        const sortedDailyList = _.sortBy(daily_list, 'order');

        // Efficient grouping and aggregation
        const weekdata_source = {};
        sortedDailyList.forEach(item => {
            const key = item.weekType;
            if (!weekdata_source[key]) {
                weekdata_source[key] = { week: key.split('//')[0], Type: key.split('//')[1], weekPV: 0, weekEV: 0 };
            }
            weekdata_source[key].weekPV += item.dayWV || 0;
            weekdata_source[key].weekEV += item.dayEV || 0;
        });

        setWeekData(Object.values(weekdata_source));
        setMultiValue([...new Set(Object.values(weekdata_source).map(item => item.Type))].slice(-4));

        var groupbyType =
            _(filteredData)
                .groupBy('uniq')
                .map((objs, key) => ({
                    'Type': key.split('//')[1],
                    '공종': key.split('//')[0],
                    '총물량': _.sumBy(objs, 'WeightValue'),
                    '누적계획물량': (_.sumBy(objs.map(v => v.plandateList).flat(1).filter(v => Number(moment(v.date).format('YYMMDD')) <= Number(moment(datevalue).format('YYMMDD'))), 'dayWV') / _.sumBy(objs, 'WeightValue')) * 100,
                    '일계획물량': (_.sumBy(objs.map(v => v.plandateList).flat(1).filter(v => moment(v.date).format('YYMMDD') === moment(datevalue).format('YYMMDD')), 'dayWV') / _.sumBy(objs, 'WeightValue')) * 100,
                    '누적실적물량': (_.sumBy(objs.map(v => v.actualdateList).flat(1).filter(v => Number(moment(v.date).format('YYMMDD')) <= Number(moment(datevalue).format('YYMMDD'))), 'dayEV') / _.sumBy(objs, 'WeightValue')) * 100,
                    '일실적물량': ((_.sumBy(objs.map(v => v.actualdateList).flat(1).filter(v => moment(v.date).format('YYMMDD') === moment(datevalue).format('YYMMDD')), 'dayEV') === undefined ? 0 : _.sumBy(objs.map(v => v.actualdateList).flat(1).filter(v => moment(v.date).format('YYMMDD') === moment(datevalue).format('YYMMDD')), 'dayEV')) / _.sumBy(objs, 'WeightValue')) * 100,
                }))
                .value();

        const quantity = groupbyType
            .map((v) => ({ ...v, '일일차이': v['일실적물량'] - v['일계획물량'], '누적차이': v['누적실적물량'] - v['누적계획물량'] }))
            .filter(v => v.공종 === disciplineValue);
        setTabledata(quantity);
    }, [disciplineValue, actData]);

    const handleAreaChange = (event) => {
        setAreaValue(event.target.value);
    };

    const handleDisciplineChange = (event) => {
        setDisciplineValue(event.target.value);
    };

    const handleDateChange = (event) => {
        setValueDate(event.target.value);
    };

    const [sort, setSort] = useState([
        { field: '누적차이', dir: 'asc' }]);

    const CustomCellColor = (props) => {
        const field = props.field || "";
        const type = props.dataItem[field];

        const plan = props.dataItem['누적계획물량']
        const actual = props.dataItem['누적실적물량']

        return (
            <>
                <td style={{ display: 'flex', alignItems: 'center', }}>
                    <span>{type}</span>&nbsp;
                    <FaCircle size={12} color={actual - plan < -3 ? '#E13D43' : actual - plan >= 0 ? '#00A170' : '#F49B42'} />
                </td>
            </>

        );
    };
    const CustomCell = ({ field, dataItem }) => {
        const value = dataItem[field];
        return (
            <td style={{ textAlign: 'right', paddingRight: 6 }}>
                {value === null || isNaN(value) ? "" : `${value.toFixed(2)}%`}
            </td>
        );
    };

    const CustomCell2 = ({ field, dataItem }) => {
        const value = dataItem[field];
        return (
            <td style={{ textAlign: 'right', paddingRight: 6 }}>
                {value === null || isNaN(value) ? "" : `${value.toLocaleString()}`}
            </td>
        );
    };


    useEffect(() => {
        const fetchData = async () => {
            const res = await axios.post(`/getbprecordsdash?path=${project_code}`, { "bpname": "주요 이슈사항", "lineitem": "yes" })
            if (res.data.success) {
                const data = _.sortBy(res.data.data.data.filter(v => typeof v._bp_lineitems !== 'undefined').reduce((c, v) => c.concat(v._bp_lineitems.map(o => Object.assign(o, { record_no: v.record_no, MainIssueDO: moment(o.MainIssueDO.slice(0, 10)).format("YY년 MM월 DD일") }))), []).filter(v => v.MainIssueDiscSPD.includes(disciplineValue)), 'record_no').reverse()
                setIssuedata(data[0])
            }
        };
        fetchData();
    }, [disciplineValue, project_code]);

    const handleChangeM = (event) => {
        setMultiValue(event.value);
    };

    let cumpv = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    let cumev = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];


    const categorylabes = (e) => {
        if (multiValue.length >= 4) {
            if (e.index % 3 === 0) {
                return e.value
            } else {
                return ""
            }
        } else {
            return e.value
        }
    }
    const SharedTooltip = (props) => {
        const { points } = props;

        return (
            <div>
                <div>{points[0].category}</div>
                {points.map((point, index) => (
                    <div key={index}>
                        <span>{point.series.name} : {Number(point.value.toFixed(1)).toLocaleString()}%</span>
                    </div>

                ))}
            </div>
        );
    };

    const sharedTooltipRender = (context) => <SharedTooltip {...context} />;
    const weekTypeList = _.uniq(weekData.map(v => v.Type))

    if (loading) {
        return <div className="loadingContainer">
            <Circles ariaLabel="loading" color="#00BFFF" height={100} width={100} />
        </div>;;
    }

    return (<>

        <div style={{ position: 'absolute', right: 16, fontSize: '0.8rem', top: 8 }}>
            <LocalizationProvider language="ko-KR">
                <IntlProvider locale="ko-KR">
                    <DatePicker format={"yyyy년 MM월 dd일 EEEE"} onChange={handleDateChange} value={datevalue} />
                </IntlProvider>
            </LocalizationProvider>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: -12 }}>
            <DropDownList
                style={{
                    width: "18%", marginRight: '8px', marginTop: 8
                }}
                data={_.sortBy(areaName, 'name')}
                textField='name'
                placeholder="Area을 선택하세요"
                value={areaValue}
                onChange={handleAreaChange}
            />

            <DropDownList
                style={{
                    width: "18%", marginRight: '8px', marginTop: 8
                }}
                data={_.sortBy(disciplineName, 'label').map(v => v.label)}
                // textField='label'
                placeholder="공종을 선택하세요"
                value={disciplineValue}
                onChange={handleDisciplineChange}
            />
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '100%' }}>
                <div style={{ display: 'flex', alignItems: 'center', zIndex: 999999 }}>
                    <FaCircle color='#00A170' /><span style={{ fontSize: '0.8rem', marginLeft: 4 }}>on schedule or ahead </span>&nbsp;&nbsp;&nbsp;
                    <FaCircle color='#F49B42' /><span style={{ fontSize: '0.8rem', marginLeft: 4 }}>공정률 지연 3% 미만</span>&nbsp;&nbsp;&nbsp;
                    <FaCircle color='#E13D43' /><span style={{ fontSize: '0.8rem', marginLeft: 4 }}>공정률 지연 3% 이상</span>
                </div>
            </div>

        </div>
        <br />
        <GridLayout style={{ height: (height - 130) }}
            rows={[{ height: '45%' }, { height: '12%' }, { height: '43%' }]}
            cols={[{ width: '65%' }, { width: '35%' }]}
            gap={{ rows: 0, cols: 10 }}>
            <GridLayoutItem col={1} row={1} style={{ position: 'relative' }}>
                <div style={{ color: '#363945', fontWeight: 'bolder', fontSize: '1rem', display: 'flex', alignItems: 'center', lineHeight: '1.2', marginBottom: 6 }}>
                    <span>{disciplineValue && disciplineValue} 공정현황</span> &nbsp;
                    <FaCircle color={actualPercentDisc - planPercentDisc < -3 ? '#E13D43' : actualPercentDisc - planPercentDisc >= 0 ? '#00A170' : '#F49B42'} />
                    <span style={{ fontSize: 'smaller' }}>&nbsp;&nbsp;(계획:{planPercentDisc.toFixed(2)}% 실적:{(isNaN(actualPercentDisc) ? 0 : actualPercentDisc).toFixed(2)}% 차이:{((isNaN(actualPercentDisc) ? 0 : actualPercentDisc) - planPercentDisc).toFixed(2)}%)</span>
                </div>

                {tableData.length > 0 && (
                    <Grid
                        data={orderBy(tableData, sort)}
                        sortable={true}
                        sort={sort}
                        onSortChange={(e) => {
                            setSort(e.sort);
                        }}
                        style={{ width: '100%', height: ((height - 150) * 0.45) }}
                        className='ptable'
                    >
                        <GridColumn
                            field="Type"
                            title="항목"
                            cell={CustomCellColor}
                        />

                        <GridColumn
                            field="총물량"
                            title="총 가중치"
                            cell={CustomCell2}
                        />

                        <GridColumn
                            title="일일"

                        >

                            <GridColumn
                                field="일계획물량"
                                title="일일 계획공정률"
                                cell={CustomCell}

                            />

                            <GridColumn
                                field="일실적물량"
                                cell={CustomCell}
                                title="일일 실적공정률"
                            />

                            <GridColumn
                                field="일일차이"
                                cell={CustomCell}
                                title="차이"
                            />

                        </GridColumn>

                        <GridColumn
                            title="누적"

                        >
                            <GridColumn
                                field="누적계획물량"
                                cell={CustomCell}
                                title="누계 계획공정률"
                            />

                            <GridColumn
                                field="누적실적물량"
                                cell={CustomCell}
                                title="누계 실적공정률"
                            />


                            <GridColumn
                                field="누적차이"
                                cell={CustomCell}
                                title="차이"
                            />
                        </GridColumn>
                    </Grid>
                )}
            </GridLayoutItem>
            <GridLayoutItem col={2} rowSpan={1} row={1} style={{ position: 'relative' }}>
                <div style={{ color: '#363945', fontWeight: 'bolder', fontSize: '1rem', marginBottom: 6 }}>
                    {disciplineValue} 주요 이슈 사항
                </div>

                <div style={{ overflowY: 'auto', padding: 8, height: ((height - 260) / 2) - 40 }}>
                    {issuedata && issuedata['MainIssueDO'] && issuedata['MainIssueDO']}
                    <div dangerouslySetInnerHTML={{ __html: issuedata && issuedata['MainIssueDesc'] && issuedata['MainIssueDesc'].replaceAll("\\n", '<br/>') }}></div>
                </div>

            </GridLayoutItem>
            <GridLayoutItem col={1} colSpan={2} row={2} style={{ position: 'relative' }}>
                <span style={{ position: 'absolute', bottom: 0, color: '#363945', fontWeight: 'bolder', fontSize: '1rem' }}>&nbsp;{disciplineValue && disciplineValue} 항목별 공정률</span>
                <MultiSelect data={weekTypeList} value={multiValue} onChange={handleChangeM} style={{ position: 'absolute', left: '20%', bottom: 0, width: '80%' }} />
            </GridLayoutItem>
            <GridLayoutItem col={1} colSpan={2} row={3} style={{ position: 'relative' }}>
                <GridLayout style={{ height: (height - 260) * 0.5 }}
                    rows={[
                        {
                            height: '100%',
                        },
                    ]}
                    cols={multiValue.map((v) => ({ width: (100 / multiValue.length) + '%' }))}
                    gap={{
                        rows: 0,
                        cols: 0,
                    }}>
                    {multiValue.map((v, index) => (
                        <GridLayoutItem col={index + 1} row={1} key={index} style={{ position: 'relative' }}>
                            <div style={{ textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
                                <span>{v} </span> &nbsp;
                                <FaCircle size={12}
                                    color={_.get(tableData.filter(f => f.Type === v)[0], '누적차이') < -3 ? '#E13D43' :
                                        _.get(tableData.filter(f => f.Type === v)[0], '누적차이') >= 0 ? '#00A170' : '#F49B42'}
                                />
                            </div>
                            <div style={{ textAlign: 'center', fontSize: '0.8rem' }}>
                                <span>계획:
                                    {
                                        _.get(tableData.filter(f => f.Type === v)[0], '누적계획물량') && (_.get(tableData.filter(f => f.Type === v)[0], '누적계획물량')).toFixed(1)
                                    }% </span>

                                <span>실적:
                                    {
                                        _.get(tableData.filter(f => f.Type === v)[0], '누적실적물량') && (_.get(tableData.filter(f => f.Type === v)[0], '누적실적물량')).toFixed(1)
                                    }% </span>

                                <span>차이:
                                    <span style={{
                                        fontWeight: 'bolder', color:
                                            _.get(tableData.filter(f => f.Type === v)[0], '누적차이') >= 0 ? '#34568B' : '#CD212A'

                                    }}>

                                        {_.get(tableData.filter(f => f.Type === v)[0], '누적차이') >= 0 ?
                                            <span>	&#40;&#43;&#41;{(_.get(tableData.filter(f => f.Type === v)[0], '누적차이')).toFixed(1)}</span>
                                            :
                                            <span>	&#40;&#45;&#41;{Math.abs(_.get(tableData.filter(f => f.Type === v)[0], '누적차이')).toFixed(1)}</span>
                                        }%</span></span>
                            </div>

                            <Chart transitions={false} >
                                {/* <ChartTitle text={`${v}\n계획: 실적: 차이:`} font="0.8rem pretendard-R" /> */}
                                <ChartArea height={(height - 260) * 0.42} margin={10} />
                                <ChartLegend position="bottom" orientation="horizontal" labels={{ font: "0.8rem pretendard-R" }} />
                                <ChartValueAxis>
                                    <ChartValueAxisItem name='월간' labels={{ font: "0.7rem pretendard-R" }} visible={true} majorTicks={{ step: 100, }} minorTicks={{ step: 100, }} majorGridLines={{ step: 10 }} minorGridLines={{ step: 10 }} />
                                    <ChartValueAxisItem name='누적(%)' min={0} max={110} labels={{ font: "0.7rem pretendard-R" }} majorGridLines={{ step: 10 }} minorGridLines={{ step: 10 }} visible={true} />
                                </ChartValueAxis>
                                <ChartTooltip shared={true} render={sharedTooltipRender} />
                                <ChartSeries>

                                    <ChartSeriesItem
                                        color='#DBF3FF'
                                        type="column"
                                        data={weekData.filter(f => f.Type === v)
                                            .map(({ week, weekPV, weekEV }) =>
                                            ({
                                                week, weekEV, weekPV,
                                                weekPV2: weekPV / _.sumBy(actData2.filter(f => f.Type === v), 'WeightValue') * 100
                                            }))
                                            // .map((v)=>({...v, C_PV:v.C_PV === null || v.C_PV === undefined ?0:v.C_PV}))
                                        }
                                        field="weekPV2"
                                        categoryField="week"
                                        name="주간 계획"
                                        autoFit={true}
                                        axis='월간'
                                    />


                                    <ChartSeriesItem
                                        color='#42B7F4'
                                        type="column"
                                        data={weekData.filter(f => f.Type === v)
                                            .map(({ week, weekPV, weekEV }) =>
                                            ({
                                                week, weekEV, weekPV,
                                                weekEV2: weekEV / _.sumBy(actData2.filter(f => f.Type === v), 'WeightValue') * 100
                                            }))
                                            // .map((v)=>({...v, C_PV:v.C_PV === null || v.C_PV === undefined ?0:v.C_PV}))
                                        }
                                        field="weekEV2"
                                        categoryField="week"
                                        name="주간 실적"
                                        autoFit={true}
                                        axis='월간'

                                    />

                                    <ChartSeriesItem
                                        color='#B1CCE4'
                                        type="line"
                                        data={weekData.filter(f => f.Type === v
                                            // && Number(f.week.slice(0,2)+f.week.slice(4,6)+f.week.slice(8,9)) <= Number(moment(datevalue).format("YYMM") + Math.ceil((new Date(datevalue)).getDate() / 7) )
                                        ).map(({ week, weekPV, weekEV }) =>
                                        ({
                                            week, weekEV, weekPV,
                                            C_EV: cumev[index] += (isNaN(weekEV) ? 0 : weekEV / _.sumBy(actData2.filter(f => f.Type === v), 'WeightValue')) * 100,
                                            C_PV: cumpv[index] += (isNaN(weekPV) ? 0 : weekPV / _.sumBy(actData2.filter(f => f.Type === v), 'WeightValue')) * 100
                                        }))
                                            // .map((v)=>({...v, C_PV:v.C_PV === null || v.C_PV === undefined ?0:v.C_PV}))
                                        }
                                        field="C_PV"
                                        categoryField="week"
                                        name="누적 계획"
                                        autoFit={true}
                                        axis='누적(%)'
                                        markers={{ visible: false }}
                                    />
                                    <ChartSeriesItem
                                        color='#5E8EFF'
                                        type="line"
                                        data={weekData.filter(f => f.Type === v
                                            && Number(f.week.slice(0, 2) + f.week.slice(4, 6)) <= Number(moment(datevalue).format("YYWW"))
                                        ).map(({ week, weekPV, weekEV }) => ({ week, weekEV, weekPV, C_EV: (cumev[index] += (weekEV)) / _.sumBy(actData2.filter(f => f.Type === v), 'WeightValue') * 100, C_PV: cumpv[index] += (weekPV / _.sumBy(actData2.filter(f => f.Type === v), 'WeightValue')) * 100 }))}
                                        field="C_EV"
                                        categoryField="week"
                                        name="누적 실적"
                                        autoFit={true}
                                        axis='누적(%)'
                                        markers={{ visible: false }}

                                    />

                                </ChartSeries>
                                <ChartCategoryAxis>
                                    <ChartCategoryAxisItem
                                        labels={{ font: "0.7rem pretendard-R", rotation: { angle: 'auto' }, content: categorylabes }}
                                        axisCrossingValue={[0, weekData.filter(f => f.Type === v).length]}
                                        majorGridLines={{ step: 100 }}
                                        minorGridLines={{ step: 100 }}
                                        plotBands={[{
                                            from:
                                                _.findIndex(weekData.filter(f => f.Type === v), { 'week': moment(datevalue).format("YY년 WW주") }),
                                            to: _.findIndex(weekData.filter(f => f.Type === v), { 'week': moment(datevalue).format("YY년 WW주") }) + 1,
                                            color: 'lightgray',
                                            opacity: 0.35
                                        }]}
                                    >
                                    </ChartCategoryAxisItem>
                                </ChartCategoryAxis>

                            </Chart>

                        </GridLayoutItem>
                    ))}
                </GridLayout>


            </GridLayoutItem>

        </GridLayout>
    </>);

}
export default ProgressBody