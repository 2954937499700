import React, { useEffect, useLayoutEffect, useState } from 'react'
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { ComboBox } from '@progress/kendo-react-dropdowns';
import { ToggleButton, ToggleButtonGroup } from '../../common/CustomToggleButton';

const SafetyBarChart = ({ height }) => {
    const [tlist, settlist] = useState([]);
    const [tstate, settState] = useState(tlist[0]);
    const [checked, setChecked] = useState("월간");
    const handletChange = (event) => {
        settState(event.target.value);
    };
    const handleChangeX =  (event, selectedValue) => {
        if (selectedValue !== null) {
            setChecked(selectedValue);
        }
    };


    useEffect(() => {

        var root = am5.Root.new("chartdiv");


        // Set themes
        // https://www.amcharts.com/docs/v5/concepts/themes/
        root.setThemes([
            am5themes_Animated.new(root)
        ]);

        // Create chart
        // https://www.amcharts.com/docs/v5/charts/xy-chart/
        var chart = root.container.children.push(am5xy.XYChart.new(root, {
            panX: false,
            panY: false,
            // wheelX: "panX",
            // wheelY: "panY",
            paddingLeft: 0,
            layout: root.verticalLayout
        }));

        chart.zoomOutButton.set("forceHidden", true);
        // if (Object.keys(newData[0]).length - 1 > 10) {
        //     var scrollbar = chart.set("scrollbarY", am5.Scrollbar.new(root, {
        //         orientation: "vertical",
        //         // maxHeight: 3,
        //         // start: 0.95
        //     }));
        //     scrollbar.startGrip.set("scale", 0.7);
        //     scrollbar.endGrip.set("scale", 0.7);
        // }



        // Add legend
        // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
        var legend = chart.children.push(am5.Legend.new(root, {
            centerX: am5.p50,
            x: am5.p50
        }))


        // Data



        // Create axes
        // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
        var yAxis = chart.yAxes.push(am5xy.CategoryAxis.new(root, {
            categoryField: "type",
            renderer: am5xy.AxisRendererY.new(root, {
                // inversed: true,
                cellStartLocation: 0,
                cellEndLocation: 0.8,
                minorGridEnabled: true,
                minGridDistance: 1
            })
        }));

        yAxis.data.setAll(data.reverse());
        // yAxis.data.setAll(am5.array.copy(series.dataItems).reverse());

        // yAxis.data.setAll(newData);
        yAxis.get("renderer").labels.template.setAll({
            fontSize: "9px"
        });

        var xAxis = chart.xAxes.push(am5xy.ValueAxis.new(root, {
            renderer: am5xy.AxisRendererX.new(root, {
                strokeOpacity: 0,
                minGridDistance: 50,

            }),
            min: 0
        }));

        xAxis.get("renderer").labels.template.setAll({
            // fontSize: "11px"
            visible: false
        });


        yAxis.get("renderer").grid.template.set("forceHidden", true);
        xAxis.get("renderer").grid.template.set("forceHidden", true);

        // Add series
        // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
        function createSeries(field, name) {
            var series = chart.series.push(am5xy.ColumnSeries.new(root, {
                name: name,
                xAxis: xAxis,
                yAxis: yAxis,
                valueXField: field,
                categoryYField: "type",
                sequencedInterpolation: true,
                tooltip: am5.Tooltip.new(root, {
                    pointerOrientation: "horizontal",
                    labelText: "[bold]{name}[/]\n{categoryY}: {valueX}"
                })
            }));

            series.columns.template.setAll({
                height: am5.p100,
                strokeOpacity: 0
            });


            series.bullets.push(function () {
                return am5.Bullet.new(root, {
                    locationX: 1,
                    locationY: 0.5,
                    sprite: am5.Label.new(root, {
                        centerY: am5.p50,
                        text: "{valueX}",
                        populateText: true,
                        fontSize: "11px"
                    })
                });
            });



            series.data.setAll(data);
            // series.data.setAll(newData);
            series.appear();

            return series;
        }

        Object.keys(data[0]).forEach(c => {
            if (c !== "type") {
                createSeries(c, c);
            }
        });

        // Object.keys(newData[0]).forEach(c => {
        //     if (c !== "type") {
        //         createSeries(c, c);
        //     }
        // });

        // createSeries("2022", "2022");
        // createSeries("2023", "2023");


        // Add legend
        // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
        var legend = chart.children.push(am5.Legend.new(root, {
            centerX: am5.p50,
            x: am5.p50
        }));

        // legend.data.setAll(chart.series.values);


        // Add cursor
        // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
        var cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
        // cursor.lineY.set("forceHidden", true);
        // cursor.lineX.set("forceHidden", true);



        // Make stuff animate on load
        // https://www.amcharts.com/docs/v5/concepts/animations/
        chart.appear(1000, 100);

        return () => {
            root && root.dispose();
        };
    }, [])

    return (
        <>
            <div style={{ position: 'absolute', top: '24px', right: '120px', transform: 'translateY(-50%)', zIndex: '99999999' }} >
                <ToggleButtonGroup size="small" onChange={handleChangeX} value={checked} exclusive aria-label="baseunit">
                    <ToggleButton value="월간" aria-label="weekly">
                        월간
                    </ToggleButton>
                    <ToggleButton value="주간" aria-label="monthly">
                        주간
                    </ToggleButton>
                </ToggleButtonGroup>
            </div>
            <ComboBox
                style={{
                    width: "100px", float: 'right', fontSize: '0.7rem', height: 30, fontWeight: 600, position: 'absolute', top: 8, right: 8
                }}
                data={tlist}
                value={tstate}
                onChange={handletChange}
            />
            <div id="chartdiv" style={{ height: height }}></div>
        </>
    )
}

export default SafetyBarChart

const data = [
    {
        type: "넘어짐",
        2022: 78,
        2023: 88
    },
    {
        type: "부딪힘",
        2022: 37,
        2023: 57
    },
    {
        type: "끼임",
        2022: 50,
        2023: 58
    },
    {
        type: "맞음",
        2022: 56,
        2023: 53
    },
    {
        type: "떨어짐",
        2022: 24,
        2023: 15
    },
    {
        type: "베임",
        2022: 15,
        2023: 16
    },
    {
        type: "이상온도",
        2022: 14,
        2023: 18
    },
    {
        type: "깔림",
        2022: 4,
        2023: 3
    },
    {
        type: "꺾임",
        2022: 3,
        2023: 3
    },
    {
        type: "물피",
        2022: 9,
        2023: 23
    },
    {
        type: "교통사고",
        2022: 2,
        2023: 3
    },
    {
        type: "유해물질",
        2022: 9,
        2023: 10
    },
    {
        type: "화재",
        2022: 12,
        2023: 8
    },
    {
        type: "아차",
        2022: 1,
        2023: 0
    },
];

const newData = data.map(function (item) {
    var newItem = { type: item.type };

    for (var i = 1; i <= 12; i++) {
        var year = 2023;
        var month = i < 10 ? "0" + i : i.toString();
        newItem[year + "-" + month] = Math.floor(Math.random() * 81); // 0부터 80 사이의 랜덤한 정수
    }

    return newItem;
});
