import React, { useEffect } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

const NCRRateQuarterChart = ({
  height,
  data = sample_data,
  category = "cate",
  columnItems = columnItems_1,
}) => {
  useEffect(() => {
    let root = am5.Root.new("ncr-rate-quarter-chart");
    root.setThemes([am5themes_Animated.new(root)]);

    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        // wheelX: "panX",
        // wheelY: "zoomX",
        layout: root.verticalLayout,
        maxTooltipDistance: -1,
      })
    );

    chart.zoomOutButton.set("forceHidden", true);

    let tooltip = am5.Tooltip.new(root, {});

    chart.plotContainer.set("tooltipPosition", "pointer");
    chart.plotContainer.set("tooltipText", "a");
    chart.plotContainer.set("tooltip", tooltip);

    tooltip.label.adapters.add("text", function (text, target) {
      text = "";
      let i = 0;
      chart.series.each(function (series) {
        let tooltipDataItem = series.get("tooltipDataItem");

        if (tooltipDataItem) {
          if (i === 0) {
            text += "[bold]" + tooltipDataItem.get("categoryX") + "\n";
          }

          if (i !== 0) {
            text += "\n";
          }

          text +=
            "[" +
            series.get("stroke") +
            "]●[/] [width:100px]" +
            series.get("name") +
            ":[/] " +
            Number(
              (tooltipDataItem.get("valueY") ?? 0).toFixed(2)
            ).toLocaleString();
        }
        i++;
      });
      return text;
    });

    tooltip.get("background").setAll({
      stroke: am5.color(0x000000),
      strokeOpacity: 0.8,
      fill: am5.color(0xffffff),
      fillOpacity: 0.8,
    });

    // Add cursor
    // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
    let cursor = chart.set(
      "cursor",
      am5xy.XYCursor.new(root, {
        behavior: "zoomX",
      })
    );

    cursor.lineX.setAll({
      stroke: "#737373",
      strokeWidth: 1,
      strokeDasharray: [5, 5],
    });

    cursor.lineY.setAll({
      stroke: "#737373",
      strokeWidth: 1,
      strokeDasharray: [5, 5],
    });

    let legend = chart.leftAxesContainer.children.push(
      am5.Legend.new(root, {
        centerY: am5.p0,
        width: 110,
      })
    );

    legend.markers.template.setAll({
      width: 10,
      height: 10,
    });

    legend.labels.template.setAll({
      fontSize: "12px",
      fontWeight: "400",

      fill: "#737373",
    });

    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    let xRenderer = am5xy.AxisRendererX.new(root, {
      minGridDistance: 30,
      cellStartLocation: 0.1,
      cellEndLocation: 0.9,
      strokeOpacity: 0,
    });

    let xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: category,
        renderer: xRenderer,
        visible: false,
      })
    );

    xRenderer.grid.template.setAll({
      location: 1,
      strokeOpacity: 0,
    });

    xAxis.data.setAll(data);

    xAxis.get("renderer").labels.template.setAll({
      // oversizedBehavior: "fit",
      oversizedBehavior: "fit",
      textAlign: "center",
      fill: "#737373",
      maxWidth: 45,
      fontSize: "0.7rem",
    });

    let yRenderer = am5xy.AxisRendererY.new(root, {
      strokeOpacity: 0.1,
      strokeWidth: 1,
      stroke: "rgba(224, 224, 224, 0.5)",
      grid: {
        disabled: true,
      },
    });

    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        min: 0,
        // max: 100,
        renderer: yRenderer,
        visible: false,
      })
    );

    yRenderer.grid.template.setAll({
      strokeOpacity: 0,
    });

    yRenderer.labels.template.setAll({
      paddingRight: 10,
      scale: 0.7,
      fill: "#737373",
    });

    yAxis.set("numberFormat", "#,###");

    chart.yAxes.autoDispose = false;

    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/

    const makeSeries = (name, fieldName, color) => {
      let series = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: name,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: fieldName,
          categoryXField: category,
          fill: color,
          stroke: color,
          // legendLabelText: name + " ({valueY.formatNumber('#,###.##')})",
          legendRangeLabelText: name,
        })
      );

      series.data.setAll(data);

      // Make stuff animate on load
      // https://www.amcharts.com/docs/v5/concepts/animations/
      series.appear();

      series.bullets.push(() => {
        let label = am5.Label.new(root, {
          text: "{valueY}",
          fill: "#737373",
          fontSize: "12px",
          centerY: am5.p100,
          centerX: am5.p50,
          populateText: true,
          textAlign: "center",
        });

        label.adapters.add("text", function (text, target) {
          if (target.dataItem && target.dataItem.get("valueYTotal") === 0) {
            return "";
          }
          return text;
        });

        return am5.Bullet.new(root, {
          locationY: 1,
          sprite: label,
        });
      });

      legend.data.push(series);
    };

    columnItems.forEach((com) => {
      const { name, field_name, color } = com;

      makeSeries(name, field_name, color);
    });

    // Create axis ranges
    function createRange(value, endValue, color) {
      let rangeDataItem = yAxis.makeDataItem({
        value: value,
        endValue: endValue,
      });

      let range = yAxis.createAxisRange(rangeDataItem);

      if (endValue) {
        range.get("axisFill").setAll({
          fill: color,
          fillOpacity: 0.2,
          visible: true,
        });

        range.get("label").setAll({
          fill: am5.color(0xffffff),

          location: 1,
        });
      } else {
        range.get("label").setAll({
          fill: am5.color(0xffffff),
        });
      }

      range.get("grid").setAll({
        stroke: color,
        strokeOpacity: 1,
        location: 1,
      });
    }

    createRange(0, undefined, "rgba(224, 224, 224, 0.5)");

    chart.appear(1000, 100);

    return () => {
      root && root.dispose();
    };
  }, [
    data,
    columnItems,
    category,
    // actual_mark_use,
    // side_unit_lable,
  ]);

  return <div id="ncr-rate-quarter-chart" style={{ height }} />;
};

export default NCRRateQuarterChart;

const sample_data = [
  { cate: "24년 2분기", shi: 100, cnt: 100, ena: 100 },
  { cate: "24년 02월", shi: 10, cnt: 20, ena: 30 },
  { cate: "24년 03월", shi: 10, cnt: 20, ena: 30 },
  { cate: "24년 04월", shi: 10, cnt: 20, ena: 30 },
];

const columnItems_1 = [
  {
    name: "삼성중공업",
    field_name: "shi",
    color: "#03045E",
  },
  {
    name: "C사",
    field_name: "cnt",
    color: "#AADEA7",
  },
  {
    name: "E사",
    field_name: "ena",
    color: "#F2E85C",
  },
];
