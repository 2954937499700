import React, { useState, useEffect, useRef } from 'react';
import { GridLayout, GridLayoutItem } from '@progress/kendo-react-layout'
import { ComboBox } from '@progress/kendo-react-dropdowns';
import _ from 'lodash';
import dayjs from 'dayjs';
import { DropDownList } from "@progress/kendo-react-dropdowns"
import axios from "../../common/axios";
import MuiToggleButton from '@mui/material/ToggleButton';
import MuiToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

const ToggleButton = styled(MuiToggleButton)(() => ({
    '&.Mui-selected, &.Mui-selected:hover': {
        color: 'white',
        backgroundColor: '#004098!important',
    },
    '&.MuiToggleButton-root': {
        fontSize: '0.6rem',
        fontFamily: 'Pretendard-R',
        padding: 0,
        // borderRadius: '8px 0px 0px 8px!important',
        border: '1px solid rgba(0, 0, 0, 0.12)!important',
        width: 60

    }
}));

const ToggleButtonGroup = styled(MuiToggleButtonGroup)(() => ({
    '&.MuiToggleButtonGroup-root': {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        "& > *:not(:last-child)": {
            marginRight: theme.spacing(0)
        }
    }
}));


const theme = createTheme({
    palette: {
        text: {
            primary: '#00ff00',
        },
    },
});
const ProjectStockStatus = ({ height }) => {
    // const [tlist, settlist] = useState(["도착지별", "대분류별", "납품회사별"]);
    const [tableData, setTableData] = useState([]);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [selectedValue, setSelectedValue] = React.useState("사급");

    const chartRef = useRef(null);

    useEffect(() => {
        const fetchData = async () => {
            const res = await axios.get(`/getprojectmaterial?type=${selectedValue}`)
            if (res.data.success) {
                setTableData(res.data.data)
            }
        };
        fetchData();

    }, [selectedValue]);


    const handleChange = (event, selectedValue) => {
        if (selectedValue !== null) {
            setSelectedValue(selectedValue);
        }
    };
    useEffect(() => {
        let root = am5.Root.new("chartdiv");
    
        root.setThemes([
            am5themes_Animated.new(root)
        ]);
    
        let chart = root.container.children.push(am5xy.XYChart.new(root, {
            panX: false,
            panY: false,
            wheelX: "panX",
            wheelY: "zoomX",
            layout: root.verticalLayout
        }));
    
        // Create axes
        var xRenderer = am5xy.AxisRendererX.new(root, {
            cellStartLocation: 0.1,
            cellEndLocation: 0.9,
            minorGridEnabled: true
        });
    
        var xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
            categoryField: "key",
            renderer: xRenderer,
            tooltip: am5.Tooltip.new(root, {})
        }));
    
        xRenderer.grid.template.setAll({
            location: 1,
            visible: false  // 세로 그리드 선 제거
        });
    
        xAxis.data.setAll(tableData);
    
        var yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
            renderer: am5xy.AxisRendererY.new(root, {
                strokeOpacity: 0,  // Y축 선 완전히 투명하게 설정
                stroke: am5.color(0x000000),  // 혹시 모를 경우를 대비해 색상도 설정
                visible: false  // 축을 완전히 보이지 않게 설정
            })
        }));
        
        yAxis.get("renderer").grid.template.setAll({
            visible: false  // 가로 그리드 선 제거
        });
        
        // Y축 라벨 제거
        yAxis.get("renderer").labels.template.set("forceHidden", true);
    
        // Create series
        function createSeries(name, fieldName) {
            var series = chart.series.push(am5xy.ColumnSeries.new(root, {
                name: name,
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: fieldName,
                categoryXField: "key",
                tooltip: am5.Tooltip.new(root, {
                    labelText: "{name}: {valueY}"
                })
            }));
    
            series.columns.template.setAll({
                tooltipY: 0,
                strokeOpacity: 0,
                tooltipText: "{name}: {valueY}"
            });
    
            series.data.setAll(tableData);
    
            // 칼럼 위에 값 표시
            series.bullets.push(function() {
                return am5.Bullet.new(root, {
                    locationY: 0,  // 0으로 설정하여 칼럼 바로 위에 위치하도록 함
                    sprite: am5.Label.new(root, {
                        text: "{valueY}",
                        fill: am5.color(0x000000),
                        centerY: am5.p100,  // 텍스트를 아래쪽으로 정렬
                        centerX: am5.p50,
                        populateText: true,
                        fontSize: 12,
                        fontWeight: "bold"
                    })
                });
            });
    
            return series;
        }
    
        let planSeries = createSeries("계획입고량", "plan");
        let inSeries = createSeries("입고량", "in");
    
        // Add legend
        let legend = chart.children.push(am5.Legend.new(root, {
            centerX: am5.p50,
            x: am5.p50
        }));
        legend.data.setAll(chart.series.values);
    
        // Add cursor
        chart.set("cursor", am5xy.XYCursor.new(root, {
            behavior: "none"
        }));
    
        chart.appear(1000, 100);
    
        chartRef.current = root;
    
        return () => {
            root.dispose();
        };
    }, [tableData]);
    return (
        <>

            <div style={{ position: 'absolute', top: '20px', right: '12px', transform: 'translateY(-50%)' }} className='manRB'>
                <ThemeProvider theme={theme}>
                    <ToggleButtonGroup size="small" onChange={handleChange} value={selectedValue} exclusive aria-label="baseunit">
                        <ToggleButton value="지급" aria-label='지급' >
                            지급
                        </ToggleButton>
                        <ToggleButton value="사급" aria-label="사급">
                            사급
                        </ToggleButton>

                    </ToggleButtonGroup>
                </ThemeProvider>

            </div>
            <div id="chartdiv" style={{ width: "100%", height: 2 * (height / 6.5) - 50}}></div>
        </>
    )
}

export default ProjectStockStatus
