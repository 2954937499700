import React from "react";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";

const InspectionApprovalCard2 = ({ height, data }) => {
  console.log(data);
  return (
    <GridLayout
      rows={[
        {
          height: height * 0.2,
        },
        {
          height: height * 0.2,
        },
        {
          height: height * 0.2,
        },
        {
          height: height * 0.2,
        },
        {
          height: height * 0.2,
        },
      ]}
      cols={[
        {
          width: "21%",
        },
        {
          width: "15%",
        },
        {
          width: "16%",
        },
        {
          width: "16%",
        },
        {
          width: "16%",
        },
        {
          width: "16%",
        },
      ]}
      gap={{
        rows: 0,
        cols: 0,
      }}
      style={{ position: "relative" }}
      className="inspection-approval-table-body-2"
    >
      <GridLayoutItem
        row={1}
        rowSpan={5}
        col={1}
        colSpan={6}
        className="inspection-approval-table-total"
      />
      <GridLayoutItem
        row={1}
        col={1}
        colSpan={6}
        className="inspection-approval-table-bottom-border"
      />
      <GridLayoutItem
        row={2}
        col={2}
        colSpan={5}
        className="inspection-approval-table-bottom-border"
      />
      <GridLayoutItem
        row={3}
        col={1}
        colSpan={6}
        className="inspection-approval-table-bottom-border"
      />
      <GridLayoutItem
        row={4}
        col={2}
        colSpan={5}
        className="inspection-approval-table-bottom-border"
      />

      <GridLayoutItem
        row={1}
        col={1}
        colSpan={2}
        className="inspection-approval-table-item bold-text"
      >
        구분
      </GridLayoutItem>
      <GridLayoutItem
        row={2}
        rowSpan={2}
        col={1}
        className="inspection-approval-table-item bold-text "
      >
        공종별
        <br />
        합격률
      </GridLayoutItem>
      <GridLayoutItem
        row={4}
        rowSpan={2}
        col={1}
        className="inspection-approval-table-item bold-text"
      >
        부적합
        <br /> 평균 조치일
      </GridLayoutItem>
      {["감리", "중공업", "감리", "중공업"].map((c, i) => {
        return (
          <GridLayoutItem
            key={i}
            row={i + 2}
            col={2}
            className="inspection-approval-table-item"
          >
            {c}
          </GridLayoutItem>
        );
      })}

      {data.map((c, i) => {
        const {
          disc,
          supervision_approval,
          contruction_approval,
          supervision_duration,
          contruction_duration,
        } = c;

        const col_value = i + 3;

        return (
          <React.Fragment key={i}>
            <GridLayoutItem
              row={1}
              col={col_value}
              className="inspection-approval-table-item bold-text"
            >
              {disc}
            </GridLayoutItem>
            <GridLayoutItem
              row={2}
              col={col_value}
              className="inspection-approval-table-item"
            >
              <div
                style={{ display: "flex", flexDirection: "row", gap: "5px" }}
              >
                <div
                  style={{
                    fontWeight: 700,
                    color: handle_approval_text_color(supervision_approval),
                  }}
                >
                  {supervision_approval.toFixed(2)}
                </div>
                <div>%</div>
              </div>
            </GridLayoutItem>
            <GridLayoutItem
              row={3}
              col={col_value}
              className="inspection-approval-table-item"
            >
              <div
                style={{ display: "flex", flexDirection: "row", gap: "5px" }}
              >
                <div
                  style={{
                    fontWeight: 700,
                    color: handle_approval_text_color(contruction_approval),
                  }}
                >
                  {contruction_approval.toFixed(2)}
                </div>
                <div>%</div>
              </div>
            </GridLayoutItem>
            <GridLayoutItem
              row={4}
              col={col_value}
              className="inspection-approval-table-item"
            >
              <div
                style={{ display: "flex", flexDirection: "row", gap: "5px" }}
              >
                <div
                  style={{
                    fontWeight: 700,
                    color: handle_duration_text_color(supervision_duration),
                  }}
                >
                  {supervision_duration.toFixed(2)}
                </div>
                <div>일</div>
              </div>
            </GridLayoutItem>
            <GridLayoutItem
              row={5}
              col={col_value}
              className="inspection-approval-table-item"
            >
              <div
                style={{ display: "flex", flexDirection: "row", gap: "5px" }}
              >
                <div
                  style={{
                    fontWeight: 700,
                    color: handle_duration_text_color(contruction_duration),
                  }}
                >
                  {contruction_duration.toFixed(2)}
                </div>
                <div>일</div>
              </div>
            </GridLayoutItem>
          </React.Fragment>
        );
      })}
    </GridLayout>
  );
};

export default InspectionApprovalCard2;

const handle_approval_text_color = (value) => {
  if (value >= 99.2) {
    //green
    return "#1FD12F";
  }

  if (99.2 > value && value >= 99.0) {
    //yellow
    return "#F2E85C";
  }

  if (99 > value) {
    //red
    return "#F8443E";
  }
};

const handle_duration_text_color = (value) => {
  if (value > 14) {
    //red
    return "#F8443E";
  }

  if (value > 7 && value <= 14) {
    //black
    return "#737373";
  }

  if (7 >= value) {
    //green
    return "#1FD12F";
  }
};
