import React from "react";
import { Button } from "@progress/kendo-react-buttons";
import change from "./change.svg";

const ChangeButton = ({ click_event_fn }) => {
  return (
    <Button
      style={{
        width: "30px",
        height: "30px",
        borderRadius: "30px",
        border: "1px solid var(--Gray-100, #F2F2F2)",
        background: "var(--Gray-50, #F9FAFB)",
      }}
      onClick={click_event_fn}
    >
      <img
        src={change}
        alt="change"
        style={{ width: "16px", height: "16px" }}
      />
    </Button>
  );
};

export default ChangeButton;
