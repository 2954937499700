import React, { useState, useEffect, useMemo } from 'react';
import _ from 'lodash';
import {
  Card,
  CardTitle,
  GridLayout, GridLayoutItem
} from "@progress/kendo-react-layout";
import moment from 'moment';
import {
  Chart, ChartLegend,
  ChartArea,
  ChartSeries,
  ChartSeriesItem,
  ChartTooltip,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartValueAxis,
  ChartValueAxisItem,
} from "@progress/kendo-react-charts";
// import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import MuiToggleButton from '@mui/material/ToggleButton';
import MuiToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';


const ToggleButton = styled(MuiToggleButton)(() => ({
  '&.Mui-selected, &.Mui-selected:hover': {
    color: 'white',
    backgroundColor: '#4B5335',
  },
  '&.MuiToggleButton-root': {
    fontSize: '0.6rem',
    fontFamily: 'Pretendard-R',
    padding: 0,
    borderRadius: '5px 5px 5px 5px!important',
    border: '1px solid rgba(0, 0, 0, 0.12)!important',
    width: 60

  }
}));

const ToggleButtonGroup = styled(MuiToggleButtonGroup)(() => ({
  '&.MuiToggleButtonGroup-root': {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1)
    }
  }
}));

const ToggleButton2 = styled(MuiToggleButton)(() => ({
  '&.Mui-selected, &.Mui-selected:hover': {
    color: 'white',
    backgroundColor: '#FF6F61',
  },
  '&.MuiToggleButton-root': {
    fontSize: '0.6rem',
    fontFamily: 'Pretendard-R',
    padding: 0,
    width: 60,
    borderRadius: '5px 5px 5px 5px!important',
    border: '1px solid rgba(0, 0, 0, 0.12)!important'

  }
}));

const ToggleButtonGroup2 = styled(MuiToggleButtonGroup)(() => ({
  '&.MuiToggleButtonGroup-root': {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1)
    }
  }
}));


const theme = createTheme({
  palette: {
    text: {
      primary: '#00ff00',
    },
  },
});

const ManPower = ({ drdata, BoxHeight, setManData }) => {
  const location = useLocation()
  const pathname = location.pathname.split('/').pop();

  const linedata = drdata
  .filter(v => v._bp_lineitems !== undefined)
  .reduce((c, v) => c.concat(v._bp_lineitems.map(o => Object.assign(o, { ReportingDO: v.ReportingDO, PALContractorBPK: v.PALContractorBPK }))), [])
  .filter(v => v.uuu_tab_id === '투입인원')
  // .filter(item => {
  //   let itemDate = new Date(item.ReportingDO);
  //   itemDate.setHours(0, 0, 0, 0);
  //   return itemDate <= todaydate
  // })
  .map((v) => ({ ...v, uniq1: v.DRJobTitleBPK + "//" + v.ReportingDO, uniq2: v.PALContractorBPK + "//" + v.ReportingDO }))


  var gdr1 =
  _(linedata)
    .groupBy('uniq1')
    .map((objs, key) => ({
      '직종': key.split('//')[0],
      'date': key.split('//')[1].slice(0, 10),
      '인원': _.sumBy(objs, 'DRResAssignDayDA')
    }))
    .value();

    var gdr2 =
    _(linedata)
      .groupBy('uniq2')
      .map((objs, key) => ({
        '업체': key.split('//')[0],
        'date': key.split('//')[1].slice(0, 10),
        '인원': _.sumBy(objs, 'DRResAssignDayDA')
      }))
      .value();

  const today = moment().format("YYYY-MM-DD");
  const today2 = moment().add(-1, 'days').format("MM-DD-YYYY");
  const weekfirst = moment(today).startOf('week').format("YYYY-MM-DD");
  const monthfirst = moment(today).startOf('month').format("YYYY-MM-DD");

  var getDaysArray = function (start, end) {
    for (var arr = [], dt = new Date(start); dt <= new Date(end); dt.setDate(dt.getDate() + 1)) {
      arr.push(new Date(dt));
    }
    return arr;
  };

  var daylistofweek = getDaysArray(new Date(weekfirst), new Date(today)).map(v => moment(v).format("MM-DD-YYYY"));
  var daylistofmonth = getDaysArray(new Date(monthfirst), new Date(today)).map(v => moment(v).format("MM-DD-YYYY"));

  const total_man_day = _.sumBy(gdr1.filter(v => v.date === today2), '인원')
  const total_man_week = _.sumBy(gdr1.filter(v => daylistofweek.includes(v.date)), '인원')
  const total_man_month = _.sumBy(gdr1.filter(v => daylistofmonth.includes(v.date)), '인원')

  const [chartxType, setchartxType] = useState([]);
  const [chartColData, setChartColData] = useState([]);
  const [chartLineData, setChartLineData] = useState([]);
  const [chartmin, setChartmin] = useState({});
  const [chartmax, setChartmax] = useState({});
  const [baseunit, setbaseunit] = useState('days');
  const [selectedValue, setSelectedValue] = React.useState("daily");
  const [selectedValue2, setSelectedValue2] = React.useState("종류");
  // const handleChange = React.useCallback(
  //     (e) => {
  //         setSelectedValue(e.value);
  //     },
  //     [setSelectedValue]
  // );
  const handleChange = (event, selectedValue) => {
    if (selectedValue !== null) {
      setSelectedValue(selectedValue);
    }
  };


  const handleChange2 = (event, selectedValue2) => {
    if (selectedValue2 !== null) {
      setSelectedValue2(selectedValue2);
    }
  };
  useEffect(() => {
    const linedata = drdata.filter(v=>v._bp_lineitems !== undefined).reduce((c, v) => c.concat(v._bp_lineitems.map(o => Object.assign(o, { ReportingDO: v.ReportingDO,PALContractorBPK:v.PALContractorBPK }))), [])
    .filter(v=>v.uuu_tab_id ==='투입인원').map((v)=>({...v, uniq1:v.DRJobTitleBPK+"//"+v.ReportingDO, uniq2:v.PALContractorBPK+"//"+v.ReportingDO}))

    const chartdata = 
    selectedValue2 === '직종' ?
    _(linedata)
      .groupBy("uniq1")
      .map((objs, key) => ({
        date: new Date(key.split('//')[1].slice(0, 10)),
        xtype: key.split("//")[0].split(' | ')[1],
        count: _.sumBy(objs, "DRResAssignDayDA"),
      }))
      .value() :

      _(linedata)
      .groupBy("uniq2")
      .map((objs, key) => ({
        date: new Date(key.split('//')[1].slice(0, 10)),
        xtype: key.split("//")[0],
        count: _.sumBy(objs, "DRResAssignDayDA"),
      }))
      .value()

    const man_result = _.sortBy(
      chartdata.map(function (obj) {
        var index = obj.xtype;
        obj[index] = obj.count;
        return obj;
      }),
      "date"
    );
    


    const linechartdata = _(chartdata)
      .groupBy("date")
      .map((objs, key) => ({
        date: key,
        count: _.sumBy(objs, "count"),
      }))
      .value();

    const xtype_list = _.uniqBy(chartdata, 'xtype');

    const max =
      man_result[man_result.length - 1] &&
      moment(man_result[man_result.length - 1]["date"]).add(1, 'days');

    const min =
      man_result[man_result.length - 1] &&
      new Date(
        moment(new Date(man_result[man_result.length - 1]["date"]))
          .subtract(63, "days")
          .format("YYYY-MM-DD")
      );

    const min2 =
      man_result[man_result.length - 1] &&
      new Date(
        moment(new Date(man_result[man_result.length - 1]["date"]))
          .subtract(7, "days")
          .format("YYYY-MM-DD")
      );


    setchartxType(xtype_list);
    setChartColData(man_result);
    setChartLineData(linechartdata);
    setChartmin(selectedValue === 'daily' ? min2 : min);
    setChartmax(max);

    setbaseunit(selectedValue === 'daily' ? 'days' : selectedValue === 'weekly' ? 'weeks' : selectedValue === 'monthly' ? 'months' : '')
    setManData(man_result)

  }, [drdata, selectedValue,selectedValue2]);

  const COLORS = [
    "#FFA500",
    "#56C6A9",
    "#6F9FD8",
    "#E8A798",
    "#D9CE52",
    "#D19C97",
    "#ECDB54",
    '#D2C29D',
    '#F0EAD6',
    '#D69C2F',
    '#FFD662',
    '#E8A798',
    '#95DEE3',
    '#ECDB54',
    '#EC9787'
  ];

  const labelContent2 = (e) =>
    e.value === 0 || e.value === null || typeof e.value === "undefined"
      ? ""
      : e.value.toLocaleString();

  const seriesLabels2 = {
    visible: true,
    // Note that visible defaults to false
    padding: 2,
    font: "normal 0.6rem Pretendard-R",
    position: "center",
    background: "none",
    content: labelContent2,
  };

  const labelContent = (e) =>
    e.value === 0 || e.value === null || typeof e.value === "undefined"
      ? ""
      : e.value.toLocaleString();

  const seriesLabels = {
    visible: true,
    // Note that visible defaults to false
    padding: 2,
    font: "bold 0.6rem Pretendard-R",
    position: "top",
    background: "none",
    content: labelContent,
  };

  const labelContent_0 = (e) => {
    return e.value === 0 || e.value === null || typeof e.value === "undefined"
      ? ""
      : selectedValue ==='monthly'
      ? moment(e.value).format("YY년 MM월")
      : 
      selectedValue ==='weekly'?
      moment(e.value).format("MM월") +
        " " +
        Math.ceil(e.value.getDate() / 7) +
        "주":moment(e.value).format("YY년 MM월 DD일")
  };

  const seriesLabels_0 = {
    visible: true,
    // Note that visible defaults to false
    padding: 0,
    font: "0.8rem Pretendard-R",
    position: "top",
    background: "none",
    rotation: { angle: "auto" },
      content: labelContent_0,
  };

  const SharedTooltip = (props) => {
    const { points } = props;
    return (
      <div>
        {selectedValue === 'monthly' ? (
          <div>{moment(points[0].category).format("YY년 MM월")}</div>
        ) :
          selectedValue === 'weekly' ?
            (
              <div>
                {moment(points[0].category).format("YY년 MM월") +
                  " " +
                  Math.ceil(points[0].category.getDate() / 7) +
                  "주"}
              </div>
            )
            : (<div>{moment(points[0].category).format("YY년 MM월 DD일")}</div>)
        }
        {points.map(function (point, index) {
          return point.series.field === "count" ? (
            <div key={index}>
             합계: {point.value.toLocaleString()}
            </div>
          ) : (
            <div key={index}>
              {point.series.name} : {point.value.toLocaleString()}
            </div>
          );
        })}
      </div>
    );
  };

  const sharedTooltipRender = (context) => <SharedTooltip {...context} />;


  return (
    <>
        <div style={{fontSize: '0.9rem',top:'8px' ,color: '#F1F2F5', zIndex: '9999999' ,position: 'absolute', fontWeight: '500', right: '12px'}}>{moment().format('YYYY년 MM월 DD일')}</div>

            <div style={{ marginLeft: '12px', position: 'absolute', top: '80px', transform: 'translateY(-50%)' }}>
              {selectedValue === 'daily' ?
                <div><span style={{ fontSize: '1.1rem', color: '#363945', fontWeight: '600' }}> 전일 </span><span style={{ fontSize: '1.6rem', color: '#363945', fontWeight: '600' }}>{total_man_day.toLocaleString()}</span> <span style={{ fontSize: '1.1rem', color: '#363945', fontWeight: '600' }}> 명 </span></div> :
                selectedValue === 'weekly' ?
                  <div><span style={{ fontSize: '1.1rem', color: '#363945', fontWeight: '600' }}> 이번 주 </span><span style={{ fontSize: '1.6rem', color: '#363945', fontWeight: '600' }}>{total_man_week.toLocaleString()}</span> <span style={{ fontSize: '1.1rem', color: '#363945', fontWeight: '600' }}> 명 </span></div> :
                  selectedValue === 'monthly' ?
                    <div><span style={{ fontSize: '1.1rem', color: '#363945', fontWeight: '600' }}> 이번 달 </span><span style={{ fontSize: '1.6rem', color: '#363945', fontWeight: '600' }}>{total_man_month.toLocaleString()}</span> <span style={{ fontSize: '1.1rem', color: '#363945', fontWeight: '600' }}> 명 </span></div> : ''
              }
            </div>
           
    {pathname ==="overview" ? (    
        <>
      <div style={{ position: 'absolute', right: '12px', transform: 'translateY(-50%)' }} className='manRB'>
        <ThemeProvider theme={theme}>
          <ToggleButtonGroup size="small" onChange={handleChange} value={selectedValue} exclusive aria-label="baseunit">
            <ToggleButton style={{ fontSize:"0.5rem", width:"50px"}} value="daily" aria-label='daily' >
              daily
            </ToggleButton>
            <ToggleButton style={{ fontSize:"0.5rem", width:"50px"}} value="weekly" aria-label="weekly">
              weekly
            </ToggleButton>
            <ToggleButton style={{ fontSize:"0.5rem", width:"50px"}} value="monthly" aria-label="monthly">
              monthly
            </ToggleButton>
          </ToggleButtonGroup>
        </ThemeProvider>
      </div>
      <div style={{ position: "absolute", top: "90px", right: '12px', transform: 'translateY(-50%)', zIndex: 999999 }}>
      <ThemeProvider theme={theme}>
        <ToggleButtonGroup2 size="small" onChange={handleChange2} value={selectedValue2} exclusive aria-label="baseunit">
          <ToggleButton2 style={{ fontSize:"0.5rem", width:"50px"}} value="종류" aria-label='종류' >
          직종
          </ToggleButton2>
          <ToggleButton2 style={{ fontSize:"0.5rem", width:"50px"}} value="업체별" aria-label="업체별">
            업체별
          </ToggleButton2>
        </ToggleButtonGroup2>
      </ThemeProvider>
      </div>
      </>) : (   
        <> 
      <div style={{ position: 'absolute', right: '12px', transform: 'translateY(-50%)' }} className='manRB'>
        <ThemeProvider theme={theme}>
          <ToggleButtonGroup size="small" onChange={handleChange} value={selectedValue} exclusive aria-label="baseunit">
            <ToggleButton value="daily" aria-label='daily' >
              daily
            </ToggleButton>
            <ToggleButton value="weekly" aria-label="weekly">
              weekly
            </ToggleButton>
            <ToggleButton value="monthly" aria-label="monthly">
              monthly
            </ToggleButton>
          </ToggleButtonGroup>
        </ThemeProvider>
      </div>
      <div style={{ position: "absolute", top: "90px", right: '12px', transform: 'translateY(-50%)', zIndex: 999999 }}>
      <ThemeProvider theme={theme}>
        <ToggleButtonGroup2 size="small" onChange={handleChange2} value={selectedValue2} exclusive aria-label="baseunit">
          <ToggleButton2 value="종류" aria-label='종류' >
            직종
          </ToggleButton2>
          <ToggleButton2 value="업체별" aria-label="업체별">
            업체별
          </ToggleButton2>
        </ToggleButtonGroup2>
      </ThemeProvider>
      </div>
    </>
      )}
            <Chart
            // transitions={false}
              pannable={{
                lock: "y",
              }}
              zoomable={{
                mousewheel: {
                  lock: "y",
                },
                selection: {
                  lock: "y",
                },
              }}
              style={{position:'absolute', top:'80px'}}
            >
              <ChartArea height={BoxHeight - 62} margin={{ bottom: 5, top: 10 }} />
              <ChartLegend position="bottom" orientation="horizontal" labels={{font:'0.8rem Pretendard-R'}} visible={pathname === "overview" ? false : true}/>
              <ChartTooltip shared={true} render={sharedTooltipRender} />
              <ChartSeries>
                <ChartCategoryAxis>
                  <ChartCategoryAxisItem
                    min={chartmin}
                    max={chartmax}
                    baseUnit={baseunit}
                    labels={seriesLabels_0}
                    majorGridLines={{ step: 1000000 }}
                  // baseUnitStep='auto' 
                  // maxDateGroups={9} 
                  />
                </ChartCategoryAxis>
                <ChartValueAxis>
                  <ChartValueAxisItem labels={{ visible: false }} majorGridLines={{ step: 1000000 }} visible={false} line={{ visible: false }}></ChartValueAxisItem>
                </ChartValueAxis>
                {chartxType.map((v, index) => (
                  <ChartSeriesItem
                    aggregate="sum"
                    key={index}
                    type="column"
                    stack={true}
                    data={chartColData}
                    field={v.xtype}
                    categoryField="date"
                    labels={seriesLabels2}
                    color={COLORS[index]}
                    border={{ width: '0' }}
                    name={v.xtype}
                  />
                ))}

                <ChartSeriesItem
                  type="line"
                  aggregate="sum"
                  data={chartLineData}
                  field="count"
                  categoryField="date"
                  labels={seriesLabels}
                  color='lightgrey'
                  name='total'
                />

              </ChartSeries>
            </Chart>

    </>
  );
};
export default ManPower;