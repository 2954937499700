import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import axios from "../../common/axios";
import dayjs from 'dayjs';
import {
  Container,
  Typography,
  Box,
  Button,
  Snackbar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import SendIcon from '@mui/icons-material/Send';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import {
  DataGridPremium,
  GridToolbarContainer,
  useGridApiRef,
  gridClasses, GridToolbarQuickFilter
} from '@mui/x-data-grid-premium';
import { styled } from '@mui/material/styles';

// DataGrid 스타일 커스터마이징
const StyledDataGridPremium = styled(DataGridPremium)(({ theme }) => ({
  [`& .${gridClasses.row}.new-row`]: {
    backgroundColor: '#ebffff',
    '&:hover': {
      backgroundColor: '#ebffff',
    },
  },
  [`& .${gridClasses.cell}.editable-cell`]: {
    backgroundColor: '#ebffff',
    fontStyle: 'italic',
  },
  width: "100%",
  height: 700,
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  borderRadius: '8px',
  overflow: 'hidden',
  '& .MuiDataGrid-cell:hover': {
    color: 'primary.main',
  },
  '& .highlight-edit-mode': {
    backgroundColor: '#ebffff !important',
    fontStyle: 'italic',
  },
  '& .new-row': {
    backgroundColor: '#ebffff !important',
  },
  '& .new-row.highlight-edit-mode': {
    backgroundColor: '#ebffff !important',
  },
}));

const NotiPage = () => {
  const [rows, setRows] = useState([]);
  const [user, setUser] = useState("");
  const [shellList, setShellList] = useState([]);
  const [objectList, setObjectList] = useState([]);
  const [initialRows, setInitialRows] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [snackbarState, setSnackbarState] = useState({ open: false, message: '' });
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [sendValid, setSendValid] = useState(false);
  const apiRef = useGridApiRef();
  const [newRowId, setNewRowId] = useState(null);
  const [selectedShell, setSelectedShell] = useState('');


  const columns = useMemo(() => [
    {
      field: 'isNew',
      headerName: 'New',
      width: 100,
      renderCell: (params) => params.row.isNew ? '✨New✨' : '',
    },
    { field: 'id', headerName: 'ID', width: 100 },
    {
      field: 'processed',
      headerName: 'processed',
      width: 120,
      editable: true,
      type: 'singleSelect',
      valueOptions: ['false', 'true', 'error'],
      isNew: true,
    },
    {
      field: 'shell_number', headerName: 'Shell Number', width: 150, editable: true, type: 'singleSelect', valueOptions: shellList

    },
    { field: 'object_name', headerName: 'Object Name', width: 300, editable: true, type: 'singleSelect', valueOptions: objectList },
    { field: 'record_no', headerName: 'Record Number', width: 150, editable: true },
    { field: 'event_date', headerName: 'Event Date', width: 200, editable: false },
    { field: 'old_status', headerName: 'Old Status', width: 200, editable: false },
    { field: 'new_status', headerName: 'New Status', width: 200, editable: true },
  ], [shellList, objectList]);



  const handleRowChange = useCallback((newRow, oldRow) => {
    if (JSON.stringify(newRow) !== JSON.stringify(oldRow)) {
      setHasUnsavedChanges(true);
      setSendValid(false);
    }
  }, []);

  // 이벤트 가져오기
  const fetchEvents = useCallback(async () => {
    // 변경사항 초기화
    setRows(initialRows);
    setIsLoading(true);
    try {
      const response = await axios.get('/get-events');
      setRows(response.data);
      setInitialRows(response.data);
      setHasUnsavedChanges(false);
    } catch (error) {
      console.error('Error fetching events:', error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchEvents();
  }, [fetchEvents]);


  useEffect(() => {
    const fetchData = async () => {
      const res = await axios.get(`/shells`);
      const updatedShellList = [null, ...res.data.data]; // 맨 앞에 빈칸 추가
      setShellList(updatedShellList);
    };
    fetchData();
  }, []);



  useEffect(() => {
    try {
        const fetchData = async () => {
            const res = await axios.get(`/dashcurrent_user`);
            setUser(`${res.data.name} insert by manual`)
        };
        fetchData();
    } catch (error) {
        console.error('데이터 가져오기 중 오류 발생:', error);
    }

}, []);

  useEffect(() => {
    const fetchObjectList = async () => {
      try {
        const res = await axios.get(`/objects?project=${selectedShell}`);
        setObjectList(res.data.data);
      } catch (error) {
        console.error("Error fetching object list:", error);
        // 에러 처리 로직 (예: 사용자에게 알림)
      }
    };
    fetchObjectList();
  }, [selectedShell]);

  const processRowUpdate = useCallback((newRow, oldRow) => {

    const updatedRow = { ...newRow, isNew: oldRow?.isNew || false };
    // 변경사항이 있으면 저장 버튼 활성화, DB 전송 버튼 비활성화

    // shell_number가 변경되었을 때 setSelectedShell 호출
    if (newRow.shell_number !== oldRow.shell_number) {
      setSelectedShell(newRow.shell_number);
    }

    handleRowChange(newRow, oldRow);
    setRows((prevRows) => prevRows.map((row) => (row.id === updatedRow.id ? updatedRow : row)));
    setHasUnsavedChanges(true);
    return updatedRow;
  }, []);

  const handleAddRecord = useCallback(() => {
    const newId = Date.now();
    const newRow = {
      id: newId,
      processed: 'false',
      isNew: true,
      old_status:user
    };

    setRows(prevRows => [newRow, ...prevRows]);
    setNewRowId(newId);
    setHasUnsavedChanges(true);
  }, [user]);

  // 새로 추가된 행이 생성되면 셀 편집 모드로 전환
  // processRowUpdate 함수가 newRow를 인식 못하는 문제 해결
  useEffect(() => {
    if (newRowId) {
      setTimeout(() => {
        apiRef.current.startCellEditMode({ id: newRowId, field: 'shell_number' });
        setNewRowId(null);
      }, 0);
    }
  }, [newRowId, apiRef]);

  // 변경 사항 저장 및 중복 체크
  const saveAll = useCallback(() => {
    const addedRows = rows.filter(row => row.isNew);
    const existingRows = rows.filter(row => !row.isNew);

    let duplicates = [];

    // 새로 추가된 행들과 기존 행들 사이의 중복 체크
    addedRows.forEach(newRow => {
      const isDuplicate = existingRows.some(existingRow =>
        existingRow.shell_number === newRow.shell_number &&
        existingRow.record_no === newRow.record_no &&
        existingRow.new_status === newRow.new_status
      );

      if (isDuplicate) {
        duplicates.push(newRow);
      }
    });

    const requiredFields = ['shell_number', 'new_status', 'record_no',];
    const requiredFieldsName = ['Shell Number', "New Status", "Record Number"]

    const missingFields = addedRows.filter((row) =>
      requiredFields.some((field) => !row[field])
    );

    if (missingFields.length > 0) {
      const missingFieldsMessage = missingFields
        .map((row) => {
          const missingFieldNames = requiredFields
            .map((field, index) => (!row[field] ? requiredFieldsName[index] : null))
            .filter((name) => name !== null)
            .join(', ');

          return `Row ${row.id}: ${missingFieldNames}`;
        })
        .join('\n');

      setSnackbarState({
        open: true,
        message: `필수값이 없습니다:\n${missingFieldsMessage}`,
      });
      return;
    }

    // 새로 추가된 행들 사이의 중복 체크
    for (let i = 0; i < addedRows.length; i++) {
      for (let j = i + 1; j < addedRows.length; j++) {
        if (
          addedRows[i].shell_number === addedRows[j].shell_number &&
          addedRows[i].record_no === addedRows[j].record_no &&
          addedRows[i].new_status === addedRows[j].new_status
        ) {
          if (!duplicates.includes(addedRows[i])) duplicates.push(addedRows[i]);
          if (!duplicates.includes(addedRows[j])) duplicates.push(addedRows[j]);
        }
      }
    }

    if (duplicates.length > 0) {
      console.log('Duplicates found:', duplicates);

      const duplicateInfo = duplicates.map(row =>
        `Shell Number: ${row.shell_number}, Record Number: ${row.record_no}, New Status: ${row.new_status}`
      ).join('; ');

      setSnackbarState({
        open: true,
        message: `Duplicates found: ${duplicateInfo}. \n Please remove duplicates before saving.`
      });
      setSendValid(false);
    } else {

      setHasUnsavedChanges(false);
      setSnackbarState({
        open: true,
        message: 'No duplicates found. Changes saved. Click "Send to DB" to update the database.'
      });
      setSendValid(true);
    }
  }, [rows]);

  // 오래된 이벤트 삭제
  const handleRemoveOldEvents = useCallback(async () => {
    setOpenConfirmDialog(false);
    try {
      const response = await axios.post('/events/remove-old');
      setSnackbarState({
        open: true,
        message: `Removed ${response.data.removedCount} old events`
      });
      fetchEvents();
    } catch (error) {
      console.error('Error removing old events:', error);
      setSnackbarState({
        open: true,
        message: error.response?.data?.message || error.message
      });
    }
  }, [fetchEvents]);

  // initialRows랑 최종 rows랑 비교해서 변경사항만 DB로 전송
  const handleSendToDB = useCallback(async () => {
    try {
      const requiredFields = ['object_name', 'record_no', 'event_date', 'new_status'];

      const formatDate = (dateString) => {
        return new Date(dateString).toISOString().slice(0, 19).replace('T', ' ');
      };

      // initialRows와 현재 rows를 비교하여 변경된 행만 추출
      const changedRows = rows.filter(row => {
        const initialRow = initialRows.find(ir => ir.id === row.id);
        return !initialRow || JSON.stringify(initialRow) !== JSON.stringify(row);
      });

      // 변경된 행들의 필수 필드 검증
      changedRows.forEach(row => {
        for (const field of requiredFields) {
          if (!row[field] || row[field].toString().trim() === '') {
            throw new Error(`Field ${field} cannot be empty in record`);
          }
        }
        row.event_date = formatDate(row.event_date);
      });


      // 백엔드로 변경된 행들을 한 번에 전송
      const response = await axios.post('/events/bulk-update',
      changedRows.map(row => ({
        ...row,
        id: row.isNew ? '' : row.id
      }))
    );

      // 변경사항 초기화 및 데이터 새로고침
      setHasUnsavedChanges(false);
      setSnackbarState({
        open: true,
        message: `Changes sent to DB successfully. Added: ${response.data.addedCount}, Updated: ${response.data.updatedCount}`
      });
      setSendValid(false);
      fetchEvents();
    } catch (error) {
      console.error('Error sending changes to DB:', error);
      setSnackbarState({
        open: true,
        message: error.response?.data?.message || error.message
      });
    }
  }, [rows, initialRows, fetchEvents]);

  const CustomToolbar = useMemo(() => {
    return () => (
      <GridToolbarContainer>
        <Button color="primary" startIcon={<AddIcon />} onClick={handleAddRecord}>
          Add Record
        </Button>
        <Button color="primary" startIcon={<SaveIcon />} onClick={saveAll} disabled={!hasUnsavedChanges}>
          Save All
        </Button>
        <Button color="primary" startIcon={<SendIcon />} onClick={handleSendToDB} disabled={!sendValid}>
          Send to DB
        </Button>
        <Button color="primary" startIcon={<RefreshIcon />} onClick={fetchEvents} disabled={isLoading}>
          Refresh
        </Button>
        <Button color="secondary" startIcon={<CleaningServicesIcon />} onClick={() => setOpenConfirmDialog(true)}>
          Clean Old Events
        </Button>
        <GridToolbarQuickFilter style={{ marginRight: '10px' }} />
      </GridToolbarContainer>
    );
  }, [handleAddRecord, saveAll, hasUnsavedChanges, handleSendToDB, sendValid, fetchEvents, isLoading]);

  const handleCellEditCommit = useCallback((params) => {
    const updatedRow = { ...params.row, [params.field]: params.value };
    processRowUpdate(updatedRow, params.row);
  }, [processRowUpdate]);

  return (
    <Container maxWidth={false} disableGutters>
      <Box
        sx={{
          height: 'calc(100vh - 120px)',
          backgroundColor: '#f9f9f9',
          borderRadius: "16px",
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          padding: '25px',
          overflow: 'auto',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        }}
      >
        <Typography variant="h4" component="h1" gutterBottom sx={{ marginBottom: '20px' }}>
          Event Notifications
        </Typography>

        <StyledDataGridPremium
          getRowClassName={(params) => params.row.isNew ? 'new-row' : ''}
          getCellClassName={(params) => {
            if (params.row.isNew && !['id', 'processed', 'isNew'].includes(params.field)) {
              return 'editable-cell';
            }
            return '';
          }}
          rows={rows}
          rowHeight={40}
          columns={columns}
          slots={{
            toolbar: CustomToolbar
          }}
          apiRef={apiRef}
          processRowUpdate={processRowUpdate}
          editMode="cell"
          disableSelectionOnClick
          // disableColumnMenu
          cellSelection
          pagination
          autoPageSize
          disableRowSelectionOnClick
          density="compact"
          isCellEditable={(params) => {
            return params.row.isNew === true || params.field === 'processed';
          }}
          onCellEditCommit={handleCellEditCommit}

        />
      </Box>
      <Dialog
        open={openConfirmDialog}
        onClose={() => setOpenConfirmDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Clean Old Events"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" sx={{ fontSize: 16, fontWeight: 'bold' }} >
            정말로 지난 이벤트를 삭제하시겠습니까? 이 작업은 되돌릴 수 없습니다.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenConfirmDialog(false)} color="primary" autoFocus sx={{ fontSize: 14, fontWeight: 'bold' }}>
            Cancel
          </Button>
          <Button onClick={handleRemoveOldEvents} color="primary" sx={{ fontSize: 14, fontWeight: 'bold' }}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbarState.open}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={() => setSnackbarState(prev => ({ ...prev, open: false }))}
        message={snackbarState.message}
      />
    </Container>
  );
};

export default NotiPage;