import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import 'hammerjs';
import axios from "../../../../common/axios";
import SCurveChart from '../../../common/SCurveChart';
import { GridLayout, GridLayoutItem } from '@progress/kendo-react-layout';
import moment from 'moment';
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import {
    IntlProvider,
    LocalizationProvider,
    loadMessages,
} from "@progress/kendo-react-intl";
import loadCldrData from "../../../common/loadCldrData";
import krMessages from "../../../common/kr.json";
import { Circles } from 'react-loader-spinner';
import { ToggleButton, ToggleButtonGroup } from '../../../common/CustomToggleButton';
import dayjs from 'dayjs';

loadCldrData();
loadMessages(krMessages, "ko-KR");


const VProgressChart = ({height, width}) => {

    const { project_code } = useParams();
    const [progressData, setProgressData] = useState([]);
    const [selectedVendor, setSelectedVendor] = useState(null);
    const [actualpercent, setActualpercent] = useState(0);
    const [planpercent, setPlanpercent] = useState(0);
    const [checked, setChecked] = React.useState("월간");
    const [vendorLists, setVendorLists] = useState([]);
    const [loading, setLoading] = useState(true);

    const handleChangeX =  (event, selectedValue) => {
        if (selectedValue !== null) {
            setChecked(selectedValue);
        }
    };



    useEffect(() => {
        setLoading(true);

        const fetchData = async (reportName) => {
            try {
                const res = await axios.get(`/getprogressv?project=${project_code}&value=${selectedVendor}`);
                setProgressData(checked === "월간"?res.data.month:res.data.week);
                setPlanpercent(res.data.planpercent)
                setActualpercent(res.data.actualpercent);
                setLoading(false);

            } catch (error) {
                console.error(`${reportName} 데이터 가져오기 중 오류 발생:`, error);
                return []; // 오류 발생 시 빈 배열 반환
            }
        };
        fetchData();
    }, [ project_code, selectedVendor, checked]);

    useEffect(() => {
        const fetchData = async () => {
            let body =
            {
                "bpname": "업체 등록",
                "lineitem": "no",
                "filter_criteria": {
                    "join": "AND",
                    "filter": [
                        {
                            "field": "status",
                            "value": 'Active',
                            "condition_type": "eq"
                        },

                    ]
                }
            }
            const res = await axios.post(`/getbprecordsdash?path=${project_code}`, body)
            if(res.data.success){
            const vendors = res.data.data.data.map(v => (v.uveVendorNameTB50))
            setVendorLists(vendors)}
        }
        fetchData()
    }, [project_code])

    const handleChange = (event) => {
        setSelectedVendor(event.target.value);
    };

    const columnItems = [
        {
          name: checked ==="월간"?"월간 계획":"주간 계획",
          field_name: "PV2",
          color: "#EB7A25",
        },
        {
          name:  checked ==="월간"?"월간 실적":"주간 실적",
          field_name: "EV2",
          color: "rgb(103, 148, 220)",
        },
        
      ];
       
      const lineItems = [
        {
          name: "누적 계획",
          field_name: "CC_PV",
          color: "#EB7A25",
        },
        {
          name: "누적 실적",
          field_name: "CC_EV",
          color: "rgb(103, 148, 220)",
        },
    ];    
    
    if (loading) {
        return <div className="loadingContainer">
        <Circles ariaLabel="loading" color="#00BFFF" height={100} width={100} />
      </div>;;
      }
    
      const maxWidth =progressData &&  width*0.8/progressData.length
    
      const tooltiptitle = `${dayjs().add(-1,"days").format('YYYY년 MM월 DD일')}까지 일할 계산된 계획값입니다.`
      const tooltiptitle2 = `실적은 최신 실적(주로 전일 작업일보)을 한시간마다 집계합니다.`
    return(
        <>

            <GridLayout style={{ height:(height/6.5)*4-50 }}
                rows={[

                    {
                        height: '20%',
                    },
                    {
                        height: '80%',
                    },
                ]}
                cols={[
                    {
                        width: '20%',
                    }, {
                        width: '80%',
                    },
                ]}
                gap={{
                    rows: 0,
                    cols: 0,
                }}>
                <div style={{ position: 'absolute', top: '78px', right: '12px', transform: 'translateY(-50%)', zIndex: '99999999' }} >
                        <ToggleButtonGroup size="small" onChange={handleChangeX} value={checked} exclusive aria-label="baseunit">

                            <ToggleButton value="월간" aria-label="weekly">
                                월간
                            </ToggleButton>
                            <ToggleButton value="주간" aria-label="monthly">
                                주간
                            </ToggleButton>
                        </ToggleButtonGroup>
                </div>

                <GridLayoutItem col={1} row={1}>
                    <div style={{ padding: 10 }}>
                        <div style={{ marginLeft: '5px' }}>업체명</div>
                        <ComboBox
                            style={{
                                width: "100%", marginRight: '5px'
                            }}
                            data={vendorLists}
                            value={selectedVendor}
                            onChange={handleChange}
                            className='disc'
                        />
                    </div>
                </GridLayoutItem>
                <GridLayoutItem col={1} row={2}>
          
                    <div style={{ width: '100%', height:((height/6.5)*4-50)*0.8 }}>
                        <div style={{ position: 'relative' }}>
                            <div style={{ position: 'absolute', top: '50px', left: '50%', transform: 'translateX(-50%)' }}>
                                <div style={{ textAlign: 'center', color: '#363945' }}>Dev.</div>

                                <div style={{ textAlign: 'center', fontSize: '2rem', fontWeight: 'bold' }}>
                                <span style={{ color: actualpercent - planpercent > 0 ? '#00539C' : '#9E1030' }}>{(actualpercent - planpercent).toFixed(2)}</span>%</div>
                            </div>
                                <div style={{ position: 'absolute', top:  ((height/6.5)*4-50)*0.8 -200, left: '8px' }}>
                                    <div style={{ color: '#939597', fontSize: '1rem', float: 'left' }}title={tooltiptitle}>Plan</div>
                                    <div style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>  {planpercent && planpercent.toFixed(2)}%</div>
                                </div>
                                <div style={{ position: 'absolute', top: ((height/6.5)*4-50)*0.8 -200, right: '8px' }}>
                                <div style={{ textAlign: 'center', color: '#939597', fontSize: '1rem', float: 'right' }} title={tooltiptitle2}>Actual</div>
                                <div style={{ textAlign: 'center', fontSize: '1.5rem', fontWeight: 'bold', color: '#525252' }}>{actualpercent && actualpercent.toFixed(2)}%</div>
                                </div>
                            <div style={{ position: 'absolute', top: ((height/6.5)*4-50)*0.8 -84, left: '50%', transform: 'translateX(-50%)' ,fontSize:'0.8rem'}}>{moment().add(-1, 'days').format('YYYY년 MM월 DD일')} 기준  </div>

                        </div>
                    </div>
                </GridLayoutItem>
                <GridLayoutItem col={2} row={1} rowSpan={2}>
                {progressData && (
                <SCurveChart data={progressData} maxWidth={maxWidth} columnItems={columnItems} lineItems={lineItems}chart_id={"scurve"} height={(height/6.5)*4-50}category={"date"}lineLabel={true}  />
            )}
                </GridLayoutItem>

                </GridLayout>

        </>
    );

}

export default VProgressChart
